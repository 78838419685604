import React, { Suspense } from "react";
import { Button, Col, Row, Tooltip } from "antd";
import { useCurrencyContext } from "../../../common/providers/CurrencyProvider";
import { useHistory } from "react-router-dom";
import Flight, { FlightSelection, FlightSe } from "../Flight/RoundtpSeFlight";
import CustomNoResultFound from "../../ErrorPages/CustomNoResultFound";
import { useEffect, useState } from "react";
import "../SeperatedView/SeperatedView.scss";
import { useSytContext } from "../../../common/providers/SytProvider";
import InfiniteScroll from "react-infinite-scroll-component";
import CustomFliImg from "../../../common/CustomImg/CusFliLogo";
import { Modal } from 'antd';
import APIClient from "../../../helpers/ApiClient";
import moment from "moment";
import RoundSort from "../SortBy/RoundSort";
const SeparatedFlight = ({
    flightSearchObj,
    selectedFlight,
    combinedOnewayFlightList,
    combinedReturnList,
    changeFlightResultsView,
    setOnwardFlights,
    changeReturnFlights,
    setFlightFareRules,
    journeyType,
    showNetFare,
    promoData,
    setMultiCityIndex,
    MultiCityIndex,
    changeMulticityResults,
    traceId,
    updateFlightAirSearchRespObj,
    airlineMatrixReset,
    ResetAirlineMatrix,
    setShowNetFare,
    flightAirSearchResp,
    selectedTripType,
}) => {

    const { flightSearchBanners } = useSytContext();
    const PromoData = promoData;


    const flightResultLength = 10;
    const [displayFlightResult, updateDisplayFlightResult] = useState([])
    const [flightList, setFlightList] = useState([]);
    const [displayFlightRResult, updateDisplayFlightRResult] = useState([]);
    const [combinedArray, SetCombinedArray] = useState([]);
    const [flightRList, setFlightRList] = useState([]);
    const [activeCollapseOnw, setActiveCollapseOnw] = useState("");
    const [activeCollapse, setActiveCollapse] = useState("");
    //  console.log(flightList, flightRList, displayFlightRResult, displayFlightResult, "list123");
    const fetchMoreflights = () => {
        setTimeout(() => {
            updateDisplayFlightResult((prev) => [...prev, ...flightList.slice(prev.length, prev.length + flightResultLength)])
            updateDisplayFlightRResult((prev) => [...prev, ...flightRList.slice(prev.length, prev.length + flightResultLength)])

        }, 100)
    }
    const setMulticityIndex = (index) => {
        setMultiCityIndex(index);
    }
    // console.log(combinedOnewayFlightList, combinedReturnList, "comb");
    useEffect(() => {

        let visibleData = combinedOnewayFlightList?.filter((item) => item.isVisible);
        updateDisplayFlightResult(visibleData.slice(0, flightResultLength))
        setFlightList(visibleData);
        let visibleData1 = combinedReturnList?.filter((item) => item.isVisible);
        updateDisplayFlightRResult(visibleData1.slice(0, flightResultLength))
        setFlightRList(visibleData1);
        SetCombinedArray([visibleData[0], visibleData1[0]])
        // const combinedArray = [visibleData[0], visibleData1[0]];
    }, [combinedOnewayFlightList, combinedReturnList]);

    let bannreIndex = -1;
    const { activeCurrency, currencyValue } = useCurrencyContext();
    const history = useHistory();
    //   const editOnclick = (type) => {
    //     if (type == 1) {
    //       setOnwardFlights();
    //     } else {
    //       changeReturnFlights();
    //     }
    //   };

    const goToCheckoutPage = () => {
        const API_URL = "flights/updatedAirPort/search/";
        if (flightSearchObj.resultsType.toUpperCase() === "SEPARATE" &&
            flightSearchObj.airTravelType.toUpperCase() === "ROUNDTRIP") {
            const searchOrigin1 = flightSearchObj.originDestinations[0].origin;
            const searchDestination1 = flightSearchObj.originDestinations[0].destination;
            const searchOrigin2 = flightSearchObj.originDestinations[1].origin;
            const searchDestination2 = flightSearchObj.originDestinations[1].destination;
            const selectedOrigin1 = selectedFlight[0].origin;
            const selectedDestination1 = selectedFlight[0].destination;
            const selectedOrigin2 = selectedFlight[1].origin;
            const selectedDestination2 = selectedFlight[1].destination;
            const selectedOriginDescription1 = selectedFlight[0].originName;
            const selectedDestinationDescription1 = selectedFlight[0].destiantionName;
            const selectedOriginDescription2 = selectedFlight[1].originName;
            const selectedDestinationDescription2 = selectedFlight[1].destiantionName
            // console.log(selectedFlight,"jjj")
            Promise.all([
                APIClient.get(`${API_URL}${searchOrigin1}`),
                APIClient.get(`${API_URL}${searchDestination1}`),
                APIClient.get(`${API_URL}${searchOrigin2}`),
                APIClient.get(`${API_URL}${searchDestination2}`)
            ]).then(([originRes1, destinationRes1, originRes2, destinationRes2]) => {
                if (originRes1.statusCode === 200 && destinationRes1.statusCode === 200 &&
                    originRes2.statusCode === 200 && destinationRes2.statusCode === 200) {

                    if (searchOrigin1 === selectedOrigin1 &&
                        searchDestination1 === selectedDestination1 &&
                        searchOrigin2 === selectedOrigin2 &&
                        searchDestination2 === selectedDestination2) {
                        history.push("/flight/checkout");
                    } else {
                        const searchOriginDescription1 = originRes1.data.find(airport => airport.AirportCode === searchOrigin1)?.AirportDesc || searchOrigin1;
                        const searchDestinationDescription1 = destinationRes1.data.find(airport => airport.AirportCode === searchDestination1)?.AirportDesc || searchDestination1;
                        const searchOriginDescription2 = originRes2.data.find(airport => airport.AirportCode === searchOrigin2)?.AirportDesc || searchOrigin2;
                        const searchDestinationDescription2 = destinationRes2.data.find(airport => airport.AirportCode === searchDestination2)?.AirportDesc || searchDestination2;

                        Modal.confirm({
                            title: 'Sector Change',
                            width: 800,
                            content: (
                                <div>

                                    <p>You have searched for <span style={{ color: 'red' }}>{searchOriginDescription1}({searchOrigin1}) - {searchDestinationDescription1}({searchDestination1}) and {searchOriginDescription2}({searchOrigin2}) - {searchDestinationDescription2}({searchDestination2})</span>, however you selected <span style={{ color: 'green' }}>{selectedOriginDescription1}({selectedOrigin1}) - {selectedDestinationDescription1}({selectedDestination1}) and {selectedOriginDescription2}({selectedOrigin2}) - {selectedDestinationDescription2}({selectedDestination2})</span>.</p>
                                    <p>Are you sure you want to continue with the selected sectors?</p>
                                </div>
                            ),
                            okText: 'CONTINUE',
                            cancelText: 'CANCEL',
                            onOk() {
                                history.push("/flight/checkout");
                            },
                        });
                    }
                }
            }).catch((error) => {
                console.log("Error fetching data:", error);
            });


        }
        //history.push("/flight/checkout");
    };
    const AirportToolTip = (heading, code, name) => {
        return (
            <div className="tooltip-dataRe">
                <h4>{heading}</h4>
                <p>{code}</p>
                <p>{name}</p>
            </div>
        );
    };
    const toolBreakCity = (breakCityDest) => {
        return (
            <div className="tooltip-data">
                <h4>Change</h4>
                <p>{breakCityDest}</p>
            </div>
        );
    };

    const getSelectedTotalFare = (selectedflightsArray) => {
        let totalAmount = 0;
        if (selectedflightsArray.length > 1) {
            totalAmount = selectedflightsArray.reduce((prev, cur) => {
                return prev + Number(cur.fareFamilies.fareFamilies[0].adultPublishFare);
            }, 0);
        }
        return Number(totalAmount).toFixed(2);
    };
    //  console.log(journeyType, combinedArray, "journeytype");
    return (
        <div>

            <div className="SepView-Rndtrip" style={{ display: "flex" }}>
                <div className="onward-fig" style={{ marginRight: "10px" }}>
                    <p className="onw-txt-hdr">Onward</p>
                    <div className="flight-sort-container">
                        <Suspense fallback={<div>Loading...</div>}>
                            <RoundSort
                                updateFlightAirSearchRespObj={
                                    updateFlightAirSearchRespObj
                                }
                                flightAirSearchResp={flightAirSearchResp}
                                airlineMatrixReset={airlineMatrixReset}
                                ResetAirlineMatrix={ResetAirlineMatrix}
                                selectedTripType={"Onward"}
                                showNetFare={showNetFare}
                                setShowNetFare={setShowNetFare}
                                MultiCityIndex={MultiCityIndex}
                            />
                        </Suspense>
                    </div>
                    {displayFlightResult.length > 0 ? (
                        <InfiniteScroll
                            dataLength={displayFlightResult.length}
                            next={fetchMoreflights}
                            hasMore={displayFlightResult.length >= flightList.length ? false : true}
                            endMessage={"End Of Flight Result"}
                            loader={<h4>Loading...</h4>}
                        >
                            {
                                displayFlightResult.map((flight, i) => {
                                    let filteredPromoData =
                                        PromoData.length > 0
                                            ? PromoData?.filter((item) => item.airlineCode === flight.airLine)
                                                .length > 0
                                                ? promoData?.filter(
                                                    (item) =>
                                                        item.airlineCode?.toUpperCase() ===
                                                        flight.airLine?.toUpperCase()
                                                )
                                                : promoData?.filter((item) => item.airlineCode === "ALL")
                                            : null;
                                    return (
                                        <>
                                            {/* {i != 0 && i % 3 == 0 && flightSearchBanners[++bannreIndex] && (
                                            <div className="combine-roundtrip-view" style={{ backgroundColor: "white" }}>
                                                <img
                                                    style={{
                                                        width: "100%",
                                                        height: "90px",
                                                        objectFit: "cover",
                                                    }}
                                                    src={
                                                        process.env.REACT_APP_BASE_URL +
                                                        flightSearchBanners[bannreIndex].path.substring(1)
                                                    }
                                                    data={
                                                        flightSearchBanners[bannreIndex + 1]
                                                            ? null
                                                            : (bannreIndex = -1)
                                                    }
                                                />{" "}
                                            </div>
                                        )} */}
                                            {/* {selectedFlight[MultiCityIndex]?.flightId == flight.flightId && MultiCityIndex != null ? <p style={{ marginTop: '3%', marginRight: '10%' }} >Selected</p> : ""} */}
                                            <div className="combine-roundtrip-view" style={{ backgroundColor: "white" }}>

                                                <Flight
                                                    flightInfo={flight}
                                                    key={flight.flightId}
                                                    flightSearchObj={flightSearchObj}
                                                    setFlightFareRules={setFlightFareRules}
                                                    fareFamilies={flight.fareFamilies}
                                                    promoData={filteredPromoData}
                                                    index={`FLC-${i + 1}`}
                                                    activeCollapse={activeCollapseOnw}
                                                    setActiveCollapse={setActiveCollapseOnw}
                                                    selectedFlight={selectedFlight}
                                                    changeFlightResultsView={changeFlightResultsView}
                                                    showNetFare={showNetFare}
                                                    journeyType={"Onward"}
                                                    MultiCityIndex={MultiCityIndex}
                                                />
                                                {/* </Col> */}
                                                {/* <Col md={6} xs={24}>
                                                        <div className="choose-btn-wrapper">
                                                            <FlightSelection
                                                                flightInfo={flight}
                                                                changeFlightResultsView={changeFlightResultsView}
                                                                flightSearchObj={flightSearchObj}
                                                                selectedFlight={selectedFlight}
                                                                showNetFare={showNetFare}
                                                                journeyType={"Onward"}
                                                                MultiCityIndex={MultiCityIndex}
                                                                index={`FLC-${i + 1}`}
                                                                activeCollapse={activeCollapse}
                                                                setActiveCollapse={setActiveCollapse}
                                                            />
                                                        </div>
                                                    </Col> */}
                                                {/* </Row> */}
                                                <Row><Col md={24}>
                                                    <FlightSe
                                                        flightInfo={flight}
                                                        changeFlightResultsView={changeFlightResultsView}
                                                        flightSearchObj={flightSearchObj}
                                                        selectedFlight={selectedFlight}
                                                        showNetFare={showNetFare}
                                                        journeyType={journeyType}
                                                        MultiCityIndex={MultiCityIndex}
                                                        index={`FLC-${i + 1}`}
                                                        activeCollapse={activeCollapseOnw}
                                                        setActiveCollapse={setActiveCollapseOnw}
                                                        traceId={traceId}
                                                        ListType={true}
                                                    />
                                                </Col></Row>
                                            </div>{" "}
                                        </>
                                    );
                                })
                            }
                        </InfiniteScroll>
                    ) : (
                        <CustomNoResultFound title={"No Flights Available"} />
                    )}
                </div>

                <div className="return-fig">
                    <p className="ret-txt-hdr">Return</p>
                    <div className="flight-sort-container">
                        <Suspense fallback={<div>Loading...</div>}>
                            <RoundSort
                                updateFlightAirSearchRespObj={
                                    updateFlightAirSearchRespObj
                                }
                                flightAirSearchResp={flightAirSearchResp}
                                airlineMatrixReset={airlineMatrixReset}
                                ResetAirlineMatrix={ResetAirlineMatrix}
                                selectedTripType={"Return"}
                                showNetFare={showNetFare}
                                setShowNetFare={setShowNetFare}
                                MultiCityIndex={MultiCityIndex}
                            />
                        </Suspense>
                    </div>
                    {displayFlightRResult.length > 0 ? (
                        <InfiniteScroll
                            dataLength={displayFlightRResult.length}
                            next={fetchMoreflights}
                            hasMore={displayFlightRResult.length >= flightRList.length ? false : true}
                            endMessage={"End Of Flight Result"}
                            loader={<h4>Loading...</h4>}
                        >
                            {
                                displayFlightRResult.map((flight, i) => {
                                    let filteredPromoData =
                                        PromoData.length > 0
                                            ? PromoData?.filter((item) => item.airlineCode === flight.airLine)
                                                .length > 0
                                                ? promoData?.filter(
                                                    (item) =>
                                                        item.airlineCode?.toUpperCase() ===
                                                        flight.airLine?.toUpperCase()
                                                )
                                                : promoData?.filter((item) => item.airlineCode === "ALL")
                                            : null;
                                    return (
                                        <>
                                            {/* {i != 0 && i % 3 == 0 && flightSearchBanners[++bannreIndex] && (
                                                <div className="combine-roundtrip-view" style={{ backgroundColor: "white" }}>
                                                    <img
                                                        style={{
                                                            width: "100%",
                                                            height: "90px",
                                                            objectFit: "cover",
                                                        }}
                                                        src={
                                                            process.env.REACT_APP_BASE_URL +
                                                            flightSearchBanners[bannreIndex].path.substring(1)
                                                        }
                                                        data={
                                                            flightSearchBanners[bannreIndex + 1]
                                                                ? null
                                                                : (bannreIndex = -1)
                                                        }
                                                    />{" "}
                                                </div>
                                            )} */}
                                            <div className="combine-roundtrip-view" style={{ backgroundColor: "white" }}>

                                                <Flight
                                                    flightInfo={flight}
                                                    key={flight.flightId}
                                                    flightSearchObj={flightSearchObj}
                                                    setFlightFareRules={setFlightFareRules}
                                                    fareFamilies={flight.fareFamilies}
                                                    promoData={filteredPromoData}
                                                    index={`FLC-${i + 2}`}
                                                    activeCollapse={activeCollapse}
                                                    setActiveCollapse={setActiveCollapse}
                                                    selectedFlight={selectedFlight}
                                                    changeFlightResultsView={changeFlightResultsView}
                                                    showNetFare={showNetFare}
                                                    journeyType={"Return"}
                                                    MultiCityIndex={MultiCityIndex}
                                                />

                                                {/* <Col md={6} xs={24}>
                                                        <div className="choose-btn-wrapper">
                                                            <FlightSelection
                                                                flightInfo={flight}
                                                                changeFlightResultsView={changeFlightResultsView}
                                                                flightSearchObj={flightSearchObj}
                                                                selectedFlight={selectedFlight}
                                                                showNetFare={showNetFare}
                                                                journeyType={"Return"}
                                                                MultiCityIndex={MultiCityIndex}
                                                                index={`FLC-${i + 2}`}
                                                                activeCollapse={activeCollapse}
                                                                setActiveCollapse={setActiveCollapse}
                                                            />
                                                        </div>
                                                    </Col> */}

                                                <Row><Col md={24}>
                                                    <FlightSe
                                                        flightInfo={flight}
                                                        changeFlightResultsView={changeFlightResultsView}
                                                        flightSearchObj={flightSearchObj}
                                                        selectedFlight={selectedFlight}
                                                        showNetFare={showNetFare}
                                                        journeyType={journeyType}
                                                        MultiCityIndex={MultiCityIndex}
                                                        index={`FLC-${i + 2}`}
                                                        activeCollapse={activeCollapse}
                                                        setActiveCollapse={setActiveCollapse}
                                                        traceId={traceId}
                                                        ListType={true}
                                                    />
                                                </Col></Row>
                                            </div > {" "}
                                        </>
                                    );
                                })
                            }
                        </InfiniteScroll>
                    ) : (
                        <CustomNoResultFound title={"No Flights Available"} />
                    )}
                </div>
            </div>
            {/* {console.log(selectedFlight,"select")} */}
            {selectedFlight?.length > 0 && flightSearchObj?.airTravelType != "multidestination" ? (
                <>
                    <div className="edit-Sep-Rndtp">
                        <div className="card-results-sep">
                            <Row gutter={[16, 8]}>
                                <Col md={10} sm={10} xs={24} className="flight-border-right">
                                    <div className="card-results-travel-wrapper">
                                        <p className="title"> Onward Selection</p>
                                        <div className="card-results-travel-details">
                                            <div className="itinary_wrapper">
                                                <div className="itinary-details">
                                                    <div className="itinary-flight-name">
                                                        {/* <img
                              style={{ width: '100%', height: '50%' }}
                              className="airline-img"
                              src={selectedFlight[0].airLineLogo}
                              alt={selectedFlight[0].airLineName}
                            /> */}
                                                        <CustomFliImg
                                                            className="airline-img"
                                                            src={selectedFlight[0]?.airLineLogo}
                                                            alt={selectedFlight[0]?.airLineName}
                                                        />
                                                        <p className="name">
                                                            {selectedFlight[0]?.airLineName}
                                                        </p>
                                                    </div>
                                                    <div className="fromTo-wrapper">
                                                        <div className="cities-from-to">
                                                            <div className="loc_wrapper">
                                                                <div className="time-txtS">
                                                                    <span className="code">
                                                                        {selectedFlight[0].flightSegments[0].origin}
                                                                    </span>
                                                                    {moment(selectedFlight[0]?.flightSegments[0]?.departureDateTime).format("HH:MM")}
                                                                </div>

                                                                <div className="break-Rejourney-container">
                                                                    <p className="break-jrny-up">
                                                                        {(() => {
                                                                            if (selectedFlight[0].flightSegments.length === 0) return "00:00 hrs";

                                                                            const departure = new Date(selectedFlight[0].flightSegments[0].departureDateTime);
                                                                            const arrival = new Date(selectedFlight[0].flightSegments[selectedFlight[0].flightSegments.length - 1].arrivalDateTime);

                                                                            const diffMs = arrival - departure;
                                                                            const hours = Math.floor(diffMs / (1000 * 60 * 60));
                                                                            const minutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));

                                                                            return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")} hrs`;
                                                                        })()}
                                                                    </p>
                                                                    <div className="break-Rejourney-city">
                                                                        <div className="inner_wrapper">
                                                                            {selectedFlight[0].flightSegments?.map((flightSegInfo, index) =>
                                                                                index !== 0 ? (
                                                                                    <div className="stop_points" key={flightSegInfo.segId}>
                                                                                        <Tooltip
                                                                                            title={AirportToolTip(
                                                                                                "Change",
                                                                                                flightSegInfo?.originName,
                                                                                                flightSegInfo?.originCity,
                                                                                                flightSegInfo?.departureDateTime
                                                                                            )}
                                                                                            className="citynames-tooltip"
                                                                                        >
                                                                                            <span className="break-city-name pointer_cursor">
                                                                                                {flightSegInfo?.origin}
                                                                                            </span>
                                                                                        </Tooltip>
                                                                                    </div>
                                                                                ) : selectedFlight[0].flightSegments.length === 1 ? "Direct" : null
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="time-txtS">
                                                                    <span className="code">
                                                                        {
                                                                            selectedFlight[0].flightSegments[
                                                                                selectedFlight[0].flightSegments.length -
                                                                                1
                                                                            ].destination
                                                                        }
                                                                    </span>
                                                                    {moment(selectedFlight[0]?.flightSegments[
                                                                        selectedFlight[0].flightSegments.length -
                                                                        1
                                                                    ].arrivalDateTime).format("HH:MM")}
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="btncontainer">
                                            <p className="price">
                                                <span> {activeCurrency}</span>
                                                {currencyValue(
                                                    selectedFlight[0].fareFamilies.fareFamilies[0]
                                                        .adultPublishFare
                                                )}
                                            </p>
                                            <div className="flight-stops-wrapper">
                                                <span className="stops-wrapper">
                                                    {selectedFlight[0].flightSegments.length - 1 ===
                                                        0 ? (
                                                        <>Direct</>
                                                    ) : (
                                                        <>
                                                            {selectedFlight[0].flightSegments.length -
                                                                1}{" "}
                                                            stops{" "}
                                                        </>
                                                    )}{" "}
                                                    | {flightSearchObj.cabinClass}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </Col>{" "}
                                {selectedFlight.length > 1 ? (
                                    <>
                                        <Col
                                            md={10}
                                            sm={10}
                                            xs={24}
                                            className="flight-border-right"
                                        >
                                            <div className="card-results-travel-wrapper">
                                                <p className="title"> Return Selection</p>
                                                <div className="card-results-travel-details">
                                                    <div className="itinary_wrapper">
                                                        <div className="itinary-details">
                                                            <div className="itinary-flight-name">
                                                                {/* <img
                              style={{ width: '100%', height: '50%' }}
                              className="airline-img"
                              src={selectedFlight[0].airLineLogo}
                              alt={selectedFlight[0].airLineName}
                            /> */}
                                                                <CustomFliImg
                                                                    className="airline-img"
                                                                    src={selectedFlight[1]?.airLineLogo}
                                                                    alt={selectedFlight[1]?.airLineName}
                                                                />
                                                                <p className="name">
                                                                    {selectedFlight[1]?.airLineName}
                                                                </p>
                                                            </div>
                                                            <div className="fromTo-wrapper">
                                                                <div className="cities-from-to">
                                                                    <div className="loc_wrapper">
                                                                        <div className="time-txtS">
                                                                            <span className="code">
                                                                                {selectedFlight[1].flightSegments[0].origin}
                                                                            </span>
                                                                            {moment(selectedFlight[1]?.flightSegments[0]?.departureDateTime).format("HH:MM")}
                                                                        </div>

                                                                        <div className="break-Rejourney-container">
                                                                            <p className="break-jrny-up">
                                                                                {(() => {
                                                                                    if (selectedFlight[1].flightSegments.length === 0) return "00:00 hrs";

                                                                                    const departure = new Date(selectedFlight[1].flightSegments[0].departureDateTime);
                                                                                    const arrival = new Date(selectedFlight[1].flightSegments[selectedFlight[1].flightSegments.length - 1].arrivalDateTime);

                                                                                    const diffMs = arrival - departure;
                                                                                    const hours = Math.floor(diffMs / (1000 * 60 * 60));
                                                                                    const minutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));

                                                                                    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")} hrs`;
                                                                                })()}
                                                                            </p>
                                                                            <div className="break-Rejourney-city">
                                                                                <div className="inner_wrapper">
                                                                                    {selectedFlight[1].flightSegments?.map((flightSegInfo, index) =>
                                                                                        index !== 0 ? (
                                                                                            <div className="stop_points" key={flightSegInfo.segId}>
                                                                                                <Tooltip
                                                                                                    title={AirportToolTip(
                                                                                                        "Change",
                                                                                                        flightSegInfo?.originName,
                                                                                                        flightSegInfo?.originCity,
                                                                                                        flightSegInfo?.departureDateTime
                                                                                                    )}
                                                                                                    className="citynames-tooltip"
                                                                                                >
                                                                                                    <span className="break-city-name pointer_cursor">
                                                                                                        {flightSegInfo?.origin}
                                                                                                    </span>
                                                                                                </Tooltip>
                                                                                            </div>
                                                                                        ) : selectedFlight[1].flightSegments.length === 1 ? "Direct" : null
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        {/* <span className="code">
                                                                            {
                                                                                selectedFlight[1].flightSegments[
                                                                                    selectedFlight[1].flightSegments.length -
                                                                                    1
                                                                                ].destination
                                                                            }
                                                                        </span> */}
                                                                        <div className="time-txtS">
                                                                            <span className="code">
                                                                                {
                                                                                    selectedFlight[1].flightSegments[
                                                                                        selectedFlight[1].flightSegments.length -
                                                                                        1
                                                                                    ].destination
                                                                                }
                                                                            </span>
                                                                            {moment(selectedFlight[1]?.flightSegments[
                                                                                selectedFlight[1].flightSegments.length -
                                                                                1
                                                                            ].arrivalDateTime).format("HH:MM")}
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="btncontainer">
                                                    <p className="price">
                                                        <span> {activeCurrency}</span>
                                                        {currencyValue(
                                                            selectedFlight[1].fareFamilies.fareFamilies[0]
                                                                .adultPublishFare
                                                        )}
                                                    </p>
                                                    <div className="flight-stops-wrapper">
                                                        <span className="stops-wrapper">
                                                            {selectedFlight[1].flightSegments.length - 1 ===
                                                                0 ? (
                                                                <>Direct</>
                                                            ) : (
                                                                <>
                                                                    {selectedFlight[1].flightSegments.length -
                                                                        1}{" "}
                                                                    stops{" "}
                                                                </>
                                                            )}{" "}
                                                            | {flightSearchObj.cabinClass}
                                                        </span>
                                                    </div>

                                                </div>
                                            </div>

                                        </Col>

                                        <Col md={4} sm={4} xs={24}>
                                            <div className="selected-flights-price-wrapper">
                                                <div className="selected-flights-price">
                                                    <div className="price-box">
                                                        <p className="totalFare">
                                                        <span>{activeCurrency === "INR" ? "₹" : activeCurrency}</span> {getSelectedTotalFare(selectedFlight)}
                                                        </p>
                                                    </div>

                                                    <Button type="primary" onClick={goToCheckoutPage}>
                                                        Select
                                                    </Button>
                                                </div>
                                            </div>
                                        </Col>
                                    </>
                                ) : null}
                            </Row>
                        </div>
                    </div>

                </>
            ) : null
            }


            {
                selectedFlight.length > 0 && flightSearchObj?.airTravelType == "multidestination" ? (
                    <>
                        <div className="edit-Sep-Rndtp">
                            <div className="card-results">
                                <Row gutter={[16, 8]}>
                                    <Col md={10} sm={10} xs={24} className="flight-border-right">
                                        <div className="card-results-travel-wrapper">
                                            <p className="title"> First Flight Selection</p>
                                            <div className="card-results-travel-details">
                                                <div className="itinary_wrapper">
                                                    <div className="itinary-details">
                                                        <div className="itinary-flight-name">
                                                            {/* <img
                              className="airline-img"
                              src={selectedFlight[0].airLineLogo}
                              alt={selectedFlight[0].airLineName}
                            /> */}
                                                            <CustomFliImg
                                                                className="airline-img"
                                                                src={selectedFlight[0]?.airLineLogo}
                                                                alt={selectedFlight[0]?.airLineName}
                                                            />
                                                            <p className="name">
                                                                {selectedFlight[0]?.airLineName}
                                                            </p>
                                                        </div>
                                                        <div className="fromTo-wrapper">
                                                            <div className="cities-from-to">
                                                                <div className="loc_wrapper">
                                                                    <span className="code">

                                                                        {selectedFlight[0].originCity}
                                                                    </span>

                                                                    <div className="break-journey-city">
                                                                        <div className="inner_wrapper">
                                                                            {selectedFlight[0].flightSegments.map(
                                                                                (flightSegInfo, index) =>
                                                                                    index !== 0 ? (
                                                                                        <div
                                                                                            className="stop_points"
                                                                                            key={flightSegInfo.segId}
                                                                                        >
                                                                                            <Tooltip
                                                                                                placement="top"
                                                                                                title={toolBreakCity(
                                                                                                    flightSegInfo.origin +
                                                                                                    " " +
                                                                                                    flightSegInfo.originName
                                                                                                )}
                                                                                                className="citynames-tooltip"
                                                                                            >
                                                                                                <span className="break-city-name pointer_cursor">
                                                                                                    {flightSegInfo.origin}
                                                                                                </span>
                                                                                            </Tooltip>
                                                                                        </div>
                                                                                    ) : null
                                                                            )}
                                                                        </div>
                                                                    </div>

                                                                    <span className="code">
                                                                        {selectedFlight[0].destinationCity}
                                                                    </span>
                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="btncontainer">
                                                <p className="price">
                                                    <span> {activeCurrency}</span>
                                                    {currencyValue(
                                                        selectedFlight[0].fareFamilies.fareFamilies[0]
                                                            .adultPublishFare
                                                    )}
                                                </p>
                                                <div className="flight-stops-wrapper">
                                                    <span className="stops-wrapper">
                                                        {selectedFlight[0].flightSegments.length - 1 ===
                                                            0 ? (
                                                            <>Direct</>
                                                        ) : (
                                                            <>
                                                                {selectedFlight[0].flightSegments.length -
                                                                    1}{" "}
                                                                stops{" "}
                                                            </>
                                                        )}{" "}
                                                        | {flightSearchObj.cabinClass}
                                                    </span>
                                                </div>
                                                <button
                                                    onClick={() => setMulticityIndex(0)}
                                                    className="tg-primary-btn"
                                                >
                                                    Change
                                                </button>
                                            </div>
                                        </div>
                                    </Col>{" "}

                                    {selectedFlight.length > 1 && selectedFlight.length <= flightSearchObj?.originDestinations?.length ? (
                                        <>
                                            <Col
                                                md={10}
                                                sm={10}
                                                xs={24}
                                                className="flight-border-right"
                                            >
                                                <div className="card-results-travel-wrapper">
                                                    <p className="title">Second Flight Selection</p>
                                                    <div className="card-results-travel-details">
                                                        <div className="itinary_wrapper">
                                                            <div className="itinary-details">
                                                                <div className="itinary-flight-name">
                                                                    {/* <img
                                  className="airline-img"
                                  src={selectedFlight[1]?.airLineLogo}
                                  alt={selectedFlight[1].airLineName}
                                /> */}
                                                                    <CustomFliImg
                                                                        className="airline-img"
                                                                        src={selectedFlight[1].airLineLogo}
                                                                        alt={selectedFlight[1].airLineName}
                                                                    />
                                                                    <p className="name">
                                                                        {selectedFlight[1].airLineName}
                                                                    </p>
                                                                </div>
                                                                <div className="fromTo-wrapper">
                                                                    <div className="cities-from-to">
                                                                        <div className="loc_wrapper">
                                                                            <span className="code">
                                                                                {selectedFlight[1].originCity}
                                                                            </span>

                                                                            <div className="break-journey-city">
                                                                                <div className="inner_wrapper">
                                                                                    {selectedFlight[1].flightSegments.map(
                                                                                        (flightSegInfo, index) =>
                                                                                            index !== 0 ? (
                                                                                                <div
                                                                                                    className="stop_points"
                                                                                                    key={flightSegInfo.segId}
                                                                                                >
                                                                                                    <Tooltip
                                                                                                        placement="top"
                                                                                                        title={toolBreakCity(
                                                                                                            flightSegInfo.origin +
                                                                                                            " " +
                                                                                                            flightSegInfo.originName
                                                                                                        )}
                                                                                                        className="citynames-tooltip"
                                                                                                    >
                                                                                                        <span className="break-city-name pointer_cursor">
                                                                                                            {flightSegInfo.origin}
                                                                                                        </span>
                                                                                                    </Tooltip>
                                                                                                </div>
                                                                                            ) : null
                                                                                    )}
                                                                                </div>
                                                                            </div>

                                                                            <span className="code">
                                                                                {selectedFlight[1].destinationCity}
                                                                            </span>
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="btncontainer">
                                                        <p className="price">
                                                            <span> {activeCurrency}</span>
                                                            {currencyValue(
                                                                selectedFlight[1].fareFamilies.fareFamilies[0]
                                                                    .adultPublishFare
                                                            )}
                                                        </p>
                                                        <div className="flight-stops-wrapper">
                                                            <span className="stops-wrapper">
                                                                {selectedFlight[1].flightSegments.length -
                                                                    1 ===
                                                                    0 ? (
                                                                    <>Direct</>
                                                                ) : (
                                                                    <>
                                                                        {selectedFlight[1].flightSegments
                                                                            .length - 1}{" "}
                                                                        stops{" "}
                                                                    </>
                                                                )}{" "}
                                                                | {flightSearchObj.cabinClass}
                                                            </span>
                                                        </div>
                                                        <button
                                                            onClick={() => setMulticityIndex(1)}
                                                            className="tg-primary-btn"
                                                        >
                                                            Change
                                                        </button>
                                                    </div>
                                                </div>
                                            </Col>

                                            {flightSearchObj?.originDestinations?.length == 2 ? <Col md={4} sm={4} xs={24}>
                                                <div className="selected-flights-price-wrapper">
                                                    <div className="selected-flights-price">
                                                        <div className="price-box">
                                                            <p className="totalFare">
                                                            <span>{activeCurrency === "INR" ? "₹" : activeCurrency}</span> {getSelectedTotalFare(selectedFlight)}
                                                            </p>
                                                        </div>

                                                        <Button type="primary" onClick={goToCheckoutPage}>
                                                            Book
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Col> : null}
                                        </>
                                    ) : null}
                                    {selectedFlight.length > 2 && selectedFlight.length <= flightSearchObj?.originDestinations?.length ? (
                                        <>
                                            <Col
                                                md={10}
                                                sm={10}
                                                xs={24}
                                                className="flight-border-right"
                                            >
                                                <div className="card-results-travel-wrapper">
                                                    <p className="title">Third Flight Selection</p>
                                                    <div className="card-results-travel-details">
                                                        <div className="itinary_wrapper">
                                                            <div className="itinary-details">
                                                                <div className="itinary-flight-name">
                                                                    {/* <img
                                  className="airline-img"
                                  src={selectedFlight[2].airLineLogo}
                                  alt={selectedFlight[2].airLineName}
                                /> */}
                                                                    <CustomFliImg
                                                                        className="airline-img"
                                                                        src={selectedFlight[2].airLineLogo}
                                                                        alt={selectedFlight[2].airLineName}
                                                                    />
                                                                    <p className="name">
                                                                        {selectedFlight[2].airLineName}
                                                                    </p>
                                                                </div>
                                                                <div className="fromTo-wrapper">
                                                                    <div className="cities-from-to">
                                                                        <div className="loc_wrapper">
                                                                            <span className="code">
                                                                                {selectedFlight[2].originCity}
                                                                            </span>

                                                                            <div className="break-journey-city">
                                                                                <div className="inner_wrapper">
                                                                                    {selectedFlight[2].flightSegments.map(
                                                                                        (flightSegInfo, index) =>
                                                                                            index !== 0 ? (
                                                                                                <div
                                                                                                    className="stop_points"
                                                                                                    key={flightSegInfo.segId}
                                                                                                >
                                                                                                    <Tooltip
                                                                                                        placement="top"
                                                                                                        title={toolBreakCity(
                                                                                                            flightSegInfo.origin +
                                                                                                            " " +
                                                                                                            flightSegInfo.originName
                                                                                                        )}
                                                                                                        className="citynames-tooltip"
                                                                                                    >
                                                                                                        <span className="break-city-name pointer_cursor">
                                                                                                            {flightSegInfo.origin}
                                                                                                        </span>
                                                                                                    </Tooltip>
                                                                                                </div>
                                                                                            ) : null
                                                                                    )}
                                                                                </div>
                                                                            </div>

                                                                            <span className="code">
                                                                                {selectedFlight[2].destinationCity}
                                                                            </span>
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="btncontainer">
                                                        <p className="price">
                                                            <span> {activeCurrency}</span>
                                                            {currencyValue(
                                                                selectedFlight[2].fareFamilies.fareFamilies[0]
                                                                    .adultPublishFare
                                                            )}
                                                        </p>
                                                        <div className="flight-stops-wrapper">
                                                            <span className="stops-wrapper">
                                                                {selectedFlight[2].flightSegments.length -
                                                                    1 ===
                                                                    0 ? (
                                                                    <>Direct</>
                                                                ) : (
                                                                    <>
                                                                        {selectedFlight[2].flightSegments
                                                                            .length - 1}{" "}
                                                                        stops{" "}
                                                                    </>
                                                                )}{" "}
                                                                | {flightSearchObj.cabinClass}
                                                            </span>
                                                        </div>
                                                        <button
                                                            onClick={() => setMulticityIndex(2)}
                                                            className="tg-primary-btn"
                                                        >
                                                            Change
                                                        </button>
                                                    </div>
                                                </div>
                                            </Col>

                                            {flightSearchObj?.originDestinations?.length === 3 ? <Col md={4} sm={4} xs={24}>
                                                <div className="selected-flights-price-wrapper">
                                                    <div className="selected-flights-price">
                                                        <div className="price-box">
                                                            <p className="totalFare">
                                                            <span>{activeCurrency === "INR" ? "₹" : activeCurrency}</span> {getSelectedTotalFare(selectedFlight)}
                                                            </p>
                                                        </div>

                                                        <Button type="primary" onClick={goToCheckoutPage}>
                                                            Book
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Col> : null}
                                        </>
                                    ) : null}
                                    {selectedFlight.length > 3 && selectedFlight.length <= flightSearchObj?.originDestinations?.length ? (
                                        <>
                                            <Col
                                                md={10}
                                                sm={10}
                                                xs={24}
                                                className="flight-border-right"
                                            >
                                                <div className="card-results-travel-wrapper">
                                                    <p className="title">Fourth Flight Selection</p>
                                                    <div className="card-results-travel-details">
                                                        <div className="itinary_wrapper">
                                                            <div className="itinary-details">
                                                                <div className="itinary-flight-name">
                                                                    {/* <img
                                  className="airline-img"
                                  src={selectedFlight[3].airLineLogo}
                                  alt={selectedFlight[3].airLineName}
                                /> */}
                                                                    <CustomFliImg
                                                                        className="airline-img"
                                                                        src={selectedFlight[3].airLineLogo}
                                                                        alt={selectedFlight[3].airLineName}
                                                                    />
                                                                    <p className="name">
                                                                        {selectedFlight[3].airLineName}
                                                                    </p>
                                                                </div>
                                                                <div className="fromTo-wrapper">
                                                                    <div className="cities-from-to">
                                                                        <div className="loc_wrapper">
                                                                            <span className="code">

                                                                                {selectedFlight[3].originCity}
                                                                            </span>

                                                                            <div className="break-journey-city">
                                                                                <div className="inner_wrapper">
                                                                                    {selectedFlight[3].flightSegments.map(
                                                                                        (flightSegInfo, index) =>
                                                                                            index !== 0 ? (
                                                                                                <div
                                                                                                    className="stop_points"
                                                                                                    key={flightSegInfo.segId}
                                                                                                >
                                                                                                    <Tooltip
                                                                                                        placement="top"
                                                                                                        title={toolBreakCity(
                                                                                                            flightSegInfo.origin +
                                                                                                            " " +
                                                                                                            flightSegInfo.originName
                                                                                                        )}
                                                                                                        className="citynames-tooltip"
                                                                                                    >
                                                                                                        <span className="break-city-name pointer_cursor">
                                                                                                            {flightSegInfo.origin}
                                                                                                        </span>
                                                                                                    </Tooltip>
                                                                                                </div>
                                                                                            ) : null
                                                                                    )}
                                                                                </div>
                                                                            </div>

                                                                            <span className="code">
                                                                                {selectedFlight[3].destinationCity}
                                                                            </span>
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="btncontainer">
                                                        <p className="price">
                                                            <span> {activeCurrency}</span>
                                                            {currencyValue(
                                                                selectedFlight[3].fareFamilies.fareFamilies[0]
                                                                    .adultPublishFare
                                                            )}
                                                        </p>
                                                        <div className="flight-stops-wrapper">
                                                            <span className="stops-wrapper">
                                                                {selectedFlight[3].flightSegments.length -
                                                                    1 ===
                                                                    0 ? (
                                                                    <>Direct</>
                                                                ) : (
                                                                    <>
                                                                        {selectedFlight[3].flightSegments
                                                                            .length - 1}{" "}
                                                                        stops{" "}
                                                                    </>
                                                                )}{" "}
                                                                | {flightSearchObj.cabinClass}
                                                            </span>
                                                        </div>
                                                        <button
                                                            onClick={() => setMulticityIndex(3)}
                                                            className="tg-primary-btn"
                                                        >
                                                            Change
                                                        </button>
                                                    </div>
                                                </div>
                                            </Col>

                                            {flightSearchObj?.originDestinations?.length === 4 ? <Col md={4} sm={4} xs={24}>
                                                <div className="selected-flights-price-wrapper">
                                                    <div className="selected-flights-price">
                                                        <div className="price-box">
                                                            <p className="totalFare">
                                                                <span>{activeCurrency === "INR" ? "₹" : activeCurrency}</span> {getSelectedTotalFare(selectedFlight)}
                                                            </p>
                                                        </div>

                                                        <Button type="primary" onClick={goToCheckoutPage}>
                                                            Book
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Col> : null}
                                        </>
                                    ) : null}
                                </Row>
                            </div>
                        </div>
                        <div className="edit-Sep-Rndtp">
                            <p className="returntext">
                                Select For {MultiCityIndex === 0 ? "First Flight" : MultiCityIndex === 1 ? "Second Flight" : MultiCityIndex === 2 ? "Third Flight" : "Fourth Flight"}{" "}
                            </p>
                        </div>

                    </>
                ) : null
            }
        </div >
    );
};

export default SeparatedFlight;

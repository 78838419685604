import { Collapse, Row, Table } from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import Slider from "react-slick";
import { useCurrencyContext } from "../../../common/providers/CurrencyProvider";
import "./FlightNearDates.scss";

import queryString from "query-string";
import { Link, useHistory } from "react-router-dom";
import { useAuthContext } from "../../../common/providers/AuthProvider";
import { GlobalStatesContext } from "../../../common/providers";

const FlightDates = ({ flights_all, airSearchData, onFlexiDateClicked, MultiCityIndex }) => {
  const { activeCurrency, currencyValue } = useCurrencyContext();
  const { Panel } = Collapse;
  function callback(key) {
    // console.log(key);
  }
  const [displayFlexi, setDisplayFlex] = useState(true);
  let flexi_dates = [
    {
      value: null,
      dated: moment(airSearchData.originDestinations[0].departureDateTime).add(
        1,
        "days"
      ),
    },
    {
      value: null,
      dated: moment(airSearchData.originDestinations[0].departureDateTime).add(
        2,
        "days"
      ),
    },
    {
      value: null,
      dated: moment(airSearchData.originDestinations[0].departureDateTime).add(
        3,
        "days"
      ),
    },
    {
      value: null,
      dated: moment(airSearchData.originDestinations[0].departureDateTime).add(
        4,
        "days"
      ),
    },
    {
      value: null,
      dated: moment(airSearchData.originDestinations[0].departureDateTime).add(
        5,
        "days"
      ),
    },
    {
      value: null,
      dated: moment(airSearchData.originDestinations[0].departureDateTime).add(
        6,
        "days"
      ),
    },
    {
      value: null,
      dated: moment(airSearchData.originDestinations[0].departureDateTime).add(
        7,
        "days"
      ),
    },
    {
      value: null,
      dated: moment(airSearchData.originDestinations[0].departureDateTime).add(
        8,
        "days"
      ),
    },
    {
      value: null,
      dated: moment(airSearchData.originDestinations[0].departureDateTime).add(
        9,
        "days"
      ),
    },
    {
      value: null,
      dated: moment(airSearchData.originDestinations[0].departureDateTime).add(
        10,
        "days"
      ),
    },
  ];

  const [flexiData, setFlexiData] = useState([]);

  const onDateClicked = (d) => {
    onFlexiDateClicked(d.format("YYYY-MM-DD") + "T00:00:00");
  };

  const createNearbyDates = (ff_list, resultsType, travelType) => {
    ff_list.forEach((flight_list, index) => {

      let flight = (resultsType.toUpperCase() == "COMBINED" && travelType.toUpperCase() == "ROUNDTRIP")
        || (resultsType.toUpperCase() == "COMBINED" && travelType.toUpperCase() == "MULTIDESTINATION") ? flight_list?.flightDetails[0] : flight_list;

      let to_check = moment(flight?.flightSegments[0]?.departureDateTime);
      let amount = flight?.fareFamilies?.fareFamilies[0]?.adultFare;

      flexi_dates.forEach((dd) => {
        if (!dd.dated.isSame(to_check)) {
          if (amount < dd.value || dd.value == null) {
            dd.value = amount;
          }
        }
      });
    });

    let temp_final_record = {
      d1: flexi_dates[0].value,
      d2: flexi_dates[1].value,
      d3: flexi_dates[2].value,
      d4: flexi_dates[3].value,
      d5: flexi_dates[5].value,
      d6: flexi_dates[5].value,
    };
    setFlexiData([temp_final_record]);

    let flag = false;

    Object.keys(temp_final_record).forEach((k) => {
      if (temp_final_record[k] != null) {
        flag = true;
      }
    });

    setDisplayFlex(flag);
  };

  useEffect(() => {
    //console.log("flightsearch", flights_all)
    if (airSearchData.resultsType.toUpperCase() == "COMBINED") {
      createNearbyDates(flights_all.flightDetails, airSearchData.resultsType, airSearchData.airTravelType);
    } else if (airSearchData.resultsType.toUpperCase() === "SEPARATE" && airSearchData.airTravelType.toUpperCase() === "MULTIDESTINATION") {
      createNearbyDates(flights_all.multicityFlightDetails[MultiCityIndex], airSearchData.resultsType, airSearchData.airTravelType);
    }
    else {
      createNearbyDates(flights_all.flightDetails, airSearchData.resultsType, airSearchData.airTravelType);
    }
  }, []);

  const renderColumn = (v, i, r, dd_dated) => {
    return v == null ? (
      ""
    ) : (
      <div
        style={{ cursor: "pointer" }}
        onClick={() => onDateClicked(dd_dated)}
      >
        {activeCurrency + " " + currencyValue(v)}
      </div>
    );
  };
  const columns = [
    {
      title: flexi_dates[0].dated.format("ddd MMM, DD"),
      dataIndex: "d1",
      key: "d1",
      render: (v, i, r) => renderColumn(v, i, r, flexi_dates[0].dated),
    },
    {
      title: flexi_dates[1].dated.format("ddd MMM, DD"),
      dataIndex: "d2",
      key: "d2",
      render: (v, i, r) => renderColumn(v, i, r, flexi_dates[1].dated),
    },
    {
      title: flexi_dates[2].dated.format("ddd MMM, DD"),
      dataIndex: "d3",
      key: "d3",
      render: (v, i, r) => renderColumn(v, i, r, flexi_dates[2].dated),
    },
    {
      title: flexi_dates[3].dated.format("ddd MMM, DD"),
      dataIndex: "d4",
      render: (v, i, r) => renderColumn(v, i, r, flexi_dates[3].dated),
      key: "d4",
    },
    {
      title: flexi_dates[4].dated.format("ddd MMM, DD"),
      dataIndex: "d5",
      key: "d5",
      render: (v, i, r) => renderColumn(v, i, r, flexi_dates[4].dated),
    },
    {
      title: flexi_dates[5].dated.format("ddd MMM, DD"),
      dataIndex: "d6",
      key: "d6",
      render: (v, i, r) => renderColumn(v, i, r, flexi_dates[5].dated),
    },
  ];

  return (
    <div>
      <div className="nearby-accordion">
        {/* {displayFlexi ? (
          <Table
            columns={columns}
            dataSource={flexiData}
            pagination={false}
            className="oneway-table table-scroll-none"
            bordered
          />
        ) : (
          <p>No Details Found</p>
        )} */}

        {displayFlexi ? (
          <WeekFareSlider flexiData={flexi_dates} serviceType={1} />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export const HotelDates = ({ hotelSearchObject }) => {
  let flexi_dates = [
    {
      value: null,

      checkInDate: moment(hotelSearchObject.checkInDate).add(1, "days"),
      checkOutDate: moment(hotelSearchObject.checkOutDate).add(1, "days"),
    },
    {
      value: null,

      checkInDate: moment(hotelSearchObject.checkInDate).add(2, "days"),
      checkOutDate: moment(hotelSearchObject.checkOutDate).add(2, "days"),
    },
    {
      value: null,

      checkInDate: moment(hotelSearchObject.checkInDate).add(3, "days"),
      checkOutDate: moment(hotelSearchObject.checkOutDate).add(3, "days"),
    },
    {
      value: null,

      checkInDate: moment(hotelSearchObject.checkInDate).add(4, "days"),
      checkOutDate: moment(hotelSearchObject.checkOutDate).add(4, "days"),
    },
    {
      value: null,

      checkInDate: moment(hotelSearchObject.checkInDate).add(5, "days"),
      checkOutDate: moment(hotelSearchObject.checkOutDate).add(5, "days"),
    },
    {
      value: null,

      checkInDate: moment(hotelSearchObject.checkInDate).add(6, "days"),
      checkOutDate: moment(hotelSearchObject.checkOutDate).add(6, "days"),
    },
    {
      value: null,

      checkInDate: moment(hotelSearchObject.checkInDate).add(7, "days"),
      checkOutDate: moment(hotelSearchObject.checkOutDate).add(7, "days"),
    },
    {
      value: null,

      checkInDate: moment(hotelSearchObject.checkInDate).add(8, "days"),
      checkOutDate: moment(hotelSearchObject.checkOutDate).add(8, "days"),
    },
    {
      value: null,

      checkInDate: moment(hotelSearchObject.checkInDate).add(9, "days"),
      checkOutDate: moment(hotelSearchObject.checkOutDate).add(9, "days"),
    },
    {
      value: null,

      checkInDate: moment(hotelSearchObject.checkInDate).add(10, "days"),
      checkOutDate: moment(hotelSearchObject.checkOutDate).add(10, "days"),
    },
  ];
  return (
    <div>
      <div className="nearby-accordion">
        <WeekFareSlider flexiData={flexi_dates} serviceType={2} />
      </div>
    </div>
  );
};

export const BusDates = ({ busSearchObject }) => {
  let flexi_dates = [
    {
      value: null,

      journeyDate: moment(busSearchObject.journeyDate, "DD-MM-YYYY").add(
        1,
        "days"
      ),
    },
    {
      value: null,

      journeyDate: moment(busSearchObject.journeyDate, "DD-MM-YYYY").add(
        2,
        "days"
      ),
    },
    {
      value: null,

      journeyDate: moment(busSearchObject.journeyDate, "DD-MM-YYYY").add(
        3,
        "days"
      ),
    },
    {
      value: null,

      journeyDate: moment(busSearchObject.journeyDate, "DD-MM-YYYY").add(
        4,
        "days"
      ),
    },
    {
      value: null,

      journeyDate: moment(busSearchObject.journeyDate, "DD-MM-YYYY").add(
        5,
        "days"
      ),
    },
    {
      value: null,

      journeyDate: moment(busSearchObject.journeyDate, "DD-MM-YYYY").add(
        6,
        "days"
      ),
    },
    {
      value: null,

      journeyDate: moment(busSearchObject.journeyDate, "DD-MM-YYYY").add(
        7,
        "days"
      ),
    },
    {
      value: null,

      journeyDate: moment(busSearchObject.journeyDate, "DD-MM-YYYY").add(
        8,
        "days"
      ),
    },
    {
      value: null,

      journeyDate: moment(busSearchObject.journeyDate, "DD-MM-YYYY").add(
        9,
        "days"
      ),
    },
    {
      value: null,

      journeyDate: moment(busSearchObject.journeyDate, "DD-MM-YYYY").add(
        10,
        "days"
      ),
    },
  ];
  return (
    <div>
      <div className="nearby-accordion">
        <WeekFareSlider flexiData={flexi_dates} serviceType={3} />
      </div>
    </div>
  );
};

export const ActivitiesDates = ({
  activitySearchObject,
  showActivityDates = false,
}) => {
  let flexi_dates = [
    {
      value: null,

      travelDate: moment(activitySearchObject.travelDate, "YYYY-MM-DD").add(
        1,
        "days"
      ),
    },
    {
      value: null,

      travelDate: moment(activitySearchObject.travelDate, "YYYY-MM-DD").add(
        2,
        "days"
      ),
    },
    {
      value: null,

      travelDate: moment(activitySearchObject.travelDate, "YYYY-MM-DD").add(
        3,
        "days"
      ),
    },
    {
      value: null,

      travelDate: moment(activitySearchObject.travelDate, "YYYY-MM-DD").add(
        4,
        "days"
      ),
    },
    {
      value: null,

      travelDate: moment(activitySearchObject.travelDate, "YYYY-MM-DD").add(
        5,
        "days"
      ),
    },
    {
      value: null,

      travelDate: moment(activitySearchObject.travelDate, "YYYY-MM-DD").add(
        6,
        "days"
      ),
    },
    {
      value: null,

      travelDate: moment(activitySearchObject.travelDate, "YYYY-MM-DD").add(
        7,
        "days"
      ),
    },
    {
      value: null,

      travelDate: moment(activitySearchObject.travelDate, "YYYY-MM-DD").add(
        8,
        "days"
      ),
    },
    {
      value: null,

      travelDate: moment(activitySearchObject.travelDate, "YYYY-MM-DD").add(
        9,
        "days"
      ),
    },
    {
      value: null,

      travelDate: moment(activitySearchObject.travelDate, "YYYY-MM-DD").add(
        10,
        "days"
      ),
    },
  ];

  return (
    <div>
      <div className="nearby-accordion">
        {showActivityDates ? (
          <WeekFareSlider flexiData={flexi_dates} serviceType={4} />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
export const HoliodayDates = ({ holidaySearchObject }) => {
  let flexi_dates = [
    {
      value: null,

      travelDate: holidaySearchObject.travelDate
        ? moment(holidaySearchObject.travelDate, "YYYY-MM-DD").add(1, "days")
        : moment().add(1, "days"),
    },

    {
      value: null,

      travelDate: holidaySearchObject.travelDate
        ? moment(holidaySearchObject.travelDate, "YYYY-MM-DD").add(2, "days")
        : moment().add(2, "days"),
    },
    {
      value: null,

      travelDate: holidaySearchObject.travelDate
        ? moment(holidaySearchObject.travelDate, "YYYY-MM-DD").add(3, "days")
        : moment().add(3, "days"),
    },
    {
      value: null,

      travelDate: holidaySearchObject.travelDate
        ? moment(holidaySearchObject.travelDate, "YYYY-MM-DD").add(4, "days")
        : moment().add(4, "days"),
    },
    {
      value: null,

      travelDate: holidaySearchObject.travelDate
        ? moment(holidaySearchObject.travelDate, "YYYY-MM-DD").add(5, "days")
        : moment().add(5, "days"),
    },
    {
      value: null,

      travelDate: holidaySearchObject.travelDate
        ? moment(holidaySearchObject.travelDate, "YYYY-MM-DD").add(6, "days")
        : moment().add(6, "days"),
    },
    {
      value: null,

      travelDate: holidaySearchObject.travelDate
        ? moment(holidaySearchObject.travelDate, "YYYY-MM-DD").add(7, "days")
        : moment().add(7, "days"),
    },
    {
      value: null,

      travelDate: holidaySearchObject.travelDate
        ? moment(holidaySearchObject.travelDate, "YYYY-MM-DD").add(8, "days")
        : moment().add(8, "days"),
    },
    {
      value: null,

      travelDate: holidaySearchObject.travelDate
        ? moment(holidaySearchObject.travelDate, "YYYY-MM-DD").add(9, "days")
        : moment().add(9, "days"),
    },
    {
      value: null,

      travelDate: holidaySearchObject.travelDate
        ? moment(holidaySearchObject.travelDate, "YYYY-MM-DD").add(10, "days")
        : moment().add(10, "days"),
    },
  ];
  return (
    <div>
      <div className="nearby-accordion">
        <WeekFareSlider flexiData={flexi_dates} serviceType={6} />
      </div>
    </div>
  );
};

export const CFDates = ({ charterFlightSearchObj }) => {
  let flexi_dates = [
    {
      value: null,
      dated: moment(charterFlightSearchObj.departureDateTime).add(1, "days"),
    },
    {
      value: null,
      dated: moment(charterFlightSearchObj.departureDateTime).add(2, "days"),
    },
    {
      value: null,
      dated: moment(charterFlightSearchObj.departureDateTime).add(3, "days"),
    },
    {
      value: null,
      dated: moment(charterFlightSearchObj.departureDateTime).add(4, "days"),
    },
    {
      value: null,
      dated: moment(charterFlightSearchObj.departureDateTime).add(5, "days"),
    },
    {
      value: null,
      dated: moment(charterFlightSearchObj.departureDateTime).add(6, "days"),
    },
    {
      value: null,
      dated: moment(charterFlightSearchObj.departureDateTime).add(7, "days"),
    },
    {
      value: null,
      dated: moment(charterFlightSearchObj.departureDateTime).add(8, "days"),
    },
    {
      value: null,
      dated: moment(charterFlightSearchObj.departureDateTime).add(9, "days"),
    },
    {
      value: null,
      dated: moment(charterFlightSearchObj.departureDateTime).add(10, "days"),
    },
  ];

  return (
    <div>
      <div className="nearby-accordion">
        <WeekFareSlider flexiData={flexi_dates} serviceType={10} />
      </div>
    </div>
  );
};

export const CruiseDates = ({
  cruiseSearchObject,
  CruiseNameFilterObj,
  setCruiseResultsList,
  cruiseSearchResultObj,
}) => {
  let flexi_dates = [
    {
      value: null,

      travelDate: cruiseSearchObject.travelDate
        ? moment(cruiseSearchObject.travelDate, "YYYY-MM-DD").add(1, "days")
        : moment().add(1, "days"),
    },

    {
      value: null,

      travelDate: cruiseSearchObject.travelDate
        ? moment(cruiseSearchObject.travelDate, "YYYY-MM-DD").add(2, "days")
        : moment().add(2, "days"),
    },
    {
      value: null,

      travelDate: cruiseSearchObject.travelDate
        ? moment(cruiseSearchObject.travelDate, "YYYY-MM-DD").add(3, "days")
        : moment().add(3, "days"),
    },
    {
      value: null,

      travelDate: cruiseSearchObject.travelDate
        ? moment(cruiseSearchObject.travelDate, "YYYY-MM-DD").add(4, "days")
        : moment().add(4, "days"),
    },
    {
      value: null,

      travelDate: cruiseSearchObject.travelDate
        ? moment(cruiseSearchObject.travelDate, "YYYY-MM-DD").add(5, "days")
        : moment().add(5, "days"),
    },
    {
      value: null,

      travelDate: cruiseSearchObject.travelDate
        ? moment(cruiseSearchObject.travelDate, "YYYY-MM-DD").add(6, "days")
        : moment().add(6, "days"),
    },
    {
      value: null,

      travelDate: cruiseSearchObject.travelDate
        ? moment(cruiseSearchObject.travelDate, "YYYY-MM-DD").add(7, "days")
        : moment().add(7, "days"),
    },
    {
      value: null,

      travelDate: cruiseSearchObject.travelDate
        ? moment(cruiseSearchObject.travelDate, "YYYY-MM-DD").add(8, "days")
        : moment().add(8, "days"),
    },
    {
      value: null,

      travelDate: cruiseSearchObject.travelDate
        ? moment(cruiseSearchObject.travelDate, "YYYY-MM-DD").add(9, "days")
        : moment().add(9, "days"),
    },
    {
      value: null,

      travelDate: cruiseSearchObject.travelDate
        ? moment(cruiseSearchObject.travelDate, "YYYY-MM-DD").add(10, "days")
        : moment().add(10, "days"),
    },
  ];

  return (
    <div>
      <div className="nearby-accordion">
        <WeekFareSlider flexiData={flexi_dates} serviceType={9} />
      </div>
    </div>
  );
};

export const WeekFareSlider = ({ flexiData, serviceType }) => {
  //console.log(serviceType,"typefli8");
  const MobileSlidersettings = {
    arrows: true,
    speed: 700,
    slidesToShow: flexiData.length >= 5 ? 5 : flexiData.length,
    slidesToScroll: 1,
    initialSlide: 0,
    infinite: true,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,

          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  let queryStringData = queryString.parse(window.location.search);
// {console.log(serviceType,"types")}
  if (serviceType === 1) {
    return (
      <>
        {flexiData.length > 0 && serviceType === 1 ? (
          <div className="mt-4    fare-Slider ">
            <Slider {...MobileSlidersettings}>
              {flexiData.map((item, ind) => {
                let departureDate = item.dated.format("YYYY-MM-DD");
                queryStringData.departureDateTime = departureDate;

                let newString = queryString.stringify(queryStringData);
                return (
                  <Link to={`/flight/results?${newString}`} exact>
                    <div key={"farelist" + ind}>
                      <div className="fare-slider-card">
                        <p className="date">
                          {item.dated.format("ddd MMM, DD")}
                        </p>
                        <p className="fetchFare"> Fetch Fare</p>
                      </div>
                    </div>
                  </Link>
                );
              })}
            </Slider>
          </div>
        ) : null}
      </>
    );
  }
  if (serviceType === 2) {
    return (
      <>
        {" "}
        {flexiData.length > 0 && serviceType === 2 ? (
          <div className="mt-4    fare-Slider ">
            <Slider {...MobileSlidersettings}>
              {flexiData.map((item, ind) => {
                let checkInDate = item.checkInDate.format("YYYY-MM-DD");
                let checkOutDate = item.checkOutDate.format("YYYY-MM-DD");
                queryStringData.checkInDate = checkInDate;
                queryStringData.checkOutDate = checkOutDate;

                let newString = queryString.stringify(queryStringData);
                return (
                  <Link to={`/hotels/listing?${newString}`}>
                    <div key={"farelist" + ind}>
                      <div className="fare-slider-card">
                        <p className="date">
                          {`${item.checkInDate.format(
                            "MMM, DD"
                          )} To ${item.checkOutDate.format("MMM, DD")} `}
                        </p>
                        <p className="fetchFare"> Fetch Fare</p>
                      </div>
                    </div>
                  </Link>
                );
              })}
            </Slider>
          </div>
        ) : (
          ""
        )}
      </>
    );
  }
  if (serviceType === 3) {
    return (
      <>
        {" "}
        {flexiData.length > 0 && serviceType === 3 ? (
          <div className="mb-3    fare-Slider ">
            <Slider {...MobileSlidersettings}>
              {flexiData.map((item, ind) => {
                let journeyDate = item.journeyDate.format("DD-MM-YYYY");

                queryStringData.journeyDate = journeyDate;

                let newString = queryString.stringify(queryStringData);
                return (
                  <Link to={`/bus/results?${newString}`}>
                    <div key={"farelist" + ind}>
                      <div className="fare-slider-card">
                        <p className="date">
                          {`${item.journeyDate.format("ddd MMM, DD")}  `}
                        </p>
                        <p className="fetchFare"> Fetch Fare</p>
                      </div>
                    </div>
                  </Link>
                );
              })}
            </Slider>
          </div>
        ) : (
          ""
        )}
      </>
    );
  }
  if (serviceType === 4) {
    return (
      <>
        {" "}
        {flexiData.length > 0 && serviceType === 4 ? (
          <div className="mb-3    fare-Slider ">
            <Slider {...MobileSlidersettings}>
              {flexiData.map((item, ind) => {
                let travelDate = item.travelDate.format("YYYY-MM-DD");

                queryStringData.travelDate = travelDate;

                let newString = queryString.stringify(queryStringData);
                return (
                  <Link to={`/activities/results?${newString}`}>
                    <div key={"farelist" + ind}>
                      <div className="fare-slider-card">
                        <p className="date">
                          {`${item.travelDate.format("ddd MMM, DD")}  `}
                        </p>
                        <p className="fetchFare"> Fetch Fare</p>
                      </div>
                    </div>
                  </Link>
                );
              })}
            </Slider>
          </div>
        ) : (
          ""
        )}
      </>
    );
  }

  if (serviceType === 6) {
    return (
      <>
        {" "}
        {flexiData.length > 0 && serviceType === 6 ? (
          <div className="mb-3    fare-Slider ">
            <Slider {...MobileSlidersettings}>
              {flexiData.map((item, ind) => {
                let travelDate = item.travelDate.format("YYYY-MM-DD");

                queryStringData.travelDate = travelDate;

                let newString = queryString.stringify(queryStringData);
                return (
                  <Link to={`/holidays/results?${newString}`}>
                    <div key={"farelist" + ind}>
                      <div className="fare-slider-card">
                        <p className="date">
                          {`${item.travelDate.format("ddd MMM, DD")}  `}
                        </p>
                        <p className="fetchFare"> Fetch Fare</p>
                      </div>
                    </div>
                  </Link>
                );
              })}
            </Slider>
          </div>
        ) : (
          ""
        )}
      </>
    );
  }

  if (serviceType === 9) {
    return (
      <>
        {" "}
        {flexiData.length > 0 && serviceType === 9 ? (
          <div className="mb-3    fare-Slider ">
            <Slider {...MobileSlidersettings}>
              {flexiData.map((item, ind) => {
                let travelDate = item.travelDate.format("YYYY-MM-DD");

                queryStringData.travelDate = travelDate;

                let newString = queryString.stringify(queryStringData);
                return (
                  <Link to={`/cruise/results?${newString}`}>
                    <div key={"farelist" + ind}>
                      <div className="fare-slider-card">
                        <p className="date">
                          {`${item.travelDate.format("ddd MMM, DD")}  `}
                        </p>
                        <p className="fetchFare"> Fetch Fare</p>
                      </div>
                    </div>
                  </Link>
                );
              })}
            </Slider>
          </div>
        ) : (
          ""
        )}
      </>
    );
  }

  if (serviceType === 10) {
    return (
      <>
        {flexiData.length > 0 && serviceType === 10 ? (
          <div className="mb-3    fare-Slider ">
            <Slider {...MobileSlidersettings}>
              {flexiData.map((item, ind) => {
                let departureDate = item.dated.format("YYYY-MM-DD HH:mm");
                queryStringData.departureDateTime = departureDate;

                let newString = queryString.stringify(queryStringData);
                return (
                  <Link to={`/charterFlights/results?${newString}`} exact>
                    <div key={"farelist" + ind}>
                      <div className="fare-slider-card">
                        <p className="date">
                          {item.dated.format("ddd MMM, DD")}
                        </p>
                        <p className="fetchFare"> Fetch Fare</p>
                      </div>
                    </div>
                  </Link>
                );
              })}
            </Slider>
          </div>
        ) : null}
      </>
    );
  }
};

export const AirlineSlider = ({
  airlineFilters,
  flightAirSearchResp,
  selectedTripType,
  ResetAirlineMatrix,
  updateFlightAirSearchRespObj,
  MultiCityIndex
}) => {
  const MobileSlidersettings = {
    arrows: false,
    speed: 700,
    slidesToShow: airlineFilters && airlineFilters.length >= 5 ? 5 : 4,
    slidesToScroll: 1,
    initialSlide: 0,
    infinite: true,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,

          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  const timeRange = { start: "00:00", end: "23:59" };
// console.log(airlineFilters,"filt-9");
  const { activeCurrency, currencyValue } = useCurrencyContext();
  const {
    isLogin: { agent },
  } = useAuthContext();

  const { updateFlightFilterForSlider } = useContext(GlobalStatesContext);

  let flightDetails =
    (selectedTripType === 1 && flightAirSearchResp.airTravelType.toUpperCase() != "MULTIDESTINATION") ||
      (flightAirSearchResp.airTravelType.toUpperCase() === "MULTIDESTINATION" && flightAirSearchResp.resultsType.toUpperCase() === "COMBINED")
      ? flightAirSearchResp.flightDetails
      : flightAirSearchResp.airTravelType.toUpperCase() == "MULTIDESTINATION" ? flightAirSearchResp.multicityFlightDetails[MultiCityIndex] : flightAirSearchResp.ibFlightDetails;

  const { Panel } = Collapse;

  const [filters, setFilters] = useState({});
  const [departureVal, setDepartureVal] = useState(timeRange);
  const [arrivalVal, setArrivalVal] = useState(timeRange);
  const [priceRange, setPriceRange] = useState([]);
  let count = flightDetails?.length;

  const [resultCount, setResultCount] = useState(0);
  let visibleCount = 0;
  let filtersObj = {
    stops: [],
    price: { minPrice: 0, maxPrice: 0, maxPriceRange: 0, minPriceRange: 0 },
    departure: {},
    arrival: {},
    airlines: [],
    fareTypes: [],
    connect: [],
  };
  useEffect(() => {
    let resCount = flightDetails.filter((item) => item.isVisible).length;
    setResultCount(resCount);
  }, [flightDetails]);

  const onChange = (event, filterType, filterId) => {
    setFilters({
      [filterType]: filters[filterType].map((filt) => {
        return { ...filt, isChecked: filt.id == filterId ? true : false };
      }),
    });

    // updateFlightFilterForSlider({
    //   ...filters,
    //   [filterType]: filters[filterType].map((filt) =>
    //     filt.id == filterId ? { ...filt, isChecked: checked } : filt
    //   ),
    // });

    scrollToListTop();
  };

  //Scroll to Top of the List
  const scrollToListTop = () => {
    document.getElementsByClassName("result_div-container")[0].scrollIntoView({
      behavior: "smooth",
    });
  };

  const getFlightMinPrice = (airline) => {
    const minPriceFlightObj = flightDetails
      .filter((air) => air.airLine === airline)
      .reduce((prev, cur, i) => {
        let prevFare = prev?.fareFamilies?.fareFamilies[0]?.adultPublishFare;
        let curFare = cur?.fareFamilies?.fareFamilies[0]?.adultPublishFare;

        return prevFare < curFare ? prev : cur;
      });

    return isNaN(
      minPriceFlightObj.fareFamilies?.fareFamilies[0]?.adultPublishFare
    )
      ? null
      : Math.floor(
        minPriceFlightObj.fareFamilies?.fareFamilies[0]?.adultPublishFare
      );
  };

  const checkIfExist = (filterTypeObj, id) =>
    filterTypeObj.filter((obj) => obj["id"] === id).length === 0;

  const setDynamicFilters = () => {
    setDepartureVal(timeRange);
    setArrivalVal(timeRange);

    if (
      (flightAirSearchResp.airTravelType.toUpperCase() === "ROUNDTRIP" &&
        flightAirSearchResp.resultsType === "COMBINED") || (flightAirSearchResp.airTravelType === "MULTIDESTINATION" &&
          flightAirSearchResp.resultsType === "COMBINED")
    ) {
      for (let i = 0; i < flightDetails.length; i++) {
        //Setting Airlines Filters
        checkIfExist(
          filtersObj.airlines,
          flightDetails[i].flightDetails[0].airLine
        ) &&
          filtersObj.airlines.push({
            id: flightDetails[i].flightDetails[0].airLine,
            label: flightDetails[i].flightDetails[0].airLineName,
            logo: flightDetails[i].airLineLogo,
            count: flightDetails.filter(
              (air) => air.airLine === flightDetails[i].airLine
            ).length,
            price: getFlightMinPrice(flightDetails[i].airLine),
            isChecked: false,
          });
      }
    } else {
      for (let i = 0; i < flightDetails.length; i++) {
        //Setting Airlines Filters
        checkIfExist(filtersObj.airlines, flightDetails[i].airLine) &&
          filtersObj.airlines.push({
            id: flightDetails[i].airLine,
            label: flightDetails[i].airLineName,
            logo: flightDetails[i].airLineLogo,
            count: flightDetails.filter(
              (air) => air.airLine === flightDetails[i].airLine
            ).length,
            price: getFlightMinPrice(flightDetails[i].airLine),
            isChecked: false,
          });
      }
    }

    setFilters(filtersObj);
    updateFlightFilterForSlider(filtersObj);

    setPriceRange([filtersObj.price.minPrice, filtersObj.price.maxPrice]);
  };

  const checkedFilters = (filterType) => {
    return filters[filterType].filter((filter) => filter.isChecked);
  };

  const mapFlightWithFilter = (flightDetails) => {
    const airlinesChecked = checkedFilters("airlines");

    return flightDetails.map((flight) => {
      let isVisible = true;

      if (
        airlinesChecked.length &&
        !airlinesChecked.map((airline) => airline.id).includes(flight.airLine)
      ) {
        isVisible = false;
      }

      isVisible && visibleCount++;

      setResultCount(visibleCount);

      return { ...flight, isVisible: isVisible };
    });
  };

  const mapCombinedFlightWithFilter = (flightDetails) => {
    const airlinesChecked = checkedFilters("airlines");

    return flightDetails.map((flight) => {
      let isVisible = true;

      flight.flightDetails.map((flightItem) => {
        if (
          airlinesChecked.length &&
          !airlinesChecked
            .map((airline) => airline.id)
            .includes(flightItem.airLine)
        ) {
          isVisible = false;
        }
      });

      isVisible && visibleCount++;

      setResultCount(visibleCount);

      return { ...flight, isVisible: isVisible };
    });
  };

  const combinedFilters = () => {
    updateFlightAirSearchRespObj({
      ...flightAirSearchResp,
      flightDetails: mapCombinedFlightWithFilter(flightDetails),
    });
  };

  const applyFilters = () => {
    if (selectedTripType === 1 && flightAirSearchResp.airTravelType.toUpperCase() != "MULTIDESTINATION" ||
      (flightAirSearchResp.airTravelType.toUpperCase() === "MULTIDESTINATION" && flightAirSearchResp.resultsType.toUpperCase() === "COMBINED")) {
      updateFlightAirSearchRespObj({
        ...flightAirSearchResp,
        flightDetails: mapFlightWithFilter(flightDetails),
      });
    } else if (flightAirSearchResp.airTravelType.toUpperCase() == "MULTIDESTINATION") {
      let copyFlightsFilters = [...flightAirSearchResp.multicityFlightDetails];

      copyFlightsFilters[MultiCityIndex] = mapFlightWithFilter(flightDetails);
      updateFlightAirSearchRespObj({
        ...flightAirSearchResp,
        multicityFlightDetails: copyFlightsFilters,
      });
    }

    else {
      updateFlightAirSearchRespObj({
        ...flightAirSearchResp,
        ibFlightDetails: mapFlightWithFilter(flightDetails),
      });
    }
  };

  useEffect(() => {
    setDynamicFilters();
  }, [selectedTripType]);

  useEffect(() => {
    if (
      (flightAirSearchResp.airTravelType.toUpperCase() === "ROUNDTRIP" &&
        flightAirSearchResp.resultsType.toUpperCase() === "COMBINED") || (flightAirSearchResp.airTravelType.toUpperCase() === "MULTIDESTINATION" &&
          flightAirSearchResp.resultsType.toUpperCase() === "COMBINED")
    ) {
      Object.keys(filters).length && combinedFilters();
    } else {
      Object.keys(filters).length && applyFilters();
    }
  }, [filters]);

  return (
    <>
      {airlineFilters && airlineFilters.length > 0 ? (
        <div className="mt-4    fare-Slider ">
          <Slider {...MobileSlidersettings}>
            {airlineFilters.map((item, ind) => {
              return (
                <div
                  key={"airlineFilters" + ind}
                  onClick={(e) => {
                    onChange(e, "airlines", item.id);
                  }}
                >
                  <div className="fare-slider-card d-flex align-items-center justify-content-between px-4">
                    <img
                      src={item?.logo}
                      className="airline-logo w-25"
                      alt=""
                    />

                    <div>
                      <p className="fetchFare">{item?.label} </p>
                      <p className="fetchFare">INR {item?.price} </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      ) : null}
    </>
  );
};
export const CruiseSlider = ({
  cruiseSearchObject,
  CruiseNameFilterObj,
  setCruiseResultsList,
  cruiseSearchResultObj,
}) => {
  const { activeCurrency, currencyValue } = useCurrencyContext();
  let {
    cruise: { cruise, filtersObj },
  } = cruiseSearchResultObj;
  let count = cruise?.length;

  const MobileSlidersettings = {
    arrows: false,
    speed: 700,
    slidesToShow:
      filtersObj?.cruiseName && filtersObj?.cruiseName >= 5
        ? 5
        : filtersObj?.cruiseName.length,
    slidesToScroll: 1,
    initialSlide: 0,
    infinite: true,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,

          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  const [resultCount, setResultCount] = useState(count);
  const [filters, setFilters] = useState({});
  const [priceRange, setPriceRange] = useState([]);

  const onChange = (event, filterType, filterId) => {
    setFilters({
      [filterType]: filters[filterType].map((filt) => {
        return { ...filt, isChecked: filt.id == filterId ? true : false };
      }),
    });
    scrollToListTop();
  };

  //Scroll to Top of the List
  const scrollToListTop = () => {
    document.getElementsByClassName("holiday-wrapper")[0].scrollIntoView({
      behavior: "smooth",
    });
  };
  const checkIfExist = (filterTypeObj, id) =>
    filterTypeObj.filter((obj) => obj["id"] === id).length === 0;

  const setDynamicFilters = () => {
    if (Object.keys(filtersObj).length > 0) {
      setFilters(filtersObj);
      setPriceRange([filtersObj?.price?.minPrice, filtersObj?.price?.maxPrice]);
    }
  };

  const checkedFilters = (filterType) => {
    return filters[filterType].filter((filter) => filter.isChecked);
  };

  const applyFilters = () => {
    let visibleCount = 0;

    const cruiseChecked = checkedFilters("cruiseName");

    let filteredList = cruise.map((tour) => {
      let isVisible = true;

      if (
        cruiseChecked.length &&
        !cruiseChecked.map((pTitle) => pTitle.id).includes(tour.CruiseName)
      ) {
        isVisible = false;
      }

      isVisible && visibleCount++;

      return { ...tour, isVisible: isVisible };
    });

    filteredList = filteredList.filter((item) => item.isVisible);

    setCruiseResultsList({
      ...cruiseSearchResultObj.cruise,
      cruise: filteredList,
    });
    setResultCount(visibleCount);
  };

  useEffect(() => {
    setDynamicFilters();
  }, []);

  useEffect(() => {
    Object.keys(filters).length && applyFilters();
  }, [filters]);

  return (
    <>
      {filtersObj?.cruiseName && filtersObj?.cruiseName.length > 0 ? (
        <div className="mb-3    fare-Slider ">
          <Slider {...MobileSlidersettings}>
            {filtersObj?.cruiseName?.map((item, ind) => {
              return (
                <div
                  key={"cruiseFilter" + ind}
                  onClick={(e) => {
                    onChange(e, "cruiseName", item.id);
                  }}
                >
                  <div className="fare-slider-card d-flex align-items-center justify-content-between px-4">
                    <div>
                      <p className="fetchFare">{item?.label} </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      ) : null}
    </>
  );
};

export default FlightDates;

import React from 'react'
import './TopDestination.scss';
import mumbai from '../../../assets/images/hotels/Mumbai.jpg'
import goa from '../../../assets/images/hotels/goa.jpg'
import agra from '../../../assets/images/hotels/Agra.jpg'
import varanasi from '../../../assets/images/hotels/varanasi.jpg'
import ooty from '../../../assets/images/hotels/Ooty.jpg'
import pondicheery from '../../../assets/images/hotels/pondicheery.jpg'
import mysore from '../../../assets/images/hotels/mysore.jpg'
import kolkata from '../../../assets/images/hotels/kolkata.jpg'
import delhi from '../../../assets/images/hotels/delhi.jpg'
import moment from "moment";
import { Link } from "react-router-dom";
import queryString from "query-string";
const TopDestination = () => {
    const hotels = [
        { name: "Goa", type: "Homestays - Villas & Apts", image: goa , cityName: "Goa,India", cityId:22506 },
        { name: "Varanasi", type: "Homestays - Villas & Apts", image:varanasi,cityName: "Varanasi,India", cityId:23118 },
        { name: "Agra", type: "Homestays - Villas & Apts", image: agra,cityName: "Agra,India", cityId:22236 },
        { name: "Mumbai", type: "Homestays - Villas & Apts", image:mumbai,cityName: "Mumbai,India", cityId: 22798 },
        { name: "Ooty", type: "Homestays - Villas & Apts", image: ooty,cityName: "Ooty,India", cityId:22856 },
        { name: "pondicherry", type: "Homestays - Villas & Apts", image: pondicheery,cityName: "Pondicherry,India", cityId:22914 },
        { name: "Delhi", type: "Homestays - Villas & Apts", image: delhi,cityName: "Delhi,India", cityId:22846 },
        { name: "kolkata", type: "Homestays - Villas & Apts", image: kolkata,cityName: "Kolkata,India", cityId:22681 },
        { name: "Mysuru", type: "Homestays - Villas & Apts", image: mysore,cityName: "Mysuru,India", cityId: 22809 }
      ];
      const tomorrowDate = moment().add(5, 'days').format("YYYY-MM-DD");
      const dayafter = moment().add(6, "days").format("YYYY-MM-DD");
    
      const getHotelSer = (cityName, cityId) => {
        const formData = {
          checkInDate: tomorrowDate,
          checkOutDate: dayafter,
          hotelCityCode: `cityName=${cityName}&&cityId=${cityId}`,
          roomGuests: JSON.stringify([{ noOfAdults: 1, noOfChilds: 0, childAge: [] }]),
          nationality: "IN",
          currency: "INR",
          countryCode: "IN",
          traceId: "string",
        };
        const query = queryString.stringify(formData);
        return "/hotels/listing?" + query;
      };
  return (
    <div className="top-hotels">
     
      <div className="hotels-grid">
        {hotels.map((hotel, index) => (
          <div key={index} className="hotel-item">
            <img src={hotel.image} alt={hotel.name} className="hotel-image" />
            <div className="hotel-info1">
              <h3 className="hotel-nmc"> <Link className="anc-tab" to={getHotelSer(hotel.cityName, hotel.cityId)} style={{color:"black"}}>{hotel.name}  </Link></h3>
              <p className="hotel-typeN">{hotel.type}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default TopDestination

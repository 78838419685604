
import React, { useState, useEffect, useContext } from "react";
import "./FlightFilters.scss";
import { Card, Checkbox, Collapse, Slider } from "antd";
import { useCurrencyContext } from "../../common/providers/CurrencyProvider";
import { useAuthContext } from "../../common/providers/AuthProvider";
import moment from "moment";
import TimeRangeSlider from "react-time-range-slider";
import { GlobalStatesContext } from "../../common/providers";
import refund from "../../assets/images/Icons/refund.png";
import norefund from "../../assets/images/Icons/no-refund.png";
import morning from "../../assets/images/Icons/morning-icon.png";
import afternoon from "../../assets/images/Icons/afternoon-icon.png";
import evening from "../../assets/images/Icons/evening-icon.png";
import night from "../../assets/images/Icons/night-icon.png";
import CusImgFlifilter from "../../common/CustomImg/CustomFliFilterImg";
const timeRange = { start: "00:00", end: "23:59" };


const FlightRoundFilter = ({
  flightAirSearchResp,
  selectedTripType,
  updateFlightAirSearchRespObj,
  ResetAirlineMatrix,
  MultiCityIndex
}) => {
  const { activeCurrency, currencyValue } = useCurrencyContext();
  const {
    isLogin: { agent },
  } = useAuthContext();
  const [selectTrip, setSelectTrip] = useState(1);
  const [selectedButton, setSelectedButton] = useState(null);
  const { updateFlightFilterForSlider } = useContext(GlobalStatesContext);
  let flightDetails = flightAirSearchResp.flightDetails;;
  const handleOnward = () => {
    // console.log("11")
    setSelectTrip(1);
    setSelectedButton('onward');
  };

  const handleReturn = () => {
    // console.log("22")
    setSelectedButton('return');
    flightDetails = flightAirSearchResp.ibFlightDetails;
    setSelectTrip(2);
  };


  const { Panel } = Collapse;

  const [filters, setFilters] = useState({});
  const [departureVal, setDepartureVal] = useState(timeRange);
  const [arrivalVal, setArrivalVal] = useState(timeRange);
  const [priceRange, setPriceRange] = useState([]);
  // const [durationFilter, setDurationFilter] = useState([]);
  // const [layoverFilter, setLayoverFilter] = useState([]);
  let count = flightDetails?.length;

  const [resultCount, setResultCount] = useState(0);
  let visibleCount = 0;
  const flightTimings = [
    {
      id: "morning",
      start: "00:00",
      end: "06:00",
      label: "Morning",
      isChecked: false,
      icon: morning,
    },
    {
      id: "afterNoon",
      start: "06:00",
      end: "12:00",
      label: "AfterNoon",
      isChecked: false,
      icon: afternoon,
    },
    {
      id: "evening",
      start: "12:00",
      end: "18:00",
      label: "Evening",
      isChecked: false,
      icon: evening,
    },
    {
      id: "night",
      start: "18:00",
      end: "23:59",
      label: "Night",
      isChecked: false,
      icon: night,
    }
  ]
  let filtersObj = {
    stops: [],
    price: { minPrice: 0, maxPrice: 0, maxPriceRange: 0, minPriceRange: 0 },
    depatureTimings: flightTimings,
    arrivaltimings: flightTimings,
    departure: {},
    arrival: {},
    airlines: [],
    fareTypes: [],
    connect: [],
    duration: { min: 0, max: 0, maxdurationRange: 0, mindurationRange: 0 },
    layover: { layovermin: 0, layovermax: 0, maxlayoverRange: 0, minlayoverRange: 0 },
    Supplier: [],
  };
  useEffect(() => {
    let resCount = flightDetails?.filter((item) => item.isVisible).length;
    setResultCount(resCount);
  }, [flightDetails]);

  const onChange = (event, filterType, filterId) => {
    let { checked } = event.target;

    setFilters({
      ...filters,
      [filterType]: filters[filterType].map((filt) =>
        filt.id == filterId ? { ...filt, isChecked: checked } : filt
      ),
    });



    scrollToListTop();
  };

  const scrollToListTop = () => {
    document.getElementsByClassName("result_div-container")[0].scrollIntoView({
      behavior: "smooth",
    });
  };

  const getFlightMinPrice = (airline) => {

    const minPriceFlightObj = flightDetails
      ?.filter((air) => air.airLine === airline)
      ?.reduce((prev, cur, i) => {
        let prevFare = prev?.fareFamilies?.fareFamilies[0]?.adultPublishFare;
        let curFare = cur?.fareFamilies?.fareFamilies[0]?.adultPublishFare;

        return prevFare < curFare ? prev : cur;
      }, 0);

    return isNaN(
      minPriceFlightObj.fareFamilies?.fareFamilies[0]?.adultPublishFare
    )
      ? null
      : Math.floor(
        minPriceFlightObj.fareFamilies?.fareFamilies[0]?.adultPublishFare
      );
  };

  const checkIfExist = (filterTypeObj, id) =>
    filterTypeObj.filter((obj) => obj["id"] === id).length === 0;

  const setDynamicFilters = () => {
    setDepartureVal(timeRange);
    setArrivalVal(timeRange);

    if (
      (selectTrip === 1)) {
      // console.log("on")
      for (let i = 0; i < flightDetails.length; i++) {
        const flightSegLength = flightDetails[i].flightSegments.length;
        checkIfExist(filtersObj.stops, flightSegLength) &&
          filtersObj.stops.push({
            id: flightSegLength,
            label:
              flightSegLength - 1 === 0
                ? "Direct"
                : `${flightSegLength - 1} Stop(s)`,
            isChecked: false,
          });

        let flightTotPrice = Math.floor(
          flightDetails[i].fareFamilies.fareFamilies[0].adultPublishFare
        );
        if (i === 0) {
          filtersObj.price.minPrice = flightTotPrice;
        }
        let minFare = flightTotPrice;
        if (flightTotPrice > filtersObj.price.maxPrice) {
          filtersObj.price.maxPrice = filtersObj.price.maxPriceRange =
            flightTotPrice;
        }
        if (minFare < filtersObj.price.minPrice) {
          filtersObj.price.minPrice = minFare;
        }
        filtersObj.departure = timeRange;
        filtersObj.price.minPriceRange = filtersObj.price.minPrice;
        filtersObj.arrival = timeRange;

        checkIfExist(filtersObj.airlines, flightDetails[i].airLine) &&
          filtersObj.airlines.push({
            id: flightDetails[i].airLine,
            label: flightDetails[i].airLineName,
            logo: flightDetails[i].airLineLogo,
            count: flightDetails.filter(
              (air) => air.airLine === flightDetails[i].airLine
            ).length,
            price: getFlightMinPrice(flightDetails?.[i]?.airLine),
            isChecked: false,
          });

        filtersObj.fareType = [
          { id: "refundable", label: "Refundable", icon: refund, isChecked: false },
          { id: "nonRefundable", label: "Non Refundable", icon: norefund, isChecked: false },
        ];

        let { coupanType } = flightDetails[i].fareFamilies.fareFamilies[0];
        checkIfExist(filtersObj.fareTypes, coupanType) &&
          filtersObj.fareTypes.push({
            id: coupanType,
            label: coupanType,
            isChecked: false,
          });

        if (flightSegLength > 1) {
          const { flightSegments } = flightDetails[i];

          const connFlightsSegments = flightSegments.filter(
            (segment, index) => index !== flightSegLength - 1
          );

          connFlightsSegments.map(({ destination, destinationName }) => {
            checkIfExist(filtersObj.connect, destination) &&
              filtersObj.connect.push({
                id: destination,
                label: destination,
                labelHeading: destinationName,
                isChecked: false,
              });
          });
        }
        if (flightDetails.length > 0) {
          let durations = flightDetails.map(flight => {
            const durationParts = flight.duration.split("hrs");
            const hours = parseInt(durationParts[0]);
            return hours;
          });


          let minDuration = Math.min(...durations);
          let maxDuration = Math.max(...durations);
          filtersObj.duration = {
            min: minDuration,
            mindurationRange: minDuration,
            max: maxDuration,
            maxdurationRange: maxDuration,
          }
        }

        // if (flightDetails.length > 0) {
        //   let layoverss = flightDetails.map(flight => {
        //     const layoverParts = flight.layoutTime.split("hrs");
        //     const hour = parseInt(layoverParts[0]);

        //     return hour; // Extract hours only
        //   });


        //   let minsDuration = Math.min(...layoverss);
        //   let maxsDuration = Math.max(...layoverss);
        //   //console.log(minsDuration,maxsDuration,"lay")
        //   filtersObj.layover = {
        //     layovermin: minsDuration,
        //     minlayoverRange: minsDuration,
        //     layovermax: maxsDuration,
        //     maxlayoverRange: maxsDuration,
        //   }
        // }
      }
    } else {
      // console.log("ib")
      for (let i = 0; i < flightDetails.length; i++) {
        const flightSegLength = flightDetails[i].flightSegments.length;
        checkIfExist(filtersObj.stops, flightSegLength) &&
          filtersObj.stops.push({
            id: flightSegLength,
            label:
              flightSegLength - 1 === 0
                ? "Direct"
                : `${flightSegLength - 1} Stop(s)`,
            isChecked: false,
          });

        let flightTotPrice = Math.floor(
          flightDetails[i].fareFamilies.fareFamilies[0].adultPublishFare
        );
        if (i === 0) {
          filtersObj.price.minPrice = flightTotPrice;
        }
        let minFare = flightTotPrice;
        if (flightTotPrice > filtersObj.price.maxPrice) {
          filtersObj.price.maxPrice = filtersObj.price.maxPriceRange =
            flightTotPrice;
        }
        if (minFare < filtersObj.price.minPrice) {
          filtersObj.price.minPrice = minFare;
        }
        filtersObj.departure = timeRange;
        filtersObj.price.minPriceRange = filtersObj.price.minPrice;
        filtersObj.arrival = timeRange;

        checkIfExist(filtersObj.airlines, flightDetails[i].airLine) &&
          filtersObj.airlines.push({
            id: flightDetails[i].airLine,
            label: flightDetails[i].airLineName,
            logo: flightDetails[i].airLineLogo,
            count: flightDetails.filter(
              (air) => air.airLine === flightDetails[i].airLine
            ).length,
            price: getFlightMinPrice(flightDetails?.[i]?.airLine),
            isChecked: false,
          });

        filtersObj.fareType = [
          { id: "refundable", label: "Refundable", icon: refund, isChecked: false },
          { id: "nonRefundable", label: "Non Refundable", icon: norefund, isChecked: false },
        ];

        let { coupanType } = flightDetails[i].fareFamilies.fareFamilies[0];
        checkIfExist(filtersObj.fareTypes, coupanType) &&
          filtersObj.fareTypes.push({
            id: coupanType,
            label: coupanType,
            isChecked: false,
          });

        if (flightSegLength > 1) {
          const { flightSegments } = flightDetails[i];

          const connFlightsSegments = flightSegments.filter(
            (segment, index) => index !== flightSegLength - 1
          );

          connFlightsSegments.map(({ destination, destinationName }) => {
            checkIfExist(filtersObj.connect, destination) &&
              filtersObj.connect.push({
                id: destination,
                label: destination,
                labelHeading: destinationName,
                isChecked: false,
              });
          });
        }
        // if (flightDetails.length > 0) {
        //   let durations = flightDetails.map(flight => {
        //     const durationParts = flight.duration.split("hrs");
        //     const hours = parseInt(durationParts[0]);
        //     return hours;
        //   });


        //   let minDuration = Math.min(...durations);
        //   let maxDuration = Math.max(...durations);
        //   filtersObj.duration = {
        //     min: minDuration,
        //     mindurationRange: minDuration,
        //     max: maxDuration,
        //     maxdurationRange: maxDuration,
        //   }
        // }

        // if (flightDetails.length > 0) {
        //   let layoverss = flightDetails.map(flight => {
        //     const layoverParts = flight.layoutTime.split("hrs");
        //     const hour = parseInt(layoverParts[0]);

        //     return hour; // Extract hours only
        //   });


        //   let minsDuration = Math.min(...layoverss);
        //   let maxsDuration = Math.max(...layoverss);
        //   //console.log(minsDuration,maxsDuration,"lay")
        //   filtersObj.layover = {
        //     layovermin: minsDuration,
        //     minlayoverRange: minsDuration,
        //     layovermax: maxsDuration,
        //     maxlayoverRange: maxsDuration,
        //   }
        // }
      }
    }

    setFilters(filtersObj);
    updateFlightFilterForSlider(filtersObj);

   // setDurationFilter([filtersObj.duration.min, filtersObj.duration.max])
    setPriceRange([filtersObj.price.minPrice, filtersObj.price.maxPrice]);
    //setLayoverFilter([filtersObj.layover.layovermin, filtersObj.layover.layovermax])
  };

  const checkedFilters = (filterType) => {
    return filters[filterType]?.filter((filter) => filter.isChecked);
  };

  const mapFlightWithFilter = (flightDetails) => {
    const stopsChecked = checkedFilters("stops");
    const airlinesChecked = checkedFilters("airlines");
    const fareTypeChecked = checkedFilters("fareType");
    const fareTypesChecked = checkedFilters("fareTypes");
    const connectChecked = checkedFilters("connect");
    const filterDepatureTimings = checkedFilters("depatureTimings");
    const filterArrivalTimings = checkedFilters("arrivaltimings");

    return flightDetails.map((flight) => {
      let isVisible = true;

      if (
        stopsChecked.length &&
        !stopsChecked
          .map((stop) => stop.id)
          .includes(flight.flightSegments.length)
      ) {
        isVisible = false;
      }

      const flightTotPrice = Math.floor(
        flight.fareFamilies.fareFamilies[0].adultPublishFare
      );

      if (
        !(
          flightTotPrice >= filters.price.minPrice &&
          flightTotPrice <= filters.price.maxPrice
        )
      ) {
        isVisible = false;
      }

      const departureTime = moment(
        flight.flightSegments[0].departureDateTime
      ).format("HH:mm");
      if (
        !(
          departureTime >= filters.departure.start &&
          departureTime <= filters.departure.end
        )
      ) {
        isVisible = false;
      }

      const arrivalTime = moment(
        flight.flightSegments[flight.flightSegments.length - 1].arrivalDateTime
      ).format("HH:mm");
      if (
        !(
          arrivalTime >= filters.arrival.start &&
          arrivalTime <= filters.arrival.end
        )
      ) {
        isVisible = false;
      }

      if (
        filterDepatureTimings.length &&
        filterDepatureTimings.filter((deptTime) => departureTime >= deptTime.start &&
          departureTime <= deptTime.end).length == 0
      ) {
        isVisible = false;
      }
      if (
        filterArrivalTimings.length &&
        filterArrivalTimings.filter((deptTime) => arrivalTime >= deptTime.start &&
          arrivalTime <= deptTime.end).length == 0
      ) {
        isVisible = false;
      }

      if (
        airlinesChecked?.length &&
        !airlinesChecked.map((airline) => airline.id).includes(flight.airLine)
      ) {
        isVisible = false;
      }

      const { isRefundable } = flight;
      if (
        fareTypeChecked.length &&
        !fareTypeChecked
          .map((ftype) => ftype.id)
          .includes(isRefundable ? "refundable" : "nonRefundable")
      ) {
        isVisible = false;
      }

      if (fareTypesChecked.length) {
        const { coupanType } = flight.fareFamilies.fareFamilies[0];
        if (!fareTypesChecked.map((ftypes) => ftypes.id).includes(coupanType)) {
          isVisible = false;
        }
      }

      if (connectChecked.length) {
        const { flightSegments } = flight,
          connFlightsSegments = flightSegments.filter(
            (_, index) => index !== flightSegments.length - 1
          );
        if (
          !connectChecked
            .map((conn) => conn.id)
            .some((connDest) =>
              connFlightsSegments
                .map(({ destination }) => destination)
                .includes(connDest)
            )
        ) {
          isVisible = false;
        }
      }

      // if ((
      //   flight.duration.split("hrs")[0] < filters.duration.min ||
      //   flight.duration.split("hrs")[0] > filters.duration.max
      // )) {
      //   isVisible = false;
      // }

      // if ((
      //   flight.layoutTime.split("hrs")[0] < filters.layover.layovermin ||
      //   flight.layoutTime.split("hrs")[0] > filters.layover.layovermax
      // )) {
      //   isVisible = false;
      // }



      isVisible && visibleCount++;

      setResultCount(visibleCount);

      return { ...flight, isVisible: isVisible };
    });
  };





  const applyFilters = () => {
    if (selectTrip === 1) {
      // console.log("flight")
      updateFlightAirSearchRespObj({
        ...flightAirSearchResp,
        flightDetails: mapFlightWithFilter(flightDetails),
      });
    }
    else {
      // console.log("ibflight")
      updateFlightAirSearchRespObj({
        ...flightAirSearchResp,
        ibFlightDetails: mapFlightWithFilter(flightDetails),
      });
    }

  };

  const appendZero = (hrMn) => {
    return ("0" + hrMn.hours).slice(-2) + ":" + ("0" + hrMn.minutes).slice(-2);
  };

  const priceChangeCompleteHandler = (priceVal) => {
    setFilters({
      ...filters,
      price: { ...filters.price, minPrice: priceVal[0], maxPrice: priceVal[1] },
    });
  };

  // const durationChangeCompleteHandler = (priceVal) => {

  //   setFilters({
  //     ...filters,
  //     duration: { ...filters.duration, min: priceVal[0], max: priceVal[1] },
  //   });
  // };
  // const layoverChangeCompleteHandler = (priceVal) => {

  //   setFilters({
  //     ...filters,
  //     layover: { ...filters.layover, layovermin: priceVal[0], layovermax: priceVal[1] },
  //   });
  // };


  const timeChangeCompleteHandler = (filterType, time) => {
    setFilters({
      ...filters,
      [filterType]: {
        start: appendZero(time.start),
        end: appendZero(time.end),
      },
    });
  };

  const priceChangeHandler = (price) => {
    setPriceRange(price);
  };

  //const durationChangeHandler = (price) => {
  //   setDurationFilter(price);
  // };
  // const layoverChangeHandler = (price) => {
  //   setLayoverFilter(price);
  // };



  const depTimeHandler = (time) => {
    if (
      Number(time.start.split(":")[0]) >= 0 &&
      Number(time.end.split(":")[0]) >= 0
    ) {
      setDepartureVal(time);
    }
  };

  const arrTimeHandler = (time) => {
    if (
      Number(time.start.split(":")[0]) >= 0 &&
      Number(time.end.split(":")[0]) >= 0
    ) {
      setArrivalVal(time);
    }
  };

  useEffect(() => {
    setDynamicFilters();
  }, [selectedTripType, MultiCityIndex]);

  useEffect(() => {
    if (
      (flightAirSearchResp.airTravelType === "roundTrip" &&
        flightAirSearchResp.resultsType === "SEPARATE")

    ) {
      Object.keys(filters).length && applyFilters();
    }
  }, [filters]);

  const handleClear = (filterType) => {
    let initFilterType;
    if (filterType === "price") {
      initFilterType = {
        ...filters[filterType],
        minPrice: filters.price.minPriceRange,
        maxPrice: filters.price.maxPriceRange,
      };
      setPriceRange([filters.price.minPriceRange, filters.price.maxPriceRange]);
    }
    //  else if (filterType === "duration") {
    //   initFilterType = {
    //     ...filters[filterType],
    //     min: filters.duration.mindurationRange,
    //     max: filters.duration.maxdurationRange,
    //   };
    //   setDurationFilter([filters.duration.mindurationRange, filters.duration.maxdurationRange]);
    // }

    else if (filterType === "departure" || filterType === "arrival") {
      initFilterType = timeRange;

      if (filterType == "departure") {
        setDepartureVal(timeRange);
      } else {
        setArrivalVal(timeRange);
      }
    } else {
      initFilterType = filters[filterType].map((filt) => ({
        ...filt,
        isChecked: false,
      }));
    }
    setFilters({
      ...filters,
      [filterType]: initFilterType,
    });
  };

  const extraPanel = (filterType) => (
    <span
      onClick={(e) => {
        e.stopPropagation();
        handleClear(filterType);
      }}
    >
      CLEAR
    </span>
  );
  



  // console.log(filters, resultCount, "filters")
  return (
    <Card style={{padding:28}}>
      <div className="flight-filters slider-icon-1">

        <div className="overall-filter-container">
          <div className="overall-filter-header">
            <p className="filter-text">FILTER</p>
            <p
              className="clear-text"
              onClick={() => {
                ResetAirlineMatrix(true);
                setDynamicFilters();
              }}
            >
              CLEAR ALL
            </p>
          </div>
          {/* <div className="flight-result-indicator" style={{ paddingLeft: '0px' }}>
            {/* <p>
            Showing {resultCount} Results
          </p> *
            <button className="onw-brn-fil" onClick={handleOnward}>Onward</button>
            <button className="ret-brn-fil" onClick={handleReturn} >Return</button>
          </div> */}
          <div className="flight-result-indicator" style={{ paddingLeft: '0px', display:"flex",justifyContent:"space-around" }}>
            <button
              className={`onw-brn-fil ${selectedButton === 'onward' ? 'selected' : ''}`}
              onClick={handleOnward}
            >
              Onward
            </button>
            <button
              className={`ret-brn-fil ${selectedButton === 'return' ? 'selected' : ''}`}
              onClick={handleReturn}
            >
              Return
            </button>
          </div>
          <div className="overall-filter-body">
            <div className="stops-filter">
              <Collapse defaultActiveKey={["1"]} expandIconPosition={"right"}>
                <Panel header="STOPS" key="1" extra={extraPanel("stops")}>
                  {filters.stops &&
                    filters.stops.map((stop, i) => (
                      <p key={i + stop.id}>
                        <Checkbox
                          checked={stop.isChecked}
                          onChange={(e) => onChange(e, "stops", stop.id)}
                        >
                          {stop.label}
                        </Checkbox>
                      </p>
                    ))}
                </Panel>
              </Collapse>
            </div>
            <div className="slider-filter">
              <Collapse defaultActiveKey={["1"]} expandIconPosition={"right"}>
                <Panel header="PRICE" key="1" extra={extraPanel("price")}>
                  <div className="slider-label">
                    <span>
                      {currencyValue(priceRange[0])}&nbsp;{activeCurrency}
                    </span>
                    <span>
                      {currencyValue(priceRange[1])}&nbsp;{activeCurrency}
                    </span>
                  </div>
                  {filters.price && (
                    <Slider
                      range
                      step={1}
                      defaultValue={[
                        filters.price.minPrice,
                        filters.price.maxPrice,
                      ]}
                      value={priceRange}
                      min={filters.price.minPriceRange}
                      max={filters.price.maxPriceRange}
                      onChange={priceChangeHandler}
                      onAfterChange={priceChangeCompleteHandler}
                    />
                  )}
                </Panel>
              </Collapse>
            </div>
            <div className="airlines-filter">
              <Collapse defaultActiveKey={["1"]} expandIconPosition={"right"}>
                <Panel
                  header="DEPARTURE TIME"
                  key="1"
                  extra={extraPanel("depatureTimings")}
                >
                  <div style={{ border: "2px solid transparent", justifyContent: "space-evenly", display: "flex", flexWrap: "wrap" }}>
                    {filters.depatureTimings &&
                      filters.depatureTimings.map((deptTime, i) => (
                        <p className="label-nav-set" key={i + deptTime.id}>
                          <Checkbox
                            className="check-box-css"
                            checked={deptTime.isChecked}
                            onChange={(e) => onChange(e, "depatureTimings", deptTime.id)}
                          >
                            <img src={deptTime.label == "Morning" ? morning : deptTime.label == "AfterNoon" ? afternoon : deptTime.label == "Evening" ? evening : night} ></img>
                            <p>{deptTime.start.split(":")[0]}-{deptTime.end.split(":")[0]}</p>
                          </Checkbox>
                        </p>
                      ))}
                  </div>

                </Panel>
              </Collapse>
            </div>
            <div className="airlines-filter">
              <Collapse defaultActiveKey={["1"]} expandIconPosition={"right"}>
                <Panel
                  header="ARRIVAL TIME"
                  key="1"
                  extra={extraPanel("arrivaltimings")}
                >

                  <div style={{ border: "2px solid transparent", justifyContent: "space-evenly", display: "flex", flexWrap: "wrap" }}>
                    {filters.arrivaltimings &&
                      filters.arrivaltimings.map((deptTime, i) => (
                        <p className="label-nav-set" key={i + deptTime.id}>
                          <Checkbox
                            className="check-box-css"
                            checked={deptTime.isChecked}
                            onChange={(e) => onChange(e, "arrivaltimings", deptTime.id)}
                          >
                            <img src={deptTime.label == "Morning" ? morning : deptTime.label == "AfterNoon" ? afternoon : deptTime.label == "Evening" ? evening : night} ></img>
                            <p>{deptTime.start.split(":")[0]}-{deptTime.end.split(":")[0]}</p>
                          </Checkbox>
                        </p>
                      ))}
                  </div>

                </Panel>
              </Collapse>
            </div>
            {/* {console.log(filters.airlines,"airline")} */}
            {/* <div className="duration-filter">
              <Collapse defaultActiveKey={["1"]} expandIconPosition={"right"}>
                <Panel
                  header={"DURATION"}
                  isDisabled={flightDetails?.length === 0}
                  key="1"
                  extra={extraPanel("duration")}
                >
                  <div className="slider-label">
                    <span>{(durationFilter[0])}</span>
                    <span>{(durationFilter[1])}</span>
                  </div>
                  {filters.duration && (
                    <Slider
                      disabled={flightDetails.length === 0}
                      range
                      defaultValue={[filters.duration.min, filters.duration.max]}
                      //onChange={(values) => setDurationFilter({ min: values[0], max: values[1] })}
                      step={1}
                      min={filters.duration.mindurationRange}
                      max={filters.duration.maxdurationRange}
                      onChange={durationChangeHandler}
                      onAfterChange={durationChangeCompleteHandler}
                    />
                  )}
                </Panel>
              </Collapse>
            </div>
            <div className="duration-filter">
              <Collapse defaultActiveKey={["1"]} expandIconPosition={"right"}>
                <Panel
                  header={"LAYOVER"}
                  isDisabled={flightDetails.length === 0}
                  key="1"
                  extra={extraPanel("layover")}
                >
                  <div className="slider-label">

                    <span>{(layoverFilter[0])}</span>
                    <span>{(layoverFilter[1])}</span>
                  </div>
                  {filters.duration && (
                    <Slider
                      disabled={flightDetails.length === 0}
                      range
                      defaultValue={[filters.layover.layovermin, filters.layover.layovermax]}

                      step={1}
                      min={filters.layover.minlayoverRange}
                      max={filters.layover.maxlayoverRange}
                      onChange={layoverChangeHandler}
                      onAfterChange={layoverChangeCompleteHandler}
                    />
                  )}
                </Panel>
              </Collapse>
            </div> */}
            <div className="airlines-filter">
              <Collapse
                className="customscroll"
                defaultActiveKey={["1"]}
                expandIconPosition={"right"}
              >
                <Panel
                  header={"AIRLINES"}
                  key="1"
                  extra={extraPanel("airlines")}
                >
                  {filters.airlines &&
                    filters.airlines.map((airline, i) => (

                      <p key={i + airline.id}>
                        <Checkbox
                          checked={airline.isChecked}
                          onChange={(e) => onChange(e, "airlines", airline.id)}
                        >
                          <span className="d-flex justify-content-between">
                            <span className="airline-wrapper">
                              {airline.logo && (
                                <CusImgFlifilter
                                  className="airline-img"
                                  src={airline.logo}
                                  alt={airline.id}
                                />
                                // <img
                                //   src={airline.logo}
                                //   alt="flight logo"
                                //   style={{
                                //     width: 20,
                                //     height: 20,
                                //     borderRadius: 4,
                                //   }}
                                // />
                              )}{" "}
                              {airline.label} ({flightDetails.filter((air) => (air.airLine === airline.id || air?.flightDetails?.[0]?.airLine === airline.id)).length})
                            </span>
                          </span>
                        </Checkbox>
                      </p>
                    ))}
                </Panel>
              </Collapse>
            </div>
            <div className="fare-filter">
              <Collapse defaultActiveKey={["1"]} expandIconPosition={"right"}>
                <Panel
                  header={"FARE TYPE"}
                  key="1"
                  extra={extraPanel("fareType")}
                >
                  {filters.fareType &&
                    filters.fareType.map((ftype, i) => (
                      <p key={i + ftype.id}>
                        <Checkbox
                          checked={ftype.isChecked}
                          onChange={(e) => onChange(e, "fareType", ftype.id)}
                        >
                          <img style={{ width: "20px", marginRight: "2%" }} src={ftype.label == "Refundable" ? refund : norefund} ></img>
                          {ftype.label}
                        </Checkbox>
                      </p>
                    ))}
                </Panel>
              </Collapse>
            </div>
            {agent && (
              <div className="fare-types-filter">
                <Collapse defaultActiveKey={["1"]} expandIconPosition={"right"}>
                  <Panel
                    header={"FARE TYPES"}
                    key="1"
                    extra={extraPanel("fareTypes")}
                  >
                    {filters.fareTypes &&
                      filters.fareTypes.map((ftypes) => (
                        <p>
                          <Checkbox
                            checked={ftypes.isChecked}
                            onChange={(e) =>
                              onChange(e, "fareTypes", ftypes.id)
                            }
                          >
                            <img style={{ width: "20px", marginRight: "2%" }} src={ftypes.icon} ></img>

                            {ftypes.label}
                          </Checkbox>
                        </p>
                      ))}
                  </Panel>
                </Collapse>
              </div>
            )}
            <div className="connecting-locations">
              <Collapse
                className="customscroll"
                defaultActiveKey={["1"]}
                expandIconPosition={"right"}
              >
                <Panel
                  header={"CONNECTING LOCATIONS"}
                  key="1"
                  extra={extraPanel("connect")}
                >

                  {filters.connect &&
                    filters.connect.map((conn, i) => (
                      <p key={conn.id + i}>
                        <Checkbox
                          checked={conn.isChecked}
                          onChange={(e) => onChange(e, "connect", conn.id)}
                        >
                          <span class="fa fa-map-marker" style={{ color: "#0123ad" }}></span>  {conn.label}-<span><b>{conn.labelHeading}</b></span>
                        </Checkbox>
                      </p>
                    ))}
                </Panel>
              </Collapse>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};
export default FlightRoundFilter;

//#region  latest code
import React, { useContext, useState, useEffect } from "react";
import {
  Button,
  Radio,
  Row,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Select,
} from "antd";
import FareDetails from "./FareDetails";
import BusDetails from "./BusDetails";
import { GlobalStatesContext } from "../../../common/providers";
import { useAuthContext } from "../../../common/providers/AuthProvider";
import "./Checkout.scss";
import { useHistory } from "react-router-dom";
import { getPassengerData } from "../../../helpers/PassegerData";
import { SafetyCertificateOutlined } from "@ant-design/icons";
import Nav1 from "../../../common/navbar/Nav1";

const { Option } = Select;
const BusCheckout = () => {
  const { user } = useAuthContext();
  const {
    state: {
      busPersist: { selectedBusData },
      otherData: { insuranceData },
    },
    updateBusPassengerData,
    setSelectedInsuranceData,
    RemovePromoConvFee,
  } = useContext(GlobalStatesContext);
  const [onlyPassengersDetails, setOnlyPassengersDetails] = useState({});
  const [form] = Form.useForm();
  let history = useHistory();

  const [insuranceRequired, setInsuranceRequired] = useState(-1);

  useEffect(() => {
    RemovePromoConvFee();
    loadpassengerData();
  }, []);

  const loadpassengerData = () => {
    if (user && user?.UserID) {
      getPassengerData(user.UserID).then((data) => {
        if (data.status) {
          form.setFieldsValue({
            mobileNo: data.Mobile,
            emailId: data.Email,
          });
        }
      });
    }
  };

  const handleInsuranceChange = (val) => {
    if (val === 1) {
      setSelectedInsuranceData(insuranceData);
    } else {
      setSelectedInsuranceData({ amount: 0, insuranceCoverage: 0, status: 0 });
    }
    setInsuranceRequired(val);
  };

  const updatePassengersData = () => {
    form
      .validateFields()
      .then((data) => {
        const { emailId, mobileNo } = data;
        sessionStorage.setItem("BusPaxFormData", JSON.stringify(data));
        sessionStorage.setItem(
          "BusPassengersDetails",
          JSON.stringify({ ...onlyPassengersDetails, emailId, mobileNo })
        );
        let busPaxArr = [];

        busPaxArr.push({
          tripId: selectedBusData[0].busCardId,

          searchId: selectedBusData[0].searchId,
          boardingId: selectedBusData[0].boardingTimes.PointId,
          droppingId: selectedBusData[0].droppingTimes.PointId,
          noofSeats: selectedBusData[0].seatsInfo.length,
          emailId: emailId,
          mobileNo: mobileNo,
          countryCode: "91",
          passengerDetails: buildPassengerArray(selectedBusData[0].seatsInfo),
          insuranceRequired: data?.insuranceRequired === 1 ? 1 : 0,
        });
        // if (props.gscontext.state.busData.type === "twoWay") {
        //   busPaxArr.push({
        //     tripId: selectedBusData[1].busCardId,
        //     searchId: selectedBusData[1].searchId,
        //     boardingId: selectedBusData[1].boardingTimes.PointId,
        //     droppingId: selectedBusData[1].droppingTimes.PointId,
        //     noofSeats: selectedBusData[1].seatsInfo.length,

        //     emailId: emailId,
        //     mobileNo: mobileNo,
        //     countryCode: "91",
        //     passengerDetails: buildPassengerArray(selectedBusData[1].seatsInfo),
        //   });
        // }

        updateBusPassengerData(busPaxArr);
        history.push("/bus/review");
      })
      .catch((e) => {});
  };

  const buildPassengerArray = (selectedBusData) => {
    return selectedBusData.map((seat) => {
      return {
        age:
          onlyPassengersDetails[seat.SeatNo] &&
          onlyPassengersDetails[seat.SeatNo].age
            ? onlyPassengersDetails[seat.SeatNo].age
            : "10",
        name:
          onlyPassengersDetails[seat.SeatNo] &&
          onlyPassengersDetails[seat.SeatNo].name
            ? onlyPassengersDetails[seat.SeatNo].name
            : "",
        countryCode: "91",
        title:
          onlyPassengersDetails[seat.SeatNo] &&
          onlyPassengersDetails[seat.SeatNo].title
            ? onlyPassengersDetails[seat.SeatNo].title
            : "Mr",
        gender:
          onlyPassengersDetails[seat.SeatNo] &&
          onlyPassengersDetails[seat.SeatNo].gender
            ? onlyPassengersDetails[seat.SeatNo].gender
            : "M",
        seatNo: seat.SeatNo,
        fare: seat.NetFare,
        seatCodes: seat.SeatCode,
        serviceTax: seat.Servicetax,
        markup: seat.markup,
        agentMarkup: seat.agentMarkup,
        // commission: seat.commission ?? 0,
        // adminCommission: seat.adminCommission ?? 0,
        serviceCharge: seat.OperatorServiceCharge,
      };
    });
  };

  useEffect(() => {
    let BusPaxFormData = JSON.parse(sessionStorage.getItem("BusPaxFormData"));
    let BusPassengersDetails = JSON.parse(
      sessionStorage.getItem("BusPassengersDetails")
    );

    if (BusPaxFormData && BusPassengersDetails) {
      form.setFieldsValue({
        ...BusPaxFormData,
      });
      setOnlyPassengersDetails(BusPassengersDetails);
    }
  }, []);
  return (
    <>
    <Nav1/>
    <div className="buscheckout_bg">
      <div className="buscheckout_wrapper">
        <Row gutter={[16, 16]}>
          <Col md={24} sm={24} xs={24}>
            <Form form={form} layout="vertical" scrollToFirstError={true}>
              <h3 className="title">Bus Details</h3>
              <Row gutter={[16, 16]}>
                <Col md={17} sm={16} xs={24}>
                  <div className="left_wrapper">
                    <BusDetails selectedBusData={selectedBusData} />
                  </div>
                  <div className="paxinfo_wrapper">
                    <h3 className="title">Passenger Details</h3>
                    <Card>
                      {selectedBusData[0].seatsInfo &&
                        selectedBusData[0].seatsInfo.map(
                          (selectedSeat, index) => (
                            <div>
                              <b>Seat No:{selectedSeat.SeatNo}</b>
                              <Row gutter={[16, 16]}>
                                <Col md={4} sm={12} xs={24}>
                                  <Form.Item
                                    name={"Title_" + index}
                                    label="Title"
                                    rules={[
                                      { required: true, message: "Required" },
                                    ]}
                                  >
                                    <Select
                                      onSelect={(val) => {
                                        setOnlyPassengersDetails({
                                          ...onlyPassengersDetails,
                                          [selectedSeat.SeatNo]: {
                                            ...onlyPassengersDetails[
                                              selectedSeat.SeatNo
                                            ],
                                            title: val,
                                          },
                                        });
                                      }}
                                      className="inputbg"
                                    >
                                      <Option value="Mr">Mr</Option>
                                      <Option value="Ms">Ms</Option>
                                      <Option value="Mrs">Mrs</Option>
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col md={8} sm={12} xs={24}>
                                  <Form.Item
                                    name={"Name_" + index}
                                    label="Name"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Required",
                                      },
                                    ]}
                                  >
                                    <Input
                                      className="inputbg"
                                      autoComplete="off"
                                      onChange={(e) =>
                                        form.setFieldsValue({
                                          [`Name_${index}`]:
                                            e.target.value.trimStart(),
                                        })
                                      }
                                      onBlur={(e) => {
                                        if (e.target.value.trim()) {
                                          setOnlyPassengersDetails({
                                            ...onlyPassengersDetails,
                                            [selectedSeat.SeatNo]: {
                                              ...onlyPassengersDetails[
                                                selectedSeat.SeatNo
                                              ],
                                              name: e.target.value.trim(),
                                            },
                                          });
                                        }
                                      }}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col md={8} sm={12} xs={24}>
                                  <Form.Item
                                    name={"Age_" + index}
                                    label="Age"
                                    rules={[
                                      { required: true, message: "Required" },

                                      {
                                        pattern: "^(0?[1-9]|[1-9][0-9])$",
                                        message:
                                          "Age Should be between 1 to 99",
                                      },
                                    ]}
                                  >
                                    <Input
                                      className="inputbg"
                                      onBlur={(e) => {
                                        setOnlyPassengersDetails({
                                          ...onlyPassengersDetails,
                                          [selectedSeat.SeatNo]: {
                                            ...onlyPassengersDetails[
                                              selectedSeat.SeatNo
                                            ],
                                            age: e.target.value,
                                          },
                                        });
                                      }}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col md={4} sm={12} xs={24}>
                                  <Form.Item
                                    name={"Gender_" + index}
                                    label="Gender"
                                    rules={[
                                      { required: true, message: "Required" },
                                    ]}
                                  >
                                    <Select
                                      onSelect={(val) => {
                                        setOnlyPassengersDetails({
                                          ...onlyPassengersDetails,
                                          [selectedSeat.SeatNo]: {
                                            ...onlyPassengersDetails[
                                              selectedSeat.SeatNo
                                            ],
                                            gender: val,
                                          },
                                        });
                                      }}
                                    >
                                      <Option value="M">Male</Option>
                                      <Option value="F">Female</Option>
                                    </Select>
                                  </Form.Item>
                                </Col>
                              </Row>
                            </div>
                          )
                        )}
                    </Card>
                  </div>
                  {insuranceData.status === 1 &&
                  insuranceData?.serviceType === 3 ? (
                    <div className="paxinfo_wrapper">
                      <Card className="insurance-section">
                        <h5>THE SMART INSURANCE COVER</h5>
                        <p>EASY CLAIM PROCESS | NO QUESTIONS ASKED</p>

                        <Row gutter={[16, 16]} align="stretch" className="mt-4">
                          <Col md={8} sm={24} xs={24}>
                            <div className="insurance-box">
                              {insuranceData.description_box_1}
                            </div>
                          </Col>
                          <Col md={8} sm={24} xs={24}>
                            <div className="insurance-box">
                              {insuranceData.description_box_2}
                            </div>
                          </Col>
                          <Col md={8} sm={24} xs={24}>
                            <div className="insurance-box">
                              {insuranceData.description_box_3}
                            </div>
                          </Col>
                        </Row>

                        <div className="insurance-coverage">
                          <SafetyCertificateOutlined />
                          <span>
                            Insurance Coverage Amount :{" "}
                            {insuranceData.insuranceCoverage}
                          </span>
                        </div>

                        <Form.Item
                          name="insuranceRequired"
                          rules={[
                            {
                              required: true,
                              message: "Please Select Insurance",
                            },
                          ]}
                        >
                          <Radio.Group
                            buttonStyle="solid"
                            onChange={(e) =>
                              handleInsuranceChange(e.target.value)
                            }
                            className="insurance-radio-wrapper"
                          >
                            <Row gutter={[16, 16]}>
                              <Col md={8} sm={24} xs={24}>
                                <Radio
                                  className={`radio-btn btn-insure  ${
                                    insuranceRequired === 1
                                      ? "btn-insure-active"
                                      : ""
                                  }`}
                                  value={1}
                                >
                                  Insure For ₹{insuranceData.amount}/pax
                                </Radio>
                              </Col>

                              <Col md={8} sm={24} xs={24}>
                                <Radio
                                  value={0}
                                  className={`radio-btn btn-risk ${
                                    insuranceRequired === 0
                                      ? "btn-risk-active"
                                      : ""
                                  }`}
                                >
                                  I'll Risk it
                                </Radio>
                              </Col>
                            </Row>
                          </Radio.Group>
                        </Form.Item>
                      </Card>
                    </div>
                  ) : null}

                  <div className="paxcontact_wrapper">
                    <h3 className="title">Contact Details</h3>
                    <Card>
                      <Row gutter={16}>
                        <Col md={10} sm={12} xs={24}>
                          <Form.Item
                            label="Phone number"
                            name="mobileNo"
                            className="phno"
                            rules={[
                              {
                                required: true,
                                message: "Required",
                              },
                              {
                                minLength: 10,
                                maxLength: 10,
                                pattern: "^[0-9]{10}$",
                                message: "Must be 10 digits",
                              },
                            ]}
                          >
                            <Input addonBefore="+91" className="inputbg" />
                          </Form.Item>
                        </Col>
                        <Col md={10} sm={12} xs={24}>
                          <Form.Item
                            label="Email"
                            name="emailId"
                            rules={[
                              { required: true, message: "Required" },
                              { type: "email", message: "Invalid Email" },
                            ]}
                          >
                            <Input className="inputbg" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Card>
                  </div>
                </Col>

                <Col md={7} sm={8} xs={24}>
                  <div className="right_wrapper">
                    <FareDetails
                      selectedBusData={selectedBusData}
                      isPromoVisible={true}
                    />
                  </div>
                </Col>

                <Col md={17} sm={16} xs={24}>
                  <Card>
                    <Form.Item
                      name="agreement"
                      valuePropName="checked"
                      rules={[
                        {
                          validator: (_, value) =>
                            value
                              ? Promise.resolve()
                              : Promise.reject("Should accept agreement"),
                        },
                      ]}
                    >
                      <Checkbox>
                        I Agree To All The{" "}
                        <a
                          href="/termsofconditions"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {"  Terms & Conditions"}
                        </a>{" "}
                        and{" "}
                        <a
                          href="/privacypolicy"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {" "}
                          Privacy Policy.
                        </a>
                      </Checkbox>
                    </Form.Item>

                    <div className="btn_wrapper">
                      <Button
                        type="primary"
                        onClick={() => {
                          updatePassengersData();
                        }}
                        style={{backgroundColor:'#bd0c21', borderRadius:'40px', border:'#bd0c21'}}
                      >
                        Continue to Payment
                      </Button>
                    </div>
                  </Card>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
    </>
  );
};
export default BusCheckout;

//#endregion

import { React, useState, useEffect } from "react";
import { Card, message, Skeleton } from "antd";
import "./PaymentFailure.scss";
import ApiClient from "../../helpers/ApiClient";
import queryString from "query-string";
import ReactHtmlParser from "react-html-parser";

function PaymentFailure() {
  const [isLoading, setIsLoading] = useState(true);
  const [paymentResp, setPaymentResp] = useState();


  const getPaymentByRefNo = (orderId) => {
    ApiClient.get("admin/pgOrdersByRefNo/" + orderId)
      .then((result) => {
        return result;
      })
      .then((resp) => {
        if (resp.status === 200) {
          setPaymentResp(resp.data);
        } else {
          message.error("Server Error: " + resp.message);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  // const mail = "support@anjmal.com";
  // const sendQueryEmail = () => {
  //   window.location.href = `mailto:${mail}?subject=Query regarding my payment failure`;
  // };
  useEffect(() => {
    const params = queryString.parse(document.location.search);
    getPaymentByRefNo(params.ref);
  }, []);

  return (
    <div className="pgFailureWrapper">
      {isLoading ? (
        <Card>
          <h2 className="title">Your payment failed !!</h2>
          <Skeleton active />
        </Card>
      ) : (
        <Card>
          <h2 className="title">Your payment failed !!</h2>
          <p className="subtitle">
            Please call or write to our customer care team for more details{" "}
            <span className="mail"><i className="fa fa-envelope-o">  </i>{" "}support@outc.com </span>
              {/* <a onClick={sendQueryEmail} style={{ cursor: 'pointer' }}>support@anjmal.com</a></span>. */}
          </p>
          {/* {console.log(paymentResp, "payment")} */}
          {paymentResp && Object.keys(paymentResp).length > 0 ? (
            <p className="subtitle1">
              Please note your{" "}
              {paymentResp.OrderId != null ?
                <>
                  <span className="ti-id">reference ID:</span>{" "}
                  <span className="tid">{paymentResp.OrderId}</span><br />
                </> : null
              }
              {paymentResp.TransactionId != null ? (
                <span>
                  and <span className="ti-id">Payment ID:
                  </span>{" "}<span className="tid">{paymentResp.TransactionId}</span>
                </span>) : null}
            </p>
          ) : null}
        </Card>
      )}
    </div>
  );
}

export default PaymentFailure;


import React, { useState, useEffect, Suspense, useContext } from "react";


import {
  Card,
  Col,
  Row,
  Grid,
  Skeleton,
  Collapse,
  Button,
  Modal,
  Form,
} from "antd";
import { useAuthContext } from "../../../common/providers/AuthProvider";
import Hotels from "../../../components/Hotels/Hotels";
import ScrollToTopButton from "../../../components/ScrollToTop";

import ApiClient from "../../../helpers/ApiClient";

import SkeletonLayout from "../../../components/Skeleton/Skeleton";
import FilterSkeletonLayout from "../../../components/FilterSkeleton/FilterSkeleton";
import queryString from "query-string";
import PageLoader from "../../../common/PageLoader/PageLoader";
import moment from "moment";
import "../HotelResults/HotelResults.scss";
import hotelimg from "../../../assets/images/hotels/ht-1.png";
import traveller from "../../../assets/images/hotels/traveller.png";
import { HotelDates } from "../../../components/SearchResults/FlightNearDates/FlightNearDates";
import { PackagePopUP } from "../../../components/SitePopups/onsitePopup";
import { useHotelContext } from "../../../common/providers/Hotels/HotelProvider";
import Nav1 from "../../../common/navbar/Nav1";
import Hotels1 from "../../../components/Hotels/Hotels1";
const Filter = React.lazy(() => import('../../../common/Filters/Filter'))
const HotelContainer = React.lazy(() => import('./HotelContainer'))
const HotelSort = React.lazy(() => import('../HotelSort/HotelSort'))

const CustomNoResultFound = React.lazy(() => import('../../../components/ErrorPages/CustomNoResultFound'))
const NoResultFound = React.lazy(() => import('../../../components/ErrorPages/NoResultFound'));
const Hotel404 = React.lazy(() => import('../../../components/ErrorPages/Hotel404'));

const { useBreakpoint } = Grid;
const { Panel } = Collapse;

const dateFormat = "DD-MM-YYYY";
const oriDateFormat = "YYYY-MM-DD";

const HotelResults = ({ hotelParam = false, isFromPackage = false }) => {
  const { md } = useBreakpoint();
  const { user } = useAuthContext();
  const [mainHotelsListResp, setMainHotelsListResp] = useState([]);
  const [listOfHotels, setListOfHotels] = useState([]);
  const [key, setKey] = useState([]);
  const [traceId, setTraceId] = useState(null);
  const [isHotelSearchLoad, setIsHotelSearchLoad] = useState(false);
  const [showNetFare, setShowNetFare] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisiblenew, setModalVisiblenew] = useState(false);
  const [popUpVisibility, setPopUpVisibility] = useState(false);
  const [searchHotelReq, setSearchHotelReq] = useState(null);
  const [hotelalldata, sethotelalldata] = useState({});
  const [HotelSearchobj, setHotelSearchobj] = useState([]);

  useEffect(() => {
    const hotelSearchParams = queryString.parse(window.location.search);
    if (hotelSearchParams) {
      let hotelCityCode = queryString.parse(hotelSearchParams.hotelCityCode);
      let staticObj = {
        traceId: "string",
        cityId: hotelCityCode.cityId,
        userId: user?.UserID ?? 1,
        roleType: user?.Role?.Role ?? 3,
        membership: user?.Membership ?? 1,
      };
      setSearchHotelReq(staticObj)
      shortedHotelFromLocalStorage(staticObj.cityId)
    }
  }, [])
  useEffect(() => {
    fetchStaticData();
  }, [window.location.search]);
  const { state: { searchhotelobj, shortedHotelModalVisible, shortedHotelCount }, setShortedHotelModalVisible, setShortedHotelListCount } = useHotelContext();
  function onInactive(ms, cb) {
    var wait = setTimeout(cb, ms);

    document.onmousemove =
      document.mousedown =
      document.mouseup =
      document.onkeydown =
      document.onkeyup =
      document.focus =
      document.scroll =
      function () {
        clearTimeout(wait);

        if (
          !localStorage.getItem("popupShown") &&
          JSON.parse(localStorage.getItem("popupShown")) !== true
        ) {
          wait = setTimeout(cb, ms);
        }
      };
  }



  const showModalflight = () => {
    setModalVisible({
      visible: true,
    });
  };
  const showModalsort = () => {
    setModalVisiblenew({
      visible: true,
    });
  };

  const fetchStaticData = () => {
    setMainHotelsListResp([]);

    setIsLoading(true);
    setIsHotelSearchLoad(true);


    if (!hotelParam) {
      const hotelSearchParams = queryString.parse(window.location.search);
      if (hotelSearchParams) {
        let hotelCityCode = queryString.parse(hotelSearchParams.hotelCityCode);
        let staticObj = {
          traceId: "string",
          cityId: hotelCityCode.cityId,
          userId: user?.UserID ?? 1,
          roleType: user?.Role?.RoleId ?? 4,
          membership: user?.Membership ?? 1,
        };
        getStaticData(staticObj);
      }
    } else if (Object.keys(hotelParam).length > 0) {
      let staticObj = {
        traceId: "string",
        cityId: hotelParam.hotelCityCode,
      };
      getStaticData(staticObj);
    }
  };

  const getStaticData = (staticObj) => {

    ApiClient.post("hotels-v2/hotelstaticdetails", staticObj)
      .then((result) => {
        return result;
      })
      .then((res) => {
        if (res?.status === 200) {
          if (!res.data.errors) {

            const hotelDetails = res?.data.hotelDetails.map((hotel) => {
              return {
                ...hotel,
                hotelCode: hotel.mappingHotelDetails[0].hotelId,
                images: hotel.mappingHotelDetails[0].images,
                starRating: hotel.mappingHotelDetails[0].starRating,
                hotelContent: hotel.mappingHotelDetails[0].hotelContent,
                addresses: hotel.mappingHotelDetails[0].addresses,
                hotelFacility: hotel.mappingHotelDetails[0].hotelFacility,
                attractions: hotel.mappingHotelDetails[0].attractions,
                hotelName: hotel.propertyName,
                isVisible: true,
                isPriceAvailable: false,
              };
            });
            setMainHotelsListResp(hotelDetails);
            const hotelIds = hotelDetails.map(hotel => hotel.hotelCode);
            fetchHotelsInBatches(hotelIds, staticObj.cityId, hotelDetails);

            // fetchHotelSearch(hotelDetails);

          } else {
            setMainHotelsListResp([]);

          }

        } else {

          fetchHotels()

        }

      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };
  // const fetchHotelsInBatches = async (hotelIds, hotelCityCode, hotelDetails) => {
  //   const BATCH_SIZE = 100;
  //   const totalHotels = hotelIds.length;
  //   const batches = Math.ceil(totalHotels / BATCH_SIZE);
  //   const fetchBatchPromises = []; // Store promises for each batch
  //   setMainHotelsListResp([])
  //   const fetchBatch = async (batchIndex) => {
  //     const start = batchIndex * BATCH_SIZE;
  //     const end = start + BATCH_SIZE;
  //     const hotelIdsBatch = hotelIds.slice(start, end);

  //     let searchReqObj;
  //     if (!hotelParam) {
  //       const hotelSearchParams = queryString.parse(window.location.search);

  //       if (hotelSearchParams) {
  //         const hotelCityCode = queryString.parse(hotelSearchParams.hotelCityCode);

  //         searchReqObj = {
  //           checkInDate: hotelSearchParams.checkInDate,
  //           checkOutDate: hotelSearchParams.checkOutDate,
  //           hotelIds: hotelIdsBatch,
  //           hotelCityCode: hotelCityCode.cityId,
  //           roomGuests: JSON.parse(hotelSearchParams.roomGuests),
  //           nationality: hotelSearchParams?.nationality ?? "IN",
  //           countryCode: "IN",
  //           isHotelDescriptionRequried: false,
  //           currency: hotelSearchParams.currency,
  //           traceId: " ",
  //           userId: user?.UserID ?? 1,
  //           roleType: user?.Role?.RoleId ?? 4,
  //           membership: user?.Membership ?? 1,
  //         };
  //       }
  //     } else if (Object.keys(hotelParam).length > 0) {
  //       searchReqObj = {
  //         ...hotelParam,
  //         userId: user?.UserID ?? 1,
  //         roleType: user?.Role?.RoleId ?? 4,
  //         membership: user?.Membership ?? 1,
  //       };
  //     }

  //     return ApiClient.post("hotels-v2/hotelsearch", searchReqObj)
  //       .then((res) => {
  //         if (res.status === 200 && res.data.hotels.length > 0) {
  //           const hotelsdata = res.data.hotels;

  //           const combinedHotelDetails = hotelsdata.map((hotel) => ({
  //             ...hotel,
  //             isPriceAvailable: true,
  //             isVisible: true,
  //           }));

  //           setListOfHotels((prevList) => [...prevList, ...combinedHotelDetails]);
  //           setMainHotelsListResp((prevList) => [...prevList, ...combinedHotelDetails]);
  //         }
  //       })
  //       .catch((err) => {
  //         console.error(err);
  //       });
  //   };


  //   for (let i = 0; i < batches; i++) {
  //     fetchBatchPromises.push(fetchBatch(i));
  //   }


  //   await Promise.all(fetchBatchPromises);

  //   // Set loading states to false once all batches are complete
  //   setIsHotelSearchLoad(false);
  //   setIsLoading(false);
  // };

  const fetchHotelsInBatches = (hotelIds, hotelCityCode, hotelDetails) => {
    const BATCH_SIZE = 100; // Define the batch size
    const totalHotels = hotelIds.length;
    const batches = Math.ceil(totalHotels / BATCH_SIZE);
    { console.log(totalHotels, batches, 'batch size') }
    setMainHotelsListResp([])
    const existingHotelCodes = new Set();
    const fetchBatch = (batchIndex) => {
      const start = batchIndex * BATCH_SIZE;
      const end = start + BATCH_SIZE;
      const hotelIdsBatch = hotelIds.slice(start, end);


      let searchReqObj
      if (!hotelParam) {
        const hotelSearchParams = queryString.parse(window.location.search);



        if (hotelSearchParams) {
          let hotelCityCode = queryString.parse(hotelSearchParams.hotelCityCode);

          searchReqObj = {
            checkInDate: hotelSearchParams.checkInDate,
            checkOutDate: hotelSearchParams.checkOutDate,
            hotelIds: hotelIdsBatch,
            hotelCityCode: hotelCityCode?.cityId,
            roomGuests: JSON.parse(hotelSearchParams?.roomGuests),
            nationality: hotelSearchParams?.nationality ?? "IN",
            countryCode: "IN",

            isHotelDescriptionRequried: false,
            currency: hotelSearchParams.currency,
            traceId: " ",
            userId: user?.UserID ?? 1,
            roleType: user?.Role?.RoleId ?? 4,
            membership: user?.Membership ?? 1,
          };

        }
      } else if (Object.keys(hotelParam).length > 0) {
        searchReqObj = {
          ...hotelParam,
          userId: user?.UserID ?? 1,
          roleType: user?.Role?.RoleId ?? 4,
          membership: user?.Membership ?? 1,
        };

        //getHotelDetails(searchReqObj, hot);
      }


      ApiClient.post("hotels-v2/hotelsearch", searchReqObj)
        .then((res) => {
          if (res.status === 200 && res.data.hotels.length > 0) {
            const hotelsdata = res.data.hotels;




            const combinedHotelDetails = [];
            for (let i = 0; i < hotelsdata.length; i++) {
              const hotel = hotelsdata[i];


              if (!existingHotelCodes.has(hotel.hotelCode)) {
                let matchHotelSearchData = hotelDetails.find(
                  (data) => data.hotelCode === hotel.hotelCode

                );

                combinedHotelDetails.push({
                  ...(matchHotelSearchData || {}),
                  ...hotel,
                  isPriceAvailable: true,
                  isVisible: true,


                });

                existingHotelCodes.add(hotel.hotelCode);
              }
            }

            setListOfHotels(prevList => [...prevList, ...combinedHotelDetails]);
            setMainHotelsListResp(prevList => [...prevList, ...combinedHotelDetails]);
            setIsHotelSearchLoad(false);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {

          if (batchIndex + 1 < batches) {
            fetchBatch(batchIndex + 1);
          }
        });
    };


    fetchBatch(0);
  };
  console.log(listOfHotels, listOfHotels.length, mainHotelsListResp, "l")
  const fetchHotels = () => {
    let hot = [];
    if (!hotelParam) {
      const hotelSearchParams = queryString.parse(window.location.search);



      if (hotelSearchParams) {
        let hotelCityCode = queryString.parse(hotelSearchParams.hotelCityCode);

        let searchReqObj = {
          checkInDate: hotelSearchParams.checkInDate,
          checkOutDate: hotelSearchParams.checkOutDate,

          hotelCityCode: hotelCityCode.cityId,
          roomGuests: JSON.parse(hotelSearchParams.roomGuests),
          nationality: hotelSearchParams?.nationality ?? "IN",
          countryCode: "IN",

          isHotelDescriptionRequried: false,
          currency: hotelSearchParams.currency,
          traceId: " ",
          userId: user?.UserID ?? 1,
          roleType: user?.Role?.RoleId ?? 4,
          membership: user?.Membership ?? 1,
        };
        getHotelDetails(searchReqObj, hot);
        setHotelSearchobj(searchReqObj);
      }
    } else if (Object.keys(hotelParam).length > 0) {
      let searchReqObj = {
        ...hotelParam,
        userId: user?.UserID ?? 1,
        roleType: user?.Role?.RoleId ?? 4,
        membership: user?.Membership ?? 1,
      };

      getHotelDetails(searchReqObj, hot);
    }
  }
  const fetchHotelSearch = (hotelDetails) => {
    console.log(hotelDetails, "hotelDetails");
    if (!hotelParam) {
      const hotelSearchParams = queryString.parse(window.location.search);


      if (hotelSearchParams) {
        let hotelCityCode = queryString.parse(hotelSearchParams.hotelCityCode);


        let searchReqObj = {
          checkInDate: hotelSearchParams.checkInDate,
          checkOutDate: hotelSearchParams.checkOutDate,

          hotelCityCode: hotelCityCode.cityId,
          roomGuests: JSON.parse(hotelSearchParams.roomGuests),
          nationality: hotelSearchParams?.nationality ?? "IN",
          countryCode: hotelDetails[0]?.countryCode,

          isHotelDescriptionRequried: false,
          currency: hotelSearchParams.currency,
          traceId: " ",
          userId: user?.UserID ?? 1,
          roleType: user?.Role?.RoleId ?? 4,
          membership: user?.Membership ?? 1,
        };
        getHotelDetails(searchReqObj, hotelDetails);
        setHotelSearchobj(searchReqObj);
      }
    } else if (Object.keys(hotelParam).length > 0) {
      let searchReqObj = {
        ...hotelParam,
        userId: user?.UserID ?? 1,
        roleType: user?.Role?.RoleId ?? 4,
        membership: user?.Membership ?? 1,
      };
      getHotelDetails(searchReqObj, hotelDetails);
    }
  };


  const processStaticData = (staticData) => {
    const hotelDetails = staticData.hotelDetails.map((hotel) => {
      return {
        ...hotel,
        hotelCode: hotel.hotelId,
        hotelName: hotel.propertyName,
        isVisible: true,
        isPriceAvailable: false,
      };
    });
    setMainHotelsListResp(hotelDetails);

  };


  const getHotelDetails = (searchReqObj, hotelDetails) => {
    ApiClient.post("hotels-v2/hotelsearch", searchReqObj)
      .then((res) => {
        if (res.status === 200 && res.data.hotels.length > 0) {
          setTraceId(res.data.traceId);
          const hotelsdata = res.data.hotels;
          setListOfHotels(hotelsdata);
          let combinedHotelDetails = [];

          if (res?.data?.hotels?.[0]?.supplier === 'AKBAR') {

            for (let i = 0; i < hotelsdata.length; i++) {
              combinedHotelDetails?.push({
                ...hotelsdata[i],
                isPriceAvailable: true,
                isVisible: true
              })
            }

            sethotelalldata(res.data.filters)
            setMainHotelsListResp(combinedHotelDetails);

            setIsHotelSearchLoad(false);

            // setMainHotelsListResp(hotelsdata);

          } else {

            if (hotelsdata.length > 0) {

              for (let i = 0; i < hotelsdata.length; i++) {
                let matchHotelSearchData = hotelDetails.filter(
                  (data) => data.hotelId == hotelsdata[i].hotelCode
                );
                combinedHotelDetails.push({
                  ...matchHotelSearchData[0],
                  ...hotelsdata[i],
                  isPriceAvailable: true,
                });
              }
            }

            setMainHotelsListResp(combinedHotelDetails);

          }


        } else {
          console.log("No hotels found from search.");
        }
        setIsHotelSearchLoad(false);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsHotelSearchLoad(false);
        setIsLoading(false);
      });
  };



  const shortedHotelFromLocalStorage = (cityId) => {
    let getShortedHotelList = localStorage.getItem('SHORTEDHOTELLIST');
    getShortedHotelList = getShortedHotelList ? JSON.parse(getShortedHotelList) : []
    const checkIsHotelCity = getShortedHotelList.find(h => h.hotelCityCode == cityId)

    if (checkIsHotelCity) {
      setShortedHotelListCount(checkIsHotelCity.hotelCode.length)
    }

  }
  const [primaryScale, setPrimaryScale] = useState(0);
  const [secondaryScale, setSecondaryScale] = useState(1);


  const [leftPosition, setLeftPosition] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isLoading && leftPosition < 49) {
        setLeftPosition(prevPosition => prevPosition + 1);
      } else {
        clearInterval(interval);
      }
    }, 70);

    return () => clearInterval(interval);
  }, [isLoading, leftPosition]);


  return (
    <>
      <Nav1 />
      <div className="hotels_results_page">
        <div className="hotels-page-wrapper">
          {isFromPackage ? null : (
            <section className="hotels_modify">
              <div className="hotel-modify-container">
                <Collapse
                  activeKey={md ? ["1"] : key}
                  showArrow={false}
                  onChange={(val) => {
                    setKey(val);
                  }}
                >
                  <Panel
                    showArrow={false}
                    header={
                      <span className="hotels-hide-search">Modify Search</span>
                    }
                    key="1"
                  >
                    <Hotels1
                      hotelSearchAPI={fetchStaticData}
                      modifySearch={true}
                    />
                  </Panel>
                </Collapse>
              </div>
            </section>
          )}
          {isLoading ? (
            <section>
              <div className="hotel-skeleton-layout">
                {/* <div className="image-container">
                {leftPosition < 49 ?
                  <div className="hotel-icon-container" style={{ left: `${leftPosition}%` }}>
                    <img className="traveller-icon" src={traveller} alt="View" />
                  </div> : null}
                <div className="second-image-container">
                  <img className="second-image" src={hotelimg} alt="Second Image" />
                </div>
              </div> */}


                <div className="hotel-skeleton-layout-container">
                  <Row gutter={16}>
                    <Col md={6} xs={0} className="filter-skeleton">
                      <FilterSkeletonLayout />
                    </Col>
                    <Col md={18} xs={24} className="result-body-skeleton">
                      <Card className="card-skeleton">
                        <Skeleton active={true} paragraph={{ rows: 0 }} />
                      </Card>
                      <PageLoader />
                      {[...Array(6)].map((i) => (
                        <SkeletonLayout key={i} />
                      ))}
                    </Col>
                  </Row>
                </div>
              </div>
            </section>
          ) : mainHotelsListResp.length > 0 ? (
            <section className="hotels_list">
              <div className="hotels-list-wrapper">
                <div className="list-container">
                  <div className="filters-box">
                    <Suspense fallback={
                      // <div>Loading...</div>
                      <FilterSkeletonLayout />
                    }>
                      <Filter
                        count={mainHotelsListResp.length}
                        data={mainHotelsListResp}
                        setListOfHotels={setListOfHotels}
                        isHotelSearchLoad={isHotelSearchLoad}
                        filtersObj={hotelalldata}
                      />
                    </Suspense>
                  </div>
                  <div>
                    <ScrollToTopButton />
                  </div>
                  <div className="hotels-box">
                    <div  >
                      {isHotelSearchLoad ? (
                        <Card className="card-skeleton">
                          <Skeleton active={true} paragraph={{ rows: 0 }} />
                        </Card>
                      ) : (
                        <><Suspense fallback={
                          <Card className="card-skeleton">
                            <Skeleton active={true} paragraph={{ rows: 0 }} />
                          </Card>}>

                          <HotelSort
                            listOfHotels={listOfHotels}
                            setListOfHotels={setListOfHotels}
                            showNetFare={showNetFare}
                            setShowNetFare={setShowNetFare}
                            setShortedHotelModalVisible={setShortedHotelModalVisible}
                            shortedHotelCount={shortedHotelCount}
                          />
                        </Suspense>
                        </>
                      )}
                    </div>
                    <div>
                      {listOfHotels.length > 0 ? (
                        <Suspense fallback={<div>Loading...</div>}>
                          <HotelContainer
                            isFromPackage={isFromPackage}
                            listOfHotels={listOfHotels}
                            traceId={traceId}
                            isHotelSearchLoad={isHotelSearchLoad}
                            showNetFare={showNetFare}
                            searchObj={HotelSearchobj}
                            searchHotelReq={searchHotelReq}
                            shortedHotelModalVisible={shortedHotelModalVisible}
                            setShortedHotelModalVisible={setShortedHotelModalVisible}
                            setShortedHotelListCount={setShortedHotelListCount}
                            setListOfHotels={setListOfHotels}
                            mainHotelsListResp={mainHotelsListResp}
                          /></Suspense>
                      ) : (
                        <Suspense fallback={<div>Loading...</div>}>
                          <CustomNoResultFound title={"No Hotels Available"} />
                        </Suspense>
                      )}
                    </div>

                  </div>
                </div>
              </div>
            </section>
          ) : (
            <Suspense fallback={<div>Loading...</div>}>
              {/* <NoResultFound /> */}
              <Hotel404 />
            </Suspense>
          )}
        </div>

        <Row className="fiters-value-hotel">
          <Col md={12} xs={12} className="hotel-center-cls">
            <h5 className="hotel-sort-by-1" onClick={showModalflight}>
              {" "}
              <i className="fa fa-filter" aria-hidden="true"></i>&nbsp;Filters
            </h5>
          </Col>
          <Col md={12} xs={12} className="hotel-center-cls">
            <h5 className="hotel-sort-by-1" onClick={showModalsort}>
              <i className="fa fa-sort-amount-asc" aria-hidden="true"></i>
              &nbsp;Sort by
            </h5>
          </Col>
        </Row>

        <Modal
          title={[
            <div>
              <h6 style={{ marginBottom: "0px" }}>
                <strong>Filters</strong>
              </h6>
            </div>,
          ]}
          className="promo-modal-header"
          visible={modalVisible}
          onOk={(e) => setModalVisible(false)}
          onCancel={(e) => setModalVisible(false)}
          footer={[
            <div>
              <Button type="primary" onClick={() => setModalVisible(false)}>
                Close
              </Button>

            </div>,
          ]}
        >
          <Row>
            <Col md={24} xs={24}>
              <Filter
                count={mainHotelsListResp.length}
                data={mainHotelsListResp}
                setListOfHotels={setListOfHotels}
                isHotelSearchLoad={isHotelSearchLoad}
                filtersObj={hotelalldata}
              />
            </Col>
          </Row>
        </Modal>

        <Modal
          title={[
            <div>
              <h6 style={{ marginBottom: "0px" }}>
                <strong>Sort by</strong>
              </h6>
            </div>,
          ]}
          className="promo-modal-header modal-hotel-show12"
          visible={modalVisiblenew}
          onOk={(e) => setModalVisiblenew(false)}
          onCancel={(e) => setModalVisiblenew(false)}
          footer={[
            <div>
              <Button type="primary" onClick={() => setModalVisiblenew(false)}>
                Close
              </Button>
            </div>,
          ]}
        >
          <Form>
            <Row>
              <Col md={24} xs={24}>
                <HotelSort
                  listOfHotels={mainHotelsListResp}
                  setListOfHotels={setListOfHotels}
                  showNetFare={showNetFare}
                  setShowNetFare={setShowNetFare}
                />
              </Col>
            </Row>
          </Form>
        </Modal>


      </div >
    </>
  );
};

export default HotelResults;

import React, { useState, useEffect, useContext } from "react";
import { Button, Card, Modal, Radio, Table } from "antd";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import { useCurrencyContext } from "../../../common/providers/CurrencyProvider";
import { useAuthContext } from "../../../common/providers/AuthProvider";
import moment from "moment";
import {
  createDataSourceFromFlightsAndColumns,
  createMatrixColumns,
} from "./AirlineMatrixHelper";
import "./Sort.scss";

const RoundSort = ({
  updateFlightAirSearchRespObj,
  flightAirSearchResp,
  airlineMatrixReset,
  ResetAirlineMatrix,
  selectedTripType,
  showNetFare,
  setShowNetFare,
  MultiCityIndex
}) => {
  //  console.log(selectedTripType,"tr")
  const { activeCurrency, currencyValue } = useCurrencyContext();
  const {
    isLogin: { agent },
    user,
  } = useAuthContext();
  const [sorting, setSorting] = useState("price");
  const [sortDirection, setSortDirection] = useState("asc");
  const [cheapestFilter, setCheapestFilter] = useState(false);
  const [matrixSelected, setMatrixSelected] = useState({
    visible: false,
    count: "",
  });
  let [columns, setColumns] = useState([]);
  let [dataSource, setDataSource] = useState([]);
  const [airlineMatrix, setAirlineMatrix] = useState(false);

  const handleChange = (e) => {
    const selSorting = e.target.value;
    setSorting(selSorting);
    setSortDirection("asc");
    sortFlights(selSorting, "asc");
  };
{console.log(flightAirSearchResp,"sort")}
  const handleClick = (e) => {
    const selSorting = e.target.value;
    if (sorting === selSorting) {
      let dir = sortDirection === "asc" ? "desc" : "asc";
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
      sortFlights(selSorting, dir);
    }
  };

  
  const handleCheapestClick = () => {
    sortFlight("price", "asc");
  };

  const handleFastestClick = () => {
    sortFlight("duration", "asc");
  };

  const handleBestClick = () => {
    sortFlight("best", "asc");
  };

  const sortFlight = (selSorting, dir) => {
    let sortedList = flightAirSearchResp.flightDetails.sort((flightA, flightB) => {
      if (selSorting === "price") {
        const priceA = parseFloat(flightA.fareFamilies.fareFamilies[0].adultPublishFare);
        const priceB = parseFloat(flightB.fareFamilies.fareFamilies[0].adultPublishFare);
        return dir === "asc" ? priceA - priceB : priceB - priceA;
      } else if (selSorting === "duration") {
        const durationA = getDurationInMinutes(flightA.duration);
        const durationB = getDurationInMinutes(flightB.duration);
        return dir === "asc" ? durationA - durationB : durationB - durationA;
      } else if (selSorting === "best") {
       
        const priceA = parseFloat(flightA.fareFamilies.fareFamilies[0].adultPublishFare);
        const priceB = parseFloat(flightB.fareFamilies.fareFamilies[0].adultPublishFare);
        const durationA = getDurationInMinutes(flightA.duration);
        const durationB = getDurationInMinutes(flightB.duration);
  
      
        const scoreA = calculateScore(priceA, durationA);
        const scoreB = calculateScore(priceB, durationB);
  
        return dir === "asc" ? scoreA - scoreB : scoreB - scoreA;
      }
    });

    updateFlightAirSearchRespObj({
      ...flightAirSearchResp,
      flightDetails: sortedList,
    });
  };

  const calculateScore = (price, duration) => {
    const priceWeight = 0.6; // Adjust this value based on your preference
    const durationWeight = 0.4; // Adjust this value based on your preference
    
    // Calculate the score based on weighted sum
    const weightedPrice = price * priceWeight;
    const weightedDuration = duration * durationWeight;
    return weightedPrice +  weightedDuration;
  };

  const getDurationInMinutes = (duration) => {
    const [hours] = duration.split("hrs");
    return parseInt(hours);
  };



  useEffect(() => {
    sortFlights("price", "asc");
  }, []);

  useEffect(() => {
    if (airlineMatrixReset) {
      setMatrixSelected({
        visible: false,
        count: "",
      });
      ResetAirlineMatrix(false);
    }
  }, [airlineMatrixReset]);

  const getSortIcon = (val) => {
    return val === "asc" ? <CaretUpOutlined /> : <CaretDownOutlined />;
  };

  let flightDetails =
    selectedTripType === "Onward"
      ? flightAirSearchResp.flightDetails
      : flightAirSearchResp.ibFlightDetails;

  const sortFlights = (selSorting, dir) => {
    if (!flightDetails) return;
    let sortedList = [];

    if (
      (flightAirSearchResp.airTravelType == "roundTrip" &&
        flightAirSearchResp.resultsType == "COMBINED") || (flightAirSearchResp.airTravelType == "multidestination" &&
          flightAirSearchResp.resultsType == "COMBINED")
    ) {
      sortedList = flightDetails.sort((flightA, flightB) => {
        if (selSorting === "airline") {
          return dir === "asc"
            ? compareFlights(
              flightA.flightDetails[0].airLineName,
              flightB.flightDetails[0].airLineName,
              selSorting
            )
            : compareFlights(
              flightB.flightDetails[0].airLineName,
              flightA.flightDetails[0].airLineName,
              selSorting
            );
        } else if (selSorting === "price") {
          const priceA = flightA.fareFamilies.fareFamilies[0].adultPublishFare;
          const priceB = flightB.fareFamilies.fareFamilies[0].adultPublishFare;

          return dir === "asc"
            ? compareFlights(priceA, priceB, selSorting)
            : compareFlights(priceB, priceA, selSorting);
        } else if (selSorting === "departure") {
          const depTimeA = new Date(
            flightA.flightDetails[0].flightSegments[0].departureDateTime
          ).getTime();
          const depTimeB = new Date(
            flightB.flightDetails[0].flightSegments[0].departureDateTime
          ).getTime();

          return dir === "asc"
            ? compareFlights(depTimeA, depTimeB, selSorting)
            : compareFlights(depTimeB, depTimeA, selSorting);
        } else if (selSorting === "arrival") {
          const arrTimeA = new Date(
            flightA.flightDetails[0].flightSegments[
              flightA.flightDetails[0].flightSegments.length - 1
            ].arrivalDateTime
          ).getTime();
          const arrTimeB = new Date(
            flightB.flightDetails[0].flightSegments[
              flightB.flightDetails[0].flightSegments.length - 1
            ].arrivalDateTime
          ).getTime();

          return dir === "asc"
            ? compareFlights(arrTimeA, arrTimeB, selSorting)
            : compareFlights(arrTimeB, arrTimeA, selSorting);
        } else if (selSorting === "duration") {
          return dir === "asc"
            ? compareFlights(
              flightA.duration,
              flightB.duration,
              selSorting
            )
            : compareFlights(
              flightB.duration,
              flightA.duration,
              selSorting
            );
        }
      });
    } else {
      sortedList = flightDetails.sort((flightA, flightB) => {
        if (selSorting === "airline") {
          return dir === "asc"
            ? compareFlights(
              flightA.airLineName,
              flightB.airLineName,
              selSorting
            )
            : compareFlights(
              flightB.airLineName,
              flightA.airLineName,
              selSorting
            );
        } else if (selSorting === "price") {
          const priceA = flightA.fareFamilies.fareFamilies[0].adultPublishFare;
          const priceB = flightB.fareFamilies.fareFamilies[0].adultPublishFare;

          return dir === "asc"
            ? compareFlights(priceA, priceB, selSorting)
            : compareFlights(priceB, priceA, selSorting);
        } else if (selSorting === "departure") {
          const depTimeA = new Date(
            flightA.flightSegments[0].departureDateTime
          ).getTime();
          const depTimeB = new Date(
            flightB.flightSegments[0].departureDateTime
          ).getTime();

          return dir === "asc"
            ? compareFlights(depTimeA, depTimeB, selSorting)
            : compareFlights(depTimeB, depTimeA, selSorting);
        } else if (selSorting === "arrival") {
          const arrTimeA = new Date(
            flightA.flightSegments[
              flightA.flightSegments.length - 1
            ].arrivalDateTime
          ).getTime();
          const arrTimeB = new Date(
            flightB.flightSegments[
              flightB.flightSegments.length - 1
            ].arrivalDateTime
          ).getTime();

          return dir === "asc"
            ? compareFlights(arrTimeA, arrTimeB, selSorting)
            : compareFlights(arrTimeB, arrTimeA, selSorting);
        } else if (selSorting === "duration") {
          return dir === "asc"
            ? compareFlights(
              flightA.duration,
              flightB.duration,
              selSorting
            )
            : compareFlights(
              flightB.duration,
              flightA.duration,
              selSorting
            );
        }
      });
    }

    if (selectedTripType === "Onward") {
      updateFlightAirSearchRespObj({
        ...flightAirSearchResp,
        flightDetails: [...sortedList],
      });
    } else {
      updateFlightAirSearchRespObj({
        ...flightAirSearchResp,

        ibFlightDetails: [...sortedList],
      });
    }
  };

  const compareFlights = (a, b, selSorting) => {
    if (selSorting === "airline" || selSorting === "price" || selSorting === "duration") {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    } else {
      return a - b;
    }
  };

  useEffect(() => {
    //dont render matrix once done
    if (
      (flightAirSearchResp.airTravelType == "roundTrip" &&
        flightAirSearchResp.resultsType == "COMBINED") || (flightAirSearchResp.airTravelType == "multidestination" &&
          flightAirSearchResp.resultsType == "COMBINED")
    ) {
    } else {
      if (columns.length > 0 && dataSource.length > 0) {
        if (Object.keys(dataSource[0]) > 1) {
          return;
        }
      }
      let temp_columns = createMatrixColumns(flightDetails);
      let temp_dataSource = createDataSourceFromFlightsAndColumns(
        columns,
        flightDetails
      );

      temp_columns = temp_columns.map((item) => {
        return {
          ...item,
          title: (
            <div
              className="cursor_pointer airlineTitle"
              onClick={() => {
                headerRowClicked(item.dataIndex);
                if (item.title === "All Flights") {
                  setMatrixSelected({ visible: false, count: "" });
                } else {
                  setMatrixSelected({ visible: true, count: item.count });
                }
              }}
            >
              {item.title} <i className="fa fa-filter" aria-hidden="true"></i>{" "}
              <span className="matrixCount">({item.count})</span>
            </div>
          ),
        };
      });

      setColumns(temp_columns);
      setDataSource(temp_dataSource);
    }
  }, flightDetails);

  const showModal = () => {
    setAirlineMatrix(true);
  };
  const handleOk = (e) => {
    setAirlineMatrix(false);
  };

  const handleCancel = (e) => {
    setAirlineMatrix(false);
  };

  const filterFLightsByNames = (name) => {
    let new_flights = flightDetails.map((flight) => {
      let visible = false;
      if (name == "type") {
        visible = true;
      } else {
        if (name == flight.airLineName) {
          visible = true;
        }
      }
      return {
        ...flight,
        isVisible: visible,
      };
    });

    if (selectedTripType === "Onward") {
      updateFlightAirSearchRespObj({
        ...flightAirSearchResp,
        flightDetails: new_flights,
      });
    } else {
      updateFlightAirSearchRespObj({
        ...flightAirSearchResp,

        ibFlightDetails: new_flights,
      });
    }
  };

  const headerRowClicked = (name) => {
    filterFLightsByNames(name);
    setAirlineMatrix(false);
  };

  const getMinumumTimeFromCriteria = (selectedSort) => {
    let flight = [];
    let fare = 0;
    if (
      (flightAirSearchResp.airTravelType === "roundTrip" &&
        flightAirSearchResp.resultsType.toUpperCase() === "COMBINED") || (flightAirSearchResp.airTravelType === "multidestination" &&
          flightAirSearchResp.resultsType.toUpperCase() === "COMBINED")
    ) {
      flight = flightAirSearchResp.flightDetails[0].flightDetails[0];
      fare =
        flightAirSearchResp.flightDetails[0].fareFamilies.fareFamilies[0]
          .adultPublishFare;
    } else if (flightAirSearchResp.airTravelType === "multidestination" &&
      flightAirSearchResp.resultsType === "SEPARATE") {

      flight = flightAirSearchResp.multicityFlightDetails[MultiCityIndex]?.[0];
      fare =
        flightAirSearchResp.multicityFlightDetails[MultiCityIndex]?.[0]?.fareFamilies.fareFamilies[0]
          .adultPublishFare;

    }

    else {
      flight =
        selectedTripType === "Onward"
          ? flightAirSearchResp.flightDetails[0]
          : flightAirSearchResp.ibFlightDetails[0];
      fare =
        selectedTripType ===  "Onward"
          ? flightAirSearchResp.flightDetails[0].fareFamilies.fareFamilies[0]
            .adultPublishFare
          : flightAirSearchResp.ibFlightDetails[0].fareFamilies.fareFamilies[0]
            .adultPublishFare;
    }

    switch (selectedSort) {
      case "price":
        return fare;
      case "departure":
        return moment(flight.flightSegments[0].departureDateTime).format(
          "hh:mm A"
        );
      case "arrival":
        return moment(
          flight.flightSegments[flight.flightSegments.length - 1]
            .arrivalDateTime
        ).format("hh:mm A");
      case "airline":
        return flight?.airLineName;
    }
    return " ";
  };
  return (
    <>
    <div className="sort-block" style={{ marginBottom: 20, marginTop:'2%', borderRadius:'10px', overflow:'hidden' }}>

      <div className="radio-btns-sort" >

        <div style={{ width: "100%", backgroundColor:'white', padding:'1%' }}>
          <Radio.Group onChange={handleChange} value={sorting} style={{ justifyContent: 'space-evenly', flexWrap: 'wrap', display: "flex", width: '91%' }} >
            <Radio value={"airline"} onClick={handleClick}>
              <span className="checkmark">
                <div className="active-background">
                  <p className="price-type">Airline  <span>
                    {sorting === "airline" ? getSortIcon(sortDirection) : ""}
                  </span></p>

                </div>
              </span>
            </Radio>
            <Radio value={"departure"} onClick={handleClick}>
              <span className="checkmark">
                <div className="active-background">
                  <p className="price-type">Departure   <span>
                    {sorting === "departure"
                      ? getSortIcon(sortDirection)
                      : ""}
                  </span>{" "}</p>

                </div>
              </span>
            </Radio>
            <Radio value={"duration"} onClick={handleClick}>
              <span className="checkmark">
                <div className="active-background">
                  <p className="price-type">Duration   <span>
                    {sorting === "duration"
                      ? getSortIcon(sortDirection)
                      : ""}
                  </span>{" "}</p>

                </div>
              </span>
            </Radio>

            <Radio value={"arrival"} onClick={handleClick}>
              <span className="checkmark">
                <div className="active-background">
                  <p className="price-type">Arrival <span>
                    {sorting === "arrival" ? getSortIcon(sortDirection) : ""}
                  </span>{" "}</p>

                </div>
              </span>
            </Radio>

            <Radio value={"price"} onClick={handleClick}>
              <span className="checkmark">
                <div className="active-background">
                  <p className="price-type">Price      <span>
                  {sorting === "price" ? getSortIcon(sortDirection) : ""}
                  </span>{" "}</p>

                </div>
              </span>
            </Radio>

          </Radio.Group>
        </div>

        {agent && user?.Role?.RoleLevel === 3 ? (
          <div
            className="netfareButton"
            onClick={() => setShowNetFare(!showNetFare)}
          >
            <Button> {showNetFare ? "Hide" : "Show"} NET FARE</Button>
          </div>
        ) : null}
      </div>


      <Modal
        className="air-trix-new"
        title="Airline Matrix"
        visible={airlineMatrix}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        width={"800px"}
      >
        <Table
          scroll={{ x: true }}
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          className="airline-matrix-table "
          bordered
        />
      </Modal>
    </div>
    {/* <div  className="sort-block" style={{ marginBottom: 20, marginTop:'2%', borderRadius:'10px', overflow:'hidden' }}>
    <div className="radio-btns-sort" >

<div style={{ width: "100%", backgroundColor:'white', padding:'1%' }}>
  <div style={{display: 'flex',justifyContent:'space-around'}}>
<button value={"Best"} onClick={handleBestClick} style={{width:162,padding:11,borderRadius:16,border:'none',background:'white'}}>
  <span className="checkmark">
    <div className="active-background">
      <p className="price-type">Best   <span>
       
      </span>{" "}</p>
    </div>
  </span>
</button>
    <button value={"cheapest"} onClick={handleCheapestClick} style={{width:162,padding:11,borderRadius:16,border:'none',background:'white'}}>
  <span className="checkmark">
    <div className="active-background">
      <p className="price-type">Cheapest   <span>
       
      </span>{" "}</p>
    </div>
  </span>
</button>
<button value={"fastest"} onClick={handleFastestClick} style={{width:162,padding:11,borderRadius:16,border:'none',background:'white'}}>
              <span className="checkmark">
                <div className="active-background">
                  <p className="price-type">Fastest   <span></span>{" "}</p>
                </div>
              </span>
            </button>
            </div>
</div>
</div>
    </div> */}
    </>
  );
};
const RoundTrip =({
    
})
export default RoundSort;

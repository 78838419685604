import React from "react";
import { Suspense } from "react";
import "../BusResultCards/BusResultCards.scss";
import { BusDates } from "../../../../components/SearchResults/FlightNearDates/FlightNearDates";
const BusSort = React.lazy(() => import('../../BusSort/BusSort'))

const BusCard = React.lazy(() => import('./BusCard'))


const BusResultCards = ({
  updateSelectedBusData,
  trips,
  busSearchResultObj,
  setBusResultsList,
  showNetFare,
  setShowNetFare,
  busSearchObject,
}) => {
  return (
    <div className="results-card-block">
      {console.log(busSearchResultObj,"bus")}
       <Suspense fallback={<div>Loading...</div>}>
      <BusDates busSearchObject={busSearchObject} />
      <BusSort
        busSearchResultObj={busSearchResultObj}
        setBusResultsList={setBusResultsList}
        showNetFare={showNetFare}
        setShowNetFare={setShowNetFare}
      />
      <BusCard
        updateSelectedBusData={updateSelectedBusData}
        trips={trips}
        searchId={busSearchResultObj.searchId}
        otherData={{
          origin: busSearchResultObj.data.origin,
          destination: busSearchResultObj.data.destination,
        }}
        showNetFare={showNetFare}
        journeyDate={busSearchResultObj.data.journeyDate}
      />
      </Suspense>
    </div>
  );
};
export default BusResultCards;

import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  message,
  Row,
  DatePicker,
  Table,
  Spin,
} from "antd";
import PaymentGateway from "../../helpers/PaymentGateway";
import excel from "../../../src/assets/vector-icons/excel.png";
import search from "../../../src/assets/vector-icons/search.png";
import { useAuthContext } from "../../common/providers/AuthProvider";
import APIClient from "../../helpers/ApiClient";
import queryString from "query-string";
import wallet from "../../../src/assets/vector-icons/wallet.jpg";
import moment from "moment";
import "./Wallet.scss";
import UpdatedComponent from "../ExportExcel";
import { CloseOutlined } from "@ant-design/icons";
import PaymentComponent from "../../helpers/CCavanuePayCheckout";
import { useCurrencyContext } from "../../common/providers/CurrencyProvider";
import Nav1 from "../../common/navbar/Nav1";

const { Content } = Layout;
let dateFormat = "DD-MM-YYYY";

const Wallet = (props) => {
  const { user } = useAuthContext();
  const [form] = Form.useForm();
  const [searchForm] = Form.useForm();
  const [Details, setDetails] = useState([]);
  const [pgDisplay, setPgDisplay] = useState(false);
  const [pgData, setPgData] = useState({});
  const [tableData, setTableData] = useState([]);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const { activeCurrency, currencyValue, currencyRatio } = useCurrencyContext();
  //  console.log(currencyRatio,activeCurrency,currencyValue,"curre")
  const submitform = (data) => {
    // let reqData = {
    //   OrderId: "WLTB-" + Math.floor(Math.random() * Math.floor(100000000)),
    //   UserId: user.UserID,
    //   OrderAmount: data.Amount,
    //   OrderCurrency: "INR",
    //   OrderNote: "user wallet amount",
    //   CustomerName: user.FirstName + user.LastName,
    //   CustomerEmail: user.Email,
    //   CustomerPhone: user.Mobile,
    // };

    // setPgData(reqData);
    // setPgDisplay(true);
    // console.log(user,"user")
    let reqData = {
      //PgType: 1,
      OrderId: "WLTB-" + Math.floor(Math.random() * Math.floor(100000000)),
      OrderAmount: Number(Number(data.Amount) * Number(currencyRatio)),
      UserId: user.UserID,
      OrderCurrency: activeCurrency,
      OrderNote: "User Wallet TopUp",
      CustomerName: user.FirstName + user.LastName,
      CustomerEmail: user.Email,
      CustomerPhone: user.Mobile,
      currencyRatio: currencyRatio,
      DeviceOS: 0,
      DeviceOSVersion: "",
      DeviceToken: "",
      RoleType: 2,
      TDS: 0,
      GST: 0,
      Commission: 0,
      AdminMarkup: 0,
      AgentMarkup: 0,
      Conveniencefee: 0,
      AdminCommission: 0
    }
    // console.log(reqData,"reqData")
    APIClient.post("admin/createpgorder", reqData)
      .then((res) => {

        // console.log(res,"res")

        if (res.status === 200) {
          if (res.data.pgType===1){
          setPgData(res?.data);
          setPgDisplay(true);
          }else {
            window.location.href = res?.data?.payment_link;
          }
        } else if (res.status === 401) {
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    if (user) {
      if (user.UserID) {
        getAllWalletbalance(user.UserID);
        getqueryparam();
      }
    }
  }, [user]);

  useEffect(() => {
    getallTopUpReports();
  }, []);

  const getqueryparam = () => {
    const params = queryString.parse(document.location.search);
    if (params.Status == "Success") {
      message.success("TopUp is successful", 3);
    } else if (params.Status == "Failed") {
      message.error("TopUp is Failed", 3);
    }
  };

  const getAllWalletbalance = (userId) => {
    APIClient.get(`admin/GetUserWalletDetails/${userId}`)
      .then((res) => {
        // console.log(res,"res")
        if (res.status == 200) {
          setDetails(res.data.Amount);
        } else if (res.status == 401) {
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  let userID = 1;
  if (user) {
    if (user.UserID) {
      userID = user.UserID;
    }
  }

  const disabledDates = (currentDate) => {
    return currentDate > moment();
  };

  const onSearchFinish = (values) => {
    if (values.fromdate && values.todate) {
      values.fromdate = values.fromdate.format("YYYY-MM-DD");
      values.todate = values.todate.format("YYYY-MM-DD");

      APIClient.get(
        `admin/pgReportsBetweenDates?fromDate=${values.fromdate}&toDate=${values.todate}&UserId=${userID}`
      )
        .then((res) => {
          if (res.status == 200) {
            let data = res.data.filter((item) => item.orderId.includes("WLTB"));

            setDataSource(data);
            setTableData(data);
          } else {
            setDataSource([]);
            setTableData([]);
          }
        })
        .catch(() => {
          setDataSource([]);
          setTableData([]);
        });
    }
  };

  const columns = [
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },

    {
      key: "Status",
      title: "Status",
      dataIndex: "status",
      sorter: (a, b) => a.status?.localeCompare(b.status),
    },
    {
      title: "Price",
      dataIndex: "amount",
      key: "Price",
      sorter: (a, b) => a.amount - b.amount,
    },
    {
      title: "OrderId",
      dataIndex: "orderId",
      key: "orderId",
      sorter: (a, b) => a.orderId?.localeCompare(b.orderId),
    },
    {
      title: "Payment Mode",
      dataIndex: "paymentMode",
      key: "paymentMode",
      render: () => "Online",
    },
  ];

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = dataSource.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setTableData(searchResults);
    } else {
      setTableData(dataSource);
    }
  };

  const searchData = () => {
    setShowSearchBox(true);
  };
  const closeSearch = () => {
    setShowSearchBox(false);
  };
  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  const handleExcel = () => {
    let excelData = tableData.map((item) => {
      return {
        PgType: item.PgType,

        amount: item.amount,
        orderId: item.orderId,
        paymentMode: item.paymentMode,
        status: item.status,
      };
    });
    props.exportExcel(excelData, "WalletTransactionReports");
  };

  const getallTopUpReports = () => {
    APIClient.get(`admin/pgReportsByUserId/${userID}`)
      .then((res) => {
        if (res.status == 200) {
          let data = res.data.filter((item) => item.orderId.includes("WLTB"));

          setDataSource(data);
          setTableData(data);
        }
      })
      .catch(() => {
        setDataSource([]);
        setTableData([]);
      });
  };

  return (
    <>
    <Nav1/>
    <div>
      <div className="manage-markup-section wallct-markup-sec ourteam-bg">
        <Content className="container-fluid">
          <Row>
            <Col span={24}>
              <div className="card-bt-gap">
                <Card bordered={false} className="retrieve-booking mb-2">
                  <div className="card-add-heading">
                    <h5 className="wallect-user">User TopUp</h5>
                    {/* onClick={handleShow} */}
                    <div className="add-icon d-none">
                      <i className="fa fa-plus"></i>
                    </div>
                  </div>
                  <div className="walletBody">
                    &nbsp;
                    <Form layout="vertical" form={form} onFinish={submitform}>
                      <Row>
                        <Col md={24} xs={24}>
                          <p className="wallect-para">
                            Wallet Amount&nbsp;&nbsp;:&nbsp;&nbsp;
                            <b>{Details}</b>
                          </p>
                        </Col>
                      </Row>
                      <Row className="wallet-img">
                        <Col md={2} xs={24}>
                          <div className="wallet">
                            <img
                              src={wallet}
                              alt="wallet"
                              width="50px"
                              height="50px"
                            />
                          </div>
                        </Col>
                        <Col md={6} xs={24} className="wallect-input">
                          <Form.Item name="Amount">
                            <Input placeholder="Enter Amount to be Added in Wallet" />
                          </Form.Item>
                        </Col>
                        <Col md={6} xs={24} className="wallect-input">
                          <Form.Item>
                            {pgDisplay == false ? (
                              <Button
                                type="primary"
                                htmlType="submit"
                                className="add-money-wallet"
                                style={{backgroundColor:'#bd0c21'}}
                              >
                                Add Money To Wallet
                              </Button>
                            ) : (
                              <PaymentComponent
                                pgData={pgData}
                                // directPayment={true}
                              />
                            
                            )}
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </Card>
                <Card bordered={false} className="retrieve-booking">
                  <div className="card-add-heading">
                    <div className="rows-count mb-1">
                      <h5> Wallet Transaction Reports</h5>
                    </div>
                  </div>
                  <Form
                    form={searchForm}
                    layout={"vertical"}
                    onFinish={onSearchFinish}
                  >
                    <Row gutter={[16, 16]} align="middle">
                      <Col md={6} sm={12} xs={24}>
                        <Form.Item
                          name="fromdate"
                          label="From Date"
                          rules={[{ required: true, message: "Required" }]}
                        >
                          <DatePicker
                            disabledDate={disabledDates}
                            style={{ width: "100%" }}
                            format={dateFormat}
                            placeholder="From Date"
                          />
                        </Form.Item>
                      </Col>
                      <Col md={6} sm={12} xs={24}>
                        <Form.Item
                          name="todate"
                          label="To Date"
                          rules={[{ required: true, message: "Required" }]}
                        >
                          <DatePicker
                            disabledDate={disabledDates}
                            style={{ width: "100%" }}
                            format={dateFormat}
                            placeholder="To Date"
                          />
                        </Form.Item>
                      </Col>

                      <Col md={6} sm={12} xs={24}>
                        <Button type="primary" htmlType="submit" style={{backgroundColor:'#bd0c21'}}>
                          Submit
                        </Button>
                      </Col>
                    </Row>
                  </Form>

                  {dataSource.length > 0 ? (
                    <>
                      <div className="card-add-heading">
                        <div className="rows-count">
                          <div>
                            <h5>View Reports</h5>
                            <p>{tableData.length} rows found !</p>
                          </div>
                        </div>
                        <div className="action-images">
                          {showSearchBox && (
                            <Input
                              placeholder="Search"
                              onChange={(e) => searchTableData(e)}
                              suffix={closeSearchInput}
                              style={{ padding: "0px 12px", width: "200px" }}
                            />
                          )}
                          &nbsp;&nbsp;
                          <img
                            src={search}
                            alt="search"
                            onClick={(e) => searchData()}
                          />
                          <img src={excel} alt="excel" onClick={handleExcel} />
                        </div>
                      </div>

                      <Table
                        bordered
                        scroll={{ x: true }}
                        dataSource={tableData}
                        columns={columns}
                      />
                    </>
                  ) : loading ? (
                    <Spin
                      size="large"
                      tip="Loading..."
                      style={{ width: "100%" }}
                    />
                  ) : (
                    <b>No Results Found!</b>
                  )}
                </Card>
              </div>
            </Col>
          </Row>
        </Content>
      </div>
    </div>
    </>
  );
};

export default UpdatedComponent(Wallet);

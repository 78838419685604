import React, { createContext, useEffect, useState, useContext } from "react";
import APIClient from "../../helpers/ApiClient";
import { useAuthContext } from "./AuthProvider";
import moment from "moment";
const SytContext = createContext();

export function useSytContext() {
  return useContext(SytContext);
}
let dateFormat = "YYYY-MM-DD";
function SytProvider({ children }) {
  const {
    isLogin: { agent },
    user,
  } = useAuthContext();

  const [logo, setLogo] = useState("");
  const [agentLogo, setAgentLogo] = useState(null);
  const [blog, setBlog] = useState([]);
  const [visaGuideFaqs, setVisaGuideFaqs] = useState([]);

  const [categoryList, setCategoryList] = useState([]);
  const [countryTourList, setCountryTourList] = useState({});
  const [countryCruiseList, setCountryCruiseList] = useState({});
  const [popularVisaList, setPopularVisaList] = useState({});
  const [cruiseTypes, setCruisetypes] = useState({});
  const [popularCFFleets, setPopularCFFleets] = useState([]);
  const [popularActivityList, setpopularActivityList] = useState({});
  const [siteDetails, setSiteDetails] = useState({
    BusinessAddress: [],
    facebook: "https://www.facebook.com/",
    youtube: "https://www.youtube.com/",
    twitter: "https://twitter.com/",
    instagram: "https://www.instagram.com/",
  });
  // console.log(logo, "logo");
  // console.log(agentLogo, "agent");
  const getSocialLinks = () => {
    let userID = 1;
    APIClient.get(`admin/settings/${userID}`)
      .then((res) => {
        if (res.status == 200) {
          const { settings } = res.data;

          let address = [];

          if (settings?.BusinessAddress?.length > 0) {
            address = settings?.BusinessAddress;
          }

          setSiteDetails((prev) => ({
            ...prev,
            BusinessAddress: address,
            facebook: settings?.userSocialDetails?.FacebookUrl,
            youtube: settings?.userSocialDetails?.Youtube,
            twitter: settings?.userSocialDetails?.Twitter,
            instagram: settings?.userSocialDetails?.Instagram,
          }));
        }
      })
      .catch((error) => {});
  };

  const [banners, setBanners] = useState({
    flightBanners: [],
    busBanners: [],
    hotelBanners: [],
    activityBanners: [],
    packBanners: [],
    agentLogos: [],
    flightSearchBanners: [],
    busSearchBanners: [],
    hotelSearchBanners: [],
    activitySearchBanners: [],
    packSearchBanners: [],
    footerBanners: [],
    bigHomeSliderBanners: [],
    smallHomeSliderBanners: [],
    carBanners: [],
    visaBanners: [],
    carSearchBanners: [],
    visaSearchBanners: [],
    holidayBanners: [],
    cruiseBanners: [],
    charterFlightBanners: [],
    charterFlightSearchBanners: [],
    holidayPackageSearchBanners: [],
    rechargeBillBanners: [],
    blogBanners: [],
    offerBanners: [],
  });

  const getBannersList = async () => {
    const res = await APIClient.get("admin/banners");
    if (res.status === 200) {
      const banners = res?.data?.reduce((obj, item) => {
        switch (item?.BannerType) {
          case 1:
       
           setLogo(item?.images?.[0]?.path);
            break;
          case 6:
            obj.agentLogos = item?.images;
            break;
          case 7:
            obj.flightBanners = item.images;
            break;
          case 13:
            obj.flightSearchBanners = item.images;
            break;
          case 8:
            obj.busBanners = item.images;
            break;
          case 14:
            obj.busSearchBanners = item.images;
            break;
          case 25:
            obj.carBanners = item.images;
            break;
          case 28:
            obj.visaBanners = item.images;
            break;
          case 9:
            obj.hotelBanners = item.images;
            break;
          case 15:
            obj.hotelSearchBanners = item.images;
            break;
          case 30:
            obj.carSearchBanners = item.images;
            break;
          case 31:
            obj.visaSearchBanners = item.images;
            break;
          case 10:
            obj.activityBanners = item.images;
            break;
          case 16:
            obj.activitySearchBanners = item.images;
            break;
          case 11:
            obj.packBanners = item.images;
            break;
          case 17:
            obj.packSearchBanners = item.images;
            break;
          case 20:
            obj.footerBanners = item.images;
            break;
          case 21:
            obj.bigHomeSliderBanners = item.images;
            break;
          case 22:
            obj.smallHomeSliderBanners = item.images;
            break;
          case 40:
            obj.charterFlightBanners = item.images;
            break;
          case 41:
            obj.charterFlightSearchBanners = item.images;
            break;
          case 42:
            obj.holidayPackageSearchBanners = item.images;
            break;
          case 46:
            obj.cruiseBanners = item.images;
            break;
          case 47:
            obj.rechargeBillBanners = item.images;
            break;
          case 48:
            obj.blogBanners = item.images;
            break;
          case 49:
            obj.offerBanners = item.images;
            break;
        }
        return obj;
      }, {});

      setBanners(banners);
      handleAgentLogo(banners.agentLogos);
    }
  };

  const getBlogs = () => {
    APIClient.get("admin/blog")
      .then((res) => {
        if (res.statusCode == 200) {
          let data = res.data
            .filter((item) => item.BlogStatus === 1)
            .map((item) => ({
              ...item,
              urlTitle: item.BlogTitle ? item.BlogTitle.replace(/ /g, "") : "",
            }));

          setBlog(data);
        } else {
          setBlog([]);
        }
      })
      .catch((error) => {
        setBlog([]);
      });
  };

  const getAllVisaGuide = () => {
    setVisaGuideFaqs([]);
    APIClient.get("visa/getAllVisaGuides").then((res) => {
      if (res) {
        if (res.status == 200) {
          setVisaGuideFaqs(res.data);
        } else if (res.statusCode === 500) {
          setVisaGuideFaqs([]);
        }
      }
    });
  };

  const handleAgentLogo = (agentLogos) => {
    if (agent && user && agentLogos?.length > 0) {
      let logo = agentLogos.filter((item) => item.UserId === user?.UserID);

      if (logo.length > 0) {
        setAgentLogo(logo[0].path);
      } else {
        setAgentLogo(null);
      }
    } else {
      setAgentLogo(null);
    }
  };
  const [promoDataSource, setPromoDataSource] = useState([]);

  const [topCities, setTopCities] = useState([]);

  const getAllServicesTopCities = () => {
    setTopCities([]);
    APIClient.get("admin/getAllServicesTopCities").then((res) => {
      if (res.status == 200) {
        setTopCities(res.data);
      }
    });
  };

  const getPromoCodes = () => {
    setPromoDataSource([]);
    APIClient.get("admin/promo")
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
              ValidFrom: moment(item.ValidFrom).format(dateFormat),
              ValidTill: moment(item.ValidTill).format(dateFormat),
            };
          });

          setPromoDataSource(data);
        }
      })
      .catch((e) => {
        console.log("api error", e);
      });
  };

  const getCityActivitiesList = () => {
    APIClient.get("extranet/getAllActivities")
      .then((res) => {
        if (res.status === 200) {
          let result = res.data.reduce(function (obj, key) {
            obj[key.countryName] = obj[key.countryName] || [];
            obj[key.countryName].push(key);
            return obj;
          }, {});
          setpopularActivityList(result);
        } else {
          setpopularActivityList({});
        }
      })
      .catch((e) => {
        setpopularActivityList({});
      });
  };

  // const getallHolidayRecords = () => {
  //   APIClient.get("ToursExtranet/GetTourExPackages")
  //     .then((res) => {
  //       if (res.status === 200) {
  //         let result = res.data.reduce(function (obj, key) {
  //           obj[key.CountryName] = obj[key.CountryName] || [];
  //           obj[key.CountryName].push(key);
  //           return obj;
  //         }, {});
  //         setCountryTourList(result);
  //         // setDataSource({ sliderData: data, cityData: result });
  //       } else {
  //         // setDataSource({ sliderData: [], cityData: [] });
  //         setCountryTourList();
  //       }
  //     })
  //     .catch((e) => {
  //       // setDataSource({ sliderData: [], cityData: [] });
  //       setCountryTourList();
  //     });
  // };

  // const getallCruiseRecords = () => {
  //   APIClient.get("GetCruisePackage")
  //     .then((res) => {
  //       if (res.status === 200) {
  //         let result = res.data.reduce(function (obj, key) {
  //           obj[key.CountryName] = obj[key.CountryName] || [];
  //           obj[key.CountryName].push(key);
  //           return obj;
  //         }, {});
  //         setCountryCruiseList(result);
  //       } else {
  //         setCountryCruiseList({});
  //       }
  //     })
  //     .catch((e) => {
  //       setCountryCruiseList({});
  //     });
  // };

  // const getallVisaRecords = () => {
  //   setPopularVisaList({});
  //   APIClient.get("visa/getAllManageMasterVisa").then((res) => {
  //     if (res?.status == 200) {
  //       let result = res.data.reduce(function (obj, key) {
  //         obj[key.Country] = obj[key.Country] || [];
  //         obj[key.Country].push(key);
  //         return obj;
  //       }, {});
  //       setPopularVisaList(result);
  //     }
  //   });
  // };
  // const getallCFFleets = () => {
  //   setPopularCFFleets([]);
  //   APIClient.get("CharterFlights/FleetMaster").then((res) => {
  //     if (res.status == 200) {
  //       setPopularCFFleets(res.data);
  //     }
  //   });
  // };

  const getCategoryList = () => {
    setCategoryList([]);
    APIClient.get("ToursExtranet/GetCategory")
      .then((res) => {
        if (res.status == 200) {
          setCategoryList(res.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  // const getCruiseTypes = () => {
  //   setCruisetypes({});
  //   APIClient.get("GetCruisePackage")
  //     .then((res) => {
  //       if (res.status == 200) {
  //         let result = res.data.reduce(function (obj, key) {
  //           obj[key.CruiseName] = obj[key.CruiseName] || [];
  //           obj[key.CruiseName].push(key);
  //           return obj;
  //         }, {});

  //         setCruisetypes(result);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // };

  useEffect(() => {
    getBannersList();
    getSocialLinks();
    getBlogs();
    // getAllVisaGuide();
    getPromoCodes();
    getAllServicesTopCities();

    // getCategoryList();
    // getallHolidayRecords();
    // getallCruiseRecords();
    // getallVisaRecords();
    // getallCFFleets();
    // getCruiseTypes();

    getCityActivitiesList();
  }, []);

  useEffect(() => {
    if (agent && user) {
      handleAgentLogo(banners.agentLogos);
    }
  }, [agent, user]);
  return (
    <SytContext.Provider
      value={{
        logo,
        blog,
        flightBanners: banners.flightBanners,
        busBanners: banners.busBanners,
        hotelBanners: banners.hotelBanners,
        activityBanners: banners.activityBanners,
        packBanners: banners.packBanners,
        agentLogo,
        flightSearchBanners: banners.flightSearchBanners,
        busSearchBanners: banners.busSearchBanners,
        hotelSearchBanners: banners.hotelSearchBanners,
        activitySearchBanners: banners.activitySearchBanners,
        packSearchBanners: banners.packSearchBanners,
        footerBanner: banners.footerBanners,
        bigHomeSliderBanners: banners.bigHomeSliderBanners,
        smallHomeSliderBanners: banners.smallHomeSliderBanners,
        getBannersList,
        siteDetails,
        carBanners: banners.carBanners,
        visaBanners: banners.visaBanners,
        carSearchBanners: banners.carSearchBanners,
        visaSearchBanners: banners.visaSearchBanners,
        holidayBanners: banners.holidayBanners,
        visaGuideFaqs,
        charterFlightBanners: banners.charterFlightBanners,
        charterFlightSearchBanners: banners.charterFlightSearchBanners,
        holidayPackageSearchBanners: banners.holidayPackageSearchBanners,
        promoDataSource: promoDataSource,
        topCities: topCities,
        cruiseBanners: banners.cruiseBanners,
        rechargeBillBanners: banners.rechargeBillBanners,
        offerBanners: banners.offerBanners,
        blogBanners: banners.blogBanners,
        popularCFFleets,
        cruiseTypes,
        popularVisaList,
        countryCruiseList,
        countryTourList,
        categoryList,
        popularActivityList,
      }}
    >
      {children}
    </SytContext.Provider>
  );
}
export default SytProvider;

import React, { useState, useEffect } from "react";
import { Button, Radio } from "antd";

import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import { useAuthContext } from "../../../common/providers/AuthProvider";
const BusSort = ({
  busSearchResultObj,
  setBusResultsList,
  showNetFare,
  setShowNetFare,
}) => {
  const {
    isLogin: { agent },
    user,
  } = useAuthContext();
  const [sorting, setSorting] = useState("price");
  const [sortDirection, setSortDirection] = useState("asc");
  const handleChange = (e) => {
    const selSorting = e.target.value;
    setSorting(selSorting);
    setSortDirection("asc");
    sortBuses(selSorting, "asc");
  };

  const handleClick = (e) => {
    const selSorting = e.target.value;
    if (sorting === selSorting) {
      let dir = sortDirection === "asc" ? "desc" : "asc";
      sortBuses(selSorting, dir);
      setSortDirection(dir);
    }
  };

  useEffect(() => {
    sortBuses("price", "asc");
  }, []);

  const sortBuses = (selSorting, dir) => {
    if (
      Object.keys(busSearchResultObj).length > 0 &&
      busSearchResultObj?.data
    ) {
      if (!busSearchResultObj.data.trips) return;
      let filterTrips = busSearchResultObj.data.trips.sort((busA, busB) => {
        if (selSorting === "price") {
          return dir === "asc"
            ? compareBusesPrice(busA, busB)
            : compareBusesPrice(busB, busA);
        } else if (selSorting === "duration") {
          const bus1 = busA.duration.split(":");
          const bus2 = busB.duration.split(":");
          return dir === "asc"
            ? compareBusesDur(bus1, bus2)
            : compareBusesDur(bus2, bus1);
        } else {
          const bus1 = busA[selSorting];
          const bus2 = busB[selSorting];
          return dir === "asc"
            ? compareBuses(bus1, bus2)
            : compareBuses(bus2, bus1);
        }
      });
      setBusResultsList({
        ...busSearchResultObj.data,
        trips: [...filterTrips],
      });
    }
  };

  const compareBusesPrice = (A, B) => {
    let a = parseFloat(A.startingFare.split("/")[0]);
    let b = parseFloat(B.startingFare.split("/")[0]);

    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    if (a == b) {
      const bus1 = A.duration.split(":");
      const bus2 = B.duration.split(":");
      return compareBusesDur(bus1, bus2);
    }
    return 0;
  };

  const compareBusesDur = (A, B) => {
    let a = Number(A[0]);
    let b = Number(B[0]);

    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    if (a == b) {
      let mA = A[1].split(" ");
      let mB = B[1].split(" ");

      if (Number(mA[0]) < Number(mB[0])) {
        return -1;
      }
      if (Number(mA[0]) > Number(mB[0])) {
        return 1;
      }
    }
    return 0;
  };
  const compareBuses = (a, b) => {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  };

  const getSortIcon = (val) => {
    return val === "asc" ? (
      <CaretUpOutlined className="mr-2" />
    ) : (
      <CaretDownOutlined className="mr-2" />
    );
  };

  return (
    <div className="sort-block sort-bus-block-22">
      <div className="results-sort-block p-3">
        <p className="sort-header mobile-sort-22">Sort by:</p>
        <Radio.Group onChange={handleChange} value={sorting}>
          <Radio value={"price"} onClick={handleClick}>
            <span className="checkmark">
              <div className="active-background">
                <p className="price-type">
                  {sorting === "price" ? getSortIcon(sortDirection) : ""}
                  Fare
                </p>
              </div>
            </span>
          </Radio>
          <Radio value={"departureTime"} onClick={handleClick}>
            <span className="checkmark">
              <div className="active-background">
                <p className="price-type">
                  {sorting === "departureTime"
                    ? getSortIcon(sortDirection)
                    : ""}
                  Departure
                </p>
              </div>
            </span>
          </Radio>
          <Radio value={"arrivalTime"} onClick={handleClick}>
            <span className="checkmark">
              <div className="active-background">
                <p className="price-type">
                  {sorting === "arrivalTime" ? getSortIcon(sortDirection) : ""}
                  Arrival
                </p>
              </div>
            </span>
          </Radio>

          <Radio value={"duration"} onClick={handleClick}>
            <span className="checkmark">
              <div className="active-background">
                <p className="price-type">
                  {sorting === "duration" ? getSortIcon(sortDirection) : ""}
                  Duration
                </p>
              </div>
            </span>
          </Radio>
        </Radio.Group>
        {agent && user?.Role?.RoleLevel === 3 ? (
          <div
            className="netfareButton"
            onClick={() => setShowNetFare(!showNetFare)}
          >
            <Button> {showNetFare ? "Hide" : "Show"} Net Fare</Button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default BusSort;

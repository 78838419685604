import React, { useState, Suspense } from 'react';
import { Link, useHistory } from "react-router-dom";
import './Combineser.scss';
import Icon1 from '../../assets/images/Icons/fliIc.png'
import Icon2 from '../../assets/images/Icons/4.png'
import Icon3 from '../../assets/images/Icons/5.png'
import { useAuthContext } from "../../common/providers/AuthProvider";
const Buses = React.lazy(() => import('../../components/buses-search/Buses'))
const HotelSearch = React.lazy(() => import('../../components/Hotels/Hotels'))
const FlightSearch = React.lazy(() => import('../../components/flight-search/FlightSearch'))



const CombineServLink = (props) => {
   
    const [index, setIndex] = useState(props.activetab);

    const {
        isLogin: { agent },
        user,
        isGuest,
    } = useAuthContext();

    let history = useHistory();
    const handelIndex = (index, link) => {
        setIndex(index)

    }
    return (
        <div className='main-bar'>
            <div className="main-searchbar-tabs-box">
                <div className="main-searchbar-tabs-box1">
                    <div className=" ">
                        <div className="tabs-main-box-outer">

                            {/* <span>
                                <Link to="/" className={`tabs-main-box-inner  ${index === 0 ? 'active2' : null}`}>  <div onClick={() => handelIndex(0, "/")}>   <span class="fa fa-plane">  <span className='combinesearch-tab-headng' >Flights</span></span> </div></Link> 
                           
                                <Link to="/hotels" className={`tabs-main-box-inner  ${index === 1 ? 'active2' : null}`}>   <div onClick={() => handelIndex(1, "/hotels")}>   <span class="fa fa-hotel" >  <span className='combinesearch-tab-headng' > Hotels</span></span></div></Link> 
                           
                                <Link to="/bus" className={`tabs-main-box-inner  ${index === 2 ? 'active2' : null}`} ><div onClick={() => handelIndex(2, "/bus")}>  <span class="fa fa-shopping-bag" >  <span className='combinesearch-tab-headng' > Bus</span></span></div></Link> </span> */}

                            <div className='tabs-main-box-inner'>
                                <Link to="/" className={`tabs-main-box-inner1  ${index === 0 ? 'active2' : null}`}>
                                    <div onClick={() => handelIndex(0, "/")}>
                                        <img src={Icon1} alt="" className='src-Icon' />
                                        {/* <span class="fa fa-plane"> */}
                                        <span className='combinesearch-tab-headng' >Flights</span>
                                        {/* </span> */}
                                    </div>
                                </Link>
                                <Link to="/buses" className={`tabs-main-box-inner3  ${index === 2 ? 'active2' : null}`} >
                                    <div onClick={() => handelIndex(2, "/buses")}>
                                        {/* <span class="fa fa-bus" >   */}
                                        <img src={Icon3} alt="" className='src-Icon' />
                                        <span className='combinesearch-tab-headng' > Buses</span>
                                        {/* </span> */}
                                    </div></Link>
                                <Link to="/hotels" className={`tabs-main-box-inner2  ${index === 1 ? 'active2' : null}`}>   <div onClick={() => handelIndex(1, "/hotels")}>
                                    {/* <span class="fa fa-hotel" >  */}
                                    <img src={Icon2} alt="" className='src-Icon' />
                                    <span className='combinesearch-tab-headng' >  Hotels</span>
                                    {/* </span> */}
                                </div></Link>


                            </div>

                        </div>
                    </div>
                    <div>
                        <div hidden={index !== 0}>
                            <Suspense fallback={<div>Loading...</div>}>
                                <FlightSearch />
                            </Suspense>
                        </div>
                        <div hidden={index !== 2}>

<Suspense fallback={<div>Loading...</div>}>
    <Buses />
</Suspense>
</div>
                        <div hidden={index !== 1}>

                            <Suspense fallback={<div>Loading...</div>}>
                                <HotelSearch />
                            </Suspense>
                        </div>
                       
                    </div>
                </div>
            </div>



        </div>
    )
}
export default CombineServLink;

import React from "react";
import CmsPages from "../../common/CMSPages/CmsPages";
import Nav1 from "../../common/navbar/Nav1";
const Disclaimer = () => {
  return (
    <>
    <Nav1/>
  <CmsPages cmsId={7} pageTitle="Disclaimer" />
  </>
  )
};

export default Disclaimer;

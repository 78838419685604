import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  message,
  Modal,
  Select,
  Spin,
  Table,
  Rate,
  Upload,
} from "antd";
import queryString from "query-string";
import { useAuthContext } from "../../common/providers/AuthProvider";
import ApiClient from "../../helpers/ApiClient";
import moment from "moment";
import { saveAs } from "file-saver";
import search from "../../../src/assets/vector-icons/search.png";
import excel from "../../assets/vector-icons/excel.png";
import UpdatedComponent from "../ExportExcel";
import { getVisaTicketStatus } from "../../common/AllTickets/AllTicketStatus";
import { CloseOutlined, PlusOutlined,EyeOutlined, DownloadOutlined, } from "@ant-design/icons";
import "./Bookingreports.scss";
import Nav1 from "../../common/navbar/Nav1";

const { Option } = Select;
const { TextArea } = Input;
let dateFormat = "DD-MM-YYYY";
let oriDateFormat = "YYYY-MM-DD";

const BASE = process.env.REACT_APP_BASE_URL;
const uploadActionUrl = BASE + "media/upload/multipleImages";

const ImageUpload = ({
  category,
  fileList,
  setFileList,
  formName,
  label = "",
  limit,
  limitSize,
  deleteBanner,
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const uploadLogoButton = !isUploading ? (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">Upload </div>
    </div>
  ) : (
    <div className="ant-upload-text">Loading... </div>
  );

  function beforeUpload(file) {
    const checkJpgOrPng =
      file.type === "image/png" ||
      file.type === "image/jpeg" ||
      file.type === "image/jpg";
    if (!checkJpgOrPng) {
      message.error("You can only upload jpg, jpeg and png file!");
    }

    const checkFileSize = file.size < 1126400;
    if (!checkFileSize) {
      message.error(" Image must be smaller than 1Mb!");
    }

    return checkJpgOrPng && checkFileSize;
  }

  function getImages(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  function handleChange(info) {
    setFileList(info.fileList);
    if (info.file.status === "uploading") {
      setIsUploading(true);
      return;
    }
    if (info.file.status === "removed") {
      setIsUploading(false);
    }
    if (info.file.status === "error") {
      setIsUploading(false);
      return;
    }
    if (info.file.status === "done") {
      setIsUploading(false);
    }
  }

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getImages(file.originFileObj);
    }
    setPreviewVisible(true);
    setPreviewImage(file.url || file.preview);

    setPreviewTitle(file.name);
  };

  const handleCancel = () => {
    setPreviewVisible(false);
  };

  return (
    <>
      <Form.Item name={formName} label={label ? label : ""}>
        <Upload
          name="image"
          listType="picture-card"
          className="avatar-uploader"
          fileList={fileList}
          action={uploadActionUrl}
          onChange={handleChange}
          onPreview={handlePreview}
          data={{ category: category }}
          // onRemove={(val) => deleteBanner(val.uid)}
          beforeUpload={beforeUpload}
        >
          {limit
            ? fileList
              ? fileList.length >= limitSize
                ? null
                : uploadLogoButton
              : uploadLogoButton
            : uploadLogoButton}
        </Upload>
      </Form.Item>
      <Modal
        destroyOnClose={true}
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img src={previewImage} alt="imagePreview" style={{ width: "100%" }} />
      </Modal>
    </>
  );
};

const Bookingreports = (props) => {
  const params = queryString.parse(window.location.search);
  const {
    isLogin: { agent },
    user,
  } = useAuthContext();
  const [reviewForm] = Form.useForm();
  const [showTable, setShowTable] = useState(false);
  // const [vocher, setVocher] = useState(false);
  const [isDateDisplay, setIsDateDisplay] = useState(false);
  const [reviewModal, setReviewModal] = useState({
    ServiceType: "",
    RefNo: "",
    UserId: "",
    EmailId: "",
    PhoneNumber: "",
    StartRating: 0,
    TotalAmount: 0,
    modalVisible: false,
  });
  const [loading, setLoading] = useState(false);
  const [fromdateto, setFromdateto] = useState(moment());
  const [todateto, setTodateto] = useState();
  const [reports, setReports] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [reviewImages, setReviewImages] = useState([]);

  const [showSearchBox, setShowSearchBox] = useState(false);
  const [serviceType, setServiceType] = useState(-1);
  const [arrivalDate, setArrivalDate] = useState(null);
  const [weekendDate, setWeekendDate] = useState(null);
  const [ordersList, setOrdersList] = useState([]);
  const [carList, setcarList] = useState([]);
  const [bookedType, setbookedType] = useState(null);
  const [imageRequestModal, setImageRequestModal] = useState(false);
  const [imageRequest, setImageRequest] = useState([]);

  let dateFormat = "YYYY-MM-DD";
  const [columns, setColumns] = useState([]);
  const [form] = Form.useForm();
  const [toDisableDate, setToDisableDate] = useState(moment());
  const searchData = () => {
    setShowSearchBox(true);
  };
  const closeSearch = () => {
    setShowSearchBox(false);
  };

  const toggleReviewModal = () => {
    reviewForm.resetFields();
    setReviewImages([]);
    setReviewModal((prev) => ({
      ...prev,
      ServiceType: "",
      RefNo: "",
      UserId: "",
      EmailId: "",
      PhoneNumber: "",
      StartRating: 0,
      TotalAmount: 0,
      modalVisible: !prev.modalVisible,
    }));
  };

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = reports.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setDataSource(searchResults);
    } else {
      setDataSource(reports);
    }
  };
  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );
  const renderReffrence = (ref, rec) => {
    const type = rec.type;
    // console.log(type,"tttt");
    let url = "";
    switch (type) {
      case "Flight": {
        url = "flight/ticket";
        break;
      }
      case "Bus": {
        url = "bus/ticket";
        break;
      }
      case "transfer": {
        url = "cartransfer/ticket";
        break;
      }
      case "Hotel": {
        url = "hotel/ticket";
        break;
      }
      case "Visa": {
        url = "visa/getVisaBookingDetailsByRefNo";
        break;
      }
      case "Activities": {
        url = "activities/ticket";
        break;
      }
    }

    return <Link to={url + "?ref=" + ref}>{ref}</Link>;
  };

  const submitReviewForm = (val) => {
    if (!reviewModal?.StartRating && reviewModal?.StartRating === 0) {
      message.error("Star Rating Required");
      return;
    }

    let array = [];
    if (val.ImagesPath) {
      if (val.ImagesPath.fileList.length > 0) {
        for (let i = 0; i < val.ImagesPath.fileList.length; i++) {
          if (val.ImagesPath.fileList[i].response.status === 200) {
            array.push(val.ImagesPath.fileList[i].response.data.filepaths[0]);
          }
        }
      }
    }

    let req = {
      ...reviewModal,
      ...val,
      ImagesPath: array,
      DeviceOS: 3,
      ApplicationType: 1,
      CreadtedBy: user?.UserID ?? 1,
      CreatedDate: moment(),
      ModifiedBy: 0,
      Status: 0,
      ModifiedDate: "string",
      DeviceToken: "string",
      DeviceType: "string",
      FirBaseToken: "string",
      DeviceOSVersion: "string",
    };

    delete req.modalVisible;

    ApiClient.post("UserReviews/userReview", req)
      .then((res) => {
        if (res.status == 200) {
          if (res?.message) message.success(res?.message, 3);

          toggleReviewModal();
        }
      })
      .catch(() => {});
  };

  const getReviewButton = (record) => {
    if (!agent) {
      if (record.type === "Bus") {
        if ([3, 7].includes(record.bookingStatus)) {
          if (moment(record.journeyDate, "DD-MM-YYYY") < moment()) {
            return (
              <Button onClick={() => handleReviewModal(record)}>
                Add Review
              </Button>
            );
          }
        }
      } else if (record.type === "Flight") {
        if ([3].includes(record.bookingStatus)) {
          if (moment(record.journeyDate) < moment()) {
            return (
              <Button onClick={() => handleReviewModal(record)}>
                Add Review
              </Button>
            );
          }
        }
      } else if (record.type === "Hotel") {
        if ([2].includes(record.bookingStatus)) {
          if (
            moment(record.CheckInDate, "YYYY-MM-DD").startOf("day") <
            moment().startOf("day")
          ) {
            return (
              <Button onClick={() => handleReviewModal(record)}>
                Add Review
              </Button>
            );
          }
        }
      } else if (record.type === "Activities") {
        if ([3].includes(record.bookingStatus)) {
          if (
            moment(record.journeyDate, "YYYY-MM-DD").startOf("day") <
            moment().startOf("day")
          ) {
            return (
              <Button onClick={() => handleReviewModal(record)}>
                Add Review
              </Button>
            );
          }
        }
      }
    }
  };

  const handleReviewModal = (record) => {
    if (record.type === "Bus") {
      setReviewModal({
        ServiceType: 3,
        RefNo: record.referenceNumber,
        UserId: record.UserId,
        EmailId: record.guestEmaiId,
        PhoneNumber: record.guestMobileNo,
        StartRating: 0,
        TotalAmount: record.totalPrice,
        modalVisible: true,
      });
    } else if (record.type === "Flight") {
      setReviewModal({
        ServiceType: 1,
        RefNo: record.referenceNumber,
        UserId: record.UserId,
        EmailId: record.guestEmaiId,
        PhoneNumber: record.guestMobileNo,
        StartRating: 0,
        TotalAmount: record.totalPrice,
        modalVisible: true,
      });
    } else if (record.type === "Hotel") {
      setReviewModal({
        ServiceType: 2,
        RefNo: record.referenceNumber,
        UserId: record.UserId,
        EmailId: record.guestEmaiId,
        PhoneNumber: record.guestMobileNo,
        StartRating: 0,
        TotalAmount: record.totalPrice,
        modalVisible: true,
      });
    } else if (record.type === "Activities") {
      setReviewModal({
        ServiceType: 4,
        RefNo: record.referenceNumber,
        UserId: record.UserId,
        EmailId: record.guestEmaiId,
        PhoneNumber: record.guestMobileNo,
        StartRating: 0,
        TotalAmount: record.totalPrice,
        modalVisible: true,
      });
    }
  };

  const getStatus = (status) => {
    switch (status) {
      case "HOLD": {
        return <p style={{ color: "#FFA500" }}>HOLD</p>;
      }
      case "BLOCKED": {
        return <p style={{ color: "#FFA500" }}>{status}</p>;
      }
      case "CONFIRMED": {
        return <p style={{ color: "#008000" }}>{status}</p>;
      }
      case "BOOKED": {
        return <p style={{ color: "#008000" }}>{status}</p>;
      }
      case "CANCELLED": {
        return <p style={{ color: "#bd0c21" }}>{status}</p>;
      }
      case "CANCELED": {
        return <p style={{ color: "" }}>{status}</p>;
      }
      case "CREATED": {
        return <p style={{ color: "#f9e218" }}>{status}</p>;
      }
      case "PARTIALLY CANCELLED": {
        return <p style={{ color: "#bd0c21" }}>{status}</p>;
      }
      case "FAILED": {
        return <p style={{ color: "#bd0c21" }}>{status}</p>;
      }
      default:
        return;
    }
  };

  const TravelType = (type) => {
    switch (type) {
      case 1:
        return <p>Local</p>;

      case 2:
        return <p>Out-Station</p>;
    }
  };

  const TripType = (triptype) => {
    switch (triptype) {
      case 1:
        return <p>One way</p>;
      case 2:
        return <p>Round trip</p>;
      case 3:
        return <p>4 hours</p>;
      case 4:
        return <p>8 hours</p>;
      case 5:
        return <p>12 hours</p>;
      case 6:
        return <p>24 hours</p>;
    }
  };

  let flightColumns = [
    {
      title: "Reference number",
      dataIndex: "referenceNumber",
      render: (text, record) => renderReffrence(text, record),
      sorter: (a, b) => a.referenceNumber?.localeCompare(b.referenceNumber),
    },
    {
      title: "Review & Rating/Photos",
      render: (_, record) => getReviewButton(record),
    },
    {
      title: "Source",
      dataIndex: "from",
      render: (text, record) => {
        return record.type == "Flight"
          ? record?.oneWaySegment[0]?.origin
          : text;
      },
    },
    {
      title: "Destination",
      dataIndex: "to",
      render: (text, record) => {
        if (record.type == "Flight") {
          let length = record?.oneWaySegment?.length;
          return record?.oneWaySegment[length - 1]?.destination;
        } else {
          return text;
        }
      },
    },
    {
      title: "Arrival Date",
      dataIndex: "oneWaySegment",
      render: (text, record) =>
        record.type == "Flight"
          ? moment(text[0]?.arrivalDateTime, oriDateFormat).format(dateFormat)
          : text,
    },
    {
      title: "Departure Date",
      dataIndex: "oneWaySegment",
      render: (text, record) => {
        if (record.type == "Flight") {
          let length = text.length;
          return moment(
            text[length - 1]?.departureDateTime,
            oriDateFormat
          ).format(dateFormat);
        } else {
          return text;
        }
      },
    },
    {
      title: "Booking Date",
      // dataIndex: "journeyDate",
      key: "bookingDate",
      render: (text) =>
        text.bookingDate != undefined
          ? moment(text.bookingDate).format("DD-MM-YYYY")
          : "",
      sorter: (a, b) => a.bookingDate?.localeCompare(b.bookingDate),
    },
    {
      title: "Journey Date",
      dataIndex: "journeyDate",
      key: "journeyDate",
      render: (text) => (text ? moment(text).format("DD-MM-YYYY") : null),
    },
    // {
    //   title: "Departure Date",
    //   dataIndex: "oneWaySegment",
    //   render: (text, record) => {
    //     if (record.type === "Flight") {
    //       let length = text.length;
    //       return moment(
    //         text[length - 1].departureDateTime,
    //         oriDateFormat
    //       ).format(dateFormat);
    //     } else {
    //       return text;
    //     }
    //   },
    // },

    {
      title: "Guest Name ",
      dataIndex: "guestName",
    },
    {
      title: "Guest Email",
      dataIndex: "guestEmaiId",
    },
    {
      title: "Guest Mobile ",
      dataIndex: "guestMobileNo",
    },
    {
      title: "Adults",
      dataIndex: "pax",
      render: (text) => (text ? text.adults : ""),
    },
    {
      title: "Childs",
      dataIndex: "pax",
      render: (text) => (text ? text.childCount : ""),
    },
    {
      title: "Infant",
      dataIndex: "pax",
      render: (text) => (text ? text.infantCount : ""),
    },
    {
      title: "Booking Status",
      dataIndex: "BookingStatus",
      render: (status) => getStatus(status),
    },
  ];
  const Visacolumns = [
    {
      title: "Reference No.",
      dataIndex: "ReferenceNumber",
      // render: (text) => {
      //   return <Link to={`visa/getVisaBookingDetailsByRefNo/${text}`}>{text}</Link>;
      render: (text) => {
        return <Link to={`/visa/ticket?ref=${text}`}>{text}</Link>;
      },
      sorter: (a, b) => a.referenceNumber?.localeCompare(b.referenceNumber),
    },

    // {
    //   title: "Name",
    //   render: (item) => `${item.title} ${item.customerFirstName} ${item.customerLastName}`
    // },

    {
      title: "Name",
      render: (item) => `${item.customerFirstName} ${item.customerLastName}`,
    },
    {
      title: "Email",
      dataIndex: "customerEmail",
    },
    {
      title: "Mobile",
      dataIndex: "customerMobile",
    },
    // {
    //   title: "Booking Status",
    //   render: (item) => {
    //     const status = getVisaTicketStatus(item.BookingStatus);
    //     return (
    //       <span style={{ color: getVisaTicketStatus(status) }}>{status}</span>
    //     );
    //   },
    // },
    {
      title: "Passport No.",
      dataIndex: "passportNumber",
    },
    {
      title: "Nationality",
      dataIndex: "customerNationality",
    },
    {
      title: "DOB",
      dataIndex: "customerDOB",
      render: (item) => moment(item).format(dateFormat),
    },
    {
      title: "Booking No",
      dataIndex: "BookingConfirmationNumber",
    },
    {
      title: "Booked On",
      dataIndex: "createdDate",
      render: (date) => moment(date).format("DD-MM-YYYY"),
    },

    // {
    //   title: "PAN",
    //   dataIndex: "pan",
    //   // render: (item) => {
    //   //   return item
    //   //   ? ImageRequest(JSON.parse(item)) : null;
    //   // },
    // },
    // {
    //   title: "Passport",
    //   dataIndex: "passport",
    //   // render: (item) => {
    //   //   return item ? ImageRequest(JSON.parse(item)) : null;
    //   // },
    // },
    // {
    //   title: "Photo",
    //   dataIndex: "photo",
    //   // render: (item) => {
    //   //   return item ? ImageRequest(JSON.parse(item)) : null;
    //   // },
    // },
    {
      title: "Booked Type",
      dataIndex: "pgType",
      render: (type) => (
        
          type === 4 ? <p>Offline</p> : <p>Online</p>
      ),
    },
    {
      title: "PAN",
      dataIndex: "passengers",
      render: (passengers) => {
        if (passengers && passengers.length > 0 && passengers[0]?.pan) {
          return ImageRequest({ imageUrl: passengers[0]?.pan });
        } else {
          return null;
        }
      },
    },
    {
      title: "Passport",
      dataIndex: "passengers",
      render: (passengers) => {
        if (passengers && passengers.length > 0 && passengers[0]?.passport) {
          return ImageRequest({ imageUrl: passengers[0]?.passport });
        } else {
          return null;
        }
      },
    },
    {
      title: "Photo",
      dataIndex: "passengers",
      render: (passengers) => {
        if (passengers && passengers.length > 0 && passengers[0]?.photo) {
          return ImageRequest({ imageUrl: passengers[0]?.photo });
        } else {
          return null;
        }
      },
    },
    {
      title: "Action",
      dataIndex: "pgType",
      render: (type, record) =>
      type === 4 && record.BookingStatus !== 3 ? (
        <span
          style={{ cursor: "pointer" }}
          onClick={() => handleUpdateClick(record.ReferenceNumber)}
        >
          Update
        </span>
      ) : null,
    },
  ];
  let allColumns = [
    {
      title: "Reference number",
      dataIndex: "referenceNumber",
      render: (text, record) => renderReffrence(text, record),
      sorter: (a, b) => a.referenceNumber?.localeCompare(b.referenceNumber),
    },

    {
      title: "Review & Rating/Photos",
      render: (_, record) => getReviewButton(record),
    },
    {
      title: "Service Type",
      dataIndex: "type",
      key: "type",
      sorter: (a, b) => a.type?.localeCompare(b.type),
    },

    {
      title: "Source",
      dataIndex: "from",
      render: (text, record) => {
        if (record.type == "Flight") {
          return record.oneWaySegment[0].origin;
        } else if (record.type == "Bus") {
          return record.from;
        } else {
          return "";
        }
      },
    },
    {
      title: "Destination",
      dataIndex: "to",
      render: (text, record) => {
        if (record.type == "Flight") {
          let length = record.oneWaySegment.length;
          return record.oneWaySegment[length - 1].destination;
        } else if (record.type == "Bus") {
          return record.to;
        } else {
          return "";
        }
      },
    },
    {
      title: "Journey Date",
      // dataIndex: "journeyDate",
      key: "journeyDate",
      render: (text) =>
        text.type == "Flight"
          ? moment(text.journeyDate).format("DD-MM-YYYY")
          : text.journeyDate,
      sorter: (a, b) => a.journeyDate?.localeCompare(b.journeyDate),
    },
    {
      title: "Booking Date",
      // dataIndex: "journeyDate",
      key: "bookingDate",
      render: (text) =>
        text.bookingDate != undefined
          ? moment(text.bookingDate).format("DD-MM-YYYY")
          : "",
      sorter: (a, b) => a.bookingDate?.localeCompare(b.bookingDate),
    },
    {
      title: "Pax Name ",
      dataIndex: "guestName",
      sorter: (a, b) => a.guestName?.localeCompare(b.guestName),
    },
    {
      title: "Pax Email",
      dataIndex: "guestEmaiId",
      sorter: (a, b) => a.guestEmaiId?.localeCompare(b.guestEmaiId),
    },
    {
      title: "Pax Mobile ",
      dataIndex: "guestMobileNo",
      sorter: (a, b) => a.guestMobileNo?.localeCompare(b.guestMobileNo),
    },
    {
      title: "Arrival Date",
      dataIndex: "oneWaySegment",
      render: (text, record) =>
        record.type == "Flight"
          ? moment(text[0].arrivalDateTime, oriDateFormat).format(dateFormat)
          : "",
    },

    {
      title: "Departure Date",
      dataIndex: "oneWaySegment",
      render: (text, record) => {
        if (record.type == "Flight") {
          let length = text.length;
          return moment(
            text[length - 1].departureDateTime,
            oriDateFormat
          ).format(dateFormat);
        } else {
          return "";
        }
      },
    },
    {
      title: "CheckIn Date",
      dataIndex: "CheckInDate",
      render: (text, record) =>
        record.type == "Hotel"
          ? moment(record.CheckInDate).format("DD-MM-YYYY")
          : "",
      sorter: (a, b) => a.CheckInDate?.localeCompare(b.CheckInDate),
      // render: (CheckInDate) =>  moment(CheckInDate).format("DD-MM-YYYY"),
    },
    {
      title: "CheckOut Date",
      dataIndex: "CheckOutDate",
      render: (text, record) =>
        record.type == "Hotel"
          ? moment(record.CheckOutDate).format("DD-MM-YYYY")
          : "",
      sorter: (a, b) => a.CheckOutDate?.localeCompare(b.CheckOutDate),
    },
    {
      title: "Adults",
      // dataIndex: "pax",
      render: (text, record) => {
        if (record.type == "Flight") {
          return record.pax ? record.pax.adults : "";
        } else if (record.type == "Bus") {
          return "";
        } else if (record.type == "Hotel") {
          return record.pax ? record.pax.adults : "";
        } else if (record.type == "Activities") {
          return record.pax ? record.pax.adults : "";
        } else {
          return "";
        }
      },
    },
    {
      title: "Childs",
      //dataIndex: "pax",
      render: (text, record) => {
        if (record.type == "Flight") {
          return record.pax ? record.pax.childCount : "";
        } else if (record.type == "Bus") {
          return "";
        } else if (record.type == "Hotel") {
          return record.pax ? record.pax.childCount : "";
        } else if (record.type == "Activities") {
          return record.pax ? record.pax.childCount : "";
        } else {
          return "";
        }
      },
    },
    {
      title: "Infant",
      //dataIndex: "pax",
      render: (record) => {
        if (record.type == "Flight") {
          return record.pax ? record.pax.infantCount : "";
        } else if (record.type == "Bus") {
          return "";
        } else if (record.type == "Hotel") {
          return "";
        } else if (record.type == "Activities") {
          return record.pax ? record.pax.infantdCount : "";
        } else {
          return "";
        }
      },
    },
    {
      title: "Operator Name",
      dataIndex: "operator",
      key: "operatorName",
    },

    {
      title: "Pickup Location",
      dataIndex: "PickupLocation",
      key: "pickUpLocation",
    },
    {
      title: "Drop Location",
      dataIndex: "DropLocation",
      key: "dropLocation",
    },
    {
      title: "Booking Status",
      dataIndex: "BookingStatus",
      key: "status",
      render: (status) => getStatus(status),
      sorter: (a, b) => a.BookingStatus?.localeCompare(b.BookingStatus),
    },
  ];
  let hotelColumns = [
    {
      title: "ReferenceNumber",
      dataIndex: "referenceNumber",
      render: (text, record) => renderReffrence(text, record),
      sorter: (a, b) => a.referenceNumber?.localeCompare(b.referenceNumber),
    },
    {
      title: "Review & Rating/Photos",
      render: (_, record) => getReviewButton(record),
    },
    {
      title: "Guest Name",
      dataIndex: "guestName",
      sorter: (a, b) => a.guestName?.localeCompare(b.guestName),
    },
    { title: "Hotel Name", dataIndex: "HotelName" },
    { title: "City", dataIndex: "City" },
    { title: "NoOfRooms", dataIndex: "NoOfRooms" },
    {
      title: "Booking Date",
      // dataIndex: "journeyDate",
      key: "bookingDate",
      render: (text) =>
        text.bookingDate != undefined
          ? moment(text.bookingDate).format("DD-MM-YYYY")
          : "",
      sorter: (a, b) => a.bookingDate?.localeCompare(b.bookingDate),
    },
    {
      title: "CheckIn Date",
      dataIndex: "CheckInDate",
      render: (CheckInDate) => moment(CheckInDate).format("DD-MM-YYYY"),
      sorter: (a, b) => a.CheckInDate?.localeCompare(b.CheckInDate),
    },
    {
      title: "CheckOut Date",
      dataIndex: "CheckOutDate",
      render: (CheckOutDate) => moment(CheckOutDate).format("DD-MM-YYYY"),
      sorter: (a, b) => a.CheckOutDate?.localeCompare(b.CheckOutDate),
    },
    {
      title: "Booking Status",
      dataIndex: "BookingStatus",
      // render: (record) => hotelBookingStatus(record.BookingStatus),
      render: (status) => getStatus(status),
      sorter: (a, b) => a.BookingStatus?.localeCompare(b.BookingStatus),
    },
  ];
  let busColumns = [
    {
      title: "Reference No.",
      dataIndex: "referenceNumber",
      render: (text, record) => renderReffrence(text, record),
      sorter: (a, b) => a.referenceNumber?.localeCompare(b.referenceNumber),
    },
    {
      title: "Review & Rating/Photos",
      render: (_, record) => getReviewButton(record),
    },
    {
      title: "Booking Date",
      // dataIndex: "journeyDate",
      key: "bookingDate",
      render: (text) =>
        text.bookingDate != undefined
          ? moment(text.bookingDate).format("DD-MM-YYYY")
          : "",
      sorter: (a, b) => a.bookingDate?.localeCompare(b.bookingDate),
    },
    {
      title: "Journey Date",
      dataIndex: "journeyDate",
      sorter: (a, b) => a.journeyDate?.localeCompare(b.journeyDate),
      // key: "journeyDate",
    },
    { title: "Source", dataIndex: "from" },
    { title: "Destination", dataIndex: "to" },
    { title: "Operator", dataIndex: "operator" },
    // { title: "PickupLocation", dataIndex: "PickupLocation" },
    // { title: "DropLocation", dataIndex: "DropLocation" },
    {
      title: "Pax Name ",
      dataIndex: "guestName",
      sorter: (a, b) => a.guestName - b.guestName,
    },
    {
      title: "Email",
      dataIndex: "guestEmaiId",
      sorter: (a, b) => a.guestEmaiId?.localeCompare(b.guestEmaiId),
    },
    {
      title: "Mobile",
      dataIndex: "guestMobileNo",
      sorter: (a, b) => a.guestMobileNo?.localeCompare(b.guestMobileNo),
    },
    {
      title: "Booking Status",
      dataIndex: "BookingStatus",
      render: (status) => getStatus(status),
      sorter: (a, b) => a.BookingStatus?.localeCompare(b.BookingStatus),
    },
  ];
  const Carcolumns = [
    // {
    //   title: "Name",
    //   dataIndex: "name",
    // },

    // {
    //   title: "Reference No",
    //   dataIndex: "referenceno",
    // },

    {
      title: "Refenrece No.",
      dataIndex: "BookingRefNo",
      render: (text) => {
        return <Link to={`/cars/ticket?ref=${text}`}>{text}</Link>;
      },
    },

    // {
    //   title: "User ID",
    //   dataIndex: "userid",
    // },

    {
      title: "Passenger Name",
      dataIndex: "GuestName",
    },

    {
      title: "Email Address",
      dataIndex: "EmailId",
    },

    {
      title: "Mobile Number",
      dataIndex: "MobileNo",
    },
    {
      title: "Source",
      dataIndex: "Source",
    },
    // {
    //   title: "PickUp Address",
    //   dataIndex: "PickUpAddress",
    // },
    {
      title: "Destination",
      dataIndex: "Destination",
    },
    {
     title:"Travel Type",
     render: (item) => {
      const status = TravelType(item.TravelType);
      return  (
        <span>{status}</span>
      );
    },
    },
    {
      title:"Trip Type",
      render: (item) => {
        const status = TripType(item.TripType);
        return (
          <span>{status}</span>
        );
      },
     },

    // {
    //   title: "Drop Address",
    //   dataIndex: "DropAddress",
    // },

    // {
    //   title: "Promo Code",
    //   dataIndex: "PromoCode",
    // },

    // {
    //   title: "Advance Paid ",
    //   dataIndex: "CollectedFare",
    // },

    // {
    //   title: "Full Amount Paid",
    //   dataIndex: "totalPrice",
    // },
    // {
    //   title: "Driver",
    //   dataIndex: "driver",
    //   render: (item) => `${item.driver.Name}`
    //   // render: (text, index) => {
    //   //   return text.Name;
    //   // },
    // },

    {
      title: "Vendor",
      dataIndex: "Vendor",
      render: (text, rec) => {
        if (text) {
          return text.CompanyName;
        }
        return "";
      },
    },
    // {
    //   title: "Booking Status",
    //   dataIndex: "BookingStatus",
    //   render: (text) => {
    //     return getCarTicketStatus(text);
    //   },
    // },

    // {
    //   title: "Created Date",
    //   dataIndex: "credate",
    // },
    // {
    //   title: "LastModified Date",
    //   dataIndex: "lmdate",
    // },

    /*   {
            title: 'Status',
            dataIndex: 'no',
            key: 'airline',
            render: (text, order) =>
            <ANTD.Switch checkedChildren="Active" unCheckedChildren="Inactive" defaultChecked />
        }, */
    // {
    //   title: "Actions",
    //   render: (rec) => {
    //     return tableActions(rec);
    //   },
    // },
    /*  {
            title: 'Delete',
            dataIndex: 'delete'

        } */
  ];
  const CarTransferColumns = [
    {
      title: "Booked By",
      dataIndex: "BookedBy",
    },
    {
      title: "RefNo",
      dataIndex: "referenceNumber",
      render: (text, record) => renderReffrence(text, record),
      sorter: (a, b) => a.referenceNumber?.localeCompare(b.referenceNumber),
    },

    {
      title: "Jrny Date",
      dataIndex: "journeyDate",
    },
    {
      title: "Src",
      dataIndex: "from",
      key: "from",
    },
    {
      title: "Dest",
      dataIndex: "to",
      key: "from",
    },
    {
      title: "Pax Name ",
      dataIndex: "guestName",
      sorter: (a, b) => a.guestName - b.guestName,
    },
    {
      title: "Amt(Rs.)",
      dataIndex: "totalPrice",
    },
    {
      title: "Pax Email",
      dataIndex: "guestEmaiId",
      sorter: (a, b) => a.guestEmaiId.localeCompare(b.guestEmaiId),
    },
    {
      title: "Pax Mobile ",
      dataIndex: "guestMobileNo",
      sorter: (a, b) => a.guestMobileNo.localeCompare(b.guestMobileNo),
    },
    {
      title: "Bkng Status",
      dataIndex: "BookingStatus",
      render: (BookingStatus) => getStatus(BookingStatus),
      sorter: (a, b) => a.BookingStatus - b.BookingStatus,
    },
  ];
  const holidayColumns = [
    {
      title: "SNo",
      dataIndex: "SNo",
    },
    {
      title: "Tours Packages",
      dataIndex: "HolidayName",
    },
    {
      title: "Reference No",
      dataIndex: "ReferenceNo",
      render: (ReferenceNo) => (
        <Link to={`holiday/ticket?ref=${ReferenceNo}`}>
          <p className="mb-0">{ReferenceNo}</p>
        </Link>
      ),
    },
    {
      title: "Passenger Name",
      dataIndex: "Name",
    },
    {
      title: "Mobile Number",
      dataIndex: "PhoneNo",
    },
    {
      title: "Email Address",
      dataIndex: "Email",
    },

    {
      title: "Total Price",
      dataIndex: "TotalPrice",
    },
    {
      title: "Booked Type",
      dataIndex: "pgType",
      render: (type) => (type === 2 ? <p>Offline</p> : <p>Online</p>),
    },
  ];

  //  Reference number	Guest Name  Activity Name	 Adult  Child  Infant  Booking Date 	Travel Date	Booking Status
  let activitiesColumns = [
    {
      title: "Reference number",
      dataIndex: "referenceNumber",
      render: (text, record) => renderReffrence(text, record),
      sorter: (a, b) => a.referenceNumber?.localeCompare(b.referenceNumber),
    },
    {
      title: "Review & Rating/Photos",
      render: (_, record) => getReviewButton(record),
    },
    {
      title: "Booking Date",
      // dataIndex: "journeyDate",
      key: "bookingDate",
      render: (text) =>
        text.bookingDate != undefined
          ? moment(text.bookingDate).format("DD-MM-YYYY")
          : "",
      sorter: (a, b) => a.bookingDate?.localeCompare(b.bookingDate),
    },

    {
      title: "journey Date",
      dataIndex: "journeyDate",
      render: (journeyDate) => moment(journeyDate).format("DD-MM-YYYY"),
      sorter: (a, b) => a.journeyDate?.localeCompare(b.journeyDate),
    },
    {
      title: "Pax Name ",
      dataIndex: "guestName",
      sorter: (a, b) => a.guestName?.localeCompare(b.guestName),
    },
    {
      title: "Pax Email",
      dataIndex: "guestEmaiId",
      sorter: (a, b) => a.guestEmaiId?.localeCompare(b.guestEmaiId),
    },
    {
      title: "Pax Mobile ",
      dataIndex: "guestMobileNo",
      sorter: (a, b) => a.guestMobileNo?.localeCompare(b.guestMobileNo),
    },
    {
      title: "Adults",
      dataIndex: "pax",
      render: (text) => text?.adults ?? "",
    },
    {
      title: "Childs",
      dataIndex: "pax",
      render: (text) => text?.childCount ?? "",
    },
    {
      title: "Infant",
      dataIndex: "pax",
      render: (text) => text?.infantCount ?? "",
    },
    {
      title: "Booking Status",
      dataIndex: "BookingStatus",
      key: "status",
      render: (status) => getStatus(status),
      sorter: (a, b) => a.BookingStatus?.localeCompare(b.BookingStatus),
    },
    // {
    //   title: "Review & Rating/Photos",
    //   dataIndex: "totalPrice",
    //   render: (price) => (
    //     <Button title="Add Review" onClick={() => handleReviewModal(price)} />
    //   ),
    // },
  ];

  const handleUpdateClick= (referenceNumber) => {
    ApiClient.put(`visa/visaOfflinebooking/${referenceNumber}`)
      .then((res) => {
        if (res.status === 200) {
          
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log(error)
      });
    
  };

  const ImageRequest = ({ imageUrl }) => {
    if (!imageUrl) {
      return null; 
    }
    return (
      <div className="text-center">
        {/* {console.log(imageUrl, "urlonly")} */}
        <span
          style={{ cursor: "pointer", color: "green" }}
          onClick={(e) => {
            setImageRequest(imageUrl);
            setImageRequestModal(true);
          }}
        >
          <EyeOutlined />
        </span>
      </div>
    );
  };
  ///////fromdate
  const onChangeDate = (currentDate) => {
    const value = moment(currentDate).format("DD-MM-YYYY");
    setFromdateto(value);
  };
  // const onChangeFromDate = (current, dateString) => {
  //   // Can not select days before today and toda
  //   setArrivalDate(dateString);
  // };
  // const onChangeToDate = (current, dateString) => {
  //   // Can not select days before today and today
  //   setWeekendDate(dateString);
  // };
  const disabledDates = (currentDate) => {
    return currentDate < moment().startOf("day");
  };

  const disabledOriginDate = (currentDate) => {
    return currentDate < moment(fromdateto).startOf("day");
  };
  // const onChangetoDate = (currentDate) => {
  //   const value = moment(currentDate).format("DD-MM-YYYY");
  //   setTodateto(value);
  // };

  const disabledtoDate = (currentDate) => {
    return currentDate < moment(fromdateto).startOf("day");
  };

  const [col, setCol] = useState();
  /* Creating service dynamic column headers */
  const setTableHeaders = (travelType) => {
    switch (travelType) {
      case 1:
        setColumns(flightColumns);
        setCol(1);
        break;
      case 2:
        setColumns(hotelColumns);
        setCol(2);
        break;
      case 3:
        setColumns(Visacolumns);
        setCol(3);
        break;
      case 4:
        setColumns(activitiesColumns);
        setCol(4);
        break;
      case 5:
        setColumns(CarTransferColumns);
        setCol(5);
        break;
      case 6:
        setColumns(Carcolumns);
        setCol(6);
        break;
      default:
        setColumns(allColumns);
        setCol(7);
    }
  };

  const reset = () => {
    form.resetFields();
    setbookedType(null);
  };

  const handleTimeAndDate = (value) => {
    if (value === 5) {
      setIsDateDisplay(true);
    } else {
      setIsDateDisplay(false);
    }
  };

  /* Switching and updating service */

  const onFormSubmit = (values) => {
    setShowTable(true);

    setColumns([]);

    setTableHeaders(serviceType);

    values.fromDate = values.fromDate
      ? moment(values.fromDate).format("YYYY-MM-DD")
      : "";
    values.toDate = values.toDate
      ? moment(values.toDate).format("YYYY-MM-DD")
      : "";

    values.userId = user?.UserID;
    values.travelType = serviceType;
    if (serviceType == 3) {
      getOrdersRequest(values);
    } else if (serviceType == 6) {
      getCarsRequest(values);
    } else {
      getBookingListData(values);
    }
    // { console.log(serviceType, "service") }
  };
  const getCarsRequest = (values) => {
    setLoading(true);

    // Carcolumns([]);
    // setDataSource([]);
    ApiClient.get("carextranet/carBookingReportsByFilter", values)
      .then((res) => {
        setLoading(false);
        setShowTable(true);
        // { console.log(res, user?.Role?.RoleId, "fami123") }
        if (res.status == 200) {
          setFromdateto();
          setTodateto();
          if (user?.Role?.RoleId == 5) {
            let filterdata = res?.data.filter(
              (item) => item.BookingStatus != "BLOCKED"
            );
            setcarList(filterdata);
            // setDataSource(filterdata);
          } else {
            setcarList(res?.data);
            // setDataSource(res?.data);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const getBookingListData = (values) => {
    setLoading(true);
    setReports([]);
    setDataSource([]);
    ApiClient.get("admin/myBookings", values)
      .then((res) => {
        setLoading(false);
        if (res?.statusCode === 200) {
          setFromdateto();
          setTodateto();

           //{ console.log(res, user?.Role?.RoleId, "fami") }
          if (user?.Role?.RoleId == 5) {
            let filterdata = res?.data.filter(
              (item) => item.BookingStatus === "BLOCKED" || "HOLD"
            );
            setReports(filterdata);
            setDataSource(filterdata);
          } else {
            setReports(res?.data);
            setDataSource(res?.data);
            // console.log((res?.data),"hhh")
          }
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const BookedType = (value) => {
    setbookedType(value);
  };
  const getOrdersRequest = (values) => {
    values.fromDate = values.fromDate
      ? moment(values.fromDate).format("DD-MM-YYYY")
      : "";
    values.toDate = values.toDate
      ? moment(values.toDate).format("DD-MM-YYYY")
      : "";
    values.options = values.options == 5 ? 4 : values.options;
    values.UserId = values.userId;
    values.BookingStatus = values.bookingStatus;

    //console.log("values", values)
    ApiClient.get("visa/getVisaBookignReportsByFilter", values)
      .then((res) => {
        // console.log("res", res)
        setShowTable(true);
        if (res.status == 200) {
          let data = res.data.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
              DepartureDate: moment(item.CreatedDate).format("DD-MM-YYYY"),
              ModifiedDate: moment(item.ModifiedDate).format("DD-MM-YYYY"),
            };
          });
          if (bookedType != null) {
            data = data.filter((e) => e.pgType == bookedType);
          }

          // console.log(data, "data")
          setOrdersList(data);
          // defineExcelData(data);
        } else {
          setOrdersList([]);
        }
      })
      .catch((e) => {
        setOrdersList([]);
      });
  };

  const handleExcel = () => {
    // const excelData = dataSource;
    const excelData = dataSource.map((item, index) => {
      if (col == 5) {
        var arival = "";
        if (item.type == "Flight") {
          arival = item.oneWaySegment[0];
        } else {
          arival = "null";
        }
        return {
          S_No: index + 1,
          ReferenceNumber: item.referenceNumber ?? "",
          Type: item.type ?? "",
          Source: arival != "null" ? item.oneWaySegment[0]?.origin : item.from,
          Destination:
            arival != "null" ? item.oneWaySegment[0].destination : item.to,
          JourneyDate: item.journeyDate ?? "",
          GuestName: item.guestName ?? "",
          GuestEmail: item.guestEmaiId ?? "",
          GuestMobileNo: item.guestMobileNo ?? "",
          Arrival: arival != "null" ? moment(arival).format("DD-MM-YYYY") : "",
          Departure:
            arival != "null"
              ? moment(item.oneWaySegment[0].departureDateTime).format(
                  "DD-MM-YYYY"
                )
              : "",
          CheckInDate: item.CheckInDate ?? "",
          CheckOutDate: item.CheckOutDate ?? "",
          Adults: item?.pax?.adults ?? "",
          Childs: item?.pax?.childCount ?? "",
          Infant: item?.pax?.infantCount ?? "",
          OperatorName: item.operator ?? "",
          PickupLocation: item.PickupLocation ?? "",
          DropLocation: item.DropLocation ?? "",
          BookingStatus: item.BookingStatus ?? "",
        };
      }
      if (col == 1) {
        return {
          S_No: index + 1,
          ReferenceNumber: item.referenceNumber ?? "",
          Type: item.type ?? "",
          Source: item?.oneWaySegment[0]?.origin ?? "",
          Destination: item.oneWaySegment[0].destination ?? "",
          JourneyDate: item.journeyDate ?? "",
          GuestName: item.guestName ?? "",
          GuestEmail: item.guestEmaiId ?? "",
          GuestMobileNo: item.guestMobileNo ?? "",
          Arrival: moment(item.oneWaySegment[0].arrivalDateTime).format(
            "DD-MM-YYYY"
          ),
          Departure: moment(item.oneWaySegment[0].departureDateTime).format(
            "DD-MM-YYYY"
          ),
          Adults: item.pax.adults ?? "",
          Childs: item.pax.childCount ?? "",
          Infant: item.pax.infantCount ?? "",
          BookingStatus: item.BookingStatus ?? "",
        };
      } else {
        return {
          S_No: index + 1,
          ...item,
        };
      }
    });
    props.exportExcel(excelData, "Booking_Reports");
  };

  if (agent) {
    flightColumns = flightColumns.filter(
      (item) => item.title !== "Review & Rating/Photos"
    );
    hotelColumns = hotelColumns.filter(
      (item) => item.title !== "Review & Rating/Photos"
    );
    // busColumns = busColumns.filter(
    //   (item) => item.title !== "Review & Rating/Photos"
    // );
    activitiesColumns = activitiesColumns.filter(
      (item) => item.title !== "Review & Rating/Photos"
    );
    allColumns = allColumns.filter(
      (item) => item.title !== "Review & Rating/Photos"
    );
  }
  const disabledFutureDate = (currentDate) => {
    return currentDate >= moment();
  };
  // const onChangeFromDate = (momentdate, _) => {
  //   setToDisableDate(momentdate);
  // };
  const disabledSelectedDate = (currentDate) => {
    return currentDate < moment(toDisableDate).startOf("day");
  };
  const onChangeFromDate = (current, dateString) => {
    // Can not select days before today and toda
    setArrivalDate(dateString);
  };
  const onChangeToDate = (current, dateString) => {
    // Can not select days before today and today
    setWeekendDate(dateString);
  };
  const disabledFromDate = (current) => {
    if (weekendDate != null) {
      return current.valueOf() > new Date(weekendDate);
    }
    // return current && current > moment().endOf('day');

    return current.valueOf() > Date.now();
  };
  const disabledToDate = (current) => {
    // Can not select days before today and today
    return (
      current.valueOf() > Date.now() ||
      current.valueOf() < new Date(arrivalDate)
    );
  };

  const getServiceType = (type) => {
    switch (type) {
      case "Flight":
        return 1;
      case "Visa":
        return 3;
      case "Hotel":
        return 2;
      case "Activity":
        return 4;
      case "Transfer":
        return 5;
      case "Cars":
        return 6;
      default:
        return "";
    }
  };
  const type = getServiceType(params.type);
  // console.log(type, "servtype");
  useEffect(() => {
    if (type > 0) setServiceType(type);
  }, [type]);
  useEffect(() => {
    setDataSource([]);
    form.resetFields();
    setIsDateDisplay(false);
  }, [window.location.search]);

  const downloadImage = () => {
    let url = `${BASE}${imageRequest}`

    const fileName = imageRequest.split('/')[imageRequest.split('/').length - 1]
    saveAs(url, fileName);
    
  }
//  console.log(dataSource,"datasource")
  return (
    <>
    <Nav1/>
    <div className="manage-markup-section ourteam-bg">
      <div className="container-fluid cms-pages-width">
        <div className="card-bt-gap">
          <div className="card-add-heading">
            <div className="add-icon d-none">
              <i className="fa fa-plus"></i>
            </div>
          </div>
          <div>
            <Form
              layout={"vertical"}
              form={form}
              initialValues={{
                options: 1,
                bookingStatus: "",
                referenceNo: "",
                travelType: 0,
              }}
              onFinish={onFormSubmit}
            >
              <Card className="retrieve-booking card-style">
                <h5 className="view-pkg-v1">{params.type} Reports</h5>

                <Row gutter={16}>
                  <Col md={6} xs={24}>
                    <Form.Item
                      label="Time and Date"
                      name="options"
                      rules={[{ required: true }]}
                    >
                      <Select
                        placeholder="Please select"
                        onChange={handleTimeAndDate}
                      >
                        <Option value={1}>Last One Hour</Option>
                        <Option value={2}>Yesterday</Option>
                        <Option value={3}>Last Week</Option>

                        <Option value={5}>Custom Dates</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  {isDateDisplay ? (
                    <>
                      <Col md={6} sm={12} xs={24}>
                        <Form.Item label="From Date" name="fromDate">
                          <DatePicker
                            format="DD-MM-YYYY"
                            style={{ width: "100%" }}
                            // disabledDate={disabledOriginDate}
                            // onChange={(date) => setFromdateto(date)}

                            disabledDate={disabledFromDate}
                            onChange={(date, dateString) =>
                              onChangeFromDate(date, dateString)
                            }
                          />
                        </Form.Item>
                      </Col>

                      <Col md={6} sm={12} xs={24}>
                        <Form.Item label="To Date" name="toDate">
                          <DatePicker
                            format="DD-MM-YYYY"
                            style={{ width: "100%" }}
                            // disabledDate={disabledtoDate}
                            disabledDate={disabledToDate}
                            onChange={(date, dateString) =>
                              onChangeToDate(date, dateString)
                            } //placeholder="Select To date" format={dateFormat}
                          />
                        </Form.Item>
                      </Col>
                    </>
                  ) : null}
                  {/* <Col className="gutter-row" md={6} xs={24}>
                    <Form.Item label="Service" name="travelType">
                      <Select placeholder="Select">
                        <Option value={0}>All</Option>
                        <Option value={1}>Flights</Option>
                        <Option value={2}>Hotels</Option>
                        <Option value={3}>Bus</Option>
                        <Option value={4}>Activities</Option>
                        <Option value={5}>Car</Option>
                      </Select>
                    </Form.Item>
                  </Col> */}

                  <Col md={6} xs={24}>
                    <Form.Item label="Reference No." name="referenceNo">
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col md={6} xs={24}>
                    <Form.Item label="Booking Status" name="bookingStatus">
                      <Select placeholder="Please Select">
                        <Option value={2}>Blocked</Option>
                        <Option value={3}>Confirmed</Option>
                        <Option value={4}>Cancelled</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  {serviceType === 6 ? (
                    ""
                  ) : (
                    <Col className="gutter-row" md={6} xs={24}>
                      <Form.Item label="Booking Type" name="BookingType">
                        <Select
                          placeholder="Please select"
                          onChange={BookedType}
                        >
                          <Option value={3}>Online</Option>
                          <Option value={2}>Offline</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  )}
                </Row>

                <Row className="reports-btns reset-button-fst reports-btns">
                  <Button type="primary" htmlType="submit">
                    Search
                  </Button>
                  <Button type="danger" onClick={reset}>
                    Reset
                  </Button>
                </Row>
              </Card>
            </Form>
          </div>
        </div>

        {(serviceType == 1 || serviceType == 2 || serviceType == 5) &&
          showTable && (
            // { showTable && (
            <div>
              <Card bordered={false} className="card-style">
                <div className="action-images">
                  {showSearchBox && (
                    <Input
                      placeholder="Search"
                      onChange={(e) => searchTableData(e)}
                      suffix={closeSearchInput}
                      style={{ padding: "0px 12px", width: "300px" }}
                    />
                  )}
                  &nbsp;&nbsp;
                  <img
                    src={search}
                    alt="search"
                    onClick={(e) => searchData()}
                  />
                  <img src={excel} alt="excel" onClick={handleExcel} />
                </div>
                {/* {console.log(dataSource, "dat")} */}
                {dataSource.length > 0 ? (
                  <Table
                    scroll={{ x: true }}
                    bordered
                    dataSource={dataSource}
                    columns={columns}
                    pagination={{
                      defaultPageSize: 10,
                      showSizeChanger: true,
                      pageSizeOptions: ["10", "25", "50", "100", "125"],
                    }}
                  />
                ) : loading ? (
                  <Spin
                    size="large"
                    tip="Loading..."
                    style={{ width: "100%" }}
                  />
                ) : (
                  <h5>No Data Found</h5>
                )}
              </Card>
            </div>
          )}
        {serviceType == 6 && showTable && (
          // { showTable && (
          <div>
            <Card bordered={false} className="card-style">
              <div className="action-images">
                {showSearchBox && (
                  <Input
                    placeholder="Search"
                    onChange={(e) => searchTableData(e)}
                    suffix={closeSearchInput}
                    style={{ padding: "0px 12px", width: "300px" }}
                  />
                )}
                &nbsp;&nbsp;
                <img src={search} alt="search" onClick={(e) => searchData()} />
                <img src={excel} alt="excel" onClick={handleExcel} />
              </div>

              {carList.length > 0 ? (
                <Table
                  scroll={{ x: true }}
                  bordered
                  dataSource={carList}
                  columns={Carcolumns}
                  pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ["10", "25", "50", "100", "125"],
                  }}
                />
              ) : loading ? (
                <Spin size="large" tip="Loading..." style={{ width: "100%" }} />
              ) : (
                <h5>No Data Found</h5>
              )}
            </Card>
          </div>
        )}
        {serviceType == 3 && showTable && (
          <Col span={24}>
            <div>
              <Card bordered={false} className="card-style">
                <div className="action-images">
                  {showSearchBox && (
                    <Input
                      placeholder="Search"
                      onChange={(e) => searchTableData(e)}
                      suffix={closeSearchInput}
                      style={{ padding: "0px 12px", width: "300px" }}
                    />
                  )}
                  &nbsp;&nbsp;
                  <img
                    src={search}
                    alt="search"
                    onClick={(e) => searchData()}
                  />
                  <img src={excel} alt="excel" onClick={handleExcel} />
                </div>
                {/* {console.log(ordersList, "ordersList1")} */}
                {ordersList.length > 0 ? (
                  <div>
                    <Table
                      className="table-scroll-none"
                      bordered
                      dataSource={ordersList}
                      columns={Visacolumns}
                      pagination={{
                        defaultPageSize: 25,
                        showSizeChanger: true,
                        pageSizeOptions: ["25", "50", "100", "125"],
                      }}
                    />
                  </div>
                ) : loading ? (
                  <Spin
                    size="large"
                    tip="Loading..."
                    style={{ width: "100%" }}
                  />
                ) : (
                  <b>No record Found</b>
                )}
              </Card>
            </div>
          </Col>
        )}
      </div>
      <Modal
        visible={imageRequestModal}
        title="Photos"
        onCancel={(e) => {
          setImageRequestModal(false);
          setImageRequest([]);
        }}
        // footer={false}
        footer={[
          <button key="download" onClick={downloadImage}>
            <DownloadOutlined /> Download
          </button>,
        ]}
        className="modal-header-bg12"
      >
        <Row gutter={12}>
          {/* {console.log(BASE + imageRequest, "imgurl")} */}
          <img width={'100%'} src={BASE + imageRequest} alt="Image" />
          {/* {imageRequest?.map((image, index) => (
            <Col md={6} sm={8} xs={12} key={index}>
              <Image src={BASE + image.substring(1)} />
            </Col>
          ))} */}
        </Row>
      </Modal>
      <Modal
        title="Review & Rating/Photos"
        className="promo-modal-header"
        visible={reviewModal.modalVisible}
        onOk={toggleReviewModal}
        onCancel={toggleReviewModal}
        footer={[
          <div>
            <Button key="close" onClick={toggleReviewModal}>
              Cancel
            </Button>

            <Button
              key="add"
              type="primary"
              htmlType="submit"
              onClick={reviewForm.submit}
            >
              Submit
            </Button>
          </div>,
        ]}
      >
        <Form
          form={reviewForm}
          layout="vertical"
          onFinish={(val) => submitReviewForm(val)}
          initialValues={{
            Comments: "",
            imagePath: [],
          }}
        >
          <Form.Item label="Star Rating">
            <Rate
              value={reviewModal.StartRating}
              onChange={(value) =>
                setReviewModal((prev) => ({ ...prev, StartRating: value }))
              }
            />
          </Form.Item>

          <Form.Item label="Comment" name="Comments">
            <TextArea rows={2} />
          </Form.Item>

          <ImageUpload
            category="reviewratingphotos"
            fileList={reviewImages}
            setFileList={setReviewImages}
            formName="ImagesPath"
            label="Upload Images"
            limit={true}
            limitSize={10}
            // deleteBanner={DeleteBannerImage}
          />
          <Row gutter={[8, 8]}>
            <Col md={12} sm={12} xs={24}>
              <Form.Item
                label="Next Travel Destination"
                name="nextTravelDestination"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={12} sm={12} xs={24}>
              <Form.Item label="Next Travel Date" name="nextTravelDate">
                <DatePicker
                  format="DD-MM-YYYY"
                  style={{ width: "100%" }}
                  disabledDate={disabledDates}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
    </>
  );
};

export default UpdatedComponent(Bookingreports);

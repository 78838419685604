// import React from "react";
// import "../HotelDetailsBox/HotelDetailsBox.scss";
// import hotelNoImg from "../../assets/images/hotels/no_img.png";
// import { Card, Col, Rate, Row } from "antd";
// import moment from "moment";
// import queryString from "query-string";
// import { useHistory } from "react-router-dom";
// import { useCurrencyContext } from "../../common/providers/CurrencyProvider";
// import nosmoke from "../../assets/images/Icons/icons8-no-smoking-48.png";
// import smoke from "../../assets/images/Icons/icons8-smoking-64.png"

// const HotelDetailsBox = ({ Ids, hotelDetailsObj, hotelSearchData }) => {
//   let history = useHistory();
//   // let checkInDateAndTime =
//   //   moment(hotelSearchData.checkInDate).format("lll").split(" ") ?? [];
//   // let checkOutDateAndTime =
//   //   moment(hotelSearchData.checkOutDate).format("lll").split(" ") ?? [];
//   const { activeCurrency, currencyValue } = useCurrencyContext();

//   let noOfNights = () => {
//     if (Object.keys(hotelSearchData).length > 0) {
//       let checkin = new Date(hotelSearchData.checkInDate);
//       let checkout = new Date(hotelSearchData.checkOutDate);
//       let diffTime = checkout - checkin;
//       let diffDays = Math.ceil(diffTime / (1000 * 24 * 60 * 60));
//       return diffDays;
//     } else {
//       return 0;
//     }
//   };

//   const getAdultChildCount = () => {
//     let adults = 0;
//     let childs = 0;

//     if (Object.keys(hotelSearchData).length > 0) {
//       if (hotelSearchData.roomGuests.length > 0) {
//         for (
//           let index = 0;
//           index < hotelSearchData.roomGuests.length;
//           index++
//         ) {
//           adults += Number(hotelSearchData.roomGuests[index].noOfAdults);
//           childs += Number(hotelSearchData.roomGuests[index].noOfChilds);
//         }
//       }
//     }
//     if (childs > 0) {
//       return `${adults} Adult &  ${childs} Children`;
//     } else return `${adults} Adult`;
//   };
//   // const RoomsInclusion = (i, idx) => {
//   //   return (
//   //     <>
//   //       {idx === 1 ? (
//   //         <p className="mb-0 mr-1" style={{ whiteSpace: "nowrap" }}>
//   //           <strong>Inclusions :</strong>
//   //         </p>
//   //       ) : null}

//   //       <p className="mb-0 mr-1 inclusions-img-wor-css">
//   //         {/* <i
//   //       className="fa fa-check color-blue"
//   //       aria-hidden="true"
//   //     ></i>{" "} */}
//   //         {(i.toLowerCase().includes('king bed') || i.toLowerCase().includes('king-bed') || i.toLowerCase().includes('double')) ?
//   //           <>
//   //             <img src={Kingbed} alt="no-photo" />
//   //             <span>  {i}  </span>
//   //           </> : ""}

//   //         {(i.toLowerCase().includes('twin bed') || i.toLowerCase().includes('twin-bed') || i.toLowerCase().includes('queen')) ?
//   //           <>

//   //             <img src={twinbeds} alt="no-photo" />
//   //             <span>  {i}  </span>
//   //           </> : ""}

//   //         {(i.toLowerCase().includes('wifi') || i.toLowerCase().includes('wi-fi')) ?
//   //           <>

//   //             <img src={wifi} alt="no-photo" />
//   //             <span>  {i}  </span>
//   //           </> : ""}

//   //         {(i.toLowerCase().includes('break')) ?
//   //           <>

//   //             <img src={breakfast} alt="no-photo" />
//   //             <span>  {i}  </span>
//   //           </> : ""}

//   //         {i.toLowerCase().includes('view') ?
//   //           <>

//   //             <img src={sea} alt="no-photo" />
//   //             <span>  {i}  </span>
//   //           </> : ""}

//   //       </p>
//   //     </>
//   //   )
//   // }

//   return (
//     <>
//       <Row className="hot-detail">
//         <Col md={8} sm={24} xs={24}>
//           <div className="image-details one-img-hotel">
//             {/* {console.log(hotelDetailsObj, "hotels")} */}
//             {hotelDetailsObj?.images?.length > 1 ? (
//               <img
//                 src={hotelDetailsObj?.images[1]}
//                 alt={hotelDetailsObj.hotelName}
//               />
//             ) : (hotelDetailsObj?.images?.length > 0 ? (
//               <img
//                 src={hotelDetailsObj?.images[0]}
//                 alt={hotelDetailsObj.hotelName}
//               />
//             ) : (
//               <img src={hotelNoImg} alt="no-photo" />
//             ))
//             }

//             {/* <div className="tag-cp-img">
//               <p>You Save Rs.2,640 </p>
//             </div> */}
//           </div>
//         </Col>

//         <Col md={16} sm={24} xs={24}>
//           <div className="disp-table-cell-div">
//             <div className="hotel-plan-div">
//               <h4 className="hot-name">{hotelDetailsObj.hotelName}</h4>
//               {hotelDetailsObj.starRating && (
//                 <div className="hotel-star">
//                   <Rate
//                     className="starRating"
//                     disabled
//                     value={Number(hotelDetailsObj.starRating)}
//                     allowHalf={true}
//                   />
//                 </div>
//               )}
//             </div>
//             <p className="post-div-para">
//               {hotelDetailsObj?.addresses?.[0]?.address}
//             </p>
//           </div>
//           <div className="rm-st">
//             <div className="rrom-type">
//               <span className="rom">Room</span> {" : "}
//               <span className="room-name">
//                 {hotelDetailsObj?.rooms?.[0]?.roomName?.split(',')?.[0]}
//               </span>
//             </div>
//             <div className="rrom-type">
//               <span className="rom">Stay</span> {" : "}
//               <span className="room-name">
//                 <i class="fa fa-moon-o" aria-hidden="true"></i>{" "}{noOfNights()} {noOfNights() > 1 ? "Nights" : "Night"}
//               </span>
//             </div>
//           </div>
//           <Row gutter={[2, 12]} className="cal-top-date-pic">
//             <Col md={12} sm={12} xs={12}>
//               <div className="cal-block-div">
//                 <div className="cb-top-div">
//                   <span className="checkin-text-div">Check-In</span>
//                   <span className="bold bkg-date-div">
//                     {/* {checkInDateAndTime[1]?.replace(",", "")} */}
//                     {moment(hotelSearchData.checkInDate).format("DD, MMM YYYY")}
//                   </span>
//                 </div>
//                 {/* <div className="cb-footer-div">
//                     {checkInDateAndTime[0]} &nbsp;|&nbsp;{checkInDateAndTime[3]}{" "}
//                     {checkInDateAndTime[4]}
//                   </div> */}
//               </div>
//             </Col>
//             <Col md={12} sm={12} xs={12}>
//               <div className="cal-block-div">
//                 <div className="cb-top-div">
//                   <span className="checkin-text-div">Check-Out</span>
//                   <span className="bold bkg-date-div">
//                     {/* {checkOutDateAndTime[1]?.replace(",", "")} */}

//                     {moment(hotelSearchData.checkOutDate).format(
//                       "DD, MMM YYYY"
//                     )}
//                   </span>
//                 </div>
//                 {/* <div className="cb-footer-div">
//                     {checkOutDateAndTime[0]} &nbsp;|&nbsp;
//                     {checkOutDateAndTime[3]} {checkOutDateAndTime[4]}
//                   </div> */}
//               </div>
//             </Col>
//           </Row>

//           <Col className="room-number-wrapper">
//             <div className="second-col-details-div">
//               {/* <ul className="cp-room-1">
//                 <li className="room-number-days">
//                   {noOfNights() + 1} Days & {noOfNights()} Nights
//                 </li>
//               </ul> */}
//               {/* {console.log(hotelSearchData, "famil")} */}
//               {Object.keys(hotelSearchData).length > 0 &&
//                 hotelSearchData.roomGuests.length > 0 && (
//                   <div className="room-info-grid">
//                     {hotelSearchData.roomGuests.map((item, index) => (
//                       <div key={`room-${index}`} className="room-info">
//                         <div className="spin-days-rooms">
//                           <div className="room-spin-cp">Room {index + 1}:</div>
//                           <div className="room-pkg-spin-cp">
//                             {item.noOfChilds > 0
//                               ? `${item.noOfAdults} Adults & ${item.noOfChilds} Childs`
//                               : `${item.noOfAdults} Adults`}
//                           </div>
//                         </div>
//                       </div>
//                     ))}


//                   </div>
//                 )}

//               {/* {getAdultChildCount()} */}
//             </div>
//           </Col>
//           {/* <Col md={24} sm={24} xs={24}> */}
//           {/* <Col className="inclusion-cp">
//               {hotelDetailsObj?.rooms[0]?.roomName[0]?.split(",").map((i, idx) =>
//                 idx > 0 ? (
//                   <>
//                     {idx === 1 ? <p>Inclusion:</p> : null}
//                     <ul>
//                       <li>
//                         <i className="fa fa-check" aria-hidden="true"></i>
//                         &nbsp;
//                         {i}
//                       </li>
//                     </ul>
//                   </>
//                 ) : (
//                   ""
//                 )
//               )}
//             </Col> */}
//           {/* <li>
//                   {i.toUpperCase().includes("WIFI") || i.toUpperCase().includes("WI-FI") ?
//                     <i class="fa fa-wifi" aria-hidden="true"></i> : null}
//                   {i.toUpperCase().includes("PARKING") || i.toUpperCase().includes("free-parking") ?
//                     <i class="fa fa-product-hunt" aria-hidden="true"></i> : null}
//                   {i.toUpperCase().includes("BREAKFAST") || i.toUpperCase().includes("Restaurant") ?
//                     <i class="fa fa-cutlery" aria-hidden="true"></i> : null}
//                   {i.toUpperCase().includes("BAR") || i.toUpperCase().includes("drinks") ?
//                     <i class="fa fa-glass" aria-hidden="true"></i> : null}
//                   {i.toUpperCase().includes("WHEELCHAIR") || i.toUpperCase().includes("handicaped") ?
//                     <i class="fa fa-wheelchair" aria-hidden="true"></i> : null}
//                   &nbsp;
//                   {i}
//                 </li> */}

//           {/* <div className="inclusion-cp">
//                 <p>Inclusion:</p>
//                 <ul>
//                   <li>
//                     <i className="fa fa-check" aria-hidden="true"></i>&nbsp;
//                     Breakfast
//                   </li>
//                   <li>
//                     <i className="fa fa-check" aria-hidden="true"></i>&nbsp;
//                     Complimentary Wi-Fi Internet
//                   </li>
//                 </ul>
//               </div> */}
//           {/* </Col> */}
//         </Col>
//         <Col md={24}>
//           {hotelDetailsObj?.rooms?.[0]?.ratePlans?.[0]?.inclusions?.length > 0 ?
//             <Col className="inclusion-cp" style={{ display: 'contents' }}>

//               <p className="inc-txt">Inclusions: 

//               {hotelDetailsObj?.rooms?.[0]?.ratePlans?.[0]?.inclusions?.map((i, idx) =>
//                 idx >= 0 ? (
//                   <>

//                     <ul style={{ paddingLeft: '0px' }} >
//                       <li key={"ind" + idx} >
//                       <i class="fa fa-check-circle-o" aria-hidden="true"></i> {" "}
//                         {i.toUpperCase()}{" ,"}
//                       </li>

//                     </ul>
//                   </>
//                 ) : (
//                   null
//                 )
//               )} </p>
//               {/* ,{hotelDetailsObj?.rooms?.[0]?.ratePlans?.[0]?.smokingPreference === "NoPreference" ? (
//                   <img src={nosmoke} alt="" />
//                 ) : (
//                   <img src={smoke} alt="" />
//                 ) */}



//             </Col> : null}
//         </Col>


//       </Row>
//       <Row>
//         <Col md={24} sm={24} xs={24} className="cancel-cp-bottom">
//           <>
//             {Object?.keys(hotelDetailsObj)?.length > 0 && (
//               <>
//                 {hotelDetailsObj?.rooms?.length > 0 ? (
//                   <>
//                     {hotelDetailsObj?.rooms?.[0]?.ratePlans[0]?.cancellationPolicy?.map((cancel, index) => (
//                         <>
//                           {cancel?.chargeType === "Percentage" ?
//                             <div className="cancel-policy-cp">
//                               <label>
//                                 <p className="cancl-txt">
//                                   <strong>Cancellation Policy: </strong>
//                                   {
//                                     cancel?.policies?.[0]
//                                   }
//                                 </p>
//                               </label>
//                             </div> :
//                             <div className="cancel-policy-cp">
//                               <label>
//                                 <p className="cancl-txt">
//                                   <strong>Cancellation Policy: </strong>
//                                   <span>
//                                     {"for "}{hotelDetailsObj?.rooms?.[0]?.roomName}{" - of total "}{activeCurrency}{" : "}{currencyValue(cancel?.penaltyAmount)}{"/- amount will be Charged, If Cancelled between "}{moment(cancel?.fromDate).format("DD-MM-YYYY")}{" from "}{moment(cancel?.fromDate).format("HH:MM:SS")}{" and "}{moment(cancel?.toDate).format("DD-MM-YYYY")}{" until "} {" IST "}{moment(cancel?.toDate).format("HH:MM:SS")}
//                                   </span>
//                                 </p>
//                               </label>
//                             </div>}
//                         </>))}


//                   </>
//                 ) : (
//                   ""
//                 )}
//               </>
//             )}

//             {/* <p>Cancellation Policy:  First night cost (including taxes and service charge) will be charged if you cancel this booking.</p> */}
//           </>
//         </Col>
//       </Row>

//       {/* <div className="hotel-details-box-wrapper cp-box-none">
//         {Object.keys(hotelDetailsObj).length > 0 && (
//           <div className="hotel-details-img-name">
//             <div className="image-details">
//               {hotelDetailsObj?.images?.length > 0 ? (
//                 <img
//                   src={hotelDetailsObj.images[0]}
//                   alt={hotelDetailsObj.hotelName}
//                 />
//               ) : (
//                 <img src={hotelNoImg} alt="no-photo" />
//               )}
//             </div>
//             <div className="name-details">
//               <p>
//                 {hotelDetailsObj.hotelName}
//                 <sup>
//                   <BeautyStars
//                     value={hotelDetailsObj.starRating}
//                     activeColor={"#f6952d"}
//                     size={"7px"}
//                     inactiveColor={"#eaebee"}
//                   />
//                 </sup>
//               </p>
//               <span>{hotelDetailsObj.hotelAddress}</span>
//             </div>
//           </div>
//         )}

//         <div className="stay-details-container">
//           <div className="staying-date">
//             <label className="hotel-stay-name">Marina Bay Beach Resort</label>
//             <p className="locaton-hotel-div">
//               Behind Bobs inn,Anna Vaddo, Candolim, GOA,
//             </p>

//             {Object.keys(hotelSearchData).length > 0 ? (
//               <span>
//                 {moment(hotelSearchData.checkInDate).format("DD MMM YYYY")}
//                 {" - "}
//                 {moment(hotelSearchData.checkOutDate).format("DD MMM YYYY")}
//               </span>
//             ) : (
//               ""
//             )}

//             <span className="number-hotel-spin">Nights: {noOfNights()}</span>
//           </div>
//           <div className="staying-date hotel-stay-date-row">
//             {Object.keys(hotelDetailsObj).length > 0 && (
//               <label>
//                 {hotelDetailsObj.rooms.length > 0 ? (
//                   <>
//                     {hotelDetailsObj.rooms[0].roomName} -{" "}
//                     {hotelDetailsObj.rooms[0].ratePlans[0].cancellationPolicy
//                       .policyName
//                       ? hotelDetailsObj.rooms[0].ratePlans[0].cancellationPolicy
//                           .policyName
//                       : ""}
//                   </>
//                 ) : (
//                   ""
//                 )}
//               </label>
//             )}
//             {getAdultChildCount()}
//           </div>
//         </div>
//       </div> */}
//     </>
//   );
// };

// export default HotelDetailsBox;

/// new structure


import React from "react";
import "../HotelDetailsBox/HotelDetailsBox.scss";
import hotelNoImg from "../../assets/images/hotels/no_img.png";
import { Card, Col, Rate, Row } from "antd";
import moment from "moment";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import backic from "../../assets/images/Icons/back.png";
import { useCurrencyContext } from "../../common/providers/CurrencyProvider";
import nosmoke from "../../assets/images/Icons/icons8-no-smoking-48.png";
import smoke from "../../assets/images/Icons/icons8-smoking-64.png"
import { EnvironmentOutlined, HeartOutlined, BankOutlined, StarTwoTone } from "@ant-design/icons";
const HotelDetailsBox = ({ Ids, hotelDetailsObj, hotelSearchData }) => {
  let history = useHistory();
  // let checkInDateAndTime =
  //   moment(hotelSearchData.checkInDate).format("lll").split(" ") ?? [];
  // let checkOutDateAndTime =
  //   moment(hotelSearchData.checkOutDate).format("lll").split(" ") ?? [];
  const { activeCurrency, currencyValue } = useCurrencyContext();

  let noOfNights = () => {
    if (Object.keys(hotelSearchData).length > 0) {
      let checkin = new Date(hotelSearchData.checkInDate);
      let checkout = new Date(hotelSearchData.checkOutDate);
      let diffTime = checkout - checkin;
      let diffDays = Math.ceil(diffTime / (1000 * 24 * 60 * 60));
      return diffDays;
    } else {
      return 0;
    }
  };

  const getAdultChildCount = () => {
    let adults = 0;
    let childs = 0;

    if (Object.keys(hotelSearchData).length > 0) {
      if (hotelSearchData.roomGuests.length > 0) {
        for (
          let index = 0;
          index < hotelSearchData.roomGuests.length;
          index++
        ) {
          adults += Number(hotelSearchData.roomGuests[index].noOfAdults);
          childs += Number(hotelSearchData.roomGuests[index].noOfChilds);
        }
      }
    }
    if (childs > 0) {
      return `${adults} Adult &  ${childs} Children`;
    } else return `${adults} Adult`;
  };
  // const RoomsInclusion = (i, idx) => {
  //   return (
  //     <>
  //       {idx === 1 ? (
  //         <p className="mb-0 mr-1" style={{ whiteSpace: "nowrap" }}>
  //           <strong>Inclusions :</strong>
  //         </p>
  //       ) : null}

  //       <p className="mb-0 mr-1 inclusions-img-wor-css">
  //         {/* <i
  //       className="fa fa-check color-blue"
  //       aria-hidden="true"
  //     ></i>{" "} */}
  //         {(i.toLowerCase().includes('king bed') || i.toLowerCase().includes('king-bed') || i.toLowerCase().includes('double')) ?
  //           <>
  //             <img src={Kingbed} alt="no-photo" />
  //             <span>  {i}  </span>
  //           </> : ""}

  //         {(i.toLowerCase().includes('twin bed') || i.toLowerCase().includes('twin-bed') || i.toLowerCase().includes('queen')) ?
  //           <>

  //             <img src={twinbeds} alt="no-photo" />
  //             <span>  {i}  </span>
  //           </> : ""}

  //         {(i.toLowerCase().includes('wifi') || i.toLowerCase().includes('wi-fi')) ?
  //           <>

  //             <img src={wifi} alt="no-photo" />
  //             <span>  {i}  </span>
  //           </> : ""}

  //         {(i.toLowerCase().includes('break')) ?
  //           <>

  //             <img src={breakfast} alt="no-photo" />
  //             <span>  {i}  </span>
  //           </> : ""}

  //         {i.toLowerCase().includes('view') ?
  //           <>

  //             <img src={sea} alt="no-photo" />
  //             <span>  {i}  </span>
  //           </> : ""}

  //       </p>
  //     </>
  //   )
  // }

  const gotoHotelDetail = (hotelCode) => {
    let queryObj = {
      hotelId: hotelCode,
      traceId: Ids.traceId,
      supplier: hotelSearchData.supplier,
    };
    const query = queryString.stringify(queryObj);
    history.push(`/hotels/hotel-details?${query}`);
  };
  const StarRating = ({ rating }) => {
    const numStars = parseFloat(rating);
    const starsArray = Array.from({ length: numStars }, (_, index) => index);

    return (
      <div className="str-top-ht" style={{ fontSize: "14px", marginLeft: -2, marginTop: -8 }}>
        {starsArray?.map((_, index) => (
          <span
            key={index}
            role="img"
            aria-label="star"
            style={{
              textShadow: "3px 2px 6px grey",
              marginRight: "1px"
            }}
          >
            <StarTwoTone />
            {/* ⭐ */}
          </span>
        ))}
      </div>
    );
  };
  return (
    <>

      <Row className="hot-detail">
        <Col md={24}>
          <div className="hotel-detail-header-wrapper">
            <p className="booking-summary-div">Hotel Details</p>

            {hotelDetailsObj?.hotelCode && Ids !== "hotel-review" && (
              <p
                className="pointer_cursor"
                onClick={() => gotoHotelDetail(hotelDetailsObj.hotelCode)}
              >
                Change Room <span><img src={backic} width={"20px"} alt="" /></span>
              </p>
            )}
          </div>
        </Col>

        <Col md={8} sm={24} xs={24}>
          <div style={{ padding: 8 }}>
            <div className="image-details one-img-hotel">

              {hotelDetailsObj?.images?.length > 1 ? (
                <img
                  src={hotelDetailsObj?.images[1]}
                  alt={hotelDetailsObj.hotelName}
                />
              ) : (hotelDetailsObj?.images?.length > 0 ? (
                <img
                  src={hotelDetailsObj?.images[0]}
                  alt={hotelDetailsObj.hotelName}
                />
              ) : (
                <img src={hotelNoImg} alt="no-photo" />
              ))
              }

              {/* <div className="tag-cp-img">
              <p>You Save Rs.2,640 </p>
            </div> */}
            </div>
          </div>
        </Col>

        <Col md={16} sm={24} xs={24}>
          <div className="rm-dt-bx" style={{ padding: 8 }}>
            <div className="disp-table-cell-div">
              <div className="hotel-plan-div">
                <h4 className="hot-name">{hotelDetailsObj.hotelName}</h4>
                {hotelDetailsObj.starRating && (
                  <div className="hotel-star">
                    {/* <Rate
                    className="starRating"
                    disabled
                    value={Number(hotelDetailsObj.starRating)}
                    allowHalf={true}
                  /> */}
                    <StarRating rating={hotelDetailsObj.starRating} />
                  </div>
                )}
              </div>
              <div className="rm-st">
                <div className="rrom-type">
                  <span className="rom">Room</span> {" : "}
                  <span className="room-name">
                    {console.log(hotelDetailsObj, "rmName")}
                    {hotelDetailsObj?.combineRoom?.[0]?.combineRooms?.[0]?.ratePlanName?.split(',')?.[0]}
                  </span>
                </div>
                <div className="rrom-type">
                  <span className="rom">Stay</span> {" : "}
                  <span className="room-name">
                    <i class="fa fa-moon-o" aria-hidden="true"></i>{" "}{noOfNights()} {noOfNights() > 1 ? "Nights" : "Night"}
                  </span>
                </div>
              </div>
              {/* <Row gutter={[2, 12]} className="cal-top-date-pic">
            <Col md={12} sm={12} xs={12}>
              <div className="cal-block-div">
                <div className="cb-top-div">
                  <span className="checkin-text-div">Check-In</span>
                  <span className="bold bkg-date-div">
                    {/* {checkInDateAndTime[1]?.replace(",", "")} *
                    {moment(hotelSearchData.checkInDate).format("DD, MMM YYYY")}
                  </span>
                </div>
                {/* <div className="cb-footer-div">
                    {checkInDateAndTime[0]} &nbsp;|&nbsp;{checkInDateAndTime[3]}{" "}
                    {checkInDateAndTime[4]}
                  </div> *
              </div>
            </Col>
            <Col md={12} sm={12} xs={12}>
              <div className="cal-block-div">
                <div className="cb-top-div">
                  <span className="checkin-text-div">Check-Out</span>
                  <span className="bold bkg-date-div">
                    {/* {checkOutDateAndTime[1]?.replace(",", "")} *

                    {moment(hotelSearchData.checkOutDate).format(
                      "DD, MMM YYYY"
                    )}
                  </span>
                </div>
                {/* <div className="cb-footer-div">
                    {checkOutDateAndTime[0]} &nbsp;|&nbsp;
                    {checkOutDateAndTime[3]} {checkOutDateAndTime[4]}
                  </div> *
              </div>
            </Col>
          </Row> */}
              <div className="booking-dates">
                <div className="check-in">
                  <div className="labelC">CHECK IN</div>
                  <div className="date">
                    <span className="day">{moment(hotelSearchData.checkInDate).format("ddd")}</span>
                    <span className="date-number">{moment(hotelSearchData.checkInDate).format("DD")}</span>
                    <span className="month-year">{moment(hotelSearchData.checkInDate).format("MMM YYYY")}</span>
                  </div>
                  <div className="time">12 PM</div>
                </div>
                <div className="nights">
                  <span className="nights-badge"> <i class="fa fa-moon-o" aria-hidden="true"></i>{" "}{noOfNights()} {noOfNights() > 1 ? "Nights" : "Night"}</span>
                </div>
                <div className="check-out">
                  <div className="labelC">CHECK OUT</div>
                  <div className="date">
                    <span className="day"> {moment(hotelSearchData.checkOutDate).format("ddd")}</span>
                    <span className="date-number">{moment(hotelSearchData.checkOutDate).format("DD")}</span>
                    <span className="month-year">{moment(hotelSearchData.checkOutDate).format("MMM YYYY")}</span>
                  </div>
                  <div className="time">10 AM</div>
                </div>
              </div>

              <Col className="room-number-wrapper">
                <div className="second-col-details-div">
                  {/* <ul className="cp-room-1">
                <li className="room-number-days">
                  {noOfNights() + 1} Days & {noOfNights()} Nights
                </li>
              </ul> */}
                  {/* {console.log(hotelSearchData, "famil")} */}
                  {/* {Object.keys(hotelSearchData).length > 0 &&
                    hotelSearchData.roomGuests.length > 0 && (
                      <div className="room-info-grid">
                        {hotelSearchData.roomGuests.map((item, index) => (
                          <div key={`room-${index}`} className="room-info">
                            <div className="spin-days-rooms">
                              <div className="room-spin-cp">Room {index + 1}:</div>
                              <div className="room-pkg-spin-cp">
                                {item.noOfChilds > 0
                                  ? `${item.noOfAdults} Adults & ${item.noOfChilds} Childs`
                                  : `${item.noOfAdults} Adults`}
                              </div>
                            </div>
                          </div>
                        ))}


                      </div>
                    )} */}
                  {Object.keys(hotelSearchData).length > 0 && hotelSearchData.roomGuests.length > 0 && (
                    <div className="room-summ-bx">
                      {(() => {
                        const summary = hotelSearchData.roomGuests.reduce(
                          (acc, room) => {
                            acc.rooms += 1;
                            acc.adults += room.noOfAdults;
                            acc.children += room.noOfChilds;
                            return acc;
                          },
                          { rooms: 0, adults: 0, children: 0 }
                        );

                        return (
                          <div className="rmsury-details">
                            <div className="rm-sum"><span>{summary.rooms} </span> Rooms</div> <div>|</div>
                            <div className="rm-sum"><span>{summary.adults}</span> Adult's {summary.children > 0 ? <>", " <span>{summary.children}</span>Child's </> : null}   </div><div>|</div>
                            <div className="rm-sum"><span>{noOfNights()} </span>{noOfNights() > 1 ? "Nights" : "Night"}</div>
                          </div>
                        );
                      })()}
                    </div>
                  )}


                  {/* {getAdultChildCount()} */}
                </div>
              </Col>
              {/* <Col md={24} sm={24} xs={24}> */}
              {/* <Col className="inclusion-cp">
              {hotelDetailsObj?.rooms[0]?.roomName[0]?.split(",").map((i, idx) =>
                idx > 0 ? (
                  <>
                    {idx === 1 ? <p>Inclusion:</p> : null}
                    <ul>
                      <li>
                        <i className="fa fa-check" aria-hidden="true"></i>
                        &nbsp;
                        {i}
                      </li>
                    </ul>
                  </>
                ) : (
                  ""
                )
              )}
            </Col> */}
              {/* <li>
                  {i.toUpperCase().includes("WIFI") || i.toUpperCase().includes("WI-FI") ?
                    <i class="fa fa-wifi" aria-hidden="true"></i> : null}
                  {i.toUpperCase().includes("PARKING") || i.toUpperCase().includes("free-parking") ?
                    <i class="fa fa-product-hunt" aria-hidden="true"></i> : null}
                  {i.toUpperCase().includes("BREAKFAST") || i.toUpperCase().includes("Restaurant") ?
                    <i class="fa fa-cutlery" aria-hidden="true"></i> : null}
                  {i.toUpperCase().includes("BAR") || i.toUpperCase().includes("drinks") ?
                    <i class="fa fa-glass" aria-hidden="true"></i> : null}
                  {i.toUpperCase().includes("WHEELCHAIR") || i.toUpperCase().includes("handicaped") ?
                    <i class="fa fa-wheelchair" aria-hidden="true"></i> : null}
                  &nbsp;
                  {i}
                </li> */}

              {/* <div className="inclusion-cp">
                <p>Inclusion:</p>
                <ul>
                  <li>
                    <i className="fa fa-check" aria-hidden="true"></i>&nbsp;
                    Breakfast
                  </li>
                  <li>
                    <i className="fa fa-check" aria-hidden="true"></i>&nbsp;
                    Complimentary Wi-Fi Internet
                  </li>
                </ul>
              </div> */}
              {/* </Col> */}
            </div>
          </div>
        </Col>
        <Col md={24} style={{ padding: 8 }}>

          {hotelDetailsObj?.combineRoom?.[0]?.inclusions?.length > 0 ?
            <Col className="inclusion-cp" style={{ display: 'contents' }}>

              <p className="inc-txt">Inclusions:

                {hotelDetailsObj?.combineRoom?.[0]?.inclusions?.map((i, idx) =>
                  idx >= 0 ? (
                    <>

                      <ul >
                        <li key={"ind" + idx} >
                          <i class="fa fa-check-circle-o" aria-hidden="true"></i> {" "}
                          {i.toUpperCase()}{hotelDetailsObj?.combineRoom?.[0]?.inclusions?.length < idx || idx > 0 ? " , " : ""}
                        </li>

                      </ul>
                    </>
                  ) : (
                    null
                  )
                )} </p>
              {/* ,{hotelDetailsObj?.rooms?.[0]?.ratePlans?.[0]?.smokingPreference === "NoPreference" ? (
                  <img src={nosmoke} alt="" />
                ) : (
                  <img src={smoke} alt="" />
                ) */}



            </Col> : null}

        </Col>


      </Row>
      <Row>
        <Col md={24} sm={24} xs={24} className="cancel-cp-bottom" style={{ padding: 0, marginTop: -4 }}>
          <>
            {Object?.keys(hotelDetailsObj)?.length > 0 && (
              <>
                {hotelDetailsObj?.combineRoom?.length > 0 ? (
                  <>
                    {hotelDetailsObj?.combineRoom?.[0]?.combineRooms[0]?.cancellationPolicy?.map((cancel, index) => (
                      <>

                        {cancel?.chargeType === "Percentage" ?
                          <div className="cancel-policy-cp">
                            <label>
                              <p className="cancl-txt">
                                <strong>Cancellation Policy: </strong>
                                {cancel?.policies ?? <span>
                                  {"for "}{hotelDetailsObj?.combineRoom?.[0]?.combineRooms[0]?.roomName}{" - Total "} {" : "} {cancel?.penaltyAmount} {" % (percentage) of amount will be Charged, If Cancelled between "}{moment(cancel?.fromDate).format("DD-MM-YYYY")}{" from "}{moment(cancel?.fromDate).format("HH:MM:SS")}{" and "}{moment(cancel?.toDate).format("DD-MM-YYYY")}{" until "} {" IST "}{moment(cancel?.toDate).format("HH:MM:SS")}
                                </span>
                                }
                              </p>
                            </label>
                          </div> :
                          <div className="cancel-policy-cp">
                            <label>
                              <p className="cancl-txt">
                                <strong>Cancellation Policy: </strong>
                                <span>
                                  {"for "}{hotelDetailsObj?.combineRoom?.[0]?.combineRooms[0]?.roomName}{" - of total "}{activeCurrency}{" : "}{currencyValue(cancel?.penaltyAmount)}{"/- amount will be Charged, If Cancelled between "}{moment(cancel?.fromDate).format("DD-MM-YYYY")}{" from "}{moment(cancel?.fromDate).format("HH:MM:SS")}{" and "}{moment(cancel?.toDate).format("DD-MM-YYYY")}{" until "} {" IST "}{moment(cancel?.toDate).format("HH:MM:SS")}
                                </span>
                              </p>
                            </label>
                          </div>}
                      </>))}


                  </>
                ) : (
                  ""
                )}
              </>
            )}

            {/* <p>Cancellation Policy:  First night cost (including taxes and service charge) will be charged if you cancel this booking.</p> */}
          </>
        </Col>
      </Row>

      {/* <div className="hotel-details-box-wrapper cp-box-none">
        {Object.keys(hotelDetailsObj).length > 0 && (
          <div className="hotel-details-img-name">
            <div className="image-details">
              {hotelDetailsObj?.images?.length > 0 ? (
                <img
                  src={hotelDetailsObj.images[0]}
                  alt={hotelDetailsObj.hotelName}
                />
              ) : (
                <img src={hotelNoImg} alt="no-photo" />
              )}
            </div>
            <div className="name-details">
              <p>
                {hotelDetailsObj.hotelName}
                <sup>
                  <BeautyStars
                    value={hotelDetailsObj.starRating}
                    activeColor={"#f6952d"}
                    size={"7px"}
                    inactiveColor={"#eaebee"}
                  />
                </sup>
              </p>
              <span>{hotelDetailsObj.hotelAddress}</span>
            </div>
          </div>
        )}

        <div className="stay-details-container">
          <div className="staying-date">
            <label className="hotel-stay-name">Marina Bay Beach Resort</label>
            <p className="locaton-hotel-div">
              Behind Bobs inn,Anna Vaddo, Candolim, GOA,
            </p>

            {Object.keys(hotelSearchData).length > 0 ? (
              <span>
                {moment(hotelSearchData.checkInDate).format("DD MMM YYYY")}
                {" - "}
                {moment(hotelSearchData.checkOutDate).format("DD MMM YYYY")}
              </span>
            ) : (
              ""
            )}

            <span className="number-hotel-spin">Nights: {noOfNights()}</span>
          </div>
          <div className="staying-date hotel-stay-date-row">
            {Object.keys(hotelDetailsObj).length > 0 && (
              <label>
                {hotelDetailsObj.rooms.length > 0 ? (
                  <>
                    {hotelDetailsObj.rooms[0].roomName} -{" "}
                    {hotelDetailsObj.rooms[0].ratePlans[0].cancellationPolicy
                      .policyName
                      ? hotelDetailsObj.rooms[0].ratePlans[0].cancellationPolicy
                          .policyName
                      : ""}
                  </>
                ) : (
                  ""
                )}
              </label>
            )}
            {getAdultChildCount()}
          </div>
        </div>
      </div> */}
    </>
  );
};

export default HotelDetailsBox;

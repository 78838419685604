import React, { useContext, useState, useEffect, useRef } from "react";

import HotelDetailsBox from "../../../components/HotelDetailsBox/HotelDetailsBox";
import {
  Row,
  Card,
  Col,
  Form,
  Input,
  DatePicker,
  Select,
  Skeleton,
  message,
  Radio,
  TimePicker,
  Tooltip,
  Modal, Statistic,
} from "antd";
import { Link, useHistory } from "react-router-dom";
import {
  ArrowLeftOutlined,
  QuestionCircleOutlined,
  SafetyCertificateOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import HotelFairBox from "./HotelFairBox";
import timeoutimg from "../../../assets/images/timeout.jpg";
import { GlobalStatesContext } from "../../../common/providers";
import { useCurrencyContext } from "../../../common/providers/CurrencyProvider";
import { useAuthContext } from "../../../common/providers/AuthProvider";
import { useHotelContext } from "../../../common/providers/Hotels/HotelProvider";
import { getPassengerData } from "../../../helpers/PassegerData";
// import { SafetyCertificateOutlined } from "@ant-design/icons";
import HotelBookPayCard from "./HotelBookPayCard";
import CountryList from "../../../common/CountryList";
import queryString from "query-string";

import ApiClient from "../../../helpers/ApiClient";
import "./HotelCheckout.scss";
import Nav1 from "../../../common/navbar/Nav1";
var moment = require("moment");
const { Option } = Select;
const { Countdown } = Statistic;
const HotelCheckout = ({ location }) => {
  const { setHotelCheckOutData, state: { sessiontimeout, status }, getsessiontimeout } = useHotelContext();
  const {
    user,
    isLogin: { agent },
  } = useAuthContext();
  const {
    state: {
      otherData: { insuranceData, selectedInsuranceData },
    },

    setSelectedInsuranceData,
    RemovePromoConvFee,
  } = useContext(GlobalStatesContext);
  let history = useHistory();
  const [contact_form] = Form.useForm();
  const [guestDetailsForm] = Form.useForm();
  const [arrival_form] = Form.useForm();
  const [departure_form] = Form.useForm();
  const [value, setValue] = useState(1);
  const [ids, setIds] = useState({
    traceId: null,
    repriceId: null,
  });
  const [loading, setLoading] = useState(true);
  const [hotelPriceData, setHotelPriceData] = useState({});
  const [transportType, setTransportType] = useState(0);
  const [arrivalType, setArrivalType] = useState(0);
  const [hotelSearchData, setHotelSearchData] = useState({});
  const { activeCurrency, currencyValue, currencyRatio } = useCurrencyContext();
  const [roomGuestInfo, setRoomGuestInfo] = useState([]);
  const mobile = useRef();

  const mobileRef = useRef(null);
  const areaCodeRef = useRef(null);

  const handleAreaCodeChange = () => {

    if (mobileRef.current) {
      mobileRef.current.focus();
    }
  };

  const handlePaxField = (val, roomIndex, paxIndex, key) => {

    let temp = [...roomGuestInfo];

    if (paxIndex === 0) {
      temp[roomIndex].paxInfoList[paxIndex]["leadGuest"] = true;
    } else {
      temp[roomIndex].paxInfoList[paxIndex]["leadGuest"] = false;
    }

    temp[roomIndex].paxInfoList[paxIndex][key] = val;

    setRoomGuestInfo(temp);

  };

  const [insuranceRequired, setInsuranceRequired] = useState(-1);

  const loadpassengerData = () => {
    if (user && user?.UserID) {
      getPassengerData(user.UserID).then((data) => {
        if (data.status) {
          contact_form.setFieldsValue({
            phoneNo: data.Mobile,
            email: data.Email,

            addressLine1: data?.Address1 ? data?.Address1 : "",
            city: data?.city ? data?.city : "",
            state: data?.state ? data?.state : "",
          });
        }
      });
    }
  };

  useEffect(() => {
    RemovePromoConvFee();
    fetchHotelPrice();
    loadpassengerData();
    getsessiontimeout();
  }, []);

  const HotelGuestReqFields = (paxObj, guestRequiredFields) => {
    guestRequiredFields.map((paxReqFieldsObj) => {
      Object.keys(paxReqFieldsObj).map((paxReqKeys) => {
        if (paxReqFieldsObj[paxReqKeys] === true) {
          paxObj[paxReqKeys] = "";
        }
      });
    });
    return paxObj;
  };

  const fetchHotelPrice = () => {
    const hotelParams = queryString.parse(window.location.search);

    setIds({
      traceId: hotelParams.traceId,
      repriceId: null,
    });

    setLoading(true);
    let Req = {
      ...hotelParams,
      roomPlan: JSON.parse(hotelParams.roomPlan),
      userId: user?.UserID ?? 1,
      roleType: user?.Role?.RoleId ?? 4,
      membership: user?.Membership ?? 1,
      supplier: hotelParams.supplier,
    };
    //console.log("hotelchec1", Req)

    setHotelPriceData({});
    setHotelSearchData({});
    setRoomGuestInfo([]);
    ApiClient.post("hotels-v2/hotelprice", Req)
      .then((res) => res)
      .then((res) => {
        if (res.status === 200) {

          if (res.data.errors.length === 0) {
            const {
              hotels,
              purchaseType,
              repriceId,
              request,
              traceId,
              isPackageFare,
              isPackageDetailsMandatory,
              isDepartureDetailsMandatory,
            } = res.data;
            if (
              Object.keys(hotels).length > 0 &&
              Object.keys(request).length > 0
            ) {
              setHotelPriceData({
                ...hotels,
                purchaseType,
                traceId,
                repriceId,
                isPackageFare,
                isPackageDetailsMandatory,
                isDepartureDetailsMandatory,
              });
              setHotelSearchData({
                ...request,
                supplier: hotelParams.supplier,
              });
              setHotelCheckOutData({});
              const roomInfoArr = [];

              let roomsInfo = request.roomGuests;

              roomsInfo.map((room, roomIndex) => {
                const paxListArr = [];

                [...Array(room.noOfAdults)].map(() => {
                  let paxObj = {
                    firstName: "",
                    lastName: "",
                    title: "Mr.",
                    guestType: "Adult",
                    guestInRoom: roomIndex + 1,
                    age: "",
                  };
                  paxObj = HotelGuestReqFields(
                    paxObj,
                    hotels.guestRequiredFields
                  );
                  paxListArr.push(paxObj);
                });
                [...Array(room.noOfChilds)].map((_, index) => {
                  let paxObj = {
                    firstName: "",
                    lastName: "",
                    title: "Mstr",
                    guestType: "Child",
                    guestInRoom: roomIndex + 1,
                    age: parseInt(room.childAge[index]),
                  };
                  paxObj = HotelGuestReqFields(
                    paxObj,
                    hotels.guestRequiredFields
                  );
                  paxListArr.push(paxObj);
                });

                roomInfoArr.push({ paxInfoList: paxListArr });
              });

              setRoomGuestInfo(...roomGuestInfo, roomInfoArr);
            }
          } else {
            res.data.errors.forEach((err) => {
              if (err.errorCode === "SOLDOUT") {
                message.error(err.errorDetail, 5);
              }
            });
          }
        } else if (res.status === 500 && res.data?.errors?.length > 0) {
          res.data.errors.forEach((err) => {
            if (err.errorCode === "SOLDOUT") {
              message.error(err.errorDetail, 5);
            }
          });
        }
        else {
          alert(res.message);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const updateTranportType = (e) => {
    if (e.target) {

      setTransportType(e.target.value);
    } else {
      setTransportType(e);
    }
  };
  const updateArrivalType = (e) => {
    if (e.target) {

      setArrivalType(e.target.value);
    } else {
      setArrivalType(e);
    }
  };

  const handleInsuranceChange = (val) => {
    if (val === 1) {
      setSelectedInsuranceData(insuranceData);
    } else {
      setSelectedInsuranceData({ amount: 0, insuranceCoverage: 0, status: 0 });
    }
    setInsuranceRequired(val);
  };

  const redirectToPreview = () => {
    departure_form
      .validateFields()
      .then((departData) => {
        arrival_form
          .validateFields()
          .then((arrivalData) => {
            contact_form
              .validateFields()
              .then((val) => {
                guestDetailsForm
                  .validateFields()
                  .then((passegersData) => {
                    let countryName = "";
                    if (val.countryCode) {
                      countryName = CountryList.filter(
                        (item) => item.code === val.countryCode
                      );
                      countryName = countryName[0].name;
                    }

                    let data = {
                      hotelPriceData,
                      hotelSearchData,
                      addressInfo: {
                        addressLine1: val.addressLine1,
                        addressLine2: val.addressLine1,
                        cellCountryCode: val.areaCode,
                        countryCode: val.countryCode,
                        areaCode: val.areaCode,
                        phoneNo: val.phoneNo,
                        email: val.email,
                        city: val.city,
                        state: val.state,
                        country: countryName,
                        zipCode: val.zipCode,
                      },
                      arrivalTransport: {
                        ArrivalTransportType: arrivalType,
                        TransportInfoId:
                          arrivalType == 0
                            ? arrivalData.FlightNo
                            : arrivalData.TransportTypeNo,
                        Time: moment(arrivalData.DateTime).format(
                          "YYYY-MM-DDTHH:mm:ss"
                        ),
                      },
                      departureTransport: {
                        DepartureTransportType: transportType,
                        TransportInfoId:
                          transportType == 0
                            ? departData.FlightNo
                            : departData.TransportTypeNo,
                        Time: moment(departData.DateTime).format(
                          "YYYY-MM-DDTHH:mm:ss"
                        ),
                      },
                      guests: roomGuestInfo,

                      insuranceRequired:
                        passegersData?.insuranceRequired === 1 ? 1 : 0,
                      insuranceData: selectedInsuranceData,
                    };
                    { console.log(roomGuestInfo, "guest") }

                    setHotelCheckOutData(data);

                    history.push("/hotels/hotel-review");
                  })
                  .catch((e) => {
                    if (e?.errorFields && e?.errorFields.length > 0)
                      guestDetailsForm.scrollToField(e.errorFields[0].name);
                  });
              })
              .catch((e) => {
                if (!e.errorFields) {
                  return;
                }
                contact_form.scrollToField(e.errorFields[0].name);
              });
          })
          .catch((e) => {
            if (!e.errorFields) {
              return;
            }
            arrival_form.scrollToField(e.errorFields[0].name);
          });
      })
      .catch((e) => {
        if (!e.errorFields) {
          return;
        }
        departure_form.scrollToField(e.errorFields[0].name);
      });
  };



  const gotoHotelDetail = (hotelCode) => {
    let queryObj = {
      hotelId: hotelCode,
      traceId: ids.traceId,
      supplier: hotelSearchData.supplier,
    };
    const query = queryString.stringify(queryObj);
    history.push(`/hotels/hotel-details?${query}`);
  };

  const timeout = () => {
    return (

      <div>

        <div className="timeout-popup-main">
          <div className="timeout-popup-main1">
            <img className="timeout-img" src={timeoutimg} alt="time" />
          </div>
          <div className="timeout-popup-main2">
            <h4 style={{ color: "red", }}><strong>SESSION TIMEOUT</strong></h4>
            <p className="popup-session-timeout-p-tag">Your Session is Expired</p>
            <p className="popup-session-timeout-p-tag">Click on "OK" to continue with New Search</p>
          </div>
        </div>

      </div>
    );
  }
  const handelCountdown = () => {


    let query = queryString.stringify(JSON.parse(localStorage.getItem('HotelSearchBar')));

    Modal.warning({
      icon: <></>,

      content: timeout(),
      onOk() {
        history.push("/hotels/listing?" + query);

      },
    });
  };

  return (
    <>
      <Nav1 />
      <div className="hotel-checkout-wrapper">
        <div className="checkout-heading">
          <div
            style={{ marginTop: "65px" }}
            fluid
            className="checkout-heading-container"
          >
            <div className="goback">
              <Link
                onClick={() => {
                  history.go(-1);
                }}
              >
                <ArrowLeftOutlined />
                <span>Go back and select another Room</span>
              </Link>
            </div>
            <h3>Fill out the form below and book your stay now!</h3>
          </div>
        </div>


        <div className="form-body">
          <div className="form-body-container">

            <Row gutter={[16]}>
              {/* <Col md={24}>
              <div className="hotel-detail-header-wrapper">
                <p className="booking-summary-div">Hotel Details</p>

                {hotelPriceData?.hotelCode && (
                  <p
                    className="pointer_cursor"
                    onClick={() => gotoHotelDetail(hotelPriceData.hotelCode)}
                  >
                    Change Room
                  </p>
                )}
              </div>
            </Col> */}
              <Col md={16} sm={24} xs={24}>


                {loading ? (
                  <Card bordered={false} className="hotel-card-wrapper">
                    <Skeleton active />
                  </Card>
                ) : Object.keys(hotelPriceData).length > 0 ? (
                  <Card bordered={false} className="hotel-card-wrapper">
                    <HotelDetailsBox
                      Ids={ids}
                      hotelDetailsObj={hotelPriceData}
                      hotelSearchData={hotelSearchData}
                    />
                  </Card>
                ) : null}

                <div className="contact-header">
                  <div className="contact-title">
                    <p className="booking-summary-div">Guests Details</p>
                  </div>
                </div>

                {loading ? (
                  <Card bordered={false} className="hotel-card-wrapper">
                    <Skeleton active />
                  </Card>
                ) : roomGuestInfo?.length > 0 ? (
                  <Form
                    form={guestDetailsForm}
                    scrollToFirstError={true}
                    layout="vertical"
                    style={{ marginBottom: 20 }}
                    requiredMark={false}
                  >


                    {roomGuestInfo.map((roomsObj, roomIndex) => (
                      <Card
                        bordered={false}
                        className="guest-details-form hotel-card-wrapper"
                        key={roomIndex}
                      >

                        <div style={{ background: "#f9f9f9", padding: 10 }}>
                          <p className="room-title">Room {1 + roomIndex}</p>
                        </div>

                        {roomsObj.paxInfoList.map((pax, paxIndex) => (
                          <div
                            key={roomIndex + "detials" + paxIndex}
                            className="guest-input-wrapper"
                          >
                            <p className="guestsType">
                              {pax.guestType === "Adult" ? "Adult" : "Child"}{" "}
                            </p>
                            <Row gutter={[16, 16]} style={{ padding: 10 }}>
                              <Col md={3} sm={12} xs={24}>
                                {pax.guestType === "Adult" ? (
                                  <Form.Item
                                    name={`Title_${roomIndex}_${paxIndex}`}
                                    label="Title"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Required",
                                      },
                                    ]}
                                  >
                                    <Select
                                      placeholder="Title"
                                      size="large"
                                      onChange={(val) => {
                                        handlePaxField(
                                          val,
                                          roomIndex,
                                          paxIndex,
                                          "title"
                                        );
                                      }}
                                    >
                                      <Option value="Mr">Mr</Option>
                                      <Option value="Ms">Ms</Option>
                                      <Option value="Mrs">Mrs</Option>
                                    </Select>
                                  </Form.Item>
                                ) : (
                                  <Form.Item
                                    name={`Title_${roomIndex}_${paxIndex}`}
                                    label="Title"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Required",
                                      },
                                    ]}
                                  >
                                    <Select
                                      size="large"
                                      onChange={(val) => {
                                        handlePaxField(
                                          val,
                                          roomIndex,
                                          paxIndex,
                                          "title"
                                        );
                                      }}
                                    >
                                      <Option value="Mstr">Mstr</Option>
                                    </Select>
                                  </Form.Item>
                                )}
                              </Col>

                              <Col md={8} sm={12} xs={24}>
                                <Form.Item
                                  label="First Name"
                                  name={`firstname_${roomIndex}_${paxIndex}`}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required",
                                    },
                                  ]}
                                >
                                  <Input
                                    placeholder="Enter Your First Name"
                                    onChange={(e) => {
                                      handlePaxField(
                                        e.target.value,
                                        roomIndex,
                                        paxIndex,
                                        "firstName"
                                      );
                                    }}
                                    onInput={(e) => e.target.value = ("" + e.target.value).toUpperCase()}
                                    size="large"
                                  />
                                </Form.Item>
                              </Col>

                              <Col md={8} sm={12} xs={24}>
                                <Form.Item
                                  label="Last Name"
                                  name={`lastname_${roomIndex}_${paxIndex}`}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required",
                                    },
                                  ]}
                                >
                                  <Input
                                    placeholder="Enter Your Last Name"
                                    onInput={(e) => e.target.value = ("" + e.target.value).toUpperCase()}
                                    onChange={(e) => {
                                      handlePaxField(
                                        e.target.value,
                                        roomIndex,
                                        paxIndex,
                                        "lastName"
                                      );
                                    }}
                                    size="large"
                                  />
                                </Form.Item>
                              </Col>
                              {pax.guestType === "Child" ? (
                                <Col md={5} sm={12} xs={24}>
                                  <Form.Item
                                    name={`childAge_${roomIndex}_${paxIndex}`}
                                    label="Child Age"

                                  >

                                    <Input

                                      className="inputbg"
                                      size="large"
                                      value={pax.age}
                                      readOnly
                                    />


                                  </Form.Item>
                                </Col>
                              ) : (
                                <Col md={5} sm={12} xs={24}>
                                  <Form.Item
                                    name={`adultage_${roomIndex}_${paxIndex}`}
                                    label="Age"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Required",
                                      },
                                      {
                                        type: "number",
                                        transform: (value) =>
                                          value >= 2 && value <= 12
                                            ? value
                                            : undefined, // Age must be between 0 and 12
                                        message: "Age must be Older than 12",
                                      },

                                    ]}
                                  >

                                    <Input
                                      placeholder="Enter Your Age"
                                      className="inputbg"
                                      onChange={(e) => {
                                        handlePaxField(
                                          e.target.value,
                                          roomIndex,
                                          paxIndex,
                                          "age"
                                        );
                                      }}
                                      size="large"
                                    />
                                  </Form.Item>
                                </Col>
                              )}
                              {pax.hasOwnProperty("pan") && (
                                <Col md={8} sm={12} xs={24}>
                                  <Form.Item

                                    label={
                                      <span>
                                        PAN Number &nbsp;
                                        {pax.guestType === "Child" ?
                                          <Tooltip title="Add Pancard No Same As Adult">
                                            <QuestionCircleOutlined />
                                          </Tooltip> : null}
                                      </span>
                                    }
                                    rules={[
                                      {
                                        required: true,
                                        // message: "Required",
                                      },
                                      {
                                        pattern:
                                          "^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$",
                                        message:
                                          "Please Enter A Valid PAN No",
                                      },
                                    ]}
                                    name={`pan_${roomIndex}_${paxIndex}`}
                                  >
                                    <Input
                                      size="large"
                                      className="inputbg"
                                      placeholder="PAN Number"
                                      onInput={(e) => e.target.value = ("" + e.target.value).toUpperCase()}
                                      onChange={(e) => {

                                        handlePaxField(
                                          // capitalizedValue,
                                          e.target.value,
                                          roomIndex,
                                          paxIndex,
                                          "pan"
                                        )
                                      }}

                                    />
                                  </Form.Item>
                                </Col>
                              )}


                              {pax.hasOwnProperty("passportNo") && (
                                <Col md={8} sm={12} xs={24}>
                                  <Form.Item
                                    label="Passport Number"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Required",
                                      },
                                    ]}
                                    name={`passportNo_${roomIndex}_${paxIndex}`}
                                  >
                                    <Input
                                      size="large"
                                      className="inputbg"
                                      placeholder="Passport Number"
                                      onChange={(e) =>
                                        handlePaxField(
                                          e.target.value,
                                          roomIndex,
                                          paxIndex,
                                          "passportNo"
                                        )
                                      }
                                    />
                                  </Form.Item>
                                </Col>
                              )}
                              {pax.hasOwnProperty("passportDOI") && (
                                <Col md={8} sm={12} xs={24}>
                                  <Form.Item
                                    label="Passport DOI"
                                    className="passport-dates"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Required",
                                      },
                                    ]}
                                    name={`passportDOI_${roomIndex}_${paxIndex}`}
                                  >
                                    <DatePicker
                                      placeholder="Issued Date"
                                      size="large"
                                      onChange={(date, dateString) =>
                                        handlePaxField(
                                          dateString,
                                          roomIndex,
                                          paxIndex,
                                          "passportDOI"
                                        )
                                      }
                                      format={"YYYY-MM-DD"}
                                      style={{ width: "100%" }}
                                    />
                                  </Form.Item>
                                </Col>
                              )}
                              {pax.hasOwnProperty("passportDOE") && (
                                <Col md={8} sm={12} xs={24}>
                                  <Form.Item
                                    label="Passport DOE"
                                    className="passport-dates"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Required",
                                      },
                                    ]}
                                    name={`passportDOE_${roomIndex}_${paxIndex}`}
                                  >
                                    <DatePicker
                                      placeholder="Expiry Date"
                                      size="large"
                                      onChange={(_, dateString) =>
                                        handlePaxField(
                                          dateString,
                                          roomIndex,
                                          paxIndex,
                                          "passportDOE"
                                        )
                                      }
                                      format={"YYYY-MM-DD"}
                                      style={{ width: "100%" }}
                                    />
                                  </Form.Item>
                                </Col>
                              )}
                            </Row>
                          </div>
                        ))}

                      </Card>
                    ))}{" "}


                  </Form>
                ) : null}
                <Card className="guest-details-form hotel-card-wrapper">
                  <div className="contact-header">

                    <Form
                      layout="vertical"
                      name="contactForm"
                      form={contact_form}
                      scrollToFirstError={true}
                      initialValues={{ areaCode: agent ? "" : "" }}
                      requiredMark={false}
                    >
                      <div className="guest-input-wrapper">
                        <div style={{ background: "#f9f9f9", padding: 10, fontSize: 18, fontWeight: 700, fontFamily: "Nunito" }}>
                          <p className="bk-cntct">Contact Details</p>
                        </div>
                        <Row gutter={16} style={{ padding: 10 }}>
                          <Col md={8} sm={12} xs={24}>
                            <Form.Item
                              label="Email"
                              name="email"
                              rules={[
                                { required: true, message: "Required" },
                                { type: "email", message: "Invalid Email" },
                              ]}
                            >
                              <Input
                                size="large"
                                placeholder="Enter Valid Email"
                              />
                            </Form.Item>
                          </Col>
                          <Col md={8} sm={12} xs={24}>
                            <Form.Item
                              label="Phone number"
                              name="phoneNo"
                              className="phno"
                              rules={[
                                {
                                  required: true,
                                  message: "Required",
                                },

                              ]}
                            >
                              <Input
                                placeholder="Enter Mobile Number"
                                size="large"
                                ref={mobileRef}
                                addonBefore={
                                  <Form.Item
                                    style={{ width: "35%" }}
                                    name="areaCode"
                                    className="phno"
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "Phone Number Code Required",
                                      },
                                    ]}
                                    noStyle
                                  >
                                    <Select
                                      showSearch
                                      placeholder="Select "
                                      style={{ width: "100%" }}
                                      onChange={handleAreaCodeChange}
                                      ref={areaCodeRef}

                                      focusRef={mobile}
                                      filterOption={(input, option) =>
                                        option.children
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }
                                    >
                                      {CountryList.map((item) => (
                                        <Option
                                          key={item.dial_code}
                                          value={item.dial_code}
                                        >
                                          {item.dial_code}
                                        </Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                }
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                              />
                            </Form.Item>
                          </Col>

                          <Col md={8} sm={12} xs={24}>
                            <Form.Item
                              name="addressLine1"
                              label="Address"
                              rules={[
                                {
                                  required: true,
                                  message: "Required",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Enter Your Address"
                                size="large"
                              />
                            </Form.Item>
                          </Col>



                          <Col md={8} sm={12} xs={24}>
                            <Form.Item
                              name="countryCode"
                              label="Country"
                              rules={[
                                {
                                  required: true,
                                  message: "Required",
                                },
                              ]}
                            >
                              <Select
                                size="large"
                                showSearch
                                placeholder="Select Country"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {CountryList.map((item) => (
                                  <Option key={item.code} value={item.code}>
                                    {item.name}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                    </Form>
                  </div>
                </Card>

                {(hotelPriceData?.isDepartureDetailsMandatory ||
                  hotelPriceData?.isPackageDetailsMandatory) && (
                    <div className="extra-pax-det">
                      <div className="contact-header">
                        <div className="contact-title">
                          <p className="booking-summary-div">Package Details</p>
                        </div>
                      </div>
                      <Card className="pax-details-card">
                        {hotelPriceData?.isDepartureDetailsMandatory && (
                          <div className="guest-input-wrapper">
                            <p className="arriv-d"> Arrival Details</p>


                            <Radio.Group
                              onChange={updateArrivalType}
                              value={arrivalType}
                              style={{ margin: "1%" }}
                            >
                              <Radio value={0} style={{ fontWeight: "bold" }}>
                                Arriving By Flight
                              </Radio>
                              <Radio value={1} style={{ fontWeight: "bold" }}>
                                {" "}
                                Arriving By Surface
                              </Radio>
                            </Radio.Group>
                            <Form
                              autoComplete="off"
                              layout="vertical"
                              name="arrivalform"
                              form={arrival_form}
                              scrollToFirstError={true}
                            >
                              <div className="guest-input-arr">
                                <Row gutter={16}>
                                  {arrivalType == 0 ? (
                                    <Col md={8} sm={12} xs={24}>
                                      <Form.Item
                                        label="Flight No"
                                        name="FlightNo"

                                      >
                                        <Input
                                          size="large"
                                          placeholder="Enter Your Flight Code"
                                          autoComplete="off"
                                        />
                                      </Form.Item>
                                    </Col>
                                  ) : (
                                    <Col md={8} sm={12} xs={24}>
                                      <Form.Item
                                        label="Transport Type/No"
                                        name="TransportTypeNo"

                                      >
                                        <Input
                                          size="large"
                                          placeholder="Transport Type / Vehicle No"
                                          autoComplete="off"
                                        />
                                      </Form.Item>
                                    </Col>
                                  )}

                                  <Col md={8} sm={12} xs={24}>
                                    <Form.Item
                                      label="Date:"
                                      name="DateTime"
                                      className="DateTime"
                                    >

                                      <DatePicker size="large" />
                                    </Form.Item>
                                  </Col>

                                  <Col md={8} sm={12} xs={24}>
                                    <Form.Item
                                      label=" Time:"
                                      name="Time"
                                      className="DateTime"
                                    >

                                      <TimePicker size="large" />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </div>
                            </Form>
                          </div>
                        )}
                        {hotelPriceData?.isPackageDetailsMandatory && (
                          <div className="guest-input-wrapper mt-4">
                            <p className="dep-det"> Departure Details</p>

                            <Radio.Group
                              onChange={updateTranportType}
                              value={transportType}
                              style={{ margin: "1%" }}
                            >
                              <Radio value={0} style={{ fontWeight: "bold" }}>
                                {" "}
                                Departing By Flight
                              </Radio>
                              <Radio value={1} style={{ fontWeight: "bold" }}>
                                {" "}
                                Departing By Surface
                              </Radio>
                            </Radio.Group>
                            <Form
                              autoComplete="off"
                              layout="vertical"
                              name="departureform"
                              form={departure_form}
                              scrollToFirstError={true}
                            >
                              <div className="guest-input-dep">
                                <Row gutter={16}>
                                  {transportType == 0 ? (
                                    <Col md={8} sm={12} xs={24}>
                                      <Form.Item
                                        label="Flight No"
                                        name="FlightNo"

                                      >
                                        <Input
                                          size="large"
                                          placeholder="Enter Your Flight Code"
                                          autoComplete="off"
                                        />
                                      </Form.Item>
                                    </Col>
                                  ) : (
                                    <Col md={8} sm={12} xs={24}>
                                      <Form.Item
                                        label="Transport Type/No"
                                        name="TransportTypeNo"

                                      >
                                        <Input
                                          size="large"
                                          placeholder="Transport Type / Vehicle No"
                                          autoComplete="off"
                                        />
                                      </Form.Item>
                                    </Col>
                                  )}

                                  <Col md={8} sm={12} xs={24}>
                                    <Form.Item
                                      label="Date:"
                                      name="DateTime"
                                      className="DateTime"
                                    >
                                      {/* <DatePicker onChange={onChange} /> */}
                                      <DatePicker size="large" />
                                    </Form.Item>
                                  </Col>

                                  <Col md={8} sm={12} xs={24}>
                                    <Form.Item
                                      label=" Time:"
                                      name="Time"
                                      className="DateTime"
                                    >
                                      {/* <TimePicker onChange={onChange} defaultOpenValue={dayjs('00:00:00', 'HH:mm:ss')} /> */}
                                      <TimePicker size="large" />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </div>
                            </Form>
                          </div>
                        )}
                      </Card>
                    </div>
                  )}
              </Col>

              <Col md={8} sm={24} xs={24}>
                {/* <Card className="fare-card-hot"> */}
                <div className="hotel-price-wrapper">

                  {loading ? (
                    <Card bordered={false} className="hotel-card-wrapper">
                      <Skeleton active />
                    </Card>
                  ) : Object.keys(hotelPriceData).length > 0 ? (
                    <HotelFairBox
                      hotelDetailsObj={hotelPriceData}
                      hotelSearchData={hotelSearchData}
                      isPromoVisible={true}
                      location={location}
                    />
                  ) : null}
                </div>
                {/* </Card> */}
              </Col>
            </Row>
            <Row gutter={16}>
              <Col sm={24} md={16}>
                <HotelBookPayCard
                  isLoading={loading}
                  pgIsLoading={false}
                  holdLoading={false}
                  purchaseType={false}
                  bookpaycardinfo={"hotel-checkout"}
                  redirectToPreview={redirectToPreview}
                  agent={false}
                />
              </Col>
            </Row>
          </div>
        </div>
      </div >
    </>
  );
};

export default HotelCheckout;

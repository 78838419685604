import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Modal, Result, Row, message, Spin } from "antd";
import APIClient from "../../helpers/ApiClient";
import EncHelper from "../../helpers/EncHelper";
import queryString from "query-string";
import { useAuthContext } from "../../common/providers/AuthProvider";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

import "./Login.scss";
import logo from "../../assets/images/Anjmallogo.jpg";
import logo1 from "../../assets/images/image1.jpg";
import right from "../../assets/images/double-right-96.png";

import GoogleButton from 'react-google-button';
import {
  TeamOutlined,
  BookOutlined,
  BlockOutlined,
  PrinterOutlined,
  InfoCircleOutlined,
  CheckCircleOutlined,
  LoadingOutlined,
  LeftOutlined

} from "@ant-design/icons";
import { auth, provider, fbAuthProvider } from "./firebase";
import { signInWithPopup } from "firebase/auth";

const CryptoJS = require("crypto-js");
const CryptoKey = process.env.REACT_APP_LOGIN_SECRETKEY;

const Login = ({
  userInfo = false,
  location,
  setModalVisible,
  type = "USER",
}) => {
  const {
    extraUserInfo,
    setExtraUserInfo,
    setUser,
    setAccessToken,
    setAdminAsUser,
    setAccessList,
    updateAgentLoginFlag,
    updateLoginFlag,
    updateDashboardFlag,

    resetIsLogin,
  } = useAuthContext();
  const [form] = Form.useForm();
  const [forgetForm] = Form.useForm();
  const [otpForm] = Form.useForm();
  const history = useHistory();
  const [userId, setUserId] = useState(-1);
  const [showSuccessMessage, setShowSuccesMessage] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [isloading, setisloading] = useState(false);
  const [showPasswordField, setShowPasswordField] = useState(false);
  const [usernamer, setUsernamer] = useState('');
  let extraVal = {
    DeviceToken: "string",
    DeviceType: "Web",
    FirBaseToken: "string",
  };

  const handleClick = () => {
    signInWithPopup(auth, provider)
      .then((data) => {
        // console.log(data, "data");
        request(data);
      })
      .catch((error) => {
        console.log("Error signing in with popup:", error);
      });
  };
  const handleClick1 = () => {
    signInWithPopup(auth, fbAuthProvider)
      .then((data) => {
        // console.log(data, "data");
        request(data);
      })
      .catch((error) => {
        console.log("Error signing in with popup:", error);
      });
  };

  const request = (data) => {
    if (data && data.user && data._tokenResponse) {
      let regObj = {
        Name: data.user.displayName ? data.user.displayName : '',
        Email: data.user.email ? data.user.email : '',
        Mobile: data.user.phoneNumber ? data.user.phoneNumber : '',
        Password: "",
        isloginType: data.providerId === "google.com" ? 2 : data.providerId === "facebook.com" ? 3 : 1,
        Role: 2,
        DeviceToken: "string",
        DeviceType: "Web",
        FirBaseToken: "string",
      };

      // console.log(regObj, " regobj");

      if (data?._tokenResponse?.isNewUser) {
        APIClient.post("admin/user/register", regObj)
          .then((response) => {
            if (response?.status === 200) {
              message.success(response.message);
              verifyOtp1(response.data.userDetails.UserId, data.providerId === "google.com" ? 2 : data.providerId === "facebook.com" ? 3 : 1,);
            } else if (response?.status === 409) {
              message.error(response.message);
            }
          })
          .catch((error) => {
            console.log("Error registering user:", error);
          });
      } else {
        attemptLogin(data.user.email, "", true, data.providerId === "google.com" ? 2 : data.providerId === "facebook.com" ? 3 : 1,);
      }
    } else {
      console.log("User data is undefined or missing _tokenResponse");
    }
  };

  const verifyOtp1 = (userId, logintype) => {
    let verifyObj = {
      UserID: userId,
      Otp: "",
      OtpType: 2,
      isloginType: logintype,
    };

    APIClient.post("admin/verifyotp", verifyObj)
      .then((resp) => {
        handleVerifyOtpResponse(resp, logintype);
      })
      .catch((error) => {
        console.log("Error verifying OTP:", error);
      });
  };

  const handleVerifyOtpResponse = (resp, logintype) => {
    if (resp.status === 202) {
      setUser(resp.data.userDetails);
      setAccessToken(resp.accessToken);
      updateLoginFlag(true);
      attemptLogin(resp.data.userDetails.Email, "", true, logintype);
    } else if (resp.status === 201) {
      setModalVisible(false);
      message.success(resp.message, 3);
      history.push("/");
    } else if (resp.status === 400) {
      message.error("Invalid Otp", 3);
    } else if (resp.status === 500) {
      message.error("Unable to Login Please Contact Administration", 3);
    } else if (resp.status === 503) {
      message.error(resp.message, 3);
    }
  };

  const attemptLogin = (username, password, redirect, logintype) => {
    setisloading(false);
    let obj = {
      UserName: username,
      Password: password,
      Role: 2,
      isloginType: logintype,
    };

    APIClient.post("admin/login", obj)
      .then((resp) => {
        handleLoginResponse(resp, redirect);
      })
      .catch((e) => {
        setisloading(false);
      });
  };

  const handleLoginResponse = (resp, redirect) => {
    setisloading(false);
    if (resp.status === 200) {
      setUser(resp.data.userDetails);
      setAccessToken(resp.accessToken);

      if (resp.data.userDetails.Role.RoleLevel === 1) {
        let screens = [];
        resp.data.userDetails.Screens.forEach((element) => {
          screens.push(element.Name);
        });
        setAccessList(screens);
        setModalVisible(false);
        updateDashboardFlag(true);
        if (redirect) {
          history.push("/");
        }
      } else if (resp.data.userDetails.Role.RoleLevel === 3) {
        setModalVisible(false);
        updateAgentLoginFlag(true);
        if (redirect) {
          handleRedirect();
        } else {
          handleAdminAsUser(resp.data.userDetails.UserId);
        }
      } else if (resp.data.userDetails.Role.RoleLevel === 2) {
        setModalVisible(false);
        updateLoginFlag(true);
        if (redirect) {
          handleRedirect();
        } else {
          handleAdminAsUser(resp.data.userDetails.UserId);
        }
      } else {
        resetIsLogin();
      }
    } else if (resp.status === 500) {
      message.error("Unable to Login Please Contact Administration", 3);
    } else if (resp.status === 503) {
      message.error(resp.message, 3);
    } else if (resp.status === 400 || resp.status === 404) {
      message.error(resp.message, 3);
      let errorMsg = resp?.message ? resp?.message : "Wrong Email or Password";
      form.setFields([
        {
          name: "username",
          errors: [" "],
          value: "",
        },
        {
          name: "password",
          errors: [errorMsg],
          value: "",
        },
      ]);
    }
  };





  useEffect(() => {
    form.resetFields();
  }, []);

  useEffect(() => {
    if (userInfo && extraUserInfo) {
      let data = EncHelper.decryptData(extraUserInfo);
      if (data) {
        onFinish(queryString.parse(data), false);
      }
    }
  }, [userInfo, extraUserInfo]);

  const handleRedirect = () => {
    let path = "/";
    if (location) {
      console.log("hemanth")
     path = location?.pathname ?? path;
    }

    history.push(path, {});
  };
  const [login, setLogin] = useState(false);
  const [data, setData] = useState({});
  const [picture, setPicture] = useState("");


  const handleAdminAsUser = (id) => {
    setAdminAsUser({
      status: true,
      id,
    });
    setExtraUserInfo(null);
  };

  const onFinish = (values, redirect = true) => {
    // setUsernamer(values.username)
    // console.log(values, "user")
    // if (showPasswordField) {
      setisloading(true)
      let obj = {
        UserName: values?.username,
        Password: CryptoJS.AES.encrypt(values.password, CryptoKey).toString(),
        ...extraVal,
        Role:2,
        isloginType: 1,
      };
      // console.log(obj, "obj")
      APIClient.post("admin/login", obj)
        .then((resp) => {
          if (resp.status === 200) {
            setUser(resp.data.userDetails);
            setAccessToken(resp.accessToken);

            if (
              resp.data.userDetails.Role.RoleLevel == 1 
            ) {
             
              let screens = [];
              resp.data.userDetails.Screens.forEach((element) => {
                screens.push(element.Name);
              });
              setAccessList(screens);
             
              updateDashboardFlag(true);
              
              if (redirect) {
                handleRedirect();
              }
             // history.push("/");
            } else if (
              resp.data.userDetails.Role.RoleLevel == 3 
            ) {
              setModalVisible(false);
              updateAgentLoginFlag(true);
              if (redirect) {
                handleRedirect();
              } else {
                handleAdminAsUser(values.id);
              }
            } else if (
              resp.data.userDetails.Role.RoleLevel == 2 
            ) {
              setModalVisible(false);
              updateLoginFlag(true);
              if (redirect) {
                handleRedirect();
              } else {
                handleAdminAsUser(values.id);
              }
            } else {
              resetIsLogin();
            }
          } else if (resp.status == 500) {
            message.error("Unable to Login Please Contact Administration", 3);
          } else if (resp.status == 503) {
            message.error(resp.message, 3);
          } else if (resp.status == 400 || resp.status == 404) {
            message.error(resp.message, 3)
            if (type !== "ALL") {
              let errorMsg = resp?.message
                ? resp?.message
                : "Wrong Email or Password";
              form.setFields([
                {
                  name: "username",
                  errors: [" "],
                  value: "",
                },
                {
                  name: "password",
                  errors: [errorMsg],
                  value: "",
                },
              ]);
            }
          }
          setisloading(false)
        })
        .catch((e) => {
          setisloading(false)
        });
    // } else {
    //   setShowPasswordField(true);
    // }
  };

  const [otpModal, setOtpModal] = useState(false);
  const [forgetModal, setForgetModal] = useState(false);
  const handleOk = (e) => {
    setOtpModal(false);
  };

  const handleCancel = (e) => {
    setOtpModal(false);
  };

  const submitforgetForm = (val) => {
    let mobileNumber;
    let email;
    let mobpattern = /^[0-9]{10}$/;
    if (mobpattern.test(Number(val.username))) {
      mobileNumber = val.username;
      email = null;
    }
    let emailpattern = /\S+@\S+\.\S+/;

    if (emailpattern.test(val.username)) {
      mobileNumber = null;
      email = val.username;
    }
    if (!mobileNumber && !email) {
      forgetForm.setFields([
        {
          name: "username",
          errors: ["Wrong Mobile Number or Email"],
        },
      ]);
      return;
    }
    let data = {
      type: "2",
      Mobile: mobileNumber,
      Email: email,
      ...extraVal,

    };
    // console.log(data,"hhhhhh")
    APIClient.post("admin/sendotp", data)
      .then((res) => {
        if (res.status === 200) {
          setUserId(res.data.userDetails.UserId);
          setShowSuccesMessage(false);
          otpForm.resetFields();
          setForgetModal(false);
          setOtpModal(true);
        } else if (res.status === 401) {
          forgetForm.setFields([
            {
              name: "username",
              errors: [res.message],
            },
          ]);
        } else if (res.status === 404) {
          if (!!res.message) message.error(res.message);
        }
      })
      .catch((e) => { });
  };

  const verifyOtp = (val) => {
    let data = {
      UserId: userId,
      ...val,
    };
    APIClient.post("admin/updatePassword", data)
      .then((res) => {
        if (res.status === 200) {
          setShowSuccesMessage(true);
        } else if (res.status === 400) {
          otpForm.setFields([
            {
              name: "Otp",
              errors: ["Invalid OTP"],
            },
          ]);
        }
      })
      .catch((e) => { });
  };

  function handleCaptcha(value) {
    if (value) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }
  const handleBack = () => {
    setShowPasswordField(false);
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 22,
        color: "white",
        marginLeft: "2px"
      }}
      spin
    />
  );

  return (
    <>
    <div style={{display:"flex"}}>
    <div  style={{flex:1}}>
      <img src={logo1} alt="Background" style={{width:"100%",height:"100%",borderTopLeftRadius:"8px",borderBottomLeftRadius:"8px"}} />
      </div>
      {/* <div className="login-header"> */}
    {/* <h3 style={{color:"white",padding:"14px 36px"}}>Login</h3>
    <Link to="/">Home <img src={right} alt="right" style={{width:"18px"}}/> Login</Link> 
  </div> */}
  <div  style={{flex:1}}>
      <Row className="img-pop-login">
        <Col md={18} xs={24}>
          <div className="login-user-box">
            <Form form={form} name="basic" onFinish={onFinish} size="large" style={{width:285,height:458}} className="custom-form">
              <div className="login-text mb-3" style={{ textAlign: 'start' }}>
                <h3>Login</h3>
                <p>Login with</p>
              </div>
<div className="scial">
              <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 16 }}>
                <GoogleButton type="light" onClick={handleClick} style={{ marginBottom: 10, borderRadius: 5 }} />
              </div>

              <div className="line-with-or-container" style={{ textAlign: 'center' }}>
                <div className="line" />
                <div className="or"> OR </div>
                <div className="line" />
              </div>
              </div>

              <Form.Item
              label="Email"
                  name="username"
                  labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: "Required",
                    },
                    {
                      type: "email",
                      message: "Email is not a valid email",
                    },
                  ]}
                >
                  <Input 
                
                    placeholder="Email" />
                </Form.Item>



              <Form.Item
                label="Password"
                name="password"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[{ required: true, message: "Please enter your password!" }]}
              >
                <Input.Password
                  placeholder="Password"
                
                />
              </Form.Item>

              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  className="loginnn"
                  style={{ borderRadius: '6px', boxShadow: '1px 2px 9px #1d19199e', width: '100%', }}
                >
                  {isloading ? <Spin indicator={antIcon} /> : "Login"}
                </Button>
              </div>
            </Form>

            <div className="forgot-text1" style={{ textAlign: 'center', marginTop: 16 }}>
              <p
                className="txt-hy mb-0 my-2"
                style={{ color: '#ef3737', cursor: 'pointer', textAlign: 'center',fontSize:14,fontWeight:600,fontFamily:'Nunito' }}
                onClick={() => {
                  
                 setForgetModal(true);
                }}
              >
                Forgot Your Password?
              </p>
            </div>

            <div className="forgot-text1" style={{ textAlign: 'center' }}>
              <p
                className="txt-hy mb-0 my-2"
                style={{ color: '#000000', cursor: 'pointer', textAlign: 'center',fontSize:14,fontWeight:600,fontFamily:'Nunito' }}
                onClick={() => {
                 
                
                    history.push("/user-registration");
                  
                  setModalVisible(false);
                }}
              >
                New User ? Please Sign Up Here
              </p>
            </div>
          </div>
        </Col>
      </Row>
      </div>
     
      {/* </div> */}

      <Modal
        visible={forgetModal}
        onOk={() => setForgetModal(false)}
        onCancel={() => setForgetModal(false)}
        footer={false}
        className="otp-modal remember-modal"
      >
        <div className="forgot-password">
          <div className="dont-remember-text">
            <h4>Don't remember password?</h4>
          </div>
          <Form
            form={forgetForm}
            onFinish={submitforgetForm}
            style={{ padding: 0 }}
          >
            <Form.Item
              name="username"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="E-mail or Mobile" size="large" />
            </Form.Item>
            <Button type="primary" htmlType="submit">
              I want to set a new password
            </Button>
          </Form>
        </div>
      </Modal>

      <Modal
        visible={otpModal}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        className="otp-modal"
      >
        <div>
          {showSuccessMessage ? (
            <div className="verified-text">
              <Result
                status="success"
                title="Password Changed successfully"
                extra={[
                  <Button
                    type="primary"
                    onClick={() => {
                      setOtpModal(false);
                      setModalVisible(true);
                    }}
                  >
                    Back To login
                  </Button>,
                ]}
              />
            </div>
          ) : (
            <div className="otp-body">
              <p>Enter Details</p>
              <Form form={otpForm} layout="vertical" onFinish={verifyOtp}>
                <Form.Item
                  name="Otp"
                  label="Enter OTP"
                  rules={[{ required: true, message: "OTP Required" }]}
                >
                  <Input placeholder="Enter  OTP" size={"large"} />
                </Form.Item>
                <Form.Item
                  name="Password"
                  label="Enter New Password"
                  rules={[{ required: true, message: "Password Required" }]}
                >
                  <Input.Password placeholder="Enter Password" />
                </Form.Item>

                <div className="otp-continue-btn">
                  <Button type={"primary"} size={"large"} htmlType="submit">
                    Submit
                  </Button>
                </div>
              </Form>
            </div>
          )}
        </div>
      </Modal>
      </div>
    </>
  );
};

export default Login;


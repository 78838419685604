import React from "react";
import { Table, Card, Col, Row } from "antd";
import getBusTicketPrice from "./busHelper";
import "./ticketBus.scss";
import { SafetyCertificateOutlined } from "@ant-design/icons";
import moment from "moment";
import CancellationPolicy from "../../../pages/Buses/BusResults/BusResultCards/CancellationPolicy";

const TicketBus = ({ ticketData }) => {

  let dateFormat = "MMM, DD-YYYY";
  let { baseFare, taxes, serviceCharge, convamount, grandTotal } =
    getBusTicketPrice(ticketData);
  let key = 0;
  const keyGenerate = () => {
    return key++;
  };

  const getStatus = (status) => {
    switch (status) {
      case "BLOCKED": {
        return <span style={{ color: "#FFA500" }}> </span>;
      }
      case "CONFIRMED": {
        return (
          <span style={{ color: "#008000" }}>
            <b> {status}</b>
          </span>
        );
      }
      case "Booked": {
        return (
          <span style={{ color: "#008000" }}>
            <b> {status}</b>
          </span>
        );
      }
      case "PartiallyCancelled": {
        return (
          <span style={{ color: "#008000" }}>
            <b> {status}</b>
          </span>
        );
      }
      case "CREATED": {
        return (
          <span style={{ color: "#f9e218" }}>
            <b> {status}</b>
          </span>
        );
      }
      case "FAILED": {
        return (
          <span style={{ color: "#FF0000" }}>
            <b> {status}</b>
          </span>
        );
      }
      case "Cancelled": {
        return (
          <span style={{ color: "#FF0000" }}>
            <b> {status}</b>
          </span>
        );
      }
      default:
        return;
    }
  };

  const busDetailsColumns = [
    {
      title: "Operator",
      dataIndex: "operator",
      key: "operator",
    },
    {
      title: "Bus Type",
      dataIndex: "busTypeName",
      key: "busTypeName",
    },
    {
      title: "Departure Time",
      dataIndex: "departureTime",
      key: "departureTime",
    },

    {
      title: "Boarding Point",
      dataIndex: "boardingPoint",
      key: "boardingPoint",
    },
    {
      title: "Dropping Point",
      dataIndex: "droppingPoint",
      key: "droppingPoint",
    },
    {
      title: "Source",
      dataIndex: "sourceName",
      key: "sourceName",
    },
    {
      title: "Destination",
      dataIndex: "destinationName",
      key: "destinationName",
    },
  ];

  const travelcolumns = [
    {
      title: "Title",
      dataIndex: "titles",
      key: "titles",
    },
    {
      title: "Name",
      dataIndex: "names",
      key: "names",
    },

    {
      title: "Age",
      dataIndex: "ages",
      key: "ages",
    },

    {
      title: "Gender",
      dataIndex: "genders",
      key: "genders",
    },

    {
      title: "Seat No.",
      dataIndex: "seatNos",
      key: "seatNos",
    },
  ];

  // const CancellationPolicy = (data) => {
  //   const policyStringArray = data?.data;
  //   return (
  //     <div className="cancellation-block-body">
  //       <ul>
  //         <li>
  //           <span className="title">Time Before Departure </span>{" "}
  //           <span className="title last">Cancellation Charges</span>
  //         </li><br />
  //         {policyStringArray.map((data, index) => {
  //           //let val = data?.split(":");
  //           // if (index === 0) {
  //           return (
  //             <li key={index}>
  //               <p>{data?.PolicyString}</p>
  //               {(data?.CancellationChargeType == 2) ? <p className="last">{data?.CancellationCharge}%</p> : null}
  //               {(data?.CancellationChargeType == 3) ? <p className="last">₹{" "}{data?.CancellationCharge}</p> : null}
  //             </li>
  //           );
  //         })}
  //       </ul>

  //     </div >
  //   );
  // };




  return (
    <>
      <span className="tic-gredient-f" ></span>
      <div className="cms-pages-width ourteam-bg"  >
        <Row>
          <Col md={24} xs={24}>
            <p className="tic-city-name-d2">
            </p>
            <Card className="card-tic-f3 contact-details-hot-det">
              <div className="city-from-d2">
                <div className="city-f3">
                  <p> {ticketData.sourceName}</p>
                  <span>
                    Source
                  </span>
                </div>
                <div className="city-f3 px-2">
                  <p>
                    <i
                      className="fa fa-long-arrow-right"
                      aria-hidden="true"
                    ></i>
                  </p>
                </div>
                <div className="city-f3">
                  <p> {ticketData.destinationName}</p>
                  <span>
                    Destination
                  </span>
                </div>
                <div className="city-f3 last-date-d3">
                  <p>
                    {ticketData.JourneyDate}
                  </p>
                  <span>Journey Date</span>
                </div>
                <div className="city-f3 last-date-d3">
                  <p>
                    {ticketData.arrivalDate}
                  </p>
                  <span>Arrival Date</span>
                </div>

                <div className="city-f3 last-date-d3">
                  <p>
                    {ticketData.tripType === "roundTrip"
                      ? ticketData.pnr.includes("~")
                        ? ticketData.pnr.split("~")[0]
                        : ticketData.pnr
                      : ticketData.pnr}
                  </p>
                  <span>PNR/Booking No</span>
                </div>

              </div>
              <div className="border-hr"></div>
              <Row className="row-top-f4">
                <Col md={7} xs={24} className="city-f4">
                  <p>{ticketData.bookingRefNo}</p>
                  <span>Ticket Ref Number</span>
                </Col>
                <Col md={7} xs={24} className="city-f4">
                  {getStatus(ticketData.bookingStatus)}<br />

                  <span>Status</span>
                </Col>
                <Col md={6} xs={24} className="city-f4">
                  <p>{ticketData.noOfSeats}</p>
                  <span>Seat(s)</span>
                </Col>
                <Col md={4} xs={24} className="city-f4">
                  <p>Paid</p>
                  <span>Payment Status</span>
                </Col>
              </Row>
            </Card>
            <Card className="card-tic-f3 contact-details-hot-det1">
              <div className="city-from-d2">
                <div className="city-f3">
                  <p> {ticketData.sourceName}</p>
                  <span>
                    Source
                  </span>
                </div>
                <div className="city-f3 px-2">
                  <p>
                    <i
                      className="fa fa-long-arrow-right"
                      aria-hidden="true"
                    ></i>
                  </p>
                </div>
                <div className="city-f3">
                  <p> {ticketData.destinationName}</p>
                  <span>
                    Destination
                  </span>
                </div>
              </div>
              <Row className="details-airlines-contact">
                <Col md={18} xs={12} className="contact-person-d4">
                  <p>Journey Date :  </p>
                </Col>
                <Col md={6} xs={12} className="contact-person-d4">
                  <p> {ticketData.JourneyDate}</p>
                </Col>
              </Row>

              <Row className="details-airlines-contact">
                <Col md={18} xs={12} className="contact-person-d4">
                  <p>Arrival Date :  </p>
                </Col>
                <Col md={6} xs={12} className="contact-person-d4">
                  <p> {ticketData.arrivalDate}</p>
                </Col>
              </Row>

              <Row className="details-airlines-contact">
                <Col md={18} xs={12} className="contact-person-d4">
                  <p>PNR/Booking No :  </p>
                </Col>
                <Col md={6} xs={12} className="contact-person-d4">
                  <p> {ticketData.tripType === "roundTrip"
                    ? ticketData.pnr.includes("~")
                      ? ticketData.pnr.split("~")[0]
                      : ticketData.pnr
                    : ticketData.pnr}</p>
                </Col>
              </Row>



              <div className="border-hr"></div>

              <Row className="details-airlines-contact">
                <Col md={18} xs={12} className="contact-person-d4">
                  <p>Ticket Ref Number :  </p>
                </Col>
                <Col md={6} xs={12} className="contact-person-d4">
                  <p> {ticketData.bookingRefNo}</p>
                </Col>
              </Row>

              <Row className="details-airlines-contact">
                <Col md={18} xs={12} className="contact-person-d4">
                  <p>Status :  </p>
                </Col>
                <Col md={6} xs={12} className="contact-person-d4">
                  <p> {getStatus(ticketData.bookingStatus)}</p>
                </Col>
              </Row>

              <Row className="details-airlines-contact">
                <Col md={18} xs={12} className="contact-person-d4">
                  <p>Seat(s) :  </p>
                </Col>
                <Col md={6} xs={12} className="contact-person-d4">
                  <p> {ticketData.noOfSeats}</p>
                </Col>
              </Row>

              <Row className="details-airlines-contact">
                <Col md={18} xs={12} className="contact-person-d4">
                  <p>Payment Status :  </p>
                </Col>
                <Col md={6} xs={12} className="contact-person-d4">
                  <p> Paid</p>
                </Col>
              </Row>


            </Card>
            <Card className="card-tic-f1 contact-details-hot-det">
              <Row className="info-flight-t22">
                <Col md={24} xs={24} className="city-tt-f4">
                  <p>Bus Details</p>
                  <span>
                    Here are the details of your bus from{" "}
                    {ticketData.sourceName} to {ticketData.destinationName} on{" "}
                    {ticketData.JourneyDate}
                  </span>
                </Col>
              </Row>

              <Row>
                <Col md={10} xs={24} className="city-tt-f5">
                  <div className="logo-f3-text">
                    <div className="city-tt-f4">
                      <p className="word-break">{ticketData.operator}</p>
                      <span>Operator </span>
                    </div>
                  </div>
                </Col>
                <Col style={{ marginLeft: "2.4%" }} md={10} xs={24} className="city-tt-f4">
                  <div style={{ width: "100%" }} className="city-from-d3">
                    <div className="city-f57 ">
                      <p className="word-break">{ticketData.sourceName}</p>
                      <span>{ticketData.departureTime}</span>
                    </div>
                    <div className="ciy-dot">
                      <p className="dotted-line-fli"></p>
                    </div>
                    <div className="city-f57">
                      <p className="word-break">{ticketData.destinationName}</p>
                      <span>{ticketData.ArrivalTime}</span>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="baggagge-bottom-sec">
                <Col md={6} xs={24} className="city-tt-f6">
                  <div className="baggagge-f4">
                    <div className="bus-ticket-info-type">
                      <th>Bus Type</th>
                      <span className="word-break">
                        {ticketData.busTypeName}
                      </span>
                    </div>
                  </div>
                </Col>
                <Col md={6} xs={24} className="city-tt-f6">
                  <div className="baggagge-f4">
                    <div className="bus-ticket-info-type">
                      {/* <th>Departure Time</th>
                      <span>{ticketData.departureTime}</span> */}
                    </div>
                  </div>
                </Col>
                <Col md={6} xs={24} className="city-tt-f6">
                  <div className="baggagge-f4">
                    <div className="bus-ticket-info-type">
                      <th>Boarding Point</th>
                      <span className="word-break">
                        {ticketData.boardingPoint}
                      </span>
                    </div>
                  </div>
                </Col>
                <Col md={6} xs={24} className="city-tt-f6">
                  <div className="baggagge-f4">
                    <div className="bus-ticket-info-type">
                      <th>Droping Point </th>
                      <span className="word-break">
                        {ticketData.droppingPoint}
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
            </Card>
            <Card className="card-tic-f1 contact-details-hot-det1">
              <Row className="info-flight-t22">
                <Col md={24} xs={24} className="city-tt-f4">
                  <p>Bus Details</p>
                  <span>
                    Here are the details of your bus from{" "}
                    {ticketData.sourceName} to {ticketData.destinationName} on{" "}
                    {ticketData.JourneyDate}
                  </span>
                </Col>
              </Row>

              <Row>
                <Col md={10} xs={24} className="city-tt-f5">
                  <div className="logo-f3-text">
                    <div className="city-tt-f4">
                      <p className="word-break">{ticketData.operator}</p>
                      <span>Operator </span>
                    </div>
                  </div>
                </Col>
                <Col style={{ marginLeft: "2.4%" }} md={10} xs={24} className="city-tt-f4">
                  <div style={{ width: "100%" }} className="city-from-d3">
                    <div className="city-f57 ">
                      <p className="word-break">{ticketData.sourceName}</p>
                      <span>{ticketData.departureTime}</span>
                    </div>
                    <div className="city-f55">
                      <p className="dotted-line-fli"></p>
                    </div>
                    <div className="city-f57">
                      <p className="word-break">{ticketData.destinationName}</p>
                      <span>{ticketData.ArrivalTime}</span>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="details-airlines-contact">
                <Col md={18} xs={12} className="contact-person-d4">
                  <p>Bus Type :  </p>
                </Col>
                <Col md={6} xs={12} className="contact-person-d4">
                  <p> {ticketData.busTypeName}</p>
                </Col>
              </Row>

              <Row className="details-airlines-contact">
                <Col md={18} xs={12} className="contact-person-d4">
                  <p>Boarding Point :  </p>
                </Col>
                <Col md={6} xs={12} className="contact-person-d4">
                  <p> {ticketData.boardingPoint}</p>
                </Col>
              </Row>
              <Row className="details-airlines-contact">
                <Col md={18} xs={12} className="contact-person-d4">
                  <p>Droping Point :  </p>
                </Col>
                <Col md={6} xs={12} className="contact-person-d4">
                  <p> {ticketData.droppingPoint}</p>
                </Col>
              </Row>

            </Card>

            <Card className="card-tic-f2 contact-details-hot-det">
              <Row className="details-airlines-contact">
                <Col md={20} xs={24} className="city-tt-f4">
                  <p>Contact Details</p>
                </Col>
              </Row>

              <table style={{ width: "100%" }}>
                <tr>
                  <th>Name</th>
                  <th>Mobile Number</th>
                  <th>Email</th>
                </tr>
                <tr>
                  <td>
                    {ticketData.passengerInfo[0].titles}{" "}
                    {ticketData.passengerInfo[0].names}
                  </td>
                  <td>{ticketData.mobileNo}</td>
                  <td>{ticketData.emailId}</td>
                </tr>
              </table>

            </Card>


            <Card className="card-tic-f2 contact-details-hot-det1">
              <Row className="details-airlines-contact">
                <Col md={20} xs={24} className="city-tt-f4">
                  <p>Contact Details</p>
                </Col>
              </Row>

              <Row className="details-airlines-contact">
                <Col md={18} xs={12} className="contact-person-d4">
                  <p>Name :  </p>
                </Col>
                <Col md={6} xs={12} className="contact-person-d4">
                  <p> {ticketData.passengerInfo[0].titles}{" "}
                    {ticketData.passengerInfo[0].names}</p>
                </Col>
              </Row>

              <Row className="details-airlines-contact">
                <Col md={18} xs={12} className="contact-person-d4">
                  <p>Mobile Number :  </p>
                </Col>
                <Col md={6} xs={12} className="contact-person-d4">
                  <p>{ticketData.mobileNo}</p>
                </Col>
              </Row>

              <Row className="details-airlines-contact">
                <Col md={18} xs={12} className="contact-person-d4">
                  <p>Email :  </p>
                </Col>
                <Col md={6} xs={12} className="contact-person-d4">
                  <p className="email-contactdet-det" >{ticketData.emailId}</p>
                </Col>
              </Row>

            </Card>
            <Card className="card-tic-f2">
              <Row className="details-airlines-contact">
                <Col md={20} xs={24} className="city-tt-f4">
                  <p>Passengers Details</p>
                </Col>
              </Row>

              <table style={{ width: "100%" }}>
                <tr>
                  <th>Name</th>
                  <th>Age</th>
                  <th>Seat No.</th>
                  <th>Gender</th>
                </tr>
                {ticketData.passengerInfo.length > 0
                  ? ticketData.passengerInfo.map((item) => (

                    <tr>
                      <td>{item.titles} {item.names}</td>
                      <td>{item.ages}</td>
                      <td>{item.seatNos}</td>
                      <td>{item.genders === "M" ? "Male" : "Female"}</td>
                    </tr>

                  ))
                  : null}
              </table>
            </Card>


            <Card className="card-tic-f2">
              <Row className="details-airlines-contact">
                <Col md={20} xs={24} className="city-tt-f4">
                  <p>Fare Details</p>
                </Col>
              </Row>

              <Row>
                <Col md={18} xs={12} className="contact-person-d4">
                  <p>Fares</p>
                </Col>
                <Col md={6} xs={12} className="contact-person-d4">
                  <p>INR {baseFare}</p>
                </Col>
              </Row>
              <Row>
                <Col md={18} xs={12} className="contact-person-d4">
                  <p> Taxes</p>
                </Col>
                <Col md={6} xs={12} className="contact-person-d4">
                  <p>INR {taxes}</p>
                </Col>
              </Row>
              <Row>
                <Col md={18} xs={12} className="contact-person-d4">
                  <p>Service Charges</p>
                </Col>
                <Col md={6} xs={12} className="contact-person-d4">
                  <p>INR {serviceCharge}</p>
                </Col>
              </Row>
              <Row>
                <Col md={18} xs={12} className="contact-person-d4">
                  <p>Convenience Fee</p>
                </Col>
                <Col md={6} xs={12} className="contact-person-d4">
                  <p>INR {convamount}</p>
                </Col>
              </Row>
              <Row>
                <Col md={18} xs={12} className="contact-person-d4">
                  <p>PromoDiscount</p>
                </Col>
                <Col md={6} xs={12} className="contact-person-d4">
                  <p>INR {ticketData?.promoData?.DiscountType == 1 ? (baseFare * Number(ticketData?.promoData?.Discount) / 100).toFixed(2) : Number(ticketData?.promoData?.Discount)}</p>
                </Col>
              </Row>
              {ticketData.RoleType == 2 ? (
                <Row>
                  <Col md={18} xs={12} className="contact-person-d4">
                    <p>AppliedCouponDiscount</p>
                  </Col>
                  <Col md={6} xs={12} className="contact-person-d4">
                    <p>INR {ticketData?.couponAmount}</p>
                  </Col>
                </Row>
              ) : null}
              <Row>
                <Col md={18} xs={12} className="contact-person-d4">
                  <p>Total</p>
                </Col>
                <Col md={6} xs={12} className="contact-person-d4">
                  <p>INR {grandTotal}</p>
                </Col>
              </Row>

              {ticketData?.Status === 5 || ticketData?.bookingStatus === "Cancelled" ? (

                <Row>
                  <Col md={18} xs={12} className="contact-person-d4">
                    <p>Refund Amount</p>
                  </Col>
                  <Col md={6} xs={12} className="contact-person-d4">
                    <p>INR {ticketData.RefundAmount}</p>
                  </Col>
                </Row>
              ) : null}

            </Card>
            <Card className="card-tic-f2">
              <div className="cancellation-wrapper">
                <Row className="details-airlines-contact">
                  <Col md={20} xs={24} className="city-tt-f4">
                    <p>Cancellation Policy</p>
                  </Col>
                </Row>
                <CancellationPolicy
                  data={ticketData.cancellationPolicy}
                  cancellation={ticketData.partialCancellationAllowed}
                />
              </div>
            </Card>
            <Card className="card-tic-f2">
              <Row>
                <Col md={20} xs={24} className="city-tt-f4">
                  <p><strong>Terms and Conditions</strong></p>
                </Col>
              </Row>

              <Row className="details-airlines-contact booking-flight-details-info pt-0">
                <Col md={24} xs={24} className="contact-person-d4">
                  <div className="termCon_wrapper">

                    <p>
                      GoTraav is ONLY a bus ticket agent. It does not operate bus services of its own. In order to provide a comprehensive choice of bus operators, departure times and prices to customers, it has tied up with many bus operators GoTraav. advices to customers for choosing the bus operators, which they are aware of and whose service they are comfortable with.{" "}
                    </p>
                    <div className="description">
                      <p className="title">1.GoTraav responsibilities include:</p>
                      <p>
                        (1)	Issuing a valid ticket (a ticket that will be accepted by the bus operator) for its network of bus operators.
                      </p>

                      <p>
                        (2)	Providing refund and support in the event of cancellation.
                      </p>
                      <p>
                        (3)	Providing customer support and information in case of any delays / inconvenience.
                      </p>
                      <p className="title">
                        2.GoTraav responsibilities do NOT include:
                      </p>
                      <p>
                        (1)	The bus operator's bus not departing / reaching on time.
                      </p>
                      <p>
                        (2)	The bus operator's employees being rude.
                      </p>
                      <p>
                        (3)	The bus operator's bus seats etc not being up to the customer's expectation.
                      </p>
                      <p>
                        (4)	The bus operator cancelling the trip due to unavoidable reasons.
                      </p>
                      <p>
                        (5)	The baggage of the customer getting lost / stolen / damaged.
                      </p>
                    </div>
                    <p>
                      3.	The departure time mentioned on the ticket is only tentative timings. However the bus will not leave the source before the time that is mentioned on the ticket.
                    </p>
                    <p>
                      4.	Passengers are required to furnish the following at the time of boarding the bus:
                    </p>
                    <div className="description">
                      <p>
                        (1)	A copy of the ticket (A print out of the ticket or the print out of the ticket e-mail).
                      </p>
                      <p>
                        (2)	A valid identity proof
                      </p>
                      <p>
                        Failing to do so, they may not be allowed to board the bus.
                      </p>
                    </div>
                    <p>Failing to do so, they may not be allowed to board the bus.</p>
                    <p>
                      5. In case one needs the refund to be credited back to his/her bank account, please write your cash coupon details to * The discount
                    </p>
                    <p>
                      6. Delivery charges (if any), will not be refunded in the event of ticket cancellation.
                    </p>
                    <p>
                      7. In case a booking confirmation e-mail and SMS gets delayed or fails because of technical reasons or as a result of incorrect e-mail ID/phone number provided by the user etc, a ticket will be considered 'booked' as long as the ticket shows up on the confirmation page of GoTraav
                    </p>
                    <p>
                      8. Grievances and claims related to the bus journey should be reported to GoTraav. support team within 2 days of your travel date.
                    </p>
                    <p><strong>Disclaimer</strong></p>
                    <p>
                      1.	In case of any sudden breakdown or unforeseen incident, the company will not be liable for any compensation or damages.
                    </p>
                    <p>
                      2.	The Company will not be responsible for any stolen luggage, compensation or damages, if occurred during your journey. Nonetheless, the company will help you in every possible manner to recover from the situation.
                    </p>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default TicketBus;

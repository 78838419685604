import {
  CaretDownOutlined,
  RightOutlined,

  SyncOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Dropdown, Menu, Modal } from "antd";
import moment from "moment";
import React, { useEffect, useState, useCallback } from "react";
import { Link, NavLink, useHistory, withRouter } from "react-router-dom";
import { useAuthContext } from "../../common/providers/AuthProvider";
import { useSytContext } from "../../common/providers/SytProvider";
import Login from "../../components/Login/Login";
import APIClient from "../../helpers/ApiClient";
import { useCurrencyContext } from "../providers/CurrencyProvider";
import "../navbar/Navbar.scss";
//import { useCurrencyContext } from "../providers/CurrencyProvider";
import FlagsList from "./FlagsList";
import hoticon from '../../assets/images/hotels/ht.png';
import LOGO from '../../assets/images/OutcL1.png';
// import LOGO from '../../assets/images/outclog1.png'
import Icon1 from '../../assets/images/Icons/fliIc.png'
import Icon2 from '../../assets/images/Icons/4.png'
import Icon3 from '../../assets/images/Icons/5.png'
const { SubMenu } = Menu;
const Nav = ({ location }) => {
  const {
    logo,
    popularCFFleets,
    cruiseTypes,
    popularVisaList,
    countryCruiseList,
    countryTourList,
    categoryList,
    popularActivityList,
  } = useSytContext();
  const BASE = process.env.REACT_APP_BASE_URL;
  const {
    isLogin: { flag, agent, isGuest },
    user,
    setUser,
    resetIsLogin,
  } = useAuthContext();

  let services = user?.Services ? user?.Services : [];
  const { activeCurrency, currencyValue } = useCurrencyContext();

  let history = useHistory();
  const [fetchingWallet, setFetchingWallet] = useState(false);
  const [navToggle, setNavToggle] = useState(false);

  const logout = () => {
    resetIsLogin();
    history.push("/");
  };



  const [colorChange, setColorchange] = useState(false);
  const changeNavbarColor = () => setColorchange(window.scrollY >= 150);
  useEffect(() => {
    window.addEventListener('scroll', changeNavbarColor);
    return () => {
      window.removeEventListener('scroll', changeNavbarColor);
    };
  }, []);
  // window.addEventListener('scroll', changeNavbarColor);

  const toggleCount = () => {
    setNavToggle((prev) => !prev);
  };

  const menuicon = (
    <Menu>
      <div>
        <p style={{ padding: "5px" }}>Comming Soon</p>
      </div>

    </Menu>
  );
  const mainicon = (
    <Menu>
      <Menu.Item key="/flight">
        <Link to="/">Flight</Link>
      </Menu.Item>
    </Menu>
  );

  const getwalletBalance = () => {
    // if (user && (agent || flag)) {
    //   setFetchingWallet(true);
    //   APIClient.get(`admin/GetAgentWalletDetails/${user.UserID}`)
    //     .then((resp) => {
    //       if (resp.status == 200) {
    //         setUser((prev) => ({ ...prev, Walletdetails: resp.data }));
    //       }
    //       setFetchingWallet(false);
    //     })
    //     .catch((error) => {
    //       setFetchingWallet(false);
    //     });
    // }

    if (user && flag) {
      setFetchingWallet(true);
      APIClient.get(`admin/GetUserWalletDetails/${user.UserID}`)
        .then((resp) => {
          if (resp.status == 200) {
            setUser((prev) => ({ ...prev, Walletdetails: resp.data }));
          }
          setFetchingWallet(false);
        })
        .catch((error) => {
          setFetchingWallet(false);
        });
    }
  };

  const [modalVisible, setModalVisible] = useState({
    visible: false,
    type: "USER",
  });

  const showModal1 = (type) => {

    setModalVisible({ visible: true, type: type });
  };


  useEffect(() => {
    if (location?.state) {
      if (location?.state?.login === true) {
        showModal1();
      }
    }
  }, [location]);



  const Balance = (
    <Menu>
      <div className="nav-wallet-wrapper">
        {agent ? (
          <span className="d-flex align-items-center justify-content-between">

            Cash : {user?.Currency}{" "}
            {currencyValue(user
              ? user?.Walletdetails
                ? user?.Walletdetails.Amount
                : "0"
              : "", user?.Currency)}
            {"  "}
            <SyncOutlined
              spin={fetchingWallet}
              style={{ marginLeft: "10px" }}
              onClick={() => {
                if (!fetchingWallet) getwalletBalance();
              }}
            />
          </span>
        ) : (
          <span className="d-flex align-items-center justify-content-between">
            Balance : {activeCurrency}
            {currencyValue(user
              ? user?.Walletdetails
                ? user?.Walletdetails.Amount
                : "0"
              : "", user?.Currency)}
            {"  "}
            <SyncOutlined
              spin={fetchingWallet}
              style={{ marginLeft: "10px" }}
              onClick={() => {
                if (!fetchingWallet) getwalletBalance();
              }}
            />
          </span>
        )}
      </div>

      {user && user?.Role?.RoleLevel == 3 ? (
        <>
          {user?.Walletdetails?.CreditAmount >= 0 ? (
            <div className="nav-wallet-wrapper">
              <b>Credit Amount :{user.Currency} {currencyValue(user?.Walletdetails?.CreditAmount, user.Currency)}</b>
            </div>
          ) : null}
          {user ? (
            user?.Walletdetails?.ExpiryDate ? (
              <div className="nav-wallet-wrapper">
                <p className="mb-0">
                  Credit Expires :{" "}
                  {user?.Walletdetails?.ExpiryDate
                    ? moment(user?.Walletdetails?.ExpiryDate).format(
                      "DD-MM-YYYY"
                    )
                    : ""}
                </p>
              </div>
            ) : null
          ) : null}

          <Menu.Item key="15">
            <NavLink
              exact
              activeClassName="selectedNav"
              className="cre-amount-14"
              to="/deposits"
            >
              TOPUP
            </NavLink>
          </Menu.Item>
        </>
      ) : null}
    </Menu>
  );


  const loginMenu = (
    <Menu className="menu-bdr-nav">
      <Menu.Item key="1">
        <NavLink exact activeClassName="selectedNav" to="/profile">
          My Profile
        </NavLink>
      </Menu.Item>

      <SubMenu key="2" title="Reports" className="border-bottom-1">
        <Menu.Item key="2-1">

          <NavLink exact activeClassName="" to="/booking-reports?type=Flight">
            Flight Reports
          </NavLink>

        </Menu.Item>
        {/* <Menu.Item key="2-5">
          
          <NavLink exact activeClassName="" to="/booking-reports?type=Cars">
            Cars Reports
          </NavLink>
          
        </Menu.Item> */}
        {/* <Menu.Item key="2-3">
         
          <NavLink exact activeClassName="" to="/booking-reports?type=Visa">
            Visa Reports
          </NavLink>
        
        </Menu.Item> */}
        <Menu.Item key="2-3">
          <NavLink exact activeClassName="" to="/booking-reports?type=Bus">
            Bus Reports
          </NavLink>
        </Menu.Item>
        <Menu.Item key="2-2">
          {/* {(user?.Role?.Name === "Agent" && services.includes("2")) || isGuest ? */}
          <NavLink exact activeClassName="" to="/booking-reports?type=Hotel">
            Hotel Reports
          </NavLink>
          {/* :null} */}
        </Menu.Item>
        {/* <Menu.Item key="2-4">
          {/* {(user?.Role?.Name === "Agent" && services.includes("5")) || isGuest ? */}
        {/* <NavLink exact activeClassName="" to="/booking-reports?type=Transfer">
            Transfer Reports
          </NavLink> */}           {/* // :null} */}
        {/* </Menu.Item> */}
        {/* <Menu.Item key="2-4">
          <NavLink exact activeClassName="" to="/booking-reports?type=Activity">
            Activities Reports
          </NavLink>
        </Menu.Item> */}
        {/* <Menu.Item key="2-18" className="">
          <NavLink exact activeClassName="" to="/r&b-orders">
            R&b Reports
          </NavLink>
        </Menu.Item> */}
      </SubMenu>
      {/* <Menu.Item key="3">
        <NavLink exact activeClassName="selectedNav" to="/mypackage">
          My Package
        </NavLink>
      </Menu.Item> */}
      <Menu.Item key="4">
        <NavLink exact activeClassName="selectedNav" to="/wallet">
          Wallet
        </NavLink>
      </Menu.Item>
      {/* <Menu.Item key="5">
        <NavLink exact activeClassName="selectedNav" to="/coupon-wallet">
          Coupon Wallet
        </NavLink>
      </Menu.Item> */}
      <Menu.Item key="6">
        <NavLink exact activeClassName="selectedNav" to="/traveller-details">
          Passenger Details
        </NavLink>
      </Menu.Item>
      <Menu.Item key="7">
        <NavLink exact activeClassName="selectedNav" to="/transaction-reports">
          Transaction Reports
        </NavLink>
      </Menu.Item>
      {user ? user.isloginType === 1 ?
        <Menu.Item key="8" className="border-bottom-0">
          <NavLink exact activeClassName="selectedNav" to="/change-password">
            Change Password
          </NavLink>
        </Menu.Item> : null : ""}

    </Menu>
  );

  // const AgentMenu = (
  //   <Menu className="menu-bdr-nav">
  //     <Menu.Item key="1">
  //       <NavLink exact activeClassName="selectedNav" to="/myprofile">
  //         My Profile
  //       </NavLink>
  //     </Menu.Item>
  //     <Menu.Item key="2">
  //       <NavLink exact activeClassName="selectedNav" to="/commission-details">
  //         My Commissions
  //       </NavLink>
  //     </Menu.Item>
  //     <Menu.Item key="3">
  //       <NavLink exact activeClassName="selectedNav" to="/bank-details">
  //         Bank Details
  //       </NavLink>
  //     </Menu.Item>
  //     <Menu.Item key="4">
  //       <NavLink exact activeClassName="selectedNav" to="/agent-markup">
  //         Markups
  //       </NavLink>
  //     </Menu.Item>
  //     <Menu.Item key="5">
  //       <NavLink exact activeClassName="selectedNav" to="/deposits">
  //         Deposits
  //       </NavLink>
  //     </Menu.Item>
  //     {/* <Menu.Item key="5">
  //       <NavLink exact activeClassName="selectedNav" to="/agent-logo">
  //         Logo
  //       </NavLink>
  //     </Menu.Item> */}
  //     {/* <Menu.Item key="6">
  //       <NavLink exact activeClassName="selectedNav" to="/store-vistors">
  //         Store Visitors
  //       </NavLink>
  //     </Menu.Item> */}
  //     <SubMenu key="7" title="Reports" className="border-bottom-1">
  //       <Menu.Item key="7-1">
  //         {(user?.Role?.Name === "Agent" && services.includes("1")) || isGuest ?
  //           <NavLink exact activeClassName="" to="/booking-reports?type=Flight">
  //             Flight Reports
  //           </NavLink> : null}
  //       </Menu.Item>

  //       {/* <Menu.Item key="7-3">
  //         <NavLink exact activeClassName="" to="/booking-reports?type=Bus">
  //           Bus Reports
  //         </NavLink>
  //       </Menu.Item> */}
  //       <Menu.Item key="7-2">
  //         {(user?.Role?.Name === "Agent" && services.includes("2")) || isGuest ?
  //           <NavLink exact activeClassName="" to="/booking-reports?type=Hotel">
  //             Hotel Reports
  //           </NavLink> : null}
  //       </Menu.Item>
  //       <Menu.Item key="7-3">
  //         {(user?.Role?.Name === "Agent" && services.includes("7")) || isGuest ?
  //           <NavLink exact activeClassName="" to="/booking-reports?type=Transfer">
  //             Transfer Reports
  //           </NavLink> : null}
  //       </Menu.Item>
  //       {/* <Menu.Item key="7-4">
  //         <NavLink exact activeClassName="" to="/booking-reports?type=Activity">
  //           Activities Reports
  //         </NavLink>
  //       </Menu.Item> */}
  //       {/* <Menu.Item key="7-4">
  //         {(user?.Role?.Name === "Agent" && services.includes("7")) || isGuest ?
  //           <NavLink exact activeClassName="" to="/booking-reports?type=Cars">
  //             Cars Reports
  //           </NavLink> : null}
  //       </Menu.Item>
  //       <Menu.Item key="7-6">
  //         {(user?.Role?.Name === "Agent" && services.includes("8")) || isGuest ?
  //           <NavLink exact activeClassName="" to="/booking-reports?type=Visa">
  //             Visa Reports
  //           </NavLink> : null}
  //       </Menu.Item> */}
  //       {/* <Menu.Item key="7-18" className="">
  //         <NavLink exact activeClassName="" to="/r&b-orders">
  //           R&b Reports
  //         </NavLink>
  //       </Menu.Item> */}
  //     </SubMenu>

  //     {/* <Menu.Item key="8">
  //       <NavLink exact activeClassName="selectedNav" to="/mypackage">
  //         My Package
  //       </NavLink>
  //     </Menu.Item> */}
  //     {/* <Menu.Item key="9">
  //       <NavLink exact activeClassName="selectedNav" to="/coupon-wallet">
  //         Coupon Wallet
  //       </NavLink>
  //     </Menu.Item> */}
  //     <Menu.Item key="13">
  //       <NavLink exact activeClassName="selectedNav" to="/margins">
  //         Margins
  //       </NavLink>
  //     </Menu.Item>
  //     <Menu.Item key="12">
  //       <NavLink exact activeClassName="selectedNav" to="/statements">
  //         Statements
  //       </NavLink>
  //     </Menu.Item>
  //     <Menu.Item key="10" className="border-bottom-0">
  //       <NavLink exact activeClassName="selectedNav" to="/change-password">
  //         Change Password
  //       </NavLink>
  //     </Menu.Item>

  //     {/* <Menu.Item key="11" className="border-bottom-0">
  //       <NavLink to="/" exact activeClassName="selectedNav" onClick={logout}>
  //         Logout
  //       </NavLink>
  //     </Menu.Item> */}
  //   </Menu>
  // );
  // const MainMenu = (
  //   // <Menu className="menu-bdr-nav">
  //   //   <Menu.Item key="21">
  //   //     <div onClick={() => showModal1("USER")}>User Login</div>
  //   //   </Menu.Item>
  //   //   <Menu.Item key="22">
  //   //     <div onClick={() => showModal1("AGENT")}>Partner Login</div>
  //   //   </Menu.Item>
  //   //   {/* <Menu.Item key="23">
  //   //     <div onClick={() => showModal1("CORPORATE")}>Corporate Login</div>
  //   //   </Menu.Item> */}
  //   // </Menu>
  // );

  //Mega Menus
  const TourPackagesMenus = (
    <Menu mode="horizontal">
      <div className="d-flex Mega-Menus ">
        {" "}
        <Menu.ItemGroup title="Category wise Tour Packages" className="px-3">

          {categoryList?.map((item, index) => {
            if (index <= 10) {
              return (
                <Menu.Item
                  icon={<RightOutlined />}
                  key={item.CategoryId + "TourCatMenus"}
                >
                  {" "}
                  <NavLink
                    exact
                    to={`/holidays/results?catId=${item.CategoryId}`}
                  >
                    {item.CategoryName}
                  </NavLink>
                </Menu.Item>
              );
            }
          })}
        </Menu.ItemGroup>
        <Menu.ItemGroup title="Country Tour Packages" className="px-3">
          {Object.keys(countryTourList).length
            ? Object.keys(countryTourList).map((index, key) => {
              return (
                <Menu.Item key={key + "TourMenus"} icon={<RightOutlined />}>
                  <NavLink
                    exact
                    // activeClassName="selectedNav"
                    to={`/holidays/results?countryId=${countryTourList[index][0].CountryID
                      }&countryName=${countryTourList[index][0].CountryName
                      }&travelDate=${moment()
                        .add(1, "days")
                        .format("YYYY-MM-DD")}`}
                  >
                    {index} Tour Packages{" "}
                  </NavLink>
                </Menu.Item>
              );
            })
            : ""}
        </Menu.ItemGroup>
      </div>
    </Menu>
  );

  const VisaMenus = (
    <Menu mode="horizontal">
      <div className="d-flex Mega-Menus ">
        {" "}
        <Menu.ItemGroup title="Popular Visa Countries" className="px-3">
          {/* {console.log(popularVisaList, "listv")} */}
          {Object.keys(popularVisaList).length
            ? Object.keys(popularVisaList)?.map((index, key) => {
              return (
                <Menu.Item icon={<RightOutlined />} key={index + "visaMenus"}>
                  {" "}
                  <NavLink
                    exact
                    to={`/visa/result?country=${popularVisaList[index][0]?.Country}`}
                  >
                    {popularVisaList[index][0]?.Country}
                  </NavLink>
                </Menu.Item>
              );
            })
            : ""}
        </Menu.ItemGroup>
      </div>
    </Menu>
  );
  const CFMenus = (
    <Menu mode="horizontal">
      <div className="d-flex Mega-Menus ">
        {" "}
        <Menu.ItemGroup title="Popular Aircraft's" className="px-3">
          {popularCFFleets?.map((item, index) => {
            if (index <= 10) {
              return (
                <Menu.Item
                  icon={<RightOutlined />}
                  key={item.FleetId + "CFMenus"}
                >
                  {" "}
                  <NavLink
                    exact
                    to={`/charterFlights/results?FleetId=${item.FleetId}`}
                  >
                    {item.AircraftModel}
                  </NavLink>
                </Menu.Item>
              );
            }
          })}
        </Menu.ItemGroup>
      </div>
    </Menu>
  );

  const CruiseMenus = (
    <Menu mode="horizontal">
      <div className="d-flex Mega-Menus ">
        {" "}
        <Menu.ItemGroup title="Popular Cruise Types" className="px-3">
          {Object.keys(cruiseTypes).length
            ? Object.keys(cruiseTypes).map((index, key) => {
              return (
                <Menu.Item icon={<RightOutlined />} key={key + 1}>
                  {" "}
                  <NavLink exact to={`/cruise/results?cruiseName=${index}`}>
                    {index}
                  </NavLink>
                </Menu.Item>
              );
            })
            : ""}
        </Menu.ItemGroup>
        <Menu.ItemGroup title="Country Cruise Packages" className="px-3">
          {Object.keys(countryCruiseList)?.length
            ? Object.keys(countryCruiseList)?.map((index, key) => {
              return (
                <Menu.Item key={key + "cruiseMenus"} icon={<RightOutlined />}>
                  <NavLink
                    exact
                    // activeClassName="selectedNav"
                    to={`/cruise/results?countryId=${countryCruiseList[index][0]?.CountryID
                      }&countryName=${countryCruiseList[index][0]?.CountryName
                      }&travelDate=${moment()
                        .add(1, "days")
                        .format("YYYY-MM-DD")}`}
                  >
                    {index} Cruise Packages{" "}
                  </NavLink>
                </Menu.Item>
              );
            })
            : ""}
        </Menu.ItemGroup>
      </div>
    </Menu>
  );

  const ActivitiesMenus = (
    <Menu mode="horizontal">
      <div className="d-flex Mega-Menus ">
        <Menu.ItemGroup title="Popular Countries & Cities" className="px-3">
          {Object.keys(popularActivityList).length
            ? Object.keys(popularActivityList).map((index, key) => {
              return (
                <Menu.Item
                  key={key + "activitiesMenu"}
                  icon={<RightOutlined />}
                >
                  <NavLink
                    exact
                    // activeClassName="selectedNav"
                    to={`/activities/results?city=${popularActivityList[index][0]?.cityName
                      }-${popularActivityList[index][0]?.cityId}&country=${popularActivityList[index][0]?.countryName
                      }-${popularActivityList[index][0]?.countryId
                      }&travelDate=${moment()
                        .add(1, "days")
                        .format("YYYY-MM-DD")}`}
                  >
                    {popularActivityList[index][0]?.cityName}, {index}
                  </NavLink>
                </Menu.Item>
              );
            })
            : ""}
        </Menu.ItemGroup>
      </div>
    </Menu>
  );

  //End Mega Menus
  const handleDirection = (direction) => {
    localStorage.setItem("direction", direction);
    document.getElementsByTagName("HTML")[0].setAttribute("dir", direction);
  };

  const NavbarLinks = ({ user, isGuest }) => {
    let services = user?.Services ? user?.Services : [];

    return (
      <>

        {colorChange && (
          <>
            {(isGuest || user?.Role?.Name === "Agent") && (
              <li onClick={toggleCount}>
                <NavLink exact style={{ display: 'flex' }} activeClassName="selectedNav" to="/">
                  {/* <i
                className="fa fa-plane nav-itmes-target"
                aria-hidden="true"
                style={{paddingRight:"2px"}}
              ></i> */}
                  <img src={Icon1} alt="" style={{ width: '35px', height: "35px", paddingRight: "2px" }} />
                  {"  "}{(user?.Role?.Name === "Agent" && services.includes("1")) || isGuest ? " Flights" : " Home"}
                </NavLink>
              </li>
            )}

            {(isGuest || services.includes("3")) && (
              <li onClick={toggleCount}>
                <NavLink exact style={{ display: 'flex' }} activeClassName="selectedNav" to="/buses">
                  {/* <i
                className="fa fa-bus nav-itmes-target"
                aria-hidden="true"
                style={{paddingRight:"2px"}}
              ></i> */}
                  <img src={Icon3} alt="" style={{ width: '35px', height: "35px", paddingRight: "2px" }} />
                  {"  "} Buses
                </NavLink>
              </li>
            )}
            {(isGuest || services.includes("2")) && (
              <li onClick={toggleCount}>
                <NavLink exact style={{ display: 'flex' }} activeClassName="selectedNav" to="/hotels">
                  {/* <i
                className="fa fa-hotel nav-itmes-target"
                aria-hidden="true"
              ></i>  */}
                  <img src={Icon2} alt="" style={{ width: '35px', height: "35px", paddingRight: "2px" }} />
                  {"  "} Hotels
                </NavLink>
              </li>
            )}
          </>
        )}
      </>
    );
  };

  return (
    <div className="header-bg">


      <div className={colorChange ? 'header-container' : 'header-containertrans-nor'} >
        <ul className="mov-viw-agent-head">
          <li className="pcvi-wor-hid cart-nav-top">
            {user &&
              Object.keys(user).length > 0 &&
              (flag == true || agent == true) ? (
              <span>
                Hi {user.FirstName}{" "}

              </span>
            ) : null}
          </li>
          {agent == true ? (
            <li className="pcvi-wor-hid cart-nav-top">
              <Dropdown overlay={Balance} trigger={["click"]}>
                <span className="user-btn-wrapper">
                  Balance <CaretDownOutlined className="mr-0" />
                </span>
              </Dropdown>
            </li>
          ) : null}

          {user && !agent ? (
            <li className="pcvi-wor-hid cart-nav-top">
              <Dropdown overlay={Balance} trigger={["click"]}>
                <span className="user-btn-wrapper">
                  Wallet <CaretDownOutlined className="mr-0" />
                </span>
              </Dropdown>
            </li>
          ) : null}
          {flag === true || agent === true ? (
            <li className="pcvi-wor-hid cart-nav-top">
              <NavLink
                to="/"
                exact
                activeClassName="selectedNav"
                onClick={logout}
              >
                Logout
              </NavLink>
            </li>
          ) : null}
        </ul>
        <div className="wrapper">
          <div className="top-nav-sec"   >
            <ul className="all-top-menu">
              <li className="mob-none cart-nav-top">
                {user &&
                  Object.keys(user).length > 0 &&
                  (flag == true || agent == true) ? (
                  <span>
                    Welcome {user.FirstName}{" "}
                    {agent ? `(${user.AgentID})` : null}
                  </span>
                ) : null}
              </li>
              {agent == true ? (
                <li className="mob-none cart-nav-top">
                  <Dropdown overlay={Balance} trigger={["click"]}>
                    <span className="user-btn-wrapper">
                      Balance <CaretDownOutlined className="mr-0" />
                    </span>
                  </Dropdown>
                </li>
              ) : null}

              {user && !agent ? (
                <li className="mob-none cart-nav-top">
                  <Dropdown overlay={Balance} trigger={["click"]}>
                    <span className="user-btn-wrapper">
                      Wallet <CaretDownOutlined className="mr-0" />
                    </span>
                  </Dropdown>
                </li>
              ) : null}
              {flag === true || agent === true ? (
                <li className="mob-none cart-nav-top">
                  <NavLink
                    to="/"
                    exact
                    activeClassName="selectedNav"
                    onClick={logout}
                  >
                    Logout
                  </NavLink>
                </li>
              ) : null}



            </ul>
          </div>

          <header className="header">
            <div className="app-logo">
              <div className="company-logo" onClick={toggleCount}>
                <NavLink exact activeClassName="" to="/">




                  <img src={LOGO} alt="Outc" loading="lazy" />
                  {/* ) : (
                    <img
                      src={
                        require("../../assets/images/Logo.png").default
                      }
                      alt="Outc" loading="lazy"
                    />
                  )} */}
                </NavLink>
              </div>
              <div className="user-icon">
                <input className="menu-btn" type="checkbox" id="menu-btn" />

                <span className="d-block d-md-none mobile-user mob-none">
                  <div className="mobile-user-icon">
                    {flag === true ? (
                      <Dropdown overlay={loginMenu} trigger={["click"]}>
                        <span className="user-btn-wrapper mob-none">
                          <UserOutlined />
                        </span>
                      </Dropdown>
                    ) :
                      // agent === true ? (
                      //   <Dropdown overlay={AgentMenu} trigger={["click"]}>
                      //     <span className="user-btn-wrapper mob-none">
                      //       <UserOutlined />
                      //     </span>
                      //   </Dropdown>
                      // ) 
                      (
                        <span className="user-btn-wrapper mob-none" onClick={showModal1}>
                          <UserOutlined />
                        </span>
                      )}
                  </div>
                </span>
                {/* {!agent ? */}
                <li className="currency-text last-mob-bdr pcvi-wor-hid">
                  <FlagsList />
                </li>
                {/* : null} */}
                <li className="pcvi-wor-hid">
                  {flag ? (
                    <Dropdown overlay={loginMenu} trigger={["click"]}>
                      <span className="user-btn-wrapper my-acc-dropdown">
                        <UserOutlined />
                        <CaretDownOutlined className="mr-0" />
                      </span>
                    </Dropdown>
                  ) : null}
                  {/* // agent ? (
                  //   <Dropdown overlay={AgentMenu} trigger={["click"]}>
                  //     <span className="user-btn-wrapper my-acc-dropdown">
                  //       <UserOutlined />

                  //       <CaretDownOutlined className="mr-0" />
                  //     </span>
                  //   </Dropdown>
                  // ) : null} */}
                </li>
                {/* {agent || user ? (<></>
                ) : (
                  <Dropdown overlay={MainMenu} trigger={["click"]} className='dropdwn d-md-none'>
                    <span className="signin-button-navbar d-md-none" style={{ backgroundColor: 'transparent' }} ><a>
                      <UserOutlined style={{ color: 'black', fontSize: '25px' }} /> <span style={{ color: '#00415c' }} > </span></a>
                    </span>
                  </Dropdown>
                )} */}
                <label className="menu-icon" onClick={toggleCount}>
                  <span className="navicon"></span>
                  <span className="menu-text d-block d-md-none">Menu</span>
                </label>
              </div>
            </div>

            <div className={agent || user ? "app-links" : "app-links1"}   >
              <ul
                className={navToggle ? "mob-viw menu" : "nor-viw menu"}
                style={{ maxHeight: navToggle ? "610px" : "0px" }}
              >
                {/* <li onClick={toggleCount}>
                  <Dropdown overlay={mainicon}>
                    <a
                      className="ant-dropdown-link"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i
                        className="fa fa-plane nav-itmes-target"
                        aria-hidden="true"
                      ></i>
                      Travel
                    </a>
                  </Dropdown>
                </li> */}
                {agent ? (
                  <NavbarLinks user={user} isGuest={false} />
                ) : (
                  <NavbarLinks user={null} isGuest={true} />
                )}



                {/* <li onClick={toggleCount}>
                  <NavLink exact to="/">
                    {" "}
                    <i
                      className="fa fa-plane nav-itmes-target"
                      aria-hidden="true"
                    ></i>
                    Charter Flights
                  </NavLink>
                </li> */}

                {/* <li onClick={toggleCount} className="delas-taget-flight">
                  <NavLink exact activeClassName="selectedNav" to="/deals">
                    {" "}
                    <i
                      className="fa fa-plane nav-itmes-target"
                      aria-hidden="true"
                    ></i>
                    Flight Deals
                  </NavLink>
                </li> */}

                <li
                  id="delas-taget-flight"
                  onClick={toggleCount}
                  className={`${agent ? null : "border-right-0"}`}
                >
                  <NavLink exact activeClassName="selectedNav" to="/offers">
                    <i
                      className="fa fa-tags nav-itmes-target"
                      aria-hidden="true"
                    ></i>
                    Offers
                  </NavLink>
                </li>
                {/* {agent && (
                  <li onClick={toggleCount} className="border-right-0">
                    <NavLink
                      exact
                      activeClassName="selectedNav"
                      to="/statements"
                    >
                      {" "}
                      <i
                        className="fa fa-superpowers nav-itmes-target"
                        aria-hidden="true"
                      ></i>
                      Statements
                    </NavLink>
                  </li>
                )} */}

                {/* <li
                    onClick={() => showModal1("USER")}
                    className="border-right-0"
                    id="only-mob-user"
                  >
                    <NavLink exact activeClassName="selectedNav" to="">
                      User Login
                    </NavLink>
                  </li> */}

                {/* <ul className="d-block d-md-none responive-more">
                  <li className="currency-text currency-panel-text">
                    <FlagsList />
                  </li>
                </ul> */}

              </ul>
              {user ? (
                <ul className="menu1">
                  <li className="border-right-0 mob-none">
                    {flag ? (
                      <Dropdown overlay={loginMenu} trigger={["click"]}>
                        <span className="user-btn-wrapper my-acc-dropdown">
                          <UserOutlined /> My_Account{" "}
                          <CaretDownOutlined className="mr-0" />
                        </span>
                      </Dropdown>
                    ) : null}
                    {/* agent ? (
                      <Dropdown overlay={AgentMenu} trigger={["click"]}>
                        <span className="user-btn-wrapper my-acc-dropdown">
                          <UserOutlined /> My_Account
                          <CaretDownOutlined className="mr-0" />
                        </span>
                      </Dropdown>
                    ) : null} */}
                  </li>
                  {/* {!agent ? */}
                  <li className="currency-text last-mob-bdr mob-none">
                    <FlagsList />
                  </li>
                  {/* : null} */}
                  <li className="border-right-0 mob-none">
                    <div id="google_translate_element"></div>
                  </li>
                </ul>
              ) : (
                <ul className="menu1">
                  {/* <li className="mob-none cart-nav-top" onClick={toggleCount}>
                    <NavLink exact to="/activities/cart">
                      <span className="user-btn-wrapper">
                        <ShoppingCartOutlined />
                        Cart
                      </span>
                    </NavLink>
                  </li> */}


                  {/* {!agent ? */}
                  <li className="currency-text last-mob-bdr mob-none sign-register-css-work1">
                    <FlagsList />
                  </li>
                  {/* : null} */}
                  {/* <li className="border-right-0 mob-none sign-register-css-work1">
                    <div id="google_translate_element"></div>
                  </li> */}
                  <li className="border-right-0 mob-none sign-register-css-work">
                    {/* <Dropdown overlay={MainMenu} trigger={["click"]}> */}
                    {/* <Link to="/login"> */}
                      <span className="user-btn-wrapper" onClick={() => showModal1("USER")}>
                        Sign-In
                        {/* <CaretDownOutlined className="mr-0" /> */}
                      </span>
                    {/* </Link> */}
                    {/* </Dropdown> */}
                  </li>
                </ul>
                // <span
                //   className="user-btn-wrapper login-register-mob"
                //   onClick={showModal1}
                // >
                //   <UserOutlined /> Login | Register
                // </span>
              )}

              {/* {user ? (
                <ul className="menu">
                  <li className="border-right-0 mob-none">
                    {flag ? (
                      <Dropdown overlay={loginMenu} trigger={["click"]}>
                        <span className="user-btn-wrapper">
                          <UserOutlined /> My Account{" "}
                          <CaretDownOutlined className="mr-0" />
                        </span>
                      </Dropdown>
                    ) : agent ? (
                      <Dropdown overlay={AgentMenu} trigger={["click"]}>
                        <span className="user-btn-wrapper">
                          <UserOutlined /> My Account
                          <CaretDownOutlined className="mr-0" />
                        </span>
                      </Dropdown>
                    ) : null}
                  </li>
                </ul>
              ) : (
                <ul className="menu">
                  <li className="border-right-0 mob-none">
                    <Dropdown overlay={MainMenu} trigger={["click"]}>
                      <span className="user-btn-wrapper">
                        My Account
                        <CaretDownOutlined className="mr-0" />
                      </span>
                    </Dropdown>
                  </li>

                  <li className="border-right-0 mob-none">
                    <NavLink exact activeClassName="selectedNav" to="/offers">
                      <span className="user-btn-wrapper">Offers</span>
                    </NavLink>
                  </li>
                </ul>
                // <span
                //   className="user-btn-wrapper login-register-mob"
                //   onClick={showModal1}
                // >
                //   <UserOutlined /> Login | Register
                // </span>
              )} */}
            </div>
          </header>
        </div>
      </div>

      <Modal
        centered
        visible={modalVisible.visible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        className="login-modal"
        footer={false}
      >
        <Login
          location={location}
          setModalVisible={(value) => setModalVisible(value)}
          type={modalVisible.type}
        />
      </Modal>
    </div>
  );
};

export default withRouter(Nav);

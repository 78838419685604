
import "../banner/Banner.scss";
import { Carousel } from "antd";

const Banner = ({ banner = [], size }) => {
  const BASE = process.env.REACT_APP_BASE_URL;
  const images = banner.map((item, index) => (
    <img
      key={`banners${index}`}
      className="d-block w-100"
      src={`${BASE}${item.path.substring(1)}`}
      alt="Slide"
      style={{ opacity: 0.01 }}
      onLoad={({ target }) => (target.style.opacity = 1)}
    />
  ));
  const height = size;
  console.log(height, size, "heightbanner");
  return (
    <div id="banner" className="fh-main-cc2">
      {/* {images.length ? (
        <Carousel autoplay dots={false}>
          {images}
        </Carousel>
      ) : ( */}
      <div
        className="d-block w-100 bn-Img"
        style={{

          borderBottom: "groove",
          height: size,
          // height: "425px",
          // backgroundColor: "#2f81edlinear-gradient(182deg, #320063, #0f76bb)",
          // background: url("../../"),
          borderRadius: "0 0 40% 40%"
        }}
      />
      {/* )} */}
    </div>
  );
};

export default Banner;

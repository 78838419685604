import React, { useState, useEffect } from "react";
import hyderabad from '../../../assets/images/hotels/hyderabad1.jpg';
import chennai from '../../../assets/images/hotels/chennai1.webp';
import mumbai from '../../../assets/images/hotels/mumbai2.webp';
import bangalore from '../../../assets/images/hotels/bangalore1.webp';
import delhi from '../../../assets/images/hotels/delhi2.jpg';
import MumbaiV from '../../../assets/vedio/mumbaivedio.mp4';
import ChennaiV from '../../../assets/vedio/CNI 1 (1).mp4';
import DelhiV from '../../../assets/vedio/del.mp4';
import HyderabadV from '../../../assets/vedio/hyd.mp4';
import BangloreV from '../../../assets/vedio/BLR (1).mp4'
import placeholderImage from '../../../assets/images/hotels/delhi2.jpg';
import './imagegrid.scss';
import { Button, Card, Col, Skeleton,  } from "antd";
import { Link } from "react-router-dom";
import queryString from "query-string";

import moment from "moment";

const tomorrowDate = moment().add(5, 'days').format("YYYY-MM-DD");
const dayafter = moment().add(6, "days").format("YYYY-MM-DD");
const getHotelSer = (cityName, cityId) => {
  const formData = {
    checkInDate: tomorrowDate,
    checkOutDate: dayafter,
    hotelCityCode: `cityName=${cityName}&&cityId=${cityId}`,
    roomGuests: JSON.stringify([{ noOfAdults: 1, noOfChilds: 0, childAge: [] }]),
    nationality: "IN",
    currency: "INR",
    countryCode: "IN",
    traceId: "string",
  };
  const query = queryString.stringify(formData);
  return "/hotels/listing?" + query;
};
const ImageGrid = () => {
  const [isLoading, setIsLoading] = useState(true);

  const handleVideoLoaded = () => {
    setIsLoading(false); // Hide placeholder once video loads
  };
  return (
    <div className="container">


      <div className="grid">
        <div style={{ display: "flex" }}>
          <div className="card">
            <Link to={getHotelSer("Delhi,India", 22846)}>
              <div className="video-container">
                {/* Placeholder image or skeleton */}
                
                  <img
                    src={delhi}
                    alt="Loading placeholder"
                    className="placeholder-image"
                  />
                
                {/* <video
                  src={DelhiV} // Update with your video path
                  autoPlay
                  loop
                  muted
                  onLoadedData={handleVideoLoaded}
                  className={`video-element ${isLoading ? 'hidden' : ''}`} // Hide video until loaded
                /> */}
                <div className="video-overlay">
                  <p className="overlay-text">the capital of India, is a bustling metropolis rich in history and culture. Known for its iconic landmarks like the Red Fort, India Gate, and Qutub Minar, Delhi blends ancient architecture with modern life. It’s a hub for politics, commerce, and vibrant street food, offering a dynamic mix of tradition and contemporary living.
                  </p></div>
              </div>
              <h2>New Delhi 🇮🇳</h2>
            </Link>
          </div>
          {/* <div className="card">
            <Link to={getHotelSer("Bangalore,India", 22327)} >
              <img src={bangalore} alt="Bangalore" />
              <h2>Bangalore 🇮🇳</h2>
            </Link>
          </div> */}
          <div className="card">
            <Link to={getHotelSer("Bangalore,India", 22327)} >
              <div className="video-container">
                {/* Placeholder image or skeleton */}
               
                <img
                  src={bangalore}
                  alt="Loading placeholder"
                  className="placeholder-image"
                />
                {/* <video
                  src={BangloreV} // Update with your video path
                  autoPlay
                  loop
                  muted
                  onLoadedData={handleVideoLoaded}
                  className={`video-element ${isLoading ? 'hidden' : ''}`} // Hide video until loaded
                /> */}
                <div className="video-overlay">
                  <p className="overlay-text">India’s tech capital, home to numerous IT companies and startups. Known for its pleasant climate, green spaces like Cubbon Park, and vibrant pub culture, it seamlessly blends modernity with tradition, making it a hub for innovation and culture in southern India. </p> </div>
              </div>
              <h2>Bangalore 🇮🇳</h2>
            </Link>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          {/* <div className="card">
            <Link to={getHotelSer("Mumbai,India", 22798)} >
              <img src={mumbai} alt="Mumbai" />
              <h2>Mumbai 🇮🇳</h2>
            </Link>
          </div> */}
          <div className="card">
            {/* <Link to={getHotelSer("Delhi,India", 22846)}> */}
            <Link to={getHotelSer("Mumbai,India", 22798)} >
              <div className="video-container">
                {/* Placeholder image or skeleton */}
               
                <img
                  src={mumbai}
                  alt="Loading placeholder"
                  className="placeholder-image"
                />
                {/* <video
                  src={MumbaiV} // Update with your video path
                  autoPlay
                  loop
                  muted
                  onLoadedData={handleVideoLoaded}
                  className={`video-element ${isLoading ? 'hidden' : ''}`} // Hide video until loaded
                /> */}
                <div className="video-overlay">
                  <p className="overlay-text">The bustling financial capital of India, is known for its vibrant blend of culture, historic landmarks, and modern skyscrapers. It's home to Bollywood, stunning beaches, and iconic sites like the Gateway of India.</p>
                </div>
              </div>
              <h2>Mumbai 🇮🇳</h2>
            </Link>
          </div>
          {/* <div className="card">
            <Link to={getHotelSer("Chennai,India", 22401)} >
              <img src={chennai} alt="Chennai" />
              <h2>Chennai 🇮🇳</h2>
            </Link>
          </div> */}
          <div className="card">

            <Link to={getHotelSer("Chennai,India", 22401)} >
              <div className="video-container">
                {/* Placeholder image or skeleton */}
               
                <img
                  src={chennai}
                  alt="Loading placeholder"
                  className="placeholder-image"
                />
                {/* <video
                  src={ChennaiV} // Update with your video path
                  autoPlay
                  loop
                  muted
                  onLoadedData={handleVideoLoaded}
                  className={`video-element ${isLoading ? 'hidden' : ''}`} // Hide video until loaded
                /> */}
                <div className="video-overlay">
                  <p className="overlay-text">vibrant coastal city known for its rich cultural heritage, historic temples, and bustling beaches.</p>
                </div>
              </div>
              <h2>Chennai 🇮🇳</h2>
            </Link>
          </div>
          {/* <div className="card">
            <Link to={getHotelSer("Hyderabad,India", 22555)} >
              <img src={hyderabad} alt="Hyderabad" />
              <h2>Hyderabad 🇮🇳</h2>
            </Link>
          </div> */}
          <div className="card">

            <Link to={getHotelSer("Hyderabad,India", 22555)} >
              <div className="video-container">
                {/* Placeholder image or skeleton */}
                {/* {isLoading && (
                  <Skeleton.Image />
                )} */}
                <img
                  src={hyderabad}
                  alt="Loading placeholder"
                  className="placeholder-image"
                />
                {/* <video
                  src={HyderabadV} // Update with your video path
                  autoPlay
                  loop
                  muted
                  onLoadedData={handleVideoLoaded}
                  className={`video-element ${isLoading ? 'hidden' : ''}`} // Hide video until loaded
                /> */}
                <div className="video-overlay">
                  <p className="overlay-text">known as the "City of Pearls," is a vibrant mix of historical charm and modern innovation. Famous for its iconic landmarks like the Charminar, delicious biryani, and booming tech industry, it offers a unique blend of culture, history, and progress</p>
                </div>
              </div>
              <h2>Hyderabad 🇮🇳</h2>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ImageGrid

import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  Form,
  Row,
  Modal,
  message,
  Spin,
  Image,
} from "antd";

import TicketBus from "./TicketBus";
import LogoImg from "../../../assets/images/OutcLogo-new.png";
import { useAuthContext } from "../../providers/AuthProvider";
import { useSytContext } from "../../providers/SytProvider";
import queryString from "query-string";
import APIClient from "../../../helpers/ApiClient";
import getBusTicketPrice from "./busHelper";
import TicketSidebar from "../TicketSidebar";
import { LoadingOutlined } from "@ant-design/icons";
// import BusDoc from "../../PdfDocuments/Bus/BusDoc";
import "./busTicketScreen.scss";
import CancellationPolicy from "../../../pages/Buses/BusResults/BusResultCards/CancellationPolicy";
// import { PDFViewer } from "@react-pdf/renderer";
// import InvoiceDoc from "../../PdfDocuments/Invoice/InvoiceDoc";

const { Panel } = Collapse;

const BusTicketScreen = ({ mode }) => {
  const {
    user,
    isLogin: { agent },
  } = useAuthContext();
  const { agentLogo, logo } = useSytContext();
  const [form] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const ticketSearchParams = queryString.parse(window.location.search);

  const [ticketData, setTicketData] = useState({});
  const [loadingTicket, setLoadingTicket] = useState(true);

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  let { grandTotal } = getBusTicketPrice(ticketData);
  const BASE = process.env.REACT_APP_BASE_URL;
  const fetchTicketDetails = (ref) => {
    setLoadingTicket(true);
    APIClient.get("buses/ticketDetails/?refNo=" + ref)
      .then((res) => {
        if (res.statusCode == 200) {
          if (mode === "USER") {
            let userId = user?.UserID ?? 1;
            if (res.data.UserId === userId) {
              setTicketData(res.data);
            } else {
              setTicketData({});
            }
          } else {
            setTicketData(res.data);
          }
        } else {
          setTicketData({});
        }
        setLoadingTicket(false);
      })
      .catch((error) => {
        setTicketData({});
        setLoadingTicket(false);
      });
  };

  const cancelTicket = () => {
    setModalVisible(true);
  };

  const submitCancelForm = (val) => {
    if (ticketData?.partialCancellationAllowed === "false") {
      if (ticketData?.passengerInfo?.length != val?.seatNo?.length) {
        message.error("Partial Cancellation Not Allowed");
        return;
      }
    }

    setLoading(true);
    if (ticketData.emailId == "" && ticketData.bookingRefNo == "") {
      return;
    }
    let data = {
      email: ticketData.emailId,
      seatNos: val.seatNo.toString(),
      referenceNo: ticketData.bookingRefNo,
    };
    APIClient.post("buses/cancelTicket/", data)
      .then((res) => {
        setLoading(false);
        if (res.statusCode == 200) {
          message.success(
            `${res.data.Message}. Your total refund amount is Rs. ${res.data.TotalRefundAmount} `,
            10
          );

          getTicketDetails();
          setModalVisible(false);
          form.resetFields();
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  useEffect(() => {
    getTicketDetails();
  }, [ticketSearchParams.ref]);

  const getTicketDetails = () => {
    fetchTicketDetails(ticketSearchParams.ref);
  };

  return (
    <div className="flight-ticket-collapse">
      <div className="print-agentlogo">
        {agent ? (
          agentLogo ? (
            <Image
              className="printImageStyle"
              src={BASE + agentLogo.substring(1)}
            ></Image>
          ) : logo ? (
            <Image
              className="printImageStyle"
              src={BASE + logo.substring(1)}
            ></Image>
          ) : (
            <Image className="printImageStyle" src={LogoImg}></Image>
          )
        ) : logo ? (
          <Image
            className="printImageStyle"
            src={BASE + logo.substring(1)}
          ></Image>
        ) : (
          <Image className="printImageStyle" src={LogoImg}></Image>
        )}
      </div>
      <Card>
        <div className="fligh-ticket-container">
          {loadingTicket ? (
            <div style={{ textAlign: "center" }} className="flight-ticket">
              <Spin indicator={antIcon} tip="Loading..." />
            </div>
          ) : Object.keys(ticketData).length > 0 ? (
            ticketData.BookingStatus === 3 ||
            ticketData.BookingStatus === 5 ||
            ticketData.BookingStatus === 7 ? (
              <div className="flight-ticket">
                {/* <PDFViewer>
                  <BusDoc ticketData={ticketData} />
                </PDFViewer> */}
                <Row gutter={[32, 16]} className="ticket-row">
                  <Col md={18} className="ticket-coloum">
                    <TicketBus ticketData={ticketData} />
                  </Col>

                  <Col xs={24} md={6} className="tic-info-flight">
                    <div className="web-tic-info">
                      <TicketSidebar
                        ticketData={ticketData}
                        ticketSearchParams={ticketSearchParams}
                        type="Bus"
                        onCancelTicket={cancelTicket}
                        getTicketDetails={getTicketDetails}
                      />
                    </div>

                    <div className="mobile-tic-info">
                      <Collapse accordion>
                        <Panel header="Manage Tickets" key="1">
                          <TicketSidebar
                            ticketData={ticketData}
                            ticketSearchParams={ticketSearchParams}
                            type="Bus"
                            onCancelTicket={cancelTicket}
                            getTicketDetails={getTicketDetails}
                          />
                        </Panel>
                      </Collapse>
                    </div>
                  </Col>
                </Row>
              </div>
            ) : (
              <p>No Ticket Found</p>
            )
          ) : (
            <p>No Ticket Found</p>
          )}
        </div>
      </Card>

      <Modal
        title="Cancel Ticket"
        className="promo-modal-header"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={[
          <div>
            <Button
              key="add"
              type="primary"
              onClick={form.submit}
              loading={loading}
            >
              Cancel Ticket
            </Button>
          </div>,
        ]}
        width={"600px"}
      >
        {Object.keys(ticketData).length > 0 ? (
          ticketData.BookingStatus === 3 ||
          ticketData.BookingStatus === 5 ||
          ticketData.BookingStatus === 7 ? (
            <div className="wrapper">
              <p>
                <b>Reference No</b> : {ticketData.bookingRefNo}
              </p>
              <p>
                <b>Journey Details</b> : {ticketData.sourceName} <b>TO </b>
                {ticketData.destinationName} on {ticketData.JourneyDate} (
                {ticketData.operator} - {ticketData.busTypeName} )
              </p>
              <p>
                <b>Total Fare</b> : Rs {grandTotal}
              </p>
              <p className="text-center">
                <b>Cancellation Policy</b>
              </p>
              <div className="cancellation-wrapper">
                <CancellationPolicy
                  data={ticketData.cancellationPolicy}
                  cancellation={ticketData.partialCancellationAllowed}
                />
              </div>

              <Form
                layout="vertical"
                className="bus-cancel-form"
                form={form}
                onFinish={submitCancelForm}
              >
                <Form.Item
                  label="Select Seat No."
                  name="seatNo"
                  rules={[{ required: true, message: "Please Select Seat No" }]}
                >
                  <Checkbox.Group>
                    <Row gutter={[8, 8]}>
                      {ticketData.passengerInfo.length > 0
                        ? ticketData.passengerInfo.map((item, i) => (
                            <Col key={i} md={24} sm={24} xs={24}>
                              <Checkbox value={item.seatNos}>
                                {item.seatNos} ({item.names})
                              </Checkbox>
                            </Col>
                          ))
                        : ""}
                    </Row>
                  </Checkbox.Group>
                </Form.Item>
              </Form>
            </div>
          ) : null
        ) : null}
      </Modal>
    </div>
  );
};
export default BusTicketScreen;
